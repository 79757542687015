/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import { Button } from '@pando-styles/nirvana'
import moment from 'moment'

import { ProgressBar } from '@atomic/index'
import { InstanceShort } from '@utils/index'
import {
  StyledButtonContainer,
  StyledSurveyContainer,
} from '@pages/Main/styles'
import { StyledHeadingThree, StyledParagraph } from '@styles/index'

import { MainActions, MainState } from '@pages/index'

type IncompleteSurveysProps = InstanceShort.AsObject

const IncompleteSurveys: React.FC<IncompleteSurveysProps> = (props) => {
  const {
    guid,
    surveyName,
    surveyDescription,
    totalAnswered = 0,
    totalQuestions = 0,
    dateCreated,
  } = props ?? {}

  const progress = Math.ceil((totalAnswered / totalQuestions) * 100)

  const { providerActions } = useContext(MainActions)
  const { providerState } = useContext(MainState)

  if (!providerActions || !providerState) return null

  const { handleSelectSurvey, handleResumeSurvey, handleNewAttempt } =
    providerActions ?? {}

  return (
    <StyledSurveyContainer
      className='margin--bottom-1 flex-column-at-600'
      key={guid}
    >
      <div className='w-60p w-50p-at-726 w-100p-at-600'>
        <div className='completed-survey-header'>
          <small>
            Sent On:{' '}
            {moment.unix(dateCreated?.seconds).format('DD-MM-YYYY hh:mm a')}{' '}
          </small>
        </div>
        <StyledHeadingThree className='margin--top-1-2 margin--bottom-1-2 teal'>
          {surveyName ?? ''}
        </StyledHeadingThree>
        <StyledParagraph>{surveyDescription ?? ''}</StyledParagraph>
      </div>
      <div className='w-40p w-50p-at-726 w-100p-at-600'>
        <ProgressBar
          progress={progress}
          showProgressText={true}
          bgColor='#D0D5DD'
          isShownOnSurveyQuestions={false}
        />
        <StyledButtonContainer className='flex-row justify-end'>
          <Button
            label='Start Survey'
            block
            hoverColorWeight='300'
            onClick={() => handleSelectSurvey(props)}
            className='font-14'
          />
        </StyledButtonContainer>
      </div>
    </StyledSurveyContainer>
  )
}

export default IncompleteSurveys
