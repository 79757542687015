import styled from 'styled-components'

export const StyledDatePicker = styled.input`
  width: 100%;
  font-size: 80%;
  height: 2.75rem;
  border-radius: 0.375rem;
  border: 0.063rem solid #98a2b3;
  text-align: left;
  padding-left: 10px;
  overflow: hidden;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary.DEFAULT};
    outline: none;
    color: #fff !important;
    ::placeholder {
      color: #fff !important;
    }
  }

  ::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none !important;
    -webkit-appearance: none !important;
  }
`

export const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .custom-date-picker {
    width: 100%;
    font-size: 85%;
    border-radius: 0.375rem;
    text-align: left;
    transition: all 0.2s ease-in-out;
  }

  .date-btn-calendar {
    span {
      padding: 0;
      padding-left: 10px;
    }

    &.calendar-open {
      pointer-events: none;
      touch-action: none;
      cursor: pointer;
    }
  }

  .react-calendar {
    border: none;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }

  .react-daterange-picker__inputGroup__input {
    font-size: 90%;
  }

  .react-calendar__navigation {
    button {
      font-size: 1rem;
    }
  }

  .react-daterange-picker__wrapper {
    border-radius: 0.375rem;
    border: 0.063rem solid #98a2b3;
    color: #98a2b3;
    padding-left: 5px;

    //
    opacity: 0;
    display: none;
    /* pointer-events: none; */
  }

  .react-daterange-picker__inputGroup__input {
    color: #98a2b3;
    outline: none;
  }

  .react-calendar__tile--now {
    background: #35b7a3 !important;

    > abbr {
      color: #fff !important;
    }
  }

  .react-calendar__tile--active.react-calendar__tile--now {
    > abbr {
      color: #747474 !important;
      font-weight: bold;
    }
  }

  .react-calendar__tile--active {
    background: #eaf5f5 !important;
    color: #2c969b !important;
  }

  .react-calendar__tile--hover:not(.react-calendar__tile--hoverEnd) {
    background: #eaf5f5;
    color: #2c969b;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #eaf5f5;
    color: #2c969b;
  }

  .react-daterange-picker__inputGroup__input:invalid {
    background: none;
  }

  .react-daterange-picker__button .react-daterange-picker__button__icon,
  .react-daterange-picker__button .react-daterange-picker__button__icon {
    stroke: #747474;
  }

  abbr {
    color: #747474;
  }

  .react-calendar__navigation button,
  .react-calendar__navigation button {
    color: #747474;
  }

  .react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #2c969b;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: #eaf5f5;
    color: #2c969b;
  }
`

export const StyledButtonContainer = styled.div`
  position: relative;

  .date-clear-x-btn {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
  }
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .button-label {
    font-size: 90%;
  }

  .button-icon-div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .button-icon-div.icon-right {
    left: unset;
    right: 12px;
  }

  .button-icon-div > i,
  .button-icon-div > div {
    object-fit: contain;
  }

  cursor: pointer;
  color: #2c969b;
  background: #fff;
  border: 1px solid #2c969b;
  border-radius: 0.375rem;
  height: 2.75rem;
  font-size: 80%;

  svg {
    stroke: #2c969b;
  }
`
