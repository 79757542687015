/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'oidc',
  initial: 'check_url_parameters',
  on: {
    EMTPY_OUT_LOCAL_STORAGE: {
      target: 'empty_local_storage',
    },
    TOKEN_EXPIRED: {
      target: '#token_expired',
    },
    GOT_NEW_PARAMS: {
      cond: 'unauthenticated',
      actions: ['updateUrlParams'],
    },
    // Don't remove this yet
    CLEAR_URL_PARAMS: {},
    // @ts-ignore
    GET_NEW_ACCESS_TOKEN_USING_REFRESH_TOKEN: [
      {
        target: '#refresh_token',
      },
    ],
    CLAIM_INSTANCE: {
      target: '#claim_instance',
    },
  },
  states: {
    check_url_parameters: {
      id: 'check_url_parameters',
      always: [
        {
          cond: 'oidcDisabledForTesting',
          actions: 'assignManuallyFetchedAccessToken',
          target: 'authenticated',
        },
        {
          cond: 'hasInvalidRefreshToken',
          target: 'recreate_survey.idle',
        },
        {
          cond: 'hasParamater',
          actions: ['clearStorage'],
          target: 'check_guid_resource',
        },
        {
          actions: ['assignLoggedInAsGuestToFalse', 'clearStorage'],
          target: 'authorization',
        },
      ],
    },
    check_guid_resource: {
      id: 'check_guid_resource',
      always: [
        {
          cond: 'isInstanceGuid',
          target: 'check_claim_code',
        },
        {
          cond: 'isSignatoryGuid',
          target: 'check_claim_code',
        },
        {
          actions: ['assignLoggedInAsGuestToFalse'],
          target: 'authorization',
        },
      ],
    },
    check_claim_code: {
      always: [
        {
          cond: 'hasClaimCode',
          target: 'check_allow_anonymous_flag',
        },
        {
          actions: ['assignLoggedInAsGuestToFalse'],
          target: 'authorization',
        },
      ],
    },
    check_allow_anonymous_flag: {
      always: [
        {
          cond: 'anonymousLoginEnabled',
          actions: ['assignLoggedInAsGuest'],
          target: 'authorization',
        },
        {
          actions: ['assignLoggedInAsGuestToFalse'],
          target: 'authorization',
        },
      ],
    },
    landing_page: {
      id: 'landing_page',
      always: [
        {
          cond: 'shouldWaitForUserAction',
          target: 'wait_for_user_interaction',
        },
        {
          target: 'authorization',
        },
      ],
    },
    wait_for_user_interaction: {
      on: {
        LOGIN_USER: {
          actions: ['assignLoggedInAsGuestToFalse'],
          target: 'authorization',
        },
        LOGIN_AS_GUEST: {
          actions: ['assignLoggedInAsGuest'],
          target: 'authorization',
        },
        GOT_NEW_PARAMS: [
          {
            cond: 'hasParamaterViaEvent',
            target: 'check_guid_resource',
            actions: ['updateUrlParams'],
          },
          {
            target: 'authorization',
            actions: ['updateUrlParams'],
          },
        ],
      },
    },
    authorization: {
      id: 'authorization',
      invoke: {
        src: 'checkAuthorization',
        id: 'check-authorization',
      },
      on: {
        AUTHORIZED: {
          target: 'authentication',
        },
      },
    },
    authentication: {
      id: 'authentication',
      invoke: {
        src: 'checkAuthentication',
        id: 'check-authentication',
      },
      on: {
        GOT_NEW_PARAMS: [
          {
            cond: 'hasParamaterViaEvent',
            target: 'check_url_parameters',
            actions: ['updateUrlParams'],
          },
          {
            target: 'authorization',
            actions: ['updateUrlParams', 'assignLoggedInAsGuestToFalse'],
          },
        ],
        REAUTHORIZE: {
          target: '#check_url_parameters',
        },
        AUTHENTICATED: [
          {
            cond: 'hasGuestToken',
            actions: ['assignAuthenticationResponse'],
            target: '#authenticated.upgrade_guest_account',
          },
          {
            cond: 'hasParamater',
            actions: ['assignAuthenticationResponse'],
            target: '#claim_instance',
          },
          {
            actions: ['assignAuthenticationResponse'],
            target: '#authenticated.idle',
          },
        ],
        AUTHENTICATION_ERROR: {
          actions: ['incrementAuthenticationAttempts'],
          target: 'retry',
        },
      },
    },
    // claim
    claim_instance: {
      id: 'claim_instance',
      invoke: {
        id: 'claim-instance',
        src: 'claimInstance',
      },
      on: {
        CLAIM_INSTANCE_SUCCESS: {
          actions: ['assignClaimedInstances'],
          target: '#authenticated.idle',
        },
        CLAIM_INSTANCE_FAILED: {
          target: '#error',
          actions: ['assignErrorMessage'],
        },
      },
    },
    claim_signatory: {
      id: 'claim_signatory',
      invoke: {
        id: 'claim-signatory',
        src: 'claimSignatory',
      },
      on: {
        CLAIM_SIGNATORY_SUCCESS: {
          target: '#authenticated.idle',
        },
        CLAIM_SIGNATORY_FAILED: {
          target: '#error',
          actions: ['assignErrorMessage'],
        },
      },
    },
    // end claim
    authenticated: {
      id: 'authenticated',
      on: {
        LOG_OUT: {
          target: 'logout',
        },
        UPGRADE_GUEST_ACCOUNT: {
          actions: ['clearUrlParams', 'updateUrlParams'],
          target: 'logout_and_retain_oidc_in_storage',
        },
        GOT_NEW_PARAMS: {
          actions: ['updateUrlParams'],
        },
      },
      initial: 'idle',
      states: {
        idle: {},
        upgrade_guest_account: {
          id: 'upgrade_guest_account',
          invoke: {
            id: 'upgrade-guest-account',
            src: 'upgradeGuestAccount',
          },
          on: {
            // @ts-ignore
            ACCOUNT_UPGRADE_SUCCESS: '#authenticated.idle',
            // @ts-ignore
            ACCOUNT_UPGRADE_FAILED: '#authenticated.idle',
          },
        },
      },
    },
    token_expired: {
      id: 'token_expired',
      on: {},
    },
    refresh_token: {
      id: 'refresh_token',
      invoke: {
        id: 'refresh-token',
        src: 'refreshToken',
      },
      on: {
        GOT_NEW_ACCESS_TOKEN: [
          {
            cond: 'isGuestLogin',
            actions: ['assignAuthenticationResponse'],
            target: '#claim_instance',
          },
          {
            actions: ['assignAuthenticationResponse'],
            target: '#authenticated.idle',
          },
        ],
        KEEP_ME_SIGNED_IN: {
          target: '#refresh_token',
        },
        LOG_OUT: {
          target: 'logout',
        },
        INVALID_REFRESH_TOKEN: [
          {
            cond: 'isGuestLogin',
            actions: ['setHasInvalidRefreshTokenToTrue'],
            target: 'recreate_survey.idle',
          },
          {
            actions: ['setHasInvalidRefreshTokenToTrue'],
            target: '#logout',
          },
        ],
      },
    },
    logout: {
      id: 'logout',
      initial: 'remove_access_token',
      states: {
        remove_access_token: {
          id: 'remove_access_token',
          entry: ['removeAccessToken'],
          always: {
            target: 'notify_identity_server',
            cond: 'userIsNotAuthenticated',
          },
        },
        notify_identity_server: {
          invoke: {
            id: 'notify-identity-server-for-logout-event',
            src: 'notifiyIdentityServerForlogoutEvent',
          },
          on: {
            SERVER_NOTIFIED: {
              target: '#logout_success',
            },
          },
        },
        logout_success: {
          id: 'logout_success',
          invoke: {
            id: 'remove-local-storage-items',
            src: 'removeLocalStorageItems',
          },
          after: {
            1000: [
              {
                cond: 'oidcDisabledForTesting',
                actions: 'assignManuallyFetchedAccessToken',
                target: '#authenticated',
              },
              {
                actions: ['clearUrlParams', 'clearGuestToken'],
                target: '#authorization',
              },
            ],
          },
        },
      },
    },
    logout_and_retain_oidc_in_storage: {
      id: 'logout_and_retain_oidc_in_storage',
      initial: 'remove_access_token',
      states: {
        remove_access_token: {
          id: 'remove_access_token',
          entry: ['removeAccessToken'],
          always: {
            target: 'notify_identity_server',
            cond: 'userIsNotAuthenticated',
          },
        },
        notify_identity_server: {
          invoke: {
            id: 'notify-identity-server-for-logout-event',
            src: 'notifiyIdentityServerForlogoutEvent',
          },
          on: {
            SERVER_NOTIFIED: {
              target: '#logout_and_retain_oidc_in_storage_success',
            },
          },
        },
        logout_and_retain_oidc_in_storage_success: {
          id: 'logout_and_retain_oidc_in_storage_success',
          invoke: {
            id: 'remove-local-storage-items-except-for-oidc',
            src: 'removeLocalStorageItemsExceptForOIDC',
          },
          after: {
            1000: [
              {
                cond: 'oidcDisabledForTesting',
                actions: 'assignManuallyFetchedAccessToken',
                target: '#authenticated',
              },
              {
                target: '#check_url_parameters',
              },
            ],
          },
        },
      },
    },
    empty_local_storage: {
      id: 'empty_local_storage',
      invoke: {
        id: 'emptyLocalStorage',
        src: 'emptyLocalStorage',
      },
      after: {
        1000: {
          target: '#authorization',
        },
      },
    },
    retry: {
      id: 'retry',
      after: {
        1000: {
          target: 'authentication',
          cond: 'hasReachMaxAuthenticationAttempts',
        },
      },
    },
    recreate_survey: {
      id: 'recreate_survey',
      initial: 'idle',
      states: {
        idle: {
          on: {
            // this event will be triggered by the MainConsumer
            RECREATE_SURVEY_INSTANCE: [
              {
                cond: 'isInstanceAlreadyClaimed',
                actions: 'assignSurveyReferenceGuid',
                target: 'trigger_recreate_survey',
              },
              {
                actions: 'assignSurveyReferenceGuid',
                target: 'get_new_access_and_refresh_tokens',
              },
            ],
            // this event will be triggered by the MainConsumer
            RELOGIN: {
              target: '#logout',
            },
          },
        },
        get_new_access_and_refresh_tokens: {
          id: 'get_new_access_and_refresh_tokens',
          invoke: {
            id: 'get-new-access-and-refresh-tokens',
            src: 'getNewAccessAndRefreshTokens',
          },
          on: {
            // @ts-ignore
            GOT_NEW_ACCESS_AND_REFRESH_TOKENS: {
              actions: ['assignAuthenticationResponse'],
              target: '#claim_instance',
            },
          },
        },
        trigger_recreate_survey: {
          id: 'trigger_recreate_survey',
          invoke: {
            id: 'recreate-survey',
            src: 'recreateSurvey',
          },
          on: {
            // @ts-ignore
            RECREATE_SURVEY_SUCCESS: {
              target: '#show_security_error_sending_new_link_state',
            },
          },
        },
      },
    },
    show_security_error_sending_new_link_state: {
      id: 'show_security_error_sending_new_link_state',
      on: {
        GOT_NEW_PARAMS: {
          actions: ['updateUrlParams', 'setHasInvalidRefreshTokenToFalse'],
        },
      },
    },
    error: {
      id: 'error',
      on: {
        GOT_NEW_PARAMS: {
          actions: ['updateUrlParams', 'setHasInvalidRefreshTokenToFalse'],
        },
      },
    },
  },
}
