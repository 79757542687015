/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { sortItems, fullTextSearch } from '@utils/index'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  assignFetchedData: assign({
    applicationData: ({ applicationConfig }, { payload }: any) => {
      const {
        query_entity,
        initialState,
        searchText = '',
        searchFields = [],
      } = applicationConfig ?? {}
      const { sortOrder = 'descending', sortBy = 'dateCompleted.seconds' } =
        initialState ?? {}
      const { pagination, ...restPayload } = payload ?? {}

      const items = restPayload[query_entity + 'List'] ?? []

      const searchResult = fullTextSearch({
        searchText,
        searchFields,
        items,
      })

      const sortedItems = sortItems(searchResult, sortOrder, sortBy)

      return {
        pagination,
        items: sortedItems ?? [],
      }
    },
  }),

  updateAccessToken: assign({
    accessToken: ({ accessToken }, { payload }: any) => payload,
  }),

  updateSearchText: assign({
    applicationConfig: ({ applicationConfig }, { payload }: any) => ({
      ...(applicationConfig ?? {}),
      searchText: payload,
    }),
  }),

  sortItems: assign({
    applicationConfig: ({ applicationConfig }) => {
      const { sortOrder = 'descending' } = applicationConfig?.initialState ?? {}

      return {
        ...(applicationConfig ?? {}),
        initialState: {
          ...(applicationConfig?.initialState ?? {}),
          sortOrder: sortOrder === 'descending' ? 'ascending' : 'descending',
        },
      }
    },
  }),

  assignDate: assign({
    params: ({ params }, { payload }: any) => ({
      ...params,
      ...payload,
    }),
  }),
}
