import styled from 'styled-components'

export const StyledProfile = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #292929; */
  background-color: rgba(41, 41, 41, 0.8);
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    /* transform: translateX(-50%); */
    height: 200px;
    background-color: none;
  }
  svg path {
    /* fill: none;
    opacity: 0;
    stroke: black;
    stroke-dasharray: 400;
    stroke-dashoffset: 0;
    animation: 5s title-outline infinite forwards; */
  }

  @keyframes title-outline {
    from {
      stroke-dashoffset: 400;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`

export const StyledNoFacePrompt = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.light.DEFAULT};
  color: ${({ theme }) => theme.colors.dark[100]};
  height: 6rem;
  padding: 0 1rem 1rem 1rem;
  border-radius: ${({ theme }) => theme.border.radius};
  width: 100%;
  max-width: 90%;

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    border-radius: 0;
  }

  @media screen and (min-width: 600px) {
    width: 60%;
  }
`
