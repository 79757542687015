/* eslint-disable no-empty-pattern */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { Context, MachineEvents } from '../types'

import { default_context } from '../../index'

const { REACT_APP_ACCESS_TOKEN_FOR_DEBUGGING = '' } = process.env

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  assignAuthenticationResponse: assign((context, { payload }: any) => {
    console.log('@DEBUG [useOIDC]: assignAuthenticationResponse', {
      context,
      payload,
    })
    return {
      ...context,
      ...payload,
    }
  }),

  assignManuallyFetchedAccessToken: assign((context) => {
    return {
      accessToken: REACT_APP_ACCESS_TOKEN_FOR_DEBUGGING,
      isAuthenticated: true,
    }
  }),

  incrementAuthenticationAttempts: assign({
    authenticationAttempts: ({ authenticationAttempts = 0 }) =>
      authenticationAttempts + 1,
  }),

  removeAccessToken: assign((context) => ({
    ...context,
    isAuthenticated: false,
    accessToken: null,
  })),

  updateUrlParams: assign((context, { payload }: any) => {
    return {
      ...context,
      ...payload,
    }
  }),

  clearUrlParams: assign((context) => {
    return {
      ...context,
      instanceGuid: '',
      signatoryGuid: '',
      claimCode: '',
      anonymousLogin: false,
      allowAccountCreation: 'true',
      guestLoginButtonText: '',
      loggedInAsGuest: false,
      hasInvalidRefreshToken: false,
      claimedInstances: {},
    }
  }),

  clearGuidsAndClaimCode: assign((context) => ({
    ...context,
    instanceGuid: '',
    signatoryGuid: '',
    claimCode: '',
  })),

  clearGuestToken: assign((context) => ({
    ...context,
    guestToken: '',
  })),

  assignLoggedInAsGuest: assign((context) => ({
    ...context,
    loggedInAsGuest: true,
  })),

  assignLoggedInAsGuestToFalse: assign((context) => ({
    ...context,
    loggedInAsGuest: false,
    anonymousLogin: false,
  })),

  setAuthorizeToTrue: assign((context) => ({
    ...context,
    isAuthorized: true,
  })),

  clearStorage: () => {
    localStorage.clear()
    localStorage.removeItem('oidc')
  },
  logTokenExpired: () => console.log('Token expired.'),

  increamentTokenRequestAttempt: assign({
    tokenRequestAttempt: ({ tokenRequestAttempt = 0 }) =>
      tokenRequestAttempt + 1,
  }),
  setHasInvalidRefreshTokenToTrue: assign({
    hasInvalidRefreshToken: ({}) => true,
  }),
  setHasInvalidRefreshTokenToFalse: assign({
    hasInvalidRefreshToken: ({}) => false,
  }),
  clearOIDCDataExceptForTheCustomParameters: assign(
    ({ loggedInAsGuest, allowAccountCreation, guestLoginButtonText }) => ({
      ...default_context,
      loggedInAsGuest,
      allowAccountCreation,
      guestLoginButtonText,
    })
  ),
  assignSurveyReferenceGuid: assign((context, { payload }: any) => ({
    ...context,
    surveyReferenceGuid: payload,
  })),
  assignNewInstanceGuid: assign((context, { payload }: any) => ({
    ...context,
    instanceGuid: payload,
  })),
  assignErrorMessage: assign((context, { payload }: any) => ({
    ...context,
    errorMessage: payload,
  })),
  assignClaimedInstances: assign((context, { payload }: any) => ({
    ...context,
    claimedInstances: {
      ...(context?.claimedInstances ?? {}),
      [payload]: true,
    },
  })),
}
