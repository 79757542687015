/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  updateAccessToken: assign({
    accessToken: ({ accessToken }, { payload }: any) => payload,
  }),

  updateUrlParamsGuid: assign({
    urlParamsGuid: ({ urlParamsGuid }, { payload }: any) => payload,
  }),

  assignInstance: assign({
    selectedInstance: ({ selectedInstance }, { payload }: any) => ({
      ...selectedInstance,
      ...payload,
    }),
  }),

  assignAttemptQuestions: assign({
    questionGuidsList: ({ questionGuidsList }, { payload }: any) => payload,
  }),

  assignQuestionsWithAnswers: assign({
    questions: ({ questions }, { payload }: any) => payload,
  }),

  assignSelectedQuestion: assign({
    selectedQuestion: ({ selectedQuestion }, { payload }: any) => payload,
  }),

  clearContext: assign({
    questions: ({}) => [],
    questionGuidsList: ({}) => [],
    selectedInstance: ({ selectedInstance }) => ({}),
  }),
  updateErrorMessage: assign({
    errorMessage: ({}, { payload }: any) => payload,
  }),
}
