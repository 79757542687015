import styled from 'styled-components'

export const StyledProgressBarContainer = styled.div<{ bgColor?: string }>`
  height: 4px;
  width: 100%;
  background: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.primary[50]};
  border-radius: 50px;
`

export const StyledProgressInfo = styled.div<{ progressColor?: string }>`
  width: 100%;
  color: ${({ theme, progressColor }) =>
    progressColor ? progressColor : theme.colors.primary['DEFAULT']};
`

export const StyledProgressBar = styled.div<{
  progress?: number
  progressColor?: string
}>`
  height: 100%;
  width: ${({ progress = 0 }) => progress}%;
  background: ${({ theme, progressColor }) =>
    progressColor ? progressColor : theme.colors.primary['DEFAULT']};
  border-radius: inherit;
  text-align: right;
  transition: all 0.3s linear;
`

export const StyledProgressContainer = styled.div<{
  progressContainerWidth: string
}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: ${({ progressContainerWidth }) => progressContainerWidth};
  max-width: 100%;
`

export const StyledNewProgressInfo = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary['DEFAULT']};
  flex-direction: row;
  display: flex;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0.75rem !important;
  align-items: center;
  justify-content: center;
`
export const ProgressTextInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0.5;
`

export const StyledProgressContainerVertical = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`
