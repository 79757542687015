/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
import { AnyEventObject, Sender } from 'xstate'
import { usePandoLogger } from '@pandolink/utils'

import {
  CompletionType,
  ConfigurationKey,
  execClaimInstance,
  execClaimSignatory,
} from '@utils/index'

import {
  getInstanceData,
  createAttempt,
  getSurveyConfiguration,
} from '@utils/index'

import { Context } from '../types'

export const services: any = {
  getSurveyConfiguration:
    ({ accessToken, selectedInstance }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result = await getSurveyConfiguration(
          {
            instanceGuid: selectedInstance?.guid,
            configurationKeysList: [
              ConfigurationKey.IS_AUDIO_OPTIONAL,
              ConfigurationKey.IS_GUEST_ALLOWED,
              ConfigurationKey.IS_VIDEO_OPTIONAL,
            ],
          },
          accessToken!
        )

        const reducedConfiguration = result
          ?.toObject()
          ?.surveyConfigurationsList?.reduce((acc: any, curr: any) => {
            let key = curr.key
            let value = curr.value === 'False' ? true : false

            if (curr.key === ConfigurationKey.IS_AUDIO_OPTIONAL) {
              key = 'audioRequired'
            }

            if (curr.key === ConfigurationKey.IS_VIDEO_OPTIONAL) {
              key = 'videoRequired'
            }

            if (curr.key === ConfigurationKey.IS_GUEST_ALLOWED) {
              key = 'guestAllowed'
              value = curr.value !== 'False'
            }

            return {
              ...acc,
              [key]: value,
            }
          }, {})

        send({
          type: 'GOT_SURVEY_CONFIGURATION',
          data: reducedConfiguration,
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getSurveyConfiguration: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_SURVEY_CONFIGURATION_ERROR',
          payload: error?.message,
        })
      }
    },

  getInstanceDetails:
    ({ accessToken, selectedInstance, instanceGuid = '' }: Context) =>
    async (send: Sender<any>) => {
      try {
        console.log('@DEBUG [getInstanceDetails]:', {
          selectedInstance,
          instanceGuid,
          chosen: instanceGuid || selectedInstance?.guid,
          accessToken,
        })
        const result = await getInstanceData(
          {
            guid: instanceGuid || selectedInstance?.guid,
          },
          accessToken!
        )

        console.log('@DEBUG [getInstanceDetails]: result', {
          result: result?.toObject(),
        })

        if (result?.toObject()?.completionType === CompletionType.ACCEPTABLE) {
          send('SURVEY_ALREADY_COMPLETED')

          return
        }

        send({
          type: 'GOT_INSTANCE_DETAILS',
          data: result?.toObject(),
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getInstanceDetails: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_INSTANCE_DETAILS_ERROR',
          payload: error?.message,
        })
      }
    },

  checkAttempt:
    ({ selectedInstance }: Context) =>
    async (send: Sender<any>) => {
      const { current } = selectedInstance ?? {}

      if (current?.guid && selectedInstance) {
        send('HAS_ATTEMPT')
      } else {
        send('NEW_ATTEMPT')
      }
    },

  createAttempt:
    ({ params, accessToken, selectedInstance }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result = await createAttempt(
          {
            surveyInstanceGuid: selectedInstance?.guid ?? '',
            guid: '',
            completionType: CompletionType.ACCEPTABLE,
            totalAnswered: 0,
            totalQuestions: 0,
          },
          accessToken!
        )

        send({
          type: 'ATTEMPT_CREATED',
          data: result.toObject(),
        })
      } catch (error: any) {
        if (error?.code === 9) {
          send({
            type: 'CONTINUE_ATTEMPT',
          })
          return
        }

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'CREATE_ATTEMPT_ERROR',
          payload: error?.message,
        })
      }
    },
}
