/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext, useState, useRef } from 'react'
import { Icon, RaySpinner, Button } from '@pando-styles/nirvana'

import { SelectionType } from '@utils/index'
import { MainState } from '@pages/index'
import { QuestionsState, QuestionsActions } from '@applications/index'
import { ProgressBar } from '@atomic/index'

import { StyledUserMicContainer } from './styles'
import { DialogProps } from './types'
import MultipleAnswer from './MultipleAnswer'
import SingleAnswer from './SingleAnswer'

const Dialog: React.FC<DialogProps> = ({
  isStreamUpdated,
  _fontSize,
  hasMicPermission,
  userRecordedVideo,
  setIsVideoPlaying,
  handleStopRecording,
  handleStartRecording,
  resumeSurvey,
  setIsStreamUpdated,
  setUserRecordedVideo,
}) => {
  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)

  const [clickedBtnId, setClickedBtnId] = useState<string | undefined>(
    undefined
  )
  const [listening, setListening] = useState<boolean>(false)
  const [micTimeOut, setMicTimeOut] = useState<any>(null)

  if (
    !providerState ||
    !providerActions ||
    !homeProviderState ||
    !providerActions
  )
    return null

  const { question, requestProgress = 0 } = providerState?.context ?? {}
  const { noAnswerWaitTimeout = 0 } = question ?? {}
  const { sitePermissions, surveyConfiguration } =
    homeProviderState?.context ?? {}

  const {
    handleTryAgain,
    handleReplayVideo,
    handleReupload,
    handleRestartRecording,
    handleStartListening,
  } = providerActions ?? {}

  const isAtListeningState = providerState.matches('ready.listening')
  const isAtWaitingForButtonClickState = providerState.matches(
    'ready.waiting_for_button_click'
  )
  const isAtSaveAnswerMultipleState = providerState.matches(
    'ready.save_answer_multiple'
  )
  const isAtErrorPlayingVideoState = providerState.matches(
    'ready.error_playing_video'
  )
  const isAtInterruptedResponseState = providerState.matches(
    'ready.interrupted_response_via_window_blur'
  )
  const isAtUploadingAnswerFailedState = providerState.matches(
    'ready.uploading_answer_failed'
  )
  const isAtTimeLimitState = providerState.matches('ready.time_limit')
  const isAtExecuteCompleteQuestionState = providerState.matches(
    'ready.execute_complete_question'
  )

  const handleRepeatQuestion = () => {
    setUserRecordedVideo([])
    setIsStreamUpdated(false)
    resumeSurvey()
    handleReplayVideo()
    handleStopRecording('Dialog handleRepeatQuestion')
    setIsVideoPlaying(true)
  }

  const showMicrophoneComponent = () => {
    if (sitePermissions?.microphone) {
      return true
    }

    if (!sitePermissions?.microphone && surveyConfiguration?.audioRequired) {
      return true
    }

    if (!sitePermissions?.microphone && !surveyConfiguration?.audioRequired) {
      return false
    }

    return false
  }

  const startSpeechRecognition = () => {
    handleStartRecording('Dialog component')

    setMicTimeOut(
      setTimeout(() => {
        handleStopRecording('Dialog setMicTimeout')
        setListening(false)
      }, noAnswerWaitTimeout)
    )
  }

  const stopSpeechRecognition = () => {
    clearTimeout(micTimeOut)
    setMicTimeOut(null)
  }

  useEffect(() => {
    if (isAtWaitingForButtonClickState || isAtInterruptedResponseState) {
      clearTimeout(micTimeOut)
      setMicTimeOut(null)
    }
  }, [providerState.value])

  useEffect(() => {
    if (!surveyConfiguration?.audioRequired) return

    if (!isStreamUpdated || !hasMicPermission) return

    if (providerState.matches('ready.waiting_for_microphone')) {
      handleStartListening()

      setListening(true)
      return
    }

    if (
      !surveyConfiguration?.videoRequired &&
      (sitePermissions?.microphone || surveyConfiguration?.audioRequired) &&
      isAtListeningState
    ) {
      startSpeechRecognition()
      return
    } else if (
      !surveyConfiguration?.videoRequired &&
      !surveyConfiguration?.audioRequired &&
      isAtListeningState
    ) {
      stopSpeechRecognition()
      return
    } else if (isAtListeningState) {
      startSpeechRecognition()
      return
    }

    return () => {
      clearTimeout(micTimeOut)
      setMicTimeOut(null)
    }
  }, [
    providerState.value,
    surveyConfiguration,
    isStreamUpdated,
    hasMicPermission,
  ])

  const getStyleForListeningStatus = () => {
    if (providerState.hasTag('invalid_response')) {
      return 'no-voice-detected'
    }

    if (!isAtListeningState) {
      return 'stops-listening'
    }

    return ''
  }

  const handleRecordAgain = () => {
    setUserRecordedVideo([])

    handleRestartRecording()
  }

  const isMultiTypeQuestion =
    question?.selectionType === SelectionType.MULTI_ANSWER

  const hasOnlyOneAnswerOption =
    (question?.answerOptionsList ?? [])?.length === 1

  const showRepeatQuestion = () => {
    if (
      isAtWaitingForButtonClickState ||
      isAtSaveAnswerMultipleState ||
      isAtErrorPlayingVideoState
    )
      return true

    return false
  }

  const displayComponent = (type: string) => {
    const shouldDisplayTryAgainBtn =
      providerState.hasTag('invalid_response') || isAtInterruptedResponseState

    const shouldDangerIcon =
      isAtInterruptedResponseState || isAtUploadingAnswerFailedState

    if (type === 'try_again_btn' && shouldDisplayTryAgainBtn) return true
    if (type === 'danger_icon' && shouldDangerIcon) return true
    return false
  }

  // don't delete this: this is used for debugging purposes
  const downloadBlob = () => {
    if (userRecordedVideo?.[0]) {
      const blobUrl = URL.createObjectURL(userRecordedVideo?.[0])

      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = 'recorded-video.mp4'

      downloadLink.click()
    }
  }

  return (
    <StyledUserMicContainer
      isListening={isAtListeningState}
      className='MicContainer w-400'
    >
      {/* don't delete this: this is used for debugging purposes */}
      {/* <h2 onClick={downloadBlob}>DOWNLOAD</h2> */}
      {
        <>
          {!isAtExecuteCompleteQuestionState &&
          !isAtErrorPlayingVideoState &&
          !isAtTimeLimitState &&
          !isMultiTypeQuestion ? (
            (isAtListeningState && !listening) ||
            providerState.hasTag('loader') ? (
              <RaySpinner
                color='dark'
                colorWeight='DEFAULT'
                size={'xs'}
                rayHeight={6}
                rayWidth={4}
                className='listening-loader'
              />
            ) : (
              !isMultiTypeQuestion &&
              !hasOnlyOneAnswerOption &&
              !providerState.matches(
                'ready.interrupted_response_via_window_blur'
              ) &&
              !providerState.matches('ready.interrupted_media_recording') &&
              !isAtWaitingForButtonClickState &&
              !isAtUploadingAnswerFailedState && (
                <div className='react-mic-div margin--bottom-1'>
                  <Icon
                    iconName='mic'
                    size='xs'
                    color='light'
                    colorWeight='200'
                    className={'mic-icon ' + getStyleForListeningStatus()}
                  />
                </div>
              )
            )
          ) : (
            <></>
          )}

          {displayComponent('danger_icon') && (
            <Icon
              iconName='alert-circle'
              color='danger'
              className={`scale-1-5 m-auto margin--bottom-1`}
            />
          )}

          {!isMultiTypeQuestion && (
            <SingleAnswer
              _fontSize={_fontSize}
              listening={listening}
              clickedBtnId={clickedBtnId}
              hasOnlyOneAnswerOption={hasOnlyOneAnswerOption}
              userRecordedVideo={userRecordedVideo}
              showMicrophoneComponent={showMicrophoneComponent}
              setClickedBtnId={setClickedBtnId}
            />
          )}

          {(isAtExecuteCompleteQuestionState ||
            providerState.matches('ready.save_answer') ||
            providerState.matches('ready.uploading_answer')) && (
            <ProgressBar
              progress={requestProgress}
              showProgressText={false}
              bgColor='#E0E0E0'
              progressColor='#000000'
              progressContainerWidth='200px'
            />
          )}

          {isMultiTypeQuestion && (
            <MultipleAnswer
              _fontSize={_fontSize}
              clickedBtnId={clickedBtnId}
              setClickedBtnId={setClickedBtnId}
            />
          )}

          {displayComponent('try_again_btn') && (
            <Button
              iconLeft='rotate-ccw'
              style={{ fontSize: _fontSize }}
              label='Try Again'
              color='primary'
              layout='outline'
              hoverColorWeight='300'
              className='m-auto margin--top-1 custom-button-link bold'
              onClick={() => {
                setUserRecordedVideo?.([])
                handleTryAgain()
              }}
            />
          )}

          {(providerState.matches('ready.video_limit_reached') ||
            isAtTimeLimitState ||
            isAtUploadingAnswerFailedState) && (
            <Button
              style={{ fontSize: `${_fontSize} !important` }}
              iconLeft='rotate-ccw'
              label='Try Again'
              color='primary'
              layout='outline'
              hoverColorWeight='300'
              className='m-auto margin--top-1 custom-button-link bold'
              onClick={
                isAtUploadingAnswerFailedState
                  ? handleReupload
                  : handleRecordAgain
              }
            />
          )}

          {showRepeatQuestion() && (
            <Button
              iconLeft='rotate-ccw'
              label='Repeat Question'
              color='primary'
              layout='outline'
              hoverColorWeight='300'
              className='m-auto margin--top-2 custom-button-link bold'
              onClick={() => {
                // @ts-ignore
                const audioTrack = window.stream.getAudioTracks()[0]

                if (audioTrack) {
                  audioTrack.stop()
                  // @ts-ignore
                  window.stream.removeTrack(audioTrack)
                }

                handleRepeatQuestion()
              }}
              isDisabled={isAtSaveAnswerMultipleState}
              style={{ fontSize: _fontSize }}
            />
          )}
        </>
      }
    </StyledUserMicContainer>
  )
}

export default Dialog
