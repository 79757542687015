import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'survey-execute-questions',
  initial: 'loading',
  on: {
    GOT_NEW_ATTTEMPT: {
      actions: ['assignParams', 'setFirstVideoPlayedToFalse'],
      target: '#loading.get_next_question',
    },
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#loading.get_next_question',
    },
    ADD_SUCCESS_UPLOAD_ID: {
      actions: ['addSuccessUploadId'],
    },
    ADD_FAILED_UPLOAD_ID: {
      actions: ['addFailedUploadId'],
    },
    CLEAR_CONTEXT: {
      actions: ['clearPreviousContext'],
    },
    CLEAR_EXPIRED_TOKEN: {
      actions: ['clearExpiredToken'],
    },
    SESSION_EXPIRED: {
      actions: [],
      target: '#session_expired',
    },
    COMPLETE_SURVEY_DIRECTLY: {
      target: '#complete_survey',
    },
    UPDATE_SITE_PERMISSIONS: {
      actions: ['updateSitePermissions'],
    },
    UPDATE_REQUEST_PROGRESS: {
      actions: ['updateRequestProgress'],
    },
  },
  states: {
    loading: {
      id: 'loading',
      initial: 'idle',
      states: {
        idle: {
          id: 'idle',
        },
        get_next_question: {
          id: 'get_next_question',
          entry: [
            'setLastQuestionUploadToFalse',
            'resetRequestProgress',
            'setHasMediaRecordingInterruptedToFalse',
          ],
          invoke: {
            id: 'get-next-question',
            src: 'getNextQuestion',
          },
          on: {
            REMOVE_CREATED_ANSWER_RESOURCE: {
              actions: ['removeCreatedAnswerResource'],
            },
            GOT_QUESTION: [
              {
                cond: 'disableSpeechRecognition',
                actions: ['assignQuestion'],
                target: '#video_playback',
              },
              {
                cond: 'isQuestionInterruptedRequiresVoiceRecognition',
                target: '#video_playback_with_voice_recognition',
              },
              {
                cond: 'hasQuestionInterrupted',
                target: '#video_playback',
              },
              {
                cond: 'hasMediaUploaded',
                target: '#execute_complete_question',
              },

              {
                cond: 'requiresVoiceRecognition',
                actions: ['assignQuestion'],
                target: '#video_playback_with_voice_recognition',
              },
              {
                cond: 'isAllowedRecording',
                actions: ['assignQuestion'],
                target: '#video_playback_with_voice_recognition',
              },
              {
                actions: ['assignQuestion'],
                target: '#video_playback',
              },
            ],
            NO_QUESTION: [
              {
                cond: 'isLastQuestion',
                target: '#complete_survey',
              },
              {
                target: '#done',
              },
            ],
            GET_QUESTION_ERROR: {
              target: '#error',
              actions: 'assignErrorMessage',
            },
          },
        },
      },
    },
    ready: {
      id: 'ready',
      initial: 'video_playback_with_voice_recognition',
      states: {
        video_playback_with_voice_recognition: {
          id: 'video_playback_with_voice_recognition',
          tags: ['video_playback'],
          on: {
            SKIP_VIDEO_PLAYBACK: [
              {
                cond: 'voiceRecognitionDisabledThruEnv',
                target: '#listening',
              },
              {
                target: '#waiting_for_microphone',
              },
            ],
            VIDEO_PLAYBACK_ENDED: [
              {
                cond: 'isMultiChoiceQuestion',
                target: '#waiting_for_button_click',
              },
              {
                cond: 'hasOnlyOneAnswerOption',
                target: '#waiting_for_button_click',
              },
              {
                cond: 'voiceRecognitionDisabledThruEnv',
                target: '#listening',
              },
              {
                target: '#waiting_for_microphone',
              },
            ],
            ERROR_PLAYING_VIDEO: '#error_playing_video',
            FIRST_VIDEO_PLAYED_TRUE: {
              actions: ['setFirstVideoPlayedToTrue'],
            },
          },
        },
        video_playback: {
          id: 'video_playback',
          tags: ['video_playback'],
          on: {
            SKIP_VIDEO_PLAYBACK: '#waiting_for_button_click',
            VIDEO_PLAYBACK_ENDED: '#waiting_for_button_click',
            ERROR_PLAYING_VIDEO: '#error_playing_video',
          },
        },
        waiting_for_microphone: {
          id: 'waiting_for_microphone',
          tags: ['show_dialog'],
          on: {
            START_LISTENING: '#listening',
          },
        },
        listening: {
          id: 'listening',
          tags: ['show_dialog'],
          entry: ['resetRequestProgress'],
          on: {
            ANSWER_VIA_VOICE_INPUT: {
              actions: [
                'assignSelectedAnswerAndAnswerTypeAsVocal',
                'setShouldRecordAgainToFalse',
              ],
              target: '#waiting_for_button_click',
            },
            NO_VOICE_INPUT_RECEIVED: {
              actions: ['setShouldRecordAgainToFalse'],
              target: '#no_voice_input_received',
            },
            LISTENING_TIME_LIMIT: {
              actions: ['setShouldRecordAgainToFalse'],
              target: '#verify_answer_via_speech_recognition_api',
            },
            INTERRUPTED_RESPONSE_VIA_WINDOW_BLUR: {
              actions: ['setShouldRecordAgainToFalse'],
              target: '#interrupted_response_via_window_blur',
            },
            INTERRUPTED_MEDIA_RECORDING: {
              actions: ['setShouldRecordAgainToFalse'],
              target: '#interrupted_media_recording',
            },
          },
        },
        verify_answer_via_speech_recognition_api: {
          id: 'verify_answer_via_speech_recognition_api',
          tags: ['show_dialog', 'loader'],
          on: {
            ANSWER_IS_YES: {
              actions: ['assignYesAsSelectedAnswer'],
              target: '#waiting_for_button_click',
            },
            ANSWER_IS_NO: {
              actions: ['assignNoAsSelectedAnswer'],
              target: '#waiting_for_button_click',
            },
            ANSWER_IS_INVALID: {
              actions: ['clearSelectedAnswer'],
              target: '#no_voice_input_received',
            },
            REQUEST_ERROR: {
              actions: ['assignErrorMessage', 'clearSelectedAnswer'],
              target: '#error',
            },
          },
        },
        interrupted_response_via_window_blur: {
          id: 'interrupted_response_via_window_blur',
          tags: ['show_dialog'],
          on: {
            TRY_AGAIN: {
              actions: ['setShouldRecordAgainToTrue'],
              target: '#waiting_for_microphone',
            },
            REPLAY_VIDEO: '#exec_repeat_question',
            INTERRUPTED_MEDIA_RECORDING: '#interrupted_media_recording',
          },
        },
        interrupted_media_recording: {
          id: 'interrupted_media_recording',
          tags: ['show_dialog'],
          on: {
            TRY_AGAIN: [
              {
                cond: 'hasMediaRecordingInterrupted',
                actions: ['setShouldRecordAgainToTrue'],
                target: '#waiting_for_button_click',
              },

              {
                actions: ['setShouldRecordAgainToTrue'],
                target: '#waiting_for_microphone',
              },
            ],
          },
        },
        no_voice_input_received: {
          id: 'no_voice_input_received',
          tags: ['invalid_response', 'nbsp', 'show_dialog'],
          on: {
            TRY_AGAIN: {
              actions: ['setShouldRecordAgainToTrue'],
              target: '#waiting_for_microphone',
            },
            COMPONENT_DID_MOUNT_PLAY_MEDIA:
              '#video_playback_with_voice_recognition',
          },
        },
        waiting_for_button_click: {
          entry: ['resetRequestProgress'],
          id: 'waiting_for_button_click',
          tags: ['show_dialog'],
          on: {
            MULTIPLE_ANSWER_VIA_BUTTON_CLICK: {
              actions: [
                'assignSelectedAnswerAndAnswerTypeAsButton',
                'setIsMultipleAnswerToTrue',
              ],
              target: 'save_answer_multiple',
            },
            CONTINUE: [
              {
                cond: 'disableSpeechRecognition',
                target: '#execute_complete_question',
              },
              {
                cond: 'isMultipleAnswer',
                target: '#execute_complete_question',
              },
              {
                cond: 'isMicOnlyRequired',
                target: '#uploading_answer',
              },
              {
                actions: [
                  'setIsProcessingSaveAnswerToFalse',
                  'setIsProcessingMediaUploadToTrue',
                ],
                target: '#uploading_answer',
              },
            ],
            ANSWER_VIA_BUTTON_CLICK: [
              {
                cond: 'disableSpeechRecognition',
                actions: ['assignSelectedAnswerAndAnswerTypeAsButton'],
                target: '#save_answer',
              },
              {
                cond: 'voiceRecognitionDisabledThruEnv',
                actions: ['assignSelectedAnswerAndAnswerTypeAsButton'],
                target: '#save_answer',
              },
              {
                cond: 'hasOnlyOneAnswerOption',
                actions: ['assignSelectedAnswerAndAnswerTypeAsButton'],
                target: '#save_answer',
              },
              {
                cond: 'isSameWithVoiceAnswer',
                actions: ['assignSelectedAnswerAndAnswerTypeAsButton'],
                target: '#save_answer',
              },
              {
                target: '#not_matched_inputs',
              },
            ],
            REPLAY_VIDEO: '#exec_repeat_question',
            WAITING_FOR_CLICK_TIME_LIMIT: '#time_limit',
            UNACCEPTABLE_ANSWER_CLICK: '#unacceptable_answer',
            INTERRUPTED_MEDIA_RECORDING: {
              actions: ['setHasMediaRecordingInterruptedToTrue'],
              target: '#interrupted_media_recording',
            },
          },
        },
        unacceptable_answer: {
          id: 'unacceptable_answer',
          on: {
            CHANGE_ANSWER: {
              actions: ['setShouldRecordAgainToTrue'],
              target: '#waiting_for_microphone',
            },
          },
        },
        exec_repeat_question: {
          id: 'exec_repeat_question',
          invoke: {
            id: 'exec-repeat-question',
            src: 'execRepeatQuestion',
          },
          tags: ['loader'],
          on: {
            EXEC_REPEAT_QUESTION: '#video_playback_with_voice_recognition',
          },
        },
        error_playing_video: {
          id: 'error_playing_video',
          tags: ['show_dialog'],
          on: {
            REPLAY_VIDEO: '#exec_repeat_question',
          },
        },
        not_matched_inputs: {
          id: 'not_matched_inputs',
          tags: ['invalid_response', 'nbsp', 'show_dialog'],
          on: {
            TRY_AGAIN: [
              {
                cond: 'voiceRecognitionDisabledThruEnv',
                actions: ['setShouldRecordAgainToTrue'],
                target: '#listening',
              },
              {
                actions: ['setShouldRecordAgainToTrue'],
                target: '#waiting_for_microphone',
              },
            ],
            COMPONENT_DID_MOUNT_PLAY_MEDIA:
              '#video_playback_with_voice_recognition',
          },
        },
        save_answer_multiple: {
          id: 'save_answer_multiple',
          tags: ['show_dialog'],
          invoke: {
            id: 'save-answer-multiple',
            src: 'saveAnswerMultiple',
          },
          on: {
            STORE_CREATED_ANSWER_RESOURCE: {
              actions: ['assignCreatedAnswerResult'],
            },
            SAVE_ANSWER_MULTIPLE_SUCCESS: {
              actions: ['assignMultipleSelectedAnswers'],
              target: '#waiting_for_button_click',
            },
            SAVE_ANSWER_MULTIPLE_ERROR: {
              actions: ['assignErrorMessage'],
              target: '#error',
            },
          },
        },
        save_answer: {
          id: 'save_answer',
          invoke: {
            id: 'save-answer',
            src: 'saveAnswer',
          },
          tags: ['loader', 'show_dialog'],
          on: {
            STORE_CREATED_ANSWER_RESOURCE: {
              actions: ['assignCreatedAnswerResult'],
            },
            CONFIRM_VOICE_ANSWER_BY_BUTTON_CLICK: {
              target: '#waiting_for_button_click',
            },
            SAVE_ANSWER_SUCCESS: [
              {
                cond: 'isVideoOnlyRequired',
                target: '#uploading_answer',
              },
              {
                cond: 'disableSpeechRecognition',
                target: '#execute_complete_question',
              },
              {
                cond: 'isMicOnlyRequired',
                target: '#uploading_answer',
              },
              {
                actions: [
                  'setIsProcessingSaveAnswerToFalse',
                  'setIsProcessingMediaUploadToTrue',
                ],
                target: '#uploading_answer',
              },
            ],
            QUESTION_ALREADY_ANSWERED: {
              actions: [
                'setIsQuestionAlreadyAnsweredToTrue',
                'assignErrorMessage',
              ],
              target: '#error',
            },
            DUPLICATE_SAVED_ANSWER: [
              {
                cond: 'hasAlreadySavedAnswer',
                target: '#done',
              },
              {
                actions: ['assignSavedAnswer'],
                target: '#save_answer',
              },
            ],
            SAVE_ANSWER_ERROR: {
              actions: ['assignErrorMessage'],
              target: '#error',
            },
            INVALID_ANSWERGUID: {
              target: '#done',
            },
            VIDEO_LIMIT_REACHED: '#video_limit_reached',
            PROCESSING_SAVE_ANSWER: {
              actions: ['setIsProcessingSaveAnswerToTrue'],
            },
            SAVED_ANSWER: {
              actions: ['assignSavedAnswer'],
            },
          },
        },
        uploading_answer: {
          id: 'uploading_answer',
          tags: ['loader', 'show_dialog'],
          on: {
            ANSWER_UPLOADED: {
              actions: [
                'setIsMediaUploadedToTrue',
                'setIsProcessingMediaUploadToFalse',
              ],
              target: '#execute_complete_question',
            },
            ANSWER_UPLOAD_FAILED: {
              actions: ['setIsProcessingMediaUploadToFalse'],
              target: '#uploading_answer_failed',
            },
            UPLOAD_MEDIA_FAILED: {
              actions: ['assignErrorMessage'],
              target: '#error',
            },
            VIDEO_LIMIT_REACHED: {
              target: '#video_limit_reached',
            },
          },
        },
        video_limit_reached: {
          id: 'video_limit_reached',
          tags: ['show_dialog'],
          on: {
            RESTART_RECORDING: {
              target: '#waiting_for_button_click',
            },
          },
        },
        time_limit: {
          id: 'time_limit',
          tags: ['show_dialog'],
          on: {
            RESTART_RECORDING: {
              target: '#waiting_for_button_click',
            },
          },
        },
        uploading_answer_failed: {
          id: 'uploading_answer_failed',
          tags: ['show_dialog'],
          on: {
            REUPLOAD: [
              // !Note: Don't remove this; in case we'll add it again
              // This is a check if the max reupload attempts is reached
              // {
              //   cond: 'hasReachedMaxReuploadAttempt',
              //   target: '#max_reupload_attempts_reached',
              // },
              {
                actions: [
                  'incrementReuploadAttempt',
                  'resetRequestProgress',
                  'setIsProcessingSaveAnswerToFalse',
                  'setIsProcessingMediaUploadToTrue',
                ],
                target: 'uploading_answer',
              },
            ],
          },
        },

        execute_complete_question: {
          id: 'execute_complete_question',
          tags: ['loader', 'show_dialog'],
          invoke: {
            id: 'exec-complete-question',
            src: 'execCompleteQuestion',
          },
          on: {
            COMPLETE_QUESTION_SUCCESS: [
              {
                cond: 'isLastQuestion',
                actions: [
                  'clearSelectedAnswers',
                  'clearPreviousQuestionContext',
                ],
                target: '#complete_survey',
              },
              {
                actions: [
                  'clearSelectedAnswers',
                  'clearPreviousQuestionContext',
                ],
                target: '#loading.get_next_question',
              },
            ],
            ANSWER_UNACCEPTABLE: {
              actions: ['clearPreviousContext'],
              target: '#complete_survey',
            },
            COMPLETE_QUESTION_ERROR: {
              actions: ['assignErrorMessage'],
              target: '#error',
            },
            SURVEY_DELETED: {
              actions: ['assignErrorMessage', 'hideTryAgainButtonOnErrorState'],
              target: '#error',
            },
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hist: {
      id: 'hist',
      type: 'history',
    },
    complete_survey: {
      id: 'complete_survey',
      tags: ['completing_survey', 'loader'],
      invoke: {
        id: 'exec-complete-survey',
        src: 'execCompleteSurvey',
      },
      on: {
        SURVEY_COMPLETED: [
          {
            cond: 'isSurveyAcceptable',
            target: '#survey_completed',
          },
          {
            target: '#done',
          },
        ],
        SURVEY_FAILED: [
          {
            target: '#done',
          },
        ],
        SURVEY_ALREADY_COMPLETED: {
          target: '#error',
          actions: ['assignErrorMessage', 'hideTryAgainButtonOnErrorState'],
        },
        UPLOADING_FAILED_ITEMS: {
          target: '#has_failed_items',
        },
      },
    },
    has_failed_items: {
      id: 'has_failed_items',
      invoke: {
        id: 'reupload-item',
        src: 'reuploadItem',
      },
      tags: ['completing_survey'],
      on: {
        REUPLOAD_SUCCESS_REMOVE_ID: {
          actions: ['removeFromFailedUploadIds'],
        },
        ALL_REUPLOADS_DONE: {
          target: '#complete_survey',
        },
        FAILED_UPLOAD: {
          target: '#survey_failed_upload',
        },
        UPLOAD: {
          target: 'has_failed_items',
        },
      },
    },
    survey_failed_upload: {
      id: 'survey_failed_upload',
      tags: ['completing_survey'],
      on: {
        REUPLOAD: [
          {
            cond: 'hasReachedMaxReuploadAttempt',
            target: '#max_reupload_attempts_reached',
          },
          {
            actions: ['incrementReuploadAttempt'],
            target: 'has_failed_items',
          },
        ],
      },
    },
    survey_completed: {
      id: 'survey_completed',
      tags: ['completing_survey'],
      entry: ['clearPreviousContext'],
    },
    survey_completed_but_unacceptable: {
      id: 'survey_completed_but_unacceptable',
    },
    max_reupload_attempts_reached: {
      id: 'max_reupload_attempts_reached',
      tags: ['completing_survey'],
      entry: ['clearPreviousContext', 'clearIndexedDBFailedUploads'],
    },
    error: {
      id: 'error',
      on: {
        RESET: '#idle',
        RELOAD: '#get_next_question',
      },
    },
    session_expired: {
      id: 'session_expired',
    },
    done: {
      id: 'done',
      entry: ['clearPreviousContext'],
      on: {
        RESET: '#idle',
      },
    },
  },
}
