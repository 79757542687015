/* eslint-disable arrow-body-style */
import React, { ReactChild, ReactChildren, useContext } from 'react'
import { Container } from '@pando-styles/nirvana'

import { StyledLink, StyledTabs } from './styles'
import { ESignState } from '../ESignProvider'

export interface TabItem {
  name: string
  state: string
  onClick?: () => void
  component: JSX.Element
}
export interface TabsProps {
  children?: ReactChild | ReactChildren | JSX.Element[] | JSX.Element
  tabItems: TabItem[]
}

const Tabs: React.FC<TabsProps> = ({ tabItems = [] }) => {
  const { providerState } = useContext(ESignState)

  if (!providerState) return null

  return (
    <Container height='full'>
      <>
        <StyledTabs className='styled-tabs margin--bottom-1'>
          {tabItems?.map(({ name, state, onClick }) => (
            <StyledLink
              className={providerState.matches(state) ? 'active' : ''}
              onClick={onClick}
              key={state}
            >
              {name}
            </StyledLink>
          ))}
        </StyledTabs>
        {/* <Container>{component}</Container> */}
      </>
    </Container>
  )
}

export default Tabs
