/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react'
import { Button, Icon } from '@pando-styles/nirvana'

import {
  StyledHeadingThree,
  StyledWrapper,
  StyledParagraph,
  StyledWrapperBodyContainer,
  StyledExitContainer,
  StyledExitSpan,
  StyledWrapperBody,
} from '@styles/index'
import { Camera } from '@atomic/index'
import { MainActions, MainState } from '@pages/index'
import { BootloaderState } from '@system/Bootloader'

import { StyledFooterContainer } from './styles'

enum TestingState {
  CHECK_CAMERA = 'CHECK_CAMERA',
  CHECK_VIDEO = 'CHECK_VIDEO',
  CHECK_AUDIO = 'CHECK_AUDIO',
}

const SetupSurveyCamera: React.FC = () => {
  const { bootloaderState } = useContext(BootloaderState)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)

  const [isCaptureVideoActive, activateVideoCapture] = useState(true)
  const [isCameraOff, setCameraOff] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [clickedBtnIndex, setClickedBtnIndex] = useState(0)

  const [testingState, setTestingState] = useState<TestingState>(
    TestingState.CHECK_CAMERA
  )

  if (!homeProviderActions || !homeProviderState || !bootloaderState)
    return null

  const { handleExitSurvey, handleStartSurveyQuestions } =
    homeProviderActions ?? {}

  const stopCamera = () => {
    activateVideoCapture(false)
  }

  const handleExit = () => {
    handleExitSurvey()
  }

  const handleNext = (btnIndex: number) => {
    stopCamera()
    setCameraOff(1)
    setIsLoading(true)
    setClickedBtnIndex(btnIndex)
  }

  /**
   * @remarks
   * After clicking handleNext function isCameraOff is incremened to one.
   * If its one then a useEffect detects it, then run the handleGoToSetupMicrophone function.
   * handleGoToSetupMicrophone is wrapped in a setTimeout to give the component
   * enough time to stop the camera before unmounting the camera component.
   */
  useEffect(() => {
    if (isCameraOff) {
      clickedBtnIndex && handleStartSurveyQuestions()
      setIsLoading(false)
      setCameraOff(0)
    }
  }, [isCameraOff])

  //will set to hidden by default as of now
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )
  const isNavBarHidden = true

  return (
    <StyledWrapper>
      {!isNavBarHidden && (
        <StyledExitContainer>
          <StyledExitSpan className='flex align-center' onClick={handleExit}>
            <Icon iconName='chevron-left' size='xl' />
            Back
          </StyledExitSpan>
        </StyledExitContainer>
      )}
      <StyledWrapperBody>
        {testingState === TestingState.CHECK_CAMERA && (
          <StyledWrapperBodyContainer justifyContent='center'>
            <StyledHeadingThree>Maintain Camera View</StyledHeadingThree>
            <StyledParagraph className='margin--top-1 margin--bottom-2 padding--x-1-at-800'>
              This survey requires your face to be in the camera frame. Please
              keep your face aligned with the silhouette.
            </StyledParagraph>
            <Camera isCaptureVideoActive={isCaptureVideoActive} />
            <StyledFooterContainer>
              <Button
                label='Continue'
                hoverColorWeight='300'
                onClick={() => handleNext(1)}
                isLoading={clickedBtnIndex === 1 && isLoading}
                loadingLabel='Continue'
                // @ts-ignore
                isDisabled={!window.stream}
                className='w-200 font-14'
              />
            </StyledFooterContainer>
          </StyledWrapperBodyContainer>
        )}
      </StyledWrapperBody>
    </StyledWrapper>
  )
}

export default SetupSurveyCamera
