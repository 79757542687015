import React, { useContext, useState } from 'react'
import { Button } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledHomeContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { BootloaderActions } from '@system/Bootloader'

import { StyledButtonDiv } from '../styles'

const TokenRequestFailed: React.FC = () => {
  const { bootloaderActions } = useContext(BootloaderActions)
  const [clickedBtnIndex, setClickedBtnIndex] = useState(0)

  if (!bootloaderActions) return null
  const { handleLogout } = bootloaderActions ?? {}

  const handleClickedLogout = () => {
    setClickedBtnIndex(1)
    handleLogout()
  }

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <StyledParagraph>
                Max Request Token attempts reached.
              </StyledParagraph>
              <StyledButtonDiv className='margin--top-1'>
                <Button
                  hoverColorWeight='300'
                  label='Logout'
                  onClick={handleClickedLogout}
                  isLoading={clickedBtnIndex === 2}
                  loadingLabel='Logging out'
                  className='font-14'
                />
              </StyledButtonDiv>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default TokenRequestFailed
