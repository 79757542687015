/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { Container } from '@pando-styles/nirvana'

import { StyledTabLabel, StyledTabs, StyledTabItem } from './styles'
import { TabsTypes } from './types'

const Tabs: React.FC<TabsTypes> = (props) => {
  const { tabItems, component } = props
  const { routes = [] } = tabItems ?? {}
  const { config } = tabItems
  const { url, path } = useRouteMatch()
  const activeRoute = useLocation()

  const isRouteActive = (route_name: string) => {
    const _activeRoute: string | undefined = activeRoute.pathname
      .split('/')
      .pop()
    return _activeRoute === route_name
  }

  return (
    <Container height='full'>
      <StyledTabs className='styled-tabs'>
        {config.is_enabled && (
          <>
            {routes.length > 0 &&
              routes.map(({ tab_name, route_name, application_id }) => (
                <StyledTabItem
                  style={{
                    backgroundColor: isRouteActive(route_name)
                      ? '#EAF5F5'
                      : '#F8F8F8',
                  }}
                  to={
                    url === '/' ? `${url}${route_name}` : `${url}/${route_name}`
                  }
                  key={application_id}
                >
                  <StyledTabLabel>{tab_name}</StyledTabLabel>
                </StyledTabItem>
              ))}
          </>
        )}
      </StyledTabs>
      <Switch>
        {routes.map(({ route_name, application_id }) => (
          <Route
            path={
              path === '/' ? `${path}${route_name}` : `${path}/${route_name}`
            }
            key={application_id}
          >
            {component.find((name) => name.key === route_name)?.name}
          </Route>
        ))}
      </Switch>
    </Container>
  )
}

export default React.memo(Tabs)
