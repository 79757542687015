/* eslint-disable indent */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon, Button, Container } from '@pando-styles/nirvana'

import { StyledSurveyContainer } from '@pages/Main/styles'
import { StyledHeadingThree, StyledParagraph } from '@styles/index'
import { parseQuestionText } from '@utils/index'

import { StyledButtonWrapper, StyledVideoResponseWrapper } from '../styles'
import {
  VideoResponseState,
  VideoResponseActions,
} from '../../VideoResponseProvider'

export interface SurveyVideoResponseTypes {
  [key: string]: Record<string, unknown>
}
const SurveyVideoResponse: React.FC<SurveyVideoResponseTypes> = () => {
  const navigate = useHistory()

  const { providerState } = useContext(VideoResponseState)
  const { providerActions } = useContext(VideoResponseActions)
  const { handleViewQuestion } = providerActions ?? {}
  if (!providerState || !providerActions) return null
  const { questions = [] } = providerState?.context ?? {}

  return (
    <StyledVideoResponseWrapper>
      <Container className='CONTAINER_VIDEO_RESPONSE_HEADER h-80'>
        <StyledButtonWrapper>
          <Button
            label='Back'
            layout='outline'
            onClick={() => {
              navigate.goBack()
            }}
            className='borderless-button has-icon video-response-back-btn font-14'
            icon='chevron-left'
          />
          <StyledHeadingThree className='margin--bottom-2 flex-center'>
            Video Response
          </StyledHeadingThree>
        </StyledButtonWrapper>
      </Container>
      {questions?.length ? (
        questions?.map((questionWithAnswer) => {
          const { question } = questionWithAnswer ?? {}

          return (
            <StyledSurveyContainer
              key={question?.guid}
              className='margin--bottom-1'
              clickable
              onClick={() => handleViewQuestion?.(questionWithAnswer)}
            >
              <div className='left-2'>
                <StyledHeadingThree className='margin--top-1 margin--bottom-1 teal'>
                  {question?.name}
                </StyledHeadingThree>
                <StyledParagraph>
                  {parseQuestionText(question?.text)}
                </StyledParagraph>
              </div>
              <Icon
                iconName='chevron-right'
                className='chevronRight'
                clickable
                hoverable
                onClick={() => handleViewQuestion?.(questionWithAnswer)}
              />
            </StyledSurveyContainer>
          )
        })
      ) : (
        <StyledParagraph className='text-center'>
          Survey was marked as unacceptable.
        </StyledParagraph>
      )}
    </StyledVideoResponseWrapper>
  )
}

export default SurveyVideoResponse
