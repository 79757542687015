import 'regenerator-runtime/runtime'
import {
  ESignRequestDataServicePromiseClient,
  GetESignRequestDetail,
  setRequest,
} from '@utils/index'

type Params = Partial<GetESignRequestDetail.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getRequestDetail = async (params: Params, accessToken: string) => {
  const service = new ESignRequestDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new GetESignRequestDetail(), params)

  const result = await service.getRequestDetail(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
