/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react'
import moment from 'moment'
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom'
import { Container, Icon } from '@pando-styles/nirvana'

import { DocumentListItem, SignatoryRequestDetail } from '@utils/index'
import { StyledHeadingThree, StyledParagraph } from '@styles/index'

import {
  StyledDocuments,
  Wrapper,
  StyledAccordion,
  AccordionHeader,
  Section,
  SubHeading,
} from './styles'

type SignatoryItemProps = Partial<DocumentListItem.AsObject> & {
  signatoryDetails?: Omit<SignatoryRequestDetail.AsObject, 'documentsList'> & {
    documentsList: DocumentListItem.AsObject[]
  }
  isFetching?: boolean
}

const SignatoryItem: React.FC<SignatoryItemProps> = (props) => {
  const { url, path } = useRouteMatch()
  const navigate = useHistory()

  const {
    guid = '',
    name,
    signatoryGuid,
    signatoryDetails,
    isFetching = false,
  } = props ?? {}
  const { documentsList = [] } = signatoryDetails ?? {}

  const [isAccordionOpen, setAccordionOpen] = useState(Boolean)

  const actions = {
    handleOpenAccordion: () => {
      setAccordionOpen(!isAccordionOpen)
    },
  }

  const handleOpenDocument = (doc: any) => {
    navigate.push(`${url}/${signatoryGuid}/${doc.guid}`)
  }

  return (
    <Fragment>
      <StyledAccordion key={guid} className='margin--bottom-1 mari'>
        <AccordionHeader
          isFetching={isFetching}
          onClick={isFetching ? () => {} : actions.handleOpenAccordion}
        >
          <Section>
            <small>ESign</small>
            <StyledHeadingThree className='margin--top-1-4 teal'>
              {name}
            </StyledHeadingThree>
            <StyledParagraph>
              {documentsList?.length ?? '0'} documents
            </StyledParagraph>
          </Section>
          <Icon
            iconName={isAccordionOpen ? 'minus-circle' : 'plus-circle'}
            className='plus-circle'
            clickable
            hoverable
            onClick={isFetching ? () => {} : actions.handleOpenAccordion}
          />
        </AccordionHeader>
        {/* <Switch>
          <Route path={`${path}/signed/:document`}>
            <h1>DISPLAY DOCUMENTS HERE</h1>
          </Route>
        </Switch> */}
        {isAccordionOpen ? (
          <Container>
            {documentsList.length > 0
              ? documentsList.map((doc, index) => (
                  <StyledDocuments
                    key={index}
                    className='styled-'
                    isAccordionOpen={isAccordionOpen}
                    onClick={() => handleOpenDocument(doc)}
                  >
                    <Wrapper>
                      <section>
                        <small>
                          Signed on:{' '}
                          {moment
                            .unix(doc?.dateCompletedUtc?.seconds)
                            .format('DD-MM-YYYY hh:mm a')}{' '}
                        </small>
                        <SubHeading className='teal margin--top-1-4'>
                          {doc?.name ?? ''}
                        </SubHeading>
                      </section>
                      <Icon
                        iconName='chevron-right'
                        className='plus-circle'
                        clickable
                        hoverable
                        onClick={() => handleOpenDocument(doc)}
                      />
                    </Wrapper>
                  </StyledDocuments>
                ))
              : 'No documents found.'}
          </Container>
        ) : (
          <></>
        )}
      </StyledAccordion>
    </Fragment>
  )
}

export default SignatoryItem
