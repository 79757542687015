/* eslint-disable no-empty */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
import { Sender } from 'xstate'
import { usePandoLogger } from '@pandolink/utils'

import {
  getNextQuestion,
  createAnswer,
  saveAnswer,
  execCompleteQuestion,
  execCompleteSurvey,
  uploadAnswerMedia,
  AnswerAction,
  AnswerType,
  CompletionType,
  SelectionType,
  execRepeatQuestion,
} from '@utils/index'

import { Context } from '../types'

export const services: any = {
  getNextQuestion:
    ({ params, question, accessToken, createdAnswer }: Context) =>
    async (send: Sender<any>) => {
      try {
        const oidcContext =
          JSON.parse(localStorage.getItem('oidc') ?? '')?.context ?? {}
        const activeAccessToken =
          oidcContext?.accessToken !== accessToken
            ? oidcContext?.accessToken
            : accessToken

        const hasQuestionData =
          Object.keys(question ?? {}).length === 0 ? false : true

        if (activeAccessToken) {
          const result = await getNextQuestion(
            {
              attemptGuid: params?.attemptGuid ?? '',
            },
            activeAccessToken
          )

          if (hasQuestionData) {
            send({ type: 'GOT_QUESTION', data: result.toObject() })
            return
          }

          if (!result.toObject()?.question) {
            send('NO_QUESTION')
            return
          }

          if (
            createdAnswer?.questionGuid !== result.toObject()?.question?.guid
          ) {
            send('REMOVE_CREATED_ANSWER_RESOURCE')
          }
          send({
            type: 'GOT_QUESTION',
            data: result.toObject(),
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'getNextQuestion Error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_QUESTION_ERROR',
          data: error?.message,
        })
      }
    },

  saveAnswer:
    (
      {
        params,
        selectedAnswer,
        selectedAnswers,
        savedAnswer,
        accessToken,
        question,
        createdAnswer: createdAnswerObj,
        isQuestionAlreadyAnswered,
        isProcessingSaveAnswer,
        isProcessingMediaUpload,
      }: Context,
      event: any
    ) =>
    async (send: Sender<any>) => {
      try {
        send({
          type: 'PROCESSING_SAVE_ANSWER',
        })

        if (Number(selectedAnswer?.videoSize) >= 10485760) {
          send('VIDEO_LIMIT_REACHED')

          return
        }
        let createdAnswer

        const isCreatedAnswerObjEmpty =
          Object.keys(createdAnswerObj ?? {}).length <= 0
        const skipSavedAnswerDelete =
          Object.keys(savedAnswer ?? {}).length > 0 &&
          savedAnswer?.answerAction === 1

        const shouldCallSavedAnswer =
          !isCreatedAnswerObjEmpty &&
          (isQuestionAlreadyAnswered ||
            isProcessingSaveAnswer ||
            isProcessingMediaUpload)

        if (shouldCallSavedAnswer && !skipSavedAnswerDelete) {
          await saveAnswer(
            {
              answerGuid: createdAnswerObj?.guid ?? '',
              answerAction: selectedAnswer?.answerAction ?? AnswerAction.DELETE,
              answerType: selectedAnswer?.answerType ?? AnswerType.BUTTON,
              text: selectedAnswer?.name ?? '',
              timestamp: 0,
            },
            accessToken!
          )

          send({
            type: 'SAVED_ANSWER',
            payload: { answerAction: AnswerAction.DELETE },
          })

          createdAnswer = createdAnswerObj
        }
        if (isCreatedAnswerObjEmpty) {
          const createdAnswerResource = await createAnswer(
            {
              attemptGuid: params?.attemptGuid ?? '',
              questionGuid: question?.guid ?? '',
              deviceId: '',
              guid: params?.attemptGuid ?? '',
              mediaGuidsList: [],
              text: '',
            },
            accessToken!
          )

          send({
            type: 'STORE_CREATED_ANSWER_RESOURCE',
            payload: createdAnswerResource.toObject(),
          })
          createdAnswer = createdAnswerResource?.toObject()
        }

        await saveAnswer(
          {
            answerGuid: createdAnswer?.guid ?? '',
            answerAction: selectedAnswer?.answerAction ?? AnswerAction.ADD,
            answerType: selectedAnswer?.answerType ?? AnswerType.BUTTON,
            text: selectedAnswer?.name ?? '',
            timestamp: 0,
          },
          accessToken!
        )
        send({
          type: 'SAVED_ANSWER',
          payload: { answerAction: AnswerAction.ADD },
        })

        if (selectedAnswer?.answerType === AnswerType.VOCAL) {
          send({
            type: 'CONFIRM_VOICE_ANSWER_BY_BUTTON_CLICK',
          })
          return
        }

        send('SAVE_ANSWER_SUCCESS')
      } catch (error: any) {
        usePandoLogger({
          name: 'saveAnswer: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }
        if (error?.code === 6) {
          //! This error is caused by CreateAnswer
          send({
            type: 'QUESTION_ALREADY_ANSWERED',
            data: error?.message,
          })
          return
        }

        if (error?.code === 9) {
          send({
            type: 'DUPLICATE_SAVED_ANSWER',
            payload: {
              errorMessage: error?.message,
            },
            data: error?.message,
          })
          return
        }
        if (error?.code === 3) {
          send({
            type: 'INVALID_ANSWERGUID',
            data: error?.message,
          })
          return
        }
        send({
          type: 'SAVE_ANSWER_ERROR',
          data: error?.message,
        })
      }
    },

  saveAnswerMultiple:
    (
      {
        params,
        selectedAnswer,
        selectedAnswers,
        accessToken,
        question,
        createdAnswer,
      }: Context,
      event: any
    ) =>
    async (send: Sender<any>) => {
      try {
        let answerResource = createdAnswer

        if (!createdAnswer?.guid) {
          const createdAnswerResource = await createAnswer(
            {
              attemptGuid: params?.attemptGuid ?? '',
              questionGuid: question?.guid ?? '',
              deviceId: '',
              guid: params?.attemptGuid ?? '',
              mediaGuidsList: [],
              text: '',
            },
            accessToken!
          )

          send({
            type: 'STORE_CREATED_ANSWER_RESOURCE',
            payload: createdAnswerResource.toObject(),
          })

          answerResource = createdAnswerResource?.toObject()
        }

        const answerAction = selectedAnswers?.[selectedAnswer?.guid ?? '']
          ? AnswerAction.DELETE
          : AnswerAction.ADD

        await saveAnswer(
          {
            answerGuid: answerResource?.guid ?? '',
            answerAction: answerAction,
            answerType: selectedAnswer?.answerType ?? AnswerType.BUTTON,
            text: selectedAnswer?.name ?? '',
            timestamp: 0,
          },
          accessToken!
        )

        send({
          type: 'SAVE_ANSWER_MULTIPLE_SUCCESS',
          payload: selectedAnswer,
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'saveAnswerMultiple: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'SAVE_ANSWER_MULTIPLE_ERROR',
          payload: error?.message,
        })
      }
    },

  execCompleteQuestion:
    ({ question, createdAnswer, accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result = await execCompleteQuestion(
          {
            answerGuid: createdAnswer?.guid ?? '',
          },
          accessToken!
        )

        if (
          result?.toObject()?.completionType === CompletionType.UNACCEPTABLE
        ) {
          send('ANSWER_UNACCEPTABLE')
          return
        }

        send({
          type: 'COMPLETE_QUESTION_SUCCESS',
          data: result?.toObject(),
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'execCompleteQuestion: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        // this is to modify the 'This survey has been deleted.' error message
        if (error?.code === 13) {
          send({
            type: 'SURVEY_DELETED',
            data: 'This survey cannot be continued because it has been deleted. Please contact the organization that sent you this survey.',
          })
        }

        send({
          type: 'COMPLETE_QUESTION_ERROR',
          data: error?.message,
        })
      }
    },

  execCompleteSurvey:
    ({ params, accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result = await execCompleteSurvey(
          {
            surveyAttemptGuid: params?.attemptGuid ?? '',
          },
          accessToken!
        )

        send({
          type: 'SURVEY_COMPLETED',
          data: result.toObject(),
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'execCompleteSurvey: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        if (error?.code === 9) {
          send({
            type: 'SURVEY_ALREADY_COMPLETED',
            data: 'This survey has already been completed or deleted. Please contact the organization that sent you this survey.',
          })
          return
        }

        send({
          type: 'SURVEY_FAILED',
          data: error,
        })
      }
    },

  reuploadItem:
    ({ accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
      } catch (error: any) {
        usePandoLogger({
          name: 'reuploadItem: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }
      }
    },
  execRepeatQuestion:
    ({ question, params, accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        params?.attemptGuid &&
          question?.guid &&
          (await execRepeatQuestion(
            {
              attemptGuid: params?.attemptGuid,
              questionGuid: question?.guid,
            },
            accessToken!
          ))
      } catch (error: any) {
        console.error('Error [execRepeatQuestion]:', error?.message)
        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }
      }
      send('EXEC_REPEAT_QUESTION')
    },
}
