import 'regenerator-runtime/runtime'
import {
  SurveyInstanceDataServicePromiseClient,
  GetInstanceRequest,
  setRequest,
} from '@utils/index'

type Params = Partial<GetInstanceRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getInstance = async (params: Params, accessToken: string) => {
  const service = new SurveyInstanceDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  console.log('@DEBUG [getInstance]:', { params, accessToken })

  const request = setRequest(new GetInstanceRequest(), params)

  const result = await service.getInstance(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
