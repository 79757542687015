/* eslint-disable no-empty-pattern */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { SignatureType } from '@utils/index'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  updateAccessToken: assign({
    accessToken: ({}, { payload }: any) => payload,
  }),

  assignGeneratedSignature: assign({
    signature: ({}, { payload, data }: any) => {
      const signature = payload || data?.signatureImage

      return {
        type: SignatureType.GENERATED,
        image: signature,
      }
    },
  }),

  assignHandwrittenSignature: assign({
    signature: ({}, { payload, data }: any) => {
      const signature = payload || data?.signatureImage

      return {
        type: SignatureType.HANDWRITTEN,
        image: signature,
      }
    },
  }),

  assignSignatoryDetails: assign({
    signatoryDetails: ({}, { data }: any) => data,
  }),

  assignDocuments: assign({
    documents: ({}, { data }: any) => data,
  }),

  assignDocument: assign({
    currentDocumentDetails: ({}, { data }: any) => data?.details ?? {},
    currentDocumentIndex: ({}, { data }: any) => data?.index,
  }),

  incrementCurrentDocumentIndex: assign({
    currentDocumentIndex: ({ currentDocumentIndex = 0 }) =>
      currentDocumentIndex + 1,
  }),

  removeSignature: assign({
    signature: ({}) => ({
      image: '',
      type: SignatureType.NOT_SET,
    }),
  }),

  clearContext: assign({
    currentDocumentDetails: ({}) => ({}),
    currentDocumentIndex: ({}) => 0,
    signatoryDetails: ({}) => ({}),
    signature: ({}) => ({}),
    signatoryGuid: ({}) => '',
  }),

  updateSignatoryGuid: assign({
    signatoryGuid: ({}, { payload }: any) => payload,
  }),
}
