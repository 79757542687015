import 'regenerator-runtime/runtime'
import {
  ESignRequestDataServicePromiseClient,
  GetDocumentRequest,
  setRequest,
} from '@utils/index'

type Params = Partial<GetDocumentRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getDocument = async (params: Params, accessToken: string) => {
  const service = new ESignRequestDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new GetDocumentRequest(), params)

  const result = await service.getDocument(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
