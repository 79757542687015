import React, { useContext } from 'react'
import { RaySpinner, Icon } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledExitContainer,
  StyledExitSpan,
  StyledHomeContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { MainState, MainActions } from '@pages/index'

// const { REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList } = process.env

const Loading: React.FC = () => {
  const { providerState } = useContext(MainState)
  const { providerActions } = useContext(MainActions)

  if (!providerActions || !providerState) return null

  // const { selectedInstance } = providerState?.context ?? {}
  // const { organizationName = '' } = selectedInstance ?? {}
  const { handleGoBackToShouldContinueAttempt } = providerActions ?? {}

  const handleExit = () => {
    handleGoBackToShouldContinueAttempt()
  }

  //will set to hidden by default as of now
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )
  const isNavBarHidden = true

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          {!isNavBarHidden && (
            <StyledExitContainer>
              <StyledExitSpan
                className='flex align-center'
                onClick={handleExit}
              >
                <Icon iconName='chevron-left' size='xl' />
                Back
              </StyledExitSpan>
            </StyledExitContainer>
          )}
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <RaySpinner color='primary' colorWeight='DEFAULT' />
              <StyledParagraph className='margin--top-1'>
                {providerState.matches('loading') && 'Initializing...'}
                {providerState.hasTag('create_attempt') &&
                  'Creating survey attempt...'}
                {providerState.hasTag('check_url_parameters') && 'Loading...'}
                {providerState.hasTag('claim_signatory') &&
                  'Claiming signatory...'}
              </StyledParagraph>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default Loading
