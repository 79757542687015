import 'regenerator-runtime/runtime'
import {
  SurveyInstanceExecutionServicePromiseClient,
  SaveAnswerRequest,
  setRequest,
} from '@utils/index'

type Params = SaveAnswerRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const saveAnswer = async (params: Params, accessToken: string) => {
  const service = new SurveyInstanceExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new SaveAnswerRequest(), params)

  const result = await service.saveAnswer(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
