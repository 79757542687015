/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, RaySpinner, Button, Icon } from '@pando-styles/nirvana'

import { StyledParagraph } from '@styles/index'

import {
  VideoResponseActions,
  VideoResponseState,
} from './VideoResponseProvider'

import { SurveyVideoResponse, MediaPlayback, Error } from './components'

const VideoResponseConsumer: React.FC = () => {
  const { providerState } = useContext(VideoResponseState)
  const { providerActions } = useContext(VideoResponseActions)

  if (!providerState || !providerActions) return null

  const { errorMessage } = providerState.context ?? {}
  const Component = () => {
    if (providerState.matches('loading')) {
      return (
        <Container
          size='lg'
          height='full'
          direction='column'
          centerX
          className='survey-tab-list--inner-container inner-container px-3-at-1200 px-1-at-600 flex-center'
        >
          <>
            <RaySpinner color='primary' colorWeight='DEFAULT' />
            <StyledParagraph className='margin--top-1'>
              Fetching questions...
            </StyledParagraph>
          </>
        </Container>
      )
    }

    if (providerState.matches('invalid_survey_attempt')) {
      return <Error text='Survey is invalid. (unacceptable)' />
    }

    if (providerState.matches('error')) {
      return (
        <div className='flex-column'>
          <Icon
            iconName='alert-circle'
            color='danger'
            className={`scale-1-5 m-auto margin--bottom-1`}
          />
          <Error text={errorMessage ?? 'Something went wrong!'} />
        </div>
      )
    }

    if (providerState.matches('ready.questions_loaded')) {
      return <SurveyVideoResponse />
    }

    if (providerState.matches('ready.view_question')) {
      return <MediaPlayback />
    }
    return <RaySpinner color='primary' colorWeight='DEFAULT' />
  }

  return <Component />
}

export default VideoResponseConsumer
