/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Themes } from '@pando-styles/nirvana'

import App from './App'

const container = document.getElementById('root')

ReactDOM.hydrateRoot(
  container!,
  <ThemeProvider theme={Themes.base ?? {}}>
    <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </ThemeProvider>
)

if (module.hot) {
  module.hot.accept()
}
