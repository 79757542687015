/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'

import { usePando } from '@utils/index'

import { config, options } from './machine'
import { ActionsProps, HomeStateType, MainActionsType } from './types'

export const MainState = createContext<Partial<HomeStateType>>({})
export const MainActions = createContext<Partial<MainActionsType>>({})

type PropsWithChildrenOnly = PropsWithChildren<unknown>

import { BootloaderState, BootloaderActions } from '@system/Bootloader'

const HomeProvider: React.FC<PropsWithChildrenOnly> = ({
  children,
  ...props
}) => {
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)
  const navigate = useHistory()

  if (!bootloaderState || !bootloaderActions) return null

  const { handleTokenExpired } = bootloaderActions ?? {}

  const oidcString = localStorage.getItem('oidc')
  const oidc = oidcString ? JSON.parse(oidcString)?.context : {}
  const accessToken = oidc?.accessToken

  const {
    accessToken: bootloaderAccessToken,
    loggedInAsGuest = false,
    instanceGuid = '',
    signatoryGuid = '',
    claimCode = '',
    claimedInstances = {},
  } = bootloaderState?.context ?? {}

  const machine = React.useMemo(
    () => ({
      machine: {
        config,
        options,
        context: {
          params: {},
          accessToken: accessToken ?? '',
          loggedInAsGuest,
          instanceGuid,
          signatoryGuid,
          claimCode,
          sitePermissions: {
            camera: false,
            microphone: false,
          },
        },
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  if (!state) return null

  const {
    accessToken: storedAccessToken,
    claimedInstance = false,
    instanceGuid: storedInstanceGuid,
  } = state.context ?? {}

  // OIDC Token Update - Start
  useEffect(() => {
    console.log('@DEBUG [MAIN PROVIDER] tokens', {
      storedAccessToken,
      accessToken: accessToken,
      areTheyTheSame: storedAccessToken === storedAccessToken,
    })

    if (storedAccessToken !== accessToken) {
      send({
        type: 'GOT_NEW_ACCESS_TOKEN',
        payload: accessToken,
      })
    }
  }, [accessToken, storedAccessToken, oidc])
  // OIDC Token Update - End

  // If OIDC did not transition to token expired
  // due to component is not rendered
  // at the time the token expired
  useEffect(() => {
    if (state.matches('session_expired')) {
      handleTokenExpired()
    }
  }, [state])
  // end

  const urlParams = [instanceGuid, signatoryGuid, claimCode]
  const hasChangesInUrlParams = urlParams.some((params) => params)

  // logged in as user; changed in url params
  useEffect(() => {
    if (!loggedInAsGuest && hasChangesInUrlParams && !claimedInstance) {
      console.count('🔥 @DEBUG [MainProvider]: Counting GOT_NEW_URL_PARAMS')

      send({
        type: 'GOT_NEW_URL_PARAMS',
        payload: {
          instanceGuid,
          claimCode,
          signatoryGuid,
        },
      })
    }
  }, [loggedInAsGuest, hasChangesInUrlParams, claimedInstance])

  // logged in as guest; changed in url params; anon login enabled
  useEffect(() => {
    if (loggedInAsGuest && hasChangesInUrlParams && !claimedInstance) {
      console.log('@DEBUG [MainProvider]:', { instanceGuid })
      send({
        type: 'GOT_NEW_URL_PARAMS',
        payload: {
          instanceGuid,
          claimCode,
          signatoryGuid,
        },
      })
    }
  }, [loggedInAsGuest, hasChangesInUrlParams, claimedInstance])

  useEffect(() => {
    console.log(
      `@DEBUG [MainProvider]: Current State: %c${JSON.stringify({
        stateValue: state.value,
        stateEventType: state.event?.type,
      })}!`,
      'color: yellow'
    )
  }, [state.value])

  // watch bootloader changes here
  // and check for instance if its already claimed
  useEffect(() => {
    if (bootloaderState.matches('authenticated.idle')) {
      const instanceAlreadyClaimed = claimedInstances[storedInstanceGuid ?? '']

      console.log('@DEBUG [BOOTLOADER_CLAIM_INSTANCE_SUCCESS]', {
        storedInstanceGuid,
        instanceAlreadyClaimed,
        claimedInstances,
      })

      if (
        storedInstanceGuid &&
        instanceAlreadyClaimed !== undefined &&
        instanceAlreadyClaimed !== null
      ) {
        console.log('@DEBUG [storedInstanceGuid] for claim instance success')
        if (instanceAlreadyClaimed) {
          // If instance is already claimed, start get_instance_details
          console.log('@DEBUG sending BOOTLOADER_CLAIM_INSTANCE_SUCCESS')
          send('BOOTLOADER_CLAIM_INSTANCE_SUCCESS')

          return
        }
      }
    }
  }, [bootloaderState.value, storedInstanceGuid, claimedInstances])

  const actions: ActionsProps = {
    handleNext: () => {
      send('NEXT')
    },
    handleReload: () => {
      send('RELOAD')
    },
    handleSelectSurvey: (instance) => {
      navigate.push('/')
      send({
        type: 'SELECT_SURVEY',
        data: instance,
      })
    },
    handleResumeSurvey: (instance) => {
      navigate.push('/')
      send({
        type: 'RESUME_SURVEY',
        data: instance,
      })
    },
    handleStartSurvey: () => {
      send('START_SURVEY')
    },
    handleStartSurveyQuestions: () => {
      send('START_SURVEY_QUESTIONS')
    },
    handleRefetchSurveys: () => {
      send('REFETCH_SURVEYS')
    },
    handleExitSurvey: () => {
      send('EXIT')
    },
    handleGoToSurveyList: () => {
      window.location.reload()
      send('GO_BACK_TO_SURVEY_LIST')
    },
    handleContinueAttempt: () => {
      send('CONTINUE_ATTEMPT')
    },
    handleNewAttempt: (instance) => {
      if (instance) {
        navigate.push('/')
        send({
          type: 'NEW_ATTEMPT',
          data: instance,
        })

        return
      }

      send('NEW_ATTEMPT')
    },
    handleShouldCreateNewAttempt: () => {
      send('SHOULD_CREATE_NEW_ATTEMPT')
    },
    handleFacialRecognition: (image) => {
      send({
        type: 'EXEC_FACIAL_RECOGNITION',
        payload: image,
      })
    },
    handleGoToSetupCamera: () => {
      send('GO_TO_SETUP_CAMERA')
    },
    handleGoToSetupMicrophone: () => {
      send('GO_TO_SETUP_MICROPHONE')
    },
    handleGoToSetupMicrophoneWithoutCamera: () => {
      send('GO_TO_SETUP_MICROPHONE_WITHOUT_CAMERA')
    },
    handleSetupDoneWithoutMicrophone: () => {
      send('SETUP_DONE_WITHOUT_MICROPHONE')
    },
    handleSetupDone: () => {
      send('SETUP_DONE')
    },
    handleAllowedCameraUsage: () => {
      send('ALLOWED_CAMERA_USAGE')
    },
    handleAssignPermissions: (sitePermissions) => {
      send({
        type: 'ASSIGN_SITE_PERMISSIONS',
        payload: sitePermissions,
      })
    },
    handleBlockedCameraUsage: () => {
      send('BLOCKED_CAMERA_USAGE')
    },
    handleAllowedMicrophoneUsage: () => {
      send('ALLOWED_MICROPHONE_USAGE')
    },
    handleBlockedMicrophoneUsage: () => {
      send('BLOCKED_MICROPHONE_USAGE')
    },
    handleStartEsignExecution: () => {
      window.location.reload()
      send('START_ESIGN_EXECUTION')
    },
    handleRetestVideoAndAudio: () => {
      send('RETEST_VIDEO_AND_AUDIO')
    },
    handleGotNewUrlParams: () => {
      send('GOT_NEW_URL_PARAMS')
    },
    handleGoBackToShouldContinueAttempt: () => {
      send('GO_BACK_TO_SHOULD_CONTINUE_ATTEMPT')
    },
  }

  return (
    <MainState.Provider
      value={{
        providerState: state,
        providerProps: props,
      }}
    >
      <MainActions.Provider
        value={{
          providerActions: actions,
        }}
      >
        {children}
      </MainActions.Provider>
    </MainState.Provider>
  )
}
export default HomeProvider
