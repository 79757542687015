/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Input, Table, Icon, RaySpinner } from '@pando-styles/nirvana'

import { usePando, fullTextSearch } from '@utils/index'
import { BootloaderState, BootloaderActions } from '@system/Bootloader'
import { StyledFlexContainer, StyledParagraph } from '@styles/index'
import { StyledLoadingContainer } from '@pages/Main/styles'

import { config, options } from './machine'
import {
  StyledGridContainer,
  StyledGridHeader,
  GridToolbar,
  SearchBar,
} from './styles'
import { ActionsTypes, GridProps } from './types'

import { DatePicker } from '@atomic/index'
import moment from 'moment'

const Grid: React.FC<GridProps> = (props) => {
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (!bootloaderState || !bootloaderActions) return null

  const { handleTokenExpired } = bootloaderActions ?? {}

  const { appConfig, queryParams, actions, customComponent } = props

  if (!bootloaderState) return null

  const { accessToken } = bootloaderState?.context ?? {}

  const { onFetch } = actions ?? {}

  const machine = React.useMemo(
    () => ({
      machine: {
        config: {
          ...config,
          id: `${config?.id}:${appConfig.storage_key}`,
        },
        context: {
          applicationConfig: appConfig,
          params: queryParams,
          onFetch,
        },
        options,
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  if (!state) return null

  const {
    applicationConfig,
    applicationData,
    accessToken: storedAccessToken,
    params,
  } = state.context
  const {
    initialState,
    showDatePicker = true,
    searchText = '',
  } = applicationConfig ?? {}
  const { pagination, items = [] } = applicationData ?? {}
  const { end = '', start = '' } = params ?? {}
  const { sortOrder = 'descending' } = initialState ?? {}

  const tableHandlers: ActionsTypes = {
    onNext: () => send({ type: 'NEXT', onFetch }),
    onPrevious: () => send({ type: 'PREVIOUS', onFetch }),
    onJumpToFirst: () => send({ type: 'JUMP_TO_FIRST', onFetch }),
    onJumpToLast: () => send({ type: 'JUMP_TO_LAST', onFetch }),
    onJumpToPage: (page) =>
      send({ type: 'JUMP_TO_PAGE', payload: page, onFetch }),
    onChecked: () => send({ type: 'CHECKED', onFetch }),
    toggleSort: () => send({ type: 'SORT', onFetch }),
    handleReload: () => send({ type: 'RELOAD', onFetch }),
    handleSelectDate: (payload) =>
      send({
        type: 'SELECT_DATE',
        payload,
        onFetch,
      }),
    handleUpdateSearchText: (searchText) =>
      send({
        type: 'UPDATE_SEARCH_TEXT',
        payload: searchText,
        onFetch,
      }),
    handleSearch: () =>
      send({
        type: 'SEARCH',
        onFetch,
      }),
  }

  if (state.matches('error')) {
    return (
      <StyledGridContainer
        hasItemsMoreThanThree={items?.length > 3}
        className='flex-center'
      >
        <StyledFlexContainer className='flex-column'>
          <Icon
            iconName='alert-circle'
            color='danger'
            className={`scale-1-5 m-auto margin--bottom-1`}
          />
          <StyledParagraph>Something went wrong!</StyledParagraph>
          <StyledFlexContainer className='margin--top-1'>
            <Button
              label='Try Again'
              hoverColorWeight='300'
              onClick={tableHandlers?.handleReload}
              className='font-14'
            />
          </StyledFlexContainer>
        </StyledFlexContainer>
      </StyledGridContainer>
    )
  }

  // OIDC Token Update - Start
  useEffect(() => {
    if (storedAccessToken !== accessToken) {
      send({
        type: 'GOT_NEW_ACCESS_TOKEN',
        payload: accessToken,
        onFetch,
      })
    }
  }, [accessToken])
  // OIDC Token Update - End

  // If OIDC did not transition to token expired
  // due to component is not rendered
  // at the time the token expired
  useEffect(() => {
    if (state.matches('session_expired')) {
      handleTokenExpired()
    }
  }, [state])
  // end

  const timeoutRef = useRef()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      tableHandlers.handleSearch()
    }, 1500)

    // @ts-ignore
    timeoutRef.current = timeoutId

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText])

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      tableHandlers.handleSearch()

      const timeoutId = timeoutRef.current

      clearTimeout(timeoutId)
    }
  }

  return (
    <StyledGridContainer
      hasItemsMoreThanThree={items?.length > 3}
      className='GRID_CONTAINER'
    >
      <StyledGridHeader showDatePicker={showDatePicker}>
        <SearchBar showDatePicker={showDatePicker}>
          <Input
            id='grid-search'
            value={searchText}
            type='text'
            placeholder='Search...'
            actions={{
              handleChange: (data) =>
                tableHandlers.handleUpdateSearchText(data?.value),
              handleKeyDown: handleKeyPress,
            }}
            name='test'
            disabled={false}
            iconLeft='search'
            className='grid-search-input'
          />
          {state.matches('ready.fetching') && (
            <RaySpinner
              className='grid-search-loader'
              color='primary'
              colorWeight='DEFAULT'
              size={'xs'}
              rayHeight={6}
              rayWidth={4}
            />
          )}
        </SearchBar>
        <GridToolbar showDatePicker={showDatePicker}>
          {showDatePicker && (
            <DatePicker
              handleSelectDate={tableHandlers?.handleSelectDate}
              initialStartDate={start}
              initialEndDate={end}
            />
          )}
          <Button
            label='Sort'
            iconRight={`${
              sortOrder === 'descending' ? 'chevron-down' : 'chevron-up'
            }`}
            layout='outline'
            className='sort-btn font-14'
            onClick={tableHandlers?.toggleSort}
          />
        </GridToolbar>
      </StyledGridHeader>

      {items?.length ? (
        <>
          {start && (
            <StyledParagraph className='date-filter-text'>
              Showing results from {moment(start).format('MM-DD-YYYY')} to{' '}
              {moment(end).format('MM-DD-YYYY')}
            </StyledParagraph>
          )}
          <Table
            columns={applicationConfig?.columns}
            data={items}
            actions={tableHandlers}
            options={{
              //@ts-ignore
              config: applicationConfig,
              pagination,
            }}
            isLoading={state.hasTag('fetching')}
            customComponent={(item: any) =>
              customComponent
                ? customComponent?.(item ?? {}, state.matches('ready.fetching'))
                : null
            }
          />
        </>
      ) : (
        <StyledFlexContainer className='absolute absolute-center'>
          {state.matches('loading') ? (
            <StyledLoadingContainer>
              <RaySpinner
                color='primary'
                colorWeight='DEFAULT'
                className='absolute-center'
              />
              <StyledParagraph>{appConfig?.fetching_data_text}</StyledParagraph>
            </StyledLoadingContainer>
          ) : (
            <StyledParagraph>
              {appConfig?.no_data_text || 'No data found.'}
            </StyledParagraph>
          )}
        </StyledFlexContainer>
      )}
    </StyledGridContainer>
  )
}

export default React.memo(Grid)
