import React from 'react'

import { SurveyMediaProvider } from './SurveyMediaProvider'
import SurveyMediaConsumer from './SurveyMediaConsumer'
import { SurveyMediaProps } from './types'

const SurveyMedia: React.FC<SurveyMediaProps> = (props) => (
  <SurveyMediaProvider {...props}>
    <SurveyMediaConsumer />
  </SurveyMediaProvider>
)

export default SurveyMedia
