/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useContext, useEffect } from 'react'
import { Button, Container } from '@pando-styles/nirvana'
import { useModal } from '@pandolink/utils'

import { MainActions, MainState } from '@pages/index'
import {
  StyledHeadingThree,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledWrapperHeader,
} from '@styles/index'
import { BootloaderActions } from '@system/Bootloader'
import { SignatureType } from '@utils/index'

import { ESignActions, ESignState } from './ESignProvider'
import {
  ESignDocument,
  ESignDrawSignature,
  ESignTextSignature,
  ESignTabs,
  ESignComponent,
} from './components/'
import { send } from 'process'
import ExistingSignature from './components/ExistingSignature'

const ESignConsumer: React.FC = () => {
  const { providerState } = useContext(ESignState)
  const { providerActions } = useContext(ESignActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (
    !providerState ||
    !providerActions ||
    !homeProviderState ||
    !homeProviderActions ||
    !bootloaderActions
  )
    return null

  const { signature, signatoryGuid: esignMachineSignatoryGuid } =
    providerState.context ?? {}
  const {
    loggedInAsGuest = false,
    signatoryGuid = '',
    selectedInstance,
  } = homeProviderState.context ?? {}
  const { signatoryGuid: selectedInstanceSignatoryGuid } =
    selectedInstance ?? {}

  const {
    handleSwitchToDrawSignature,
    handleSwitchToTypeSignature,
    handleReset,
    handleNewSignatory,
  } = providerActions ?? {}
  const { handleGoToSurveyList } = homeProviderActions ?? {}
  const { handleLogout } = bootloaderActions ?? {}

  const { openModal, closeModal, Modal, modalIsOpen } = useModal()

  const signatoryGuidFromProps = selectedInstanceSignatoryGuid || signatoryGuid

  useEffect(() => {
    if (
      providerState.hasTag('open_modal') &&
      !providerState.matches('loading')
    ) {
      openModal()
    }
  }, [providerState?.value])

  useEffect(() => {
    if (!modalIsOpen) {
      handleReset()
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (esignMachineSignatoryGuid !== signatoryGuidFromProps) {
      handleNewSignatory?.(signatoryGuidFromProps)
    }
  }, [signatoryGuidFromProps])

  useEffect(() => {
    if (signatoryGuidFromProps) {
      handleNewSignatory?.(signatoryGuidFromProps)
    }
  }, [])

  if (providerState.matches('loading')) {
    return <ESignComponent text='Fetching signatory details...' showLoader />
  }

  if (providerState.matches('get_signatory_details_error')) {
    return (
      <ESignComponent text='Signatory is invalid or  marked as complete already.' />
    )
  }

  if (providerState.matches('error')) {
    return <ESignComponent text='Something went wrong!' />
  }

  if (providerState.matches('esign_done')) {
    return <ESignComponent text='ESign Completed.' />
  }

  if (providerState.matches('no_documents_found')) {
    return <ESignComponent text='No documents found.' />
  }

  return (
    <>
      <Modal
        portalClassName='ReactModalPortal document'
        bodyOpenClassName='ReactModal__Body--open document'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <ESignDocument closeModal={closeModal} />
      </Modal>

      <StyledWrapper className='flex-center flex-column'>
        <Button
          label='Exit'
          layout='outline'
          onClick={handleGoToSurveyList}
          className='borderless-button font-14'
        />

        <StyledWrapperBody
          esignComponent
          className='w-100p ESignComponent-WrapperBody'
        >
          <StyledWrapperBodyContainer overflowY='auto'>
            <StyledHeadingThree className='margin--top-2 margin--bottom-1'>
              E-Sign
            </StyledHeadingThree>
            <StyledParagraph className='padding--x-1-at-800'>
              {providerState?.matches(
                'ready.signature.existing_signature_loaded'
              )
                ? 'A signature has been created for this signatory, click on Proceed to continue signing documents.'
                : 'Enter the following information as you would like it to appear on these documents.'}
            </StyledParagraph>
            {providerState?.matches(
              'ready.signature.existing_signature_loaded'
            ) ? (
              <Container
                direction='column'
                centerX
                className='survey-tab-list--inner-container inner-container px-1-at-600'
              >
                <ExistingSignature />
              </Container>
            ) : (
              <Container
                direction='column'
                centerX
                className='survey-tab-list--inner-container inner-container px-1-at-600'
              >
                <>
                  <ESignTabs
                    tabItems={[
                      {
                        name: 'Type Signature',
                        state: 'ready.signature.type_signature',
                        onClick: handleSwitchToTypeSignature,
                        component: <ESignTextSignature />,
                      },
                      {
                        name: 'Draw Signature',
                        state: 'ready.signature.draw_signature',
                        onClick: handleSwitchToDrawSignature,
                        component: <ESignDrawSignature />,
                      },
                    ]}
                  />
                  <Container>
                    <>
                      {(providerState.matches(
                        'ready.signature.type_signature'
                      ) ||
                        (providerState.hasTag('saving_signature') &&
                          signature?.type === SignatureType.GENERATED)) && (
                        <ESignTextSignature />
                      )}

                      {(providerState.matches(
                        'ready.signature.draw_signature'
                      ) ||
                        (providerState.hasTag('saving_signature') &&
                          signature?.type === SignatureType.HANDWRITTEN)) && (
                        <ESignDrawSignature />
                      )}
                    </>
                  </Container>
                </>
              </Container>
            )}
          </StyledWrapperBodyContainer>
        </StyledWrapperBody>
      </StyledWrapper>
    </>
  )
}

export default ESignConsumer
