import { StyledContainer, StyledHeadingThree } from '@styles/index'

const InvalidRefreshToken = () => (
  <StyledContainer>
    <StyledHeadingThree>
      For your security, a new link has been sent to your email or phone number.
    </StyledHeadingThree>
  </StyledContainer>
)

export default InvalidRefreshToken
