import styled from 'styled-components'

export const StyledParagraph = styled.p<{ fontSize?: number }>`
  color: #000000 !important;
  font-size: ${({ fontSize = 14 }) => fontSize}px !important;
  margin: 0.4rem 0;
  line-height: 150%;
`

export const StyledParagraphWarning = styled.small`
  color: ${({ theme }) => theme.colors.warning.DEFAULT};
  font-size: 80%;
  margin: 0.4rem 0;
  display: block;
`

export const StyledParagraphAgreement = styled.p<{
  error?: boolean
  deniedAccess?: boolean
}>`
  color: ${({ error = false }) => (error ? '#E90000' : '#000000')};
  margin: ${({ deniedAccess = false }) => (deniedAccess ? '0' : '1.5rem')} 1rem
    1rem;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 90% !important;
  line-height: 18px;
  max-width: 400px;

  span.link {
    color: #1570ef !important;
    cursor: pointer;

    &:hover {
      color: #0f62d7 !important;
      transition: all 0.2s linear;
    }
  }

  span {
    display: inline;
    margin-top: 10px;
    color: #000000 !important;

    b {
      font-weight: 500;
      text-decoration: underline;
      color: #1570ef !important;
      cursor: pointer;

      &:hover {
        color: #0f62d7 !important;
        transition: all 0.2s linear;
      }
    }
  }
`

export const StyledHeadingTwo = styled.h2`
  color: #1b4960;
  font-weight: 500;
  line-height: 150%;
  font-size: 24px;
`
export const StyledHeadingThree = styled.h3`
  color: #1b4960;
  font-weight: 500;
  font-size: 16px;
`

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.primary[300]};
  font-weight: 600;
  display: block;
`

export const StyledError = styled.span`
  color: ${({ theme }) => theme.colors.danger['DEFAULT']};
  font-size: inherit;
`

export const StyledBoldText = styled.b`
  font-weight: 500;
  color: #3a404d;
`
export const StyledBoldTextBlue = styled.b`
  font-weight: 500;
  color: #1570ef;
`
