/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { usePandoLogger } from '@pandolink/utils'
import { Dispatch, useEffect } from 'react'

export interface UseMediaRecorderProps {
  mediaRecorderRef: any
  shouldRecordAgain?: boolean
  setUserRecordedVideo: Dispatch<any>
  setIsStreamUpdated: Dispatch<any>
}

export const useMediaRecorder = (props: UseMediaRecorderProps) => {
  const {
    mediaRecorderRef,
    shouldRecordAgain,
    setUserRecordedVideo,
    setIsStreamUpdated,
  } = props

  // Saves Recording Chunks
  const handleDataAvailable = ({ data }: any) => {
    if (data.size > 0) {
      usePandoLogger({
        name: 'Recorded Chunks',
        body: {
          data,
        },
        color: 'primary',
      })

      setUserRecordedVideo((prev: any) => prev.concat(data))
    }
  }

  // Start Recording
  const handleStartRecording = (invokedBy: string) => {
    console.log(`\n🟢 - Recording Started at [${invokedBy}]\n\n`, {
      // @ts-ignore
      stream: window?.stream,
    })
    try {
      mediaRecorderRef.current = new MediaRecorder(
        // @ts-ignore
        window?.stream,
        {
          mimeType: 'video/webm',
        }
      )
    } catch (err1) {
      try {
        // Fallback for iOS
        mediaRecorderRef.current = new MediaRecorder(
          // @ts-ignore
          window?.stream,
          {
            mimeType: 'video/mp4',
          }
        )
      } catch (err2) {
        console.error('Error [MediaRecorder]:', { err1, err2 })
      }
    }

    mediaRecorderRef.current.onstop = () => {}
    mediaRecorderRef.current.ondataavailable = handleDataAvailable
    mediaRecorderRef.current.start()
  }

  // Stop Recording
  const handleStopRecording = (invokedBy: string) => {
    if (
      mediaRecorderRef.current !== null &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      console.log(`\n🛑 - Recording Stopped at [${invokedBy}]\n\n`)
      // @ts-ignore
      mediaRecorderRef?.current?.stop()
      setIsStreamUpdated(true)
      setUserRecordedVideo([])
    }
  }

  // This effect should handle the restarting of the recorder
  useEffect(() => {
    if (shouldRecordAgain) {
      handleStartRecording('> Recording started again [useMediaRecorder]')
    }
  }, [shouldRecordAgain])

  return {
    handleDataAvailable,
    handleStartRecording,
    handleStopRecording,
  }
}
