/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react'

import { Button, RaySpinner } from '@pando-styles/nirvana'
import { useModal } from '@pandolink/utils'

import { MainActions, MainState } from '@pages/index'

import {
  StyledHeadingThree,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledParagraphAgreement,
  StyledExitContainer,
  StyledExitSpan,
} from '@styles/index'
import { capitalize } from '@utils/index'
import {
  SitePermissionState,
  SitePermissionActions,
  BootloaderActions,
} from '@system/Bootloader'

import { StyledButtonContainer, StyledSurveyLoadingContainer } from '../styles'

// const { REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList } = process.env

const HomeStartSurvey: React.FC = () => {
  const { providerState } = useContext(MainState)
  const { providerActions } = useContext(MainActions)
  const { sitePermissionState, PermissionComponent, PrivacyPolicyComponent } =
    useContext(SitePermissionState)
  const { sitePermissionActions } = useContext(SitePermissionActions)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (
    !providerActions ||
    !providerState ||
    !sitePermissionState ||
    !sitePermissionActions ||
    !bootloaderActions
  )
    return null

  const { selectedInstance, surveyConfiguration } = providerState?.context ?? {}
  const {
    preSurveyText = '',
    organizationName = '',
    surveyName = '',
  } = selectedInstance ?? {}

  const {
    handleStartSurvey,
    handleGoToSurveyList,
    handleAssignPermissions,
    handleSelectSurvey,
  } = providerActions ?? {}
  const { handleRequestUserPermission } = sitePermissionActions ?? {}
  const { handleClearBootloaderUrlParams } = bootloaderActions ?? {}

  const handleExit = () => {
    handleClearBootloaderUrlParams()
    handleGoToSurveyList()
  }

  const {
    sitePermissions,
    requestingPermission = false,
    deniedAccess = false,
    checkPermissionsAttempt = 0,
  } = sitePermissionState?.context ?? {}

  const handleAllowPermissions = () => {
    handleRequestUserPermission(surveyConfiguration)
  }

  const { openModal, closeModal, modalIsOpen, Modal } = useModal()

  const vidAndAudioNotRequired =
    !surveyConfiguration?.audioRequired && !surveyConfiguration?.videoRequired

  if (sitePermissionState.matches('permission_instructions')) {
    return <PermissionComponent />
  }

  const getAgreementText = () => {
    if (
      surveyConfiguration?.audioRequired &&
      surveyConfiguration?.videoRequired
    ) {
      return 'Camera and microphone access are required to continue this survey.'
    }

    if (
      !surveyConfiguration?.audioRequired &&
      surveyConfiguration?.videoRequired
    ) {
      return 'Camera access is required to continue this survey.'
    }

    if (
      surveyConfiguration?.audioRequired &&
      !surveyConfiguration?.videoRequired
    ) {
      return 'Microphone access is required to continue this survey.'
    }

    return 'Camera, microphone and location access are NOT required to continue with this survey.'
  }

  const getErrorText = () => {
    if (
      surveyConfiguration?.audioRequired &&
      surveyConfiguration?.videoRequired
    ) {
      return 'Camera and microphone access are'
    }
    if (
      !surveyConfiguration?.audioRequired &&
      surveyConfiguration?.videoRequired
    ) {
      return 'Camera access is'
    }
    if (
      surveyConfiguration?.audioRequired &&
      !surveyConfiguration?.videoRequired
    ) {
      return 'Microphone access is'
    }
  }

  useEffect(() => {
    if (sitePermissionState.context.sitePermissions?.browserDeviceDetails) {
      handleStartSurvey()
    }
  }, [sitePermissionState.context.sitePermissions?.browserDeviceDetails])

  useEffect(() => {
    if (selectedInstance && sitePermissionState.matches('permission_denied')) {
      handleAssignPermissions(sitePermissions)
      handleSelectSurvey(selectedInstance as any)
    }

    if (selectedInstance && sitePermissionState?.matches('permitted')) {
      handleAssignPermissions(sitePermissions)
      handleSelectSurvey(selectedInstance as any)
    }
  }, [sitePermissionState.value, selectedInstance])

  const handleOpenPrivacyPolicy = () => {
    openModal()
  }

  // const isNavBarHidden = () => {
  //   if (!organizationName) {
  //     return true
  //   }

  //   return (
  //     Array.isArray(hiddenNavBarOrgList) &&
  //     hiddenNavBarOrgList.find(
  //       (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //     )
  //   )
  // }

  //will set to hidden by default as of now
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )
  const isNavBarHidden = true

  return (
    <>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <PrivacyPolicyComponent
            type='privacy_policy'
            handleHideModal={closeModal}
          />
        </Modal>
      )}

      <StyledWrapper>
        {!isNavBarHidden && (
          <StyledExitContainer>
            <StyledExitSpan onClick={handleExit}>Exit Survey</StyledExitSpan>
          </StyledExitContainer>
        )}

        {(providerState.hasTag('getting_survey_details') ||
          providerState.hasTag('get_survey_config')) && (
          <StyledSurveyLoadingContainer className='margin--bottom-2'>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <RaySpinner color='primary' colorWeight='DEFAULT' />
              <StyledParagraph className='margin--top-1'>
                Loading Survey...
              </StyledParagraph>
            </div>
          </StyledSurveyLoadingContainer>
        )}
        {providerState.hasTag('survey_initiated') && (
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <StyledHeadingThree>{capitalize(surveyName)}</StyledHeadingThree>
              <StyledParagraph className='margin--bottom-2 margin--top-2 padding--x-1-at-800 text-black'>
                {preSurveyText}
              </StyledParagraph>
              <StyledButtonContainer>
                {deniedAccess || checkPermissionsAttempt >= 3 ? null : (
                  <Button
                    label='Start Survey'
                    hoverColorWeight='300'
                    className='w-300 font-14'
                    onClick={handleAllowPermissions}
                    isDisabled={providerState.hasTag(
                      'start_survey_btn_disabled'
                    )}
                    isLoading={
                      (sitePermissionState?.matches(
                        'user_permissions.execute_permissions'
                      ) ||
                        sitePermissionState?.matches(
                          'user_permissions.execute_location_permission'
                        ) ||
                        providerState.hasTag('getting_survey_details')) &&
                      requestingPermission
                    }
                    style={{ maxWidth: '60%', fontSize: 14 }}
                  />
                )}
                {!deniedAccess && checkPermissionsAttempt < 3 && (
                  <>
                    <StyledParagraphAgreement
                      deniedAccess={deniedAccess}
                      error={checkPermissionsAttempt > 0}
                    >
                      {providerState.hasTag('survey_initiated') &&
                        getAgreementText()}
                      <span>
                        {' '}
                        By continuing, you agree to our{' '}
                        <b onClick={handleOpenPrivacyPolicy}>Privacy Policy</b>.
                      </span>
                    </StyledParagraphAgreement>
                  </>
                )}
                {(deniedAccess || checkPermissionsAttempt >= 3) &&
                  (surveyConfiguration?.videoRequired ||
                    surveyConfiguration?.audioRequired) &&
                  !sitePermissionState.matches('no_device_found') && (
                    <StyledParagraphAgreement
                      error
                      deniedAccess={
                        deniedAccess || checkPermissionsAttempt >= 3
                      }
                    >
                      {getErrorText() + ' required to continue this survey.'}{' '}
                      <span>
                        Please check that the settings on your device or browser
                        have the camera and microphone permissions set to
                        ‘Allow’ and then refresh this page.
                      </span>
                    </StyledParagraphAgreement>
                  )}
                {sitePermissionState.matches('no_device_found') &&
                  (surveyConfiguration?.videoRequired ||
                    surveyConfiguration?.audioRequired) && (
                    <>
                      <StyledParagraphAgreement
                        error
                        className='w-500'
                        deniedAccess={
                          deniedAccess || checkPermissionsAttempt >= 3
                        }
                      >
                        No camera or microphone device detected.{' '}
                        <span>
                          Make sure to plugin a camera or microphone to proceed.
                        </span>
                      </StyledParagraphAgreement>
                    </>
                  )}
              </StyledButtonContainer>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        )}
      </StyledWrapper>
    </>
  )
}

export default HomeStartSurvey
