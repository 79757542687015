import React from 'react'

import VideoResponseProvider from './VideoResponseProvider'
import VideoResponseConsumer from './VideoResponseConsumer'
import { VideoResponsePropType } from './types'

export * from './VideoResponseProvider'

const Questions: React.FC<VideoResponsePropType> = (props) => (
  <VideoResponseProvider {...props}>
    <VideoResponseConsumer />
  </VideoResponseProvider>
)

export default Questions
