import React from 'react'
import { useLocation } from 'react-router-dom'

import MainProvider from './MainProvider'
import MainConsumer from './MainConsumer'

import Loader from '@system/Bootloader/Loader'

export * from './MainProvider'

const Main: React.FC = (props) => {
  const { search } = useLocation()

  if (search.includes('token')) {
    console.log('@DEBUG [MainProvider]: Still fetching new token')

    return <Loader />
  }

  return (
    <MainProvider {...props}>
      <MainConsumer />
    </MainProvider>
  )
}

export default Main
