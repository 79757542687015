/* eslint-disable indent */
import styled, { css } from 'styled-components'

export const StyledSurveyMedia = styled.div<{
  isNavBarHidden?: boolean
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh;
  background-color: #292929;
  border-radius: 0.4rem;
  max-width: 100%;
  width: 100%;

  .video-control-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(20px) contrast(80%);
  }

  .video-control {
    position: relative;
    z-index: 100;
    transform: scale(3);

    svg {
      stroke: #ddd;
      transition: all 0.2s linear;
    }
  }

  .video-control:hover {
    svg {
      stroke: #fff;
    }
  }

  .video-unmute-btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 200;
  }

  .video-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    border-radius: 0;
    /* min-height: 60vh; */
    overflow: hidden;
    position: fixed;
    top: 0;
    padding-top: 0 !important;
    height: 55vh !important;

    .video-control {
      transform: scale(2.5);
    }
  }
`

export const SurveyCameraContainer = styled.div`
  position: absolute;
  top: 0.998rem;
  bottom: 0;
  right: 1rem;
  width: 9rem;
  height: 5.625rem;
  background: black;
  border-radius: 0.4rem;
  overflow: hidden;
  z-index: 20;

  display: block;

  video {
    width: 9rem;
    height: 5.625rem;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 5.3rem;
    height: 7.3rem;
  }
`

export const SurveyMicrophoneContainer = styled.div<{
  hasVideoEnded: boolean
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 0.4rem;
  transition: all 0.1s linear;
  backdrop-filter: blur(20px) contrast(80%);

  @media screen and (max-height: 750px) and (max-width: 600px) {
    & > div {
      transform: translateY(1rem);
    }
  }
`

interface Props {
  isMultiChoiceType?: boolean
  showFaceNotInFramePrompt?: boolean
}

const getStyledReactPlayerStyles = ({ showFaceNotInFramePrompt }: Props) => {
  if (showFaceNotInFramePrompt)
    return css`
      filter: blur(10px);
      -webkit-filter: blur(10px);
    `
}

export const StyledReactPlayer = styled.div<Props>`
  ${getStyledReactPlayerStyles}

  height: 45vh;

  video {
    overflow-x: hidden;
    height: 100%;
    object-fit: cover;
  }

  .video-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    position: absolute;
    top: -5rem;

    &.fetching-video {
      top: 0;
    }

    video {
      width: unset !important;
      height: ${({ isMultiChoiceType = false }) =>
        isMultiChoiceType ? '55rem' : '100vh'} !important;
    }
  }
`

export const FontButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 20;
  width: 70px;
  justify-content: space-between;
  align-items: center;
  height: 40px !important;
  bottom: 0.3rem !important;
  right: 0.5rem !important;
`
