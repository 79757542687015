/* eslint-disable indent */
import styled from 'styled-components'

export const StyledLink = styled.span`
  color: #747474;
  list-style: none;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s linear;

  &.active {
    color: #2c969b;
    font-weight: 600;
  }

  &:hover {
    color: #2c969b;
  }
`

export const StyledTabs = styled.div`
  position: relative;
  padding-top: 1.563rem;
  width: 100%;
  border-bottom: 0.2px solid gray !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 2px solid #d0d5dd;
  padding-bottom: 0.8rem;
`

export const StyledSigPadContainer = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  max-width: 100%;
  margin: 0 auto;

  .sigpad {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
`

export const StyledSigImage = styled.img`
  position: absolute;
  bottom: 5px;
  left: 5px;
  border: 1px solid #ddd;
  width: 60px;
  height: 60px;
  object-fit: scale-down;
  opacity: 0.8;
`

export const StyledSigImage2 = styled.img`
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: scale-down;
  opacity: 0.8;
  margin: 0 auto;
`

export const StyledDocumentBody = styled.div<{
  isSignatureGenerated?: boolean
}>`
  overflow: auto;

  .p-10 {
    padding: 15px;
  }

  .bottom_client {
    .signatureBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .signatureBox {
    img.inserted-signature {
      width: ${({ isSignatureGenerated = false }) =>
        isSignatureGenerated ? '100px' : 'auto'};
      padding: 0 5px;
    }
  }
`

export const StyledTextSignatureToolbar = styled.div`
  display: flex;
  gap: 5px;

  > div:first-child {
    width: calc(100% - 150px);
  }

  > div.select-container {
    width: 150px;
  }

  > div,
  .select-component {
    margin: 0 !important;
  }
`

export const StyledImageWrappper = styled.div`
  height: 25vh;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 15px;
`
