import styled from 'styled-components'

export const StyledCamera = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 9rem;
  height: 5.625rem;
  overflow: hidden;

  .camera-icon {
    transform: scale(2);
  }
  video {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
  }

  canvas {
    position: absolute;
  }

  .x-icon {
    text-align: left;
  }

  @media screen and (max-width: 600px) {
    height: 100%;
    width: 100%;
    background-color: #333;
  }
`

export const StyledProfile = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 200px;
    background-color: none;
    z-index: 1;
  }

  svg path {
  }

  @keyframes title-outline {
    from {
      stroke-dashoffset: 400;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`

export const StyledContainer = styled.div`
  position: absolute;
  top: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.6rem;
  color: ${({ theme }) => theme.colors.dark[50]};
  transform: scale(0.6);
  margin: 0;
  z-index: 5;

  @media screen and (max-width: 600px) {
    top: unset;
    bottom: 0;
  }
`

export const StyledStatus = styled.small`
  position: absolute;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.6rem;
  color: #dfeaf2;
`

export const StyledRecordingIcon = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  background-color: red;
  border-radius: 25px;
  margin-right: 0.2rem;
`
