import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledNavLink = styled(NavLink)`
  margin-right: 2rem;
  color: #747474;
  list-style: none;
  text-decoration: none;
  font-weight: 400;

  &.active {
    color: #2c969b;
    font-weight: 600;
  }

  &.logout {
    position: absolute;
    right: 1rem;

    &:hover {
      color: #2c969b;
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 762px) {
    &.logout {
      margin-right: 0;
    }
  }
`

export const StyledTabs = styled.div`
  position: relative;
  width: 100%;
  padding: 3.688rem 0 0 3.063rem;
  border-bottom: 0.2px solid gray !important;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #d0d5dd;
  padding-bottom: 0.8rem;
  background-color: #f8f8f8;

  @media screen and (max-width: 762px) {
    & {
      padding: 3rem 1rem 0.8rem;
    }
  }
`
