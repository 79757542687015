/* eslint-disable arrow-body-style */
import ReactHtmlParser from 'react-html-parser'
import { usePandoLogger } from '@pandolink/utils'
import Browser from 'bowser'

/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 *
 * @param str
 * @returns Capitalized first letter in each words
 */
export const capitalize = (str = ''): string =>
  str
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())

export const removeSpace = (str = '') => str.replace(/\s+/g, '-')
/**
 *
 * @param object A key:value pair object
 * @param valueKey Specify a key to extract (e.g 'placeholder' in inputs) | Default: 'value'
 * @returns Still a key:value pair but value will only be the selected valueKey
 */
export const extractValues = (
  object = {},
  valueKey?: string
): {
  [key: string]: string | number
} => {
  const value = valueKey || 'value'

  return Object.entries(object).reduce(
    (acc, [key, data]: any) => ({
      ...acc,
      [key]: data[value] || '',
    }),
    {}
  )
}

export const formatData = (fields = {}, data: any) =>
  Object.entries({ ...data, ...fields }).reduce(
    (acc, [key, value]: any) => ({
      ...acc,
      [key]:
        value?.id && value?.type
          ? {
              ...value,
              value: data[key],
            }
          : value,
    }),
    {}
  )

/**
 *
 * @param data The string with react-mention markups to parse
 * @param route Possible route for mentions and tags for navigation
 * @returns The html-parsed data
 */
export const mentionStringify = (data = '', route?: string) => {
  let content = data
  const path = route || ''

  content = content.split('@{{id:').join(`<a href="${path}/`)
  content = content.split('}}:{{').join(`">@`)
  content = content.split('}}').join('</a>')

  content = content.split('#[[id:').join(`<a href="tag/`)
  content = content.split(']]:[[').join(`">#`)
  content = content.split(']]').join('</a>')

  return content
}

export const getStorageItem = (key: string) => {
  try {
    const item = localStorage.getItem(key) ?? ''

    return JSON.parse(item)
  } catch (error: any) {
    usePandoLogger({
      name: 'getStorageItem: error',
      body: error?.message,
    })
  }
}

export const shallowCompareObject = (
  object1: Record<string, any>,
  object2: Record<string, any>
): boolean => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const parseQuestionText: any = (questionText: string) => {
  return ReactHtmlParser(questionText?.replaceAll('font>', 'q>'))
}

export const getObjectValue = (
  object: Record<string, any>,
  path: string
): any => path.split('.').reduce((r, k) => r?.[k], object)

export const sortItems = (
  items: Record<string, any>[] | any,
  sortOrder: 'ascending' | 'descending',
  sortBy: string
) =>
  items?.sort((a: any, b: any) => {
    const A = getObjectValue(a, sortBy)
    const B = getObjectValue(b, sortBy)

    if (sortOrder === 'ascending') return A - B

    return B - A
  }) ?? []

export const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-')
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

export const convertHtmlStyleToJSXStyle = (str: string) => {
  const style: any = {}

  if (!str) return str

  if (!str?.includes(';')) {
    const [property, value] = str.split(':')
    if (!property) return

    const formattedProperty = formatStringToCamelCase(property.trim())
    style[formattedProperty] = `'${value.trim()}'`

    return style
  }

  str?.split(';').forEach((el) => {
    const [property, value] = el.split(':')
    if (!property) return

    const formattedProperty = formatStringToCamelCase(property.trim())
    style[formattedProperty] = `'${value.trim()}'`
  })

  return style
}

interface FullTextSearchParams {
  searchText: string
  searchFields: string[]
  items: Record<string, any>[]
}

export const fullTextSearch = (params: FullTextSearchParams) => {
  const { searchText = '', searchFields = [], items = [] } = params

  return items?.reduce((acc: any, curr: any) => {
    const searchableValues = searchFields?.length
      ? searchFields?.map((key) => curr[key])
      : Object.values(curr)

    const stringifiedItem = JSON.stringify(searchableValues)

    const regexp = new RegExp(searchText, 'gi')

    const hasSearchItem = stringifiedItem.match(regexp)

    if (!hasSearchItem) return [...acc]

    return [...acc, curr]
  }, [])
}

export const encodeToBase64 = (data: any) => btoa(JSON.stringify(data))
export const decodeFromBase64 = (base64: string) => JSON.parse(atob(base64))

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const isBrowserUsedSupported = () => {
  const { userAgent } = navigator

  const browserUsed = Browser.parse(userAgent).browser

  return (
    browserUsed.name === 'Microsoft Edge' ||
    browserUsed.name === 'Chrome' ||
    browserUsed.name === 'Safari'
  )
}

export const isAccessTokenExpired = (issuedAt: number, expiresIn: number) => {
  const issuedAtMilliseconds = issuedAt * 1000
  const issuedAtDate = new Date(issuedAtMilliseconds)
  const millisecondDifference = new Date().getTime() - issuedAtDate.getTime()

  return millisecondDifference >= expiresIn * 1000
}

export const snakeToCamel = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item))
  }

  const camelObj: any = {}
  for (const key of Object.keys(obj)) {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
      letter.toUpperCase()
    )
    camelObj[camelKey] = snakeToCamel(obj[key])
  }

  return camelObj
}
