/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '@pando-styles/nirvana'

import { StyledParagraph, StyledFlexContainer } from '@styles/index'
import { QuestionsState, QuestionsActions } from '@applications/index'

import { StyledGridSection } from './styles'
import { IMultipleAnswerProps } from './types'
import { AnswerOption } from '@utils/queries/imports'

const MultipleAnswer: React.FC<IMultipleAnswerProps> = (props) => {
  const { _fontSize, clickedBtnId, setClickedBtnId } = props

  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)

  const [scrollable, setScrollable] = useState<boolean>(false)

  const multiChoiceSectionRef = useRef<any>(null)

  if (!providerState || !providerActions) return null

  const { question, selectedAnswers = {} } = providerState?.context ?? {}

  const { handleMultipleAnswerViaButtonClick, handleContinue } =
    providerActions ?? {}

  const handleClickedContinue = (continueId: string) => {
    handleContinue?.()
    setClickedBtnId(continueId)
  }

  useEffect(() => {
    if (multiChoiceSectionRef && multiChoiceSectionRef.current !== null) {
      const scrollableCheck =
        multiChoiceSectionRef?.current?.scrollHeight >
        multiChoiceSectionRef?.current?.clientHeight

      setScrollable(scrollableCheck)
    }
  }, [multiChoiceSectionRef])

  useEffect(() => {
    const checkScrollable = () => {
      if (multiChoiceSectionRef && multiChoiceSectionRef.current !== null) {
        const scrollableCheck =
          multiChoiceSectionRef?.current?.scrollHeight >
          multiChoiceSectionRef?.current?.clientHeight

        setScrollable(scrollableCheck)
      }
    }

    window.addEventListener('resize', checkScrollable)

    return () => window.removeEventListener('resize', checkScrollable)
  }, [multiChoiceSectionRef])

  return (
    <>
      <StyledParagraph
        className='margin--bottom-2 font-12 font-weight-500'
        style={{ fontSize: _fontSize }}
      >
        Select all answers that apply.
        {scrollable && ' Scroll below to view more.'}
      </StyledParagraph>
      <StyledGridSection
        ref={multiChoiceSectionRef}
        className='MULTIPLE_ANSWERS'
      >
        {question?.answerOptionsList?.map((answer) => (
          <Button
            style={{ fontSize: _fontSize }}
            isDisabled={
              !providerState.matches('ready.waiting_for_button_click')
            }
            key={answer?.guid}
            label={answer?.name}
            hoverColorWeight='300'
            layout='outline'
            onClick={() =>
              handleMultipleAnswerViaButtonClick(
                answer as AnswerOption.AsObject[] | any
              )
            }
            isLoading={
              clickedBtnId === answer?.guid && providerState.hasTag('loader')
            }
            loadingLabel={answer?.name}
            className={
              Object.values(selectedAnswers)?.some(
                (selectedAnswer) => answer?.guid === selectedAnswer?.guid
              )
                ? 'selected'
                : 'not-selected'
            }
            icon={
              Object.values(selectedAnswers)?.some(
                (selectedAnswer) => answer?.guid === selectedAnswer?.guid
              )
                ? 'check-square'
                : 'square'
            }
          />
        ))}
      </StyledGridSection>

      <StyledFlexContainer className='MULTIPLE_ANSWER_CONTINUE_BTN w-200 margin--top-1'>
        <Button
          style={{ fontSize: _fontSize }}
          block
          isDisabled={
            !providerState.matches('ready.waiting_for_button_click') ||
            Boolean(!Object.values(selectedAnswers ?? {})?.length)
          }
          label='Continue'
          hoverColorWeight='300'
          onClick={() =>
            Object.keys(selectedAnswers ?? {})?.length
              ? handleClickedContinue('continue_id')
              : {}
          }
          isLoading={
            clickedBtnId === 'continue_id' && providerState.hasTag('loader')
          }
          loadingLabel='Continue'
        />
      </StyledFlexContainer>
    </>
  )
}

export default MultipleAnswer
