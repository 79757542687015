/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { css, createGlobalStyle } from 'styled-components'
import { ThemeProvider } from '@pando-styles/nirvana'

const getHeaderStyles = (theme: any) => {
  const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

  const style = css<ThemeProvider>`
    ${headings?.map(
      (header) =>
        `
        ${header} {
          font-size: ${theme.headers[header].fontSize};
          font-weight: ${theme.headers[header].fontWeight};
          padding: 0;
          margin: 0;
        }
        `
    )}
  `

  return style
}

export const Modifiers = createGlobalStyle<ThemeProvider>`
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    overflow: hidden;
    width: 100%;
  }

  body {
    height: 100%;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100% !important;
  }

  html, body {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px !important;
  }

  html, body, p, q, i, b, a {
    font-family: 'Roboto' !important;
  }

  body > #root {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  body > #root > div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  video::-webkit-media-controls {
    display: none !important;
  }

  video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }

 
  // when modal is open, remove StyledContainer background color
  .ReactModal__Body--open {
    max-width: 100%;
  }

  .ReactModal__Body--open .styled-container {
    background: none;
  }

  .ReactModal__Overlay {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background: rgba(0, 0, 0, 0.68) !important;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  .ReactModal__Content--after-open {
    outline: none !important;
    max-width: 90% !important;
    opacity: 1;
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.5);
  }

 
  h3, h4 {
    margin: 0;
  }

  a {
    text-decoration: none !important;
  }

  q {
    quotes: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary['DEFAULT']}
  }

  ${({ theme }) => getHeaderStyles(theme)}

  .fw-100 {
    font-weight: 100 !important;
  }
  .fw-200 {
    font-weight: 200 !important;
  }
  .fw-300 {
    font-weight: 300 !important;
  }
  .fw-400 {
    font-weight: 400 !important;
  }
  .fw-500 {
    font-weight: 500 !important;
  }
  .fw-600 {
    font-weight: 600 !important;
  }

  .condense {
    font-family: 'Barlow Semi Condensed', sans-serif!important;
  }
  .margin-0 {
    margin: 0 !important
  }
  .margin--right {
    margin-right: 6rem !important;
  }
  .margin__spacer {
    margin-right: .5rem !important;
  }

  .margin--top-1-4 {
    margin-top: .25rem !important;
  }

  .margin--top-1-2 {
    margin-top: 0.5rem !important;
  }
  
  .margin--top-0 {
    margin-top: 0 !important;
  }
  .margin--top-1 {
    margin-top: 1rem !important;
  }

  .margin--top-2 {
    margin-top: 2rem !important;
  }

  .margin--top-3 {
    margin-top: 3rem !important;
  }

  .margin--top-4 {
    margin-top: 4rem !important;
  }

  .margin--top-4-n {
    margin-top: -4rem !important;
  }

  .margin--bottom-1-2 {
    margin-bottom: 0.5rem !important;
  }

  .margin--bottom-1 {
    margin-bottom: 1rem !important;
  }

  .margin--bottom-1-5 {
    margin-bottom: 1.5rem !important;
  }

  .margin--bottom-2 {
    margin-bottom: 2rem !important;
  }

  .margin--bottom-3 {
    margin-bottom: 3rem !important;
  }

  .margin--bottom-4 {
    margin-bottom: 4rem !important;
  }

  .margin--bottom-5 {
    margin-bottom: 5rem !important;
  }

  .margin--x-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .padding-0 {
    padding: 0 !important
  }

  .padding--top-1 {
    padding-top: 1rem !important;
  }

  .padding--bottom-1 {
    padding-bottom: 1rem !important;
  }

  .padding--bottom-half {
    padding-bottom: 0.5rem !important;
  }

  .padding--left-1 {
    padding-left: 1rem !important;
  }

  .padding--l-r {
    padding-left: 0 3.063rem 0 3.063rem!important;
  }

  .padding--right-1 {
    padding-right: 1rem !important;
  }

  .padding--l-r {
    padding: 0 1rem 0 1rem !important;
  }

  .padding--bottom-2 {
    padding-bottom: 2rem !important;
  }

  .padding--bottom-4 {
    padding-bottom: 4rem !important;
  }

  .padding--x-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .padding--x-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .padding--x-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .padding--y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .padding--y-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .padding--y-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }
  
  .text-left {
    text-align: left !important;
  }

  .gap {
    gap: 5px;
  }

  .border--top-1 {
    border-top: 1px solid ${({ theme }) => theme.colors.light[400]}
  }

  .border--bottom-1 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.light[400]}
  }

  .border--bottom-1-dark {
    border-bottom: 1px solid ${({ theme }) => theme.colors.dark[100]}
  }

  .border--left-1 {
    border-left: 1px solid ${({ theme }) => theme.colors.light[400]}
  }
  
  .border--right-1 {
    border-right: 1px solid ${({ theme }) => theme.colors.light[400]}
  }

  .border-bottom-custom {
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 30px;
  }
  
  .bottom-50 {
    bottom: 50px !important;
  }

  // chrome or browser extensions with iframe will not work
  /* iframe {
    pointer-events: none;
    display: none
  } */

  .text-white {
    color: #fff !important;
  }
  .text-black {
    color: #000 !important;
  }
  .text-grey {
    color: #667085 !important;
  }
  .text-red {
    color: #E90000 !important;
  }
  .bg-white {
    background: #ffffff !important;
  }
  .bg-light {
    background: #cdcfd6 !important;
  }
  .bg-light-green {
    background: #EAF5F5 !important;
  }

  .text-gray {
    color: #aaa !important;
  }

  .float--right {
    float: right
  }

  .float--left {
    float: left
  }

  .relative {
    position: relative;
  }

  .block {
    display: block
  }

  .flex {
    display: flex;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-start {
    justify-content: flex-start !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .align-end {
    align-items: flex-end !important;
  }
  .align-start {
    align-items: flex-start !important;
  }
  .align-center {
    align-items: center !important;
  }
  .align-self-end {
    align-items: flex-end !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-center {
    align-self: center !important;
  }

  .flex-column {
    flex-direction: column !important;
  }
  
  .flex-row {
    flex-direction: row !important;
  }

  .flex-row-at-600 {
    @media screen and (max-width: 600px) {
      & {
        flex-direction: row !important;
      }
    }
  }
  .flex-column-at-600 {
    @media screen and (max-width: 600px) {
      & {
        flex-direction: column !important;
      }
    }
  }

  .vh-80 {
    height: 80vh;
  }

  .vh-85 {
    height: 85vh;

    @media screen and (max-width: 600px) {
      & {
        height: 75vh;
      }
    }
  }

  .vh-90 {
    height: 90vh;
  }

  .vh-100 {
    height: 100vh;
  }

  .mh-75 {
    max-height: 75% !important;
  }

  .h-75 {
    height: 75px;
  }
  .h-80 {
    height: 80px;
  }

  .h-300 {
    height: 300px;
  }

  .object-cover {
    object-fit: cover;
  }

  .absolute {
    position: absolute
  }

  .absolute-center {
    top:  50%;
    left:  50%;
    transform: translate(-50%, -50%)
  }

  .w-auto {
    width: auto !important;
  }
  
  .w-50 {
    width: 50px !important;
    max-width: 90%;
  }
  .w-200 {
    width: 200px !important;
    max-width: 90%;
  }
  .w-300 {
    width: 300px !important;
    max-width: 90%;
  }
  .w-400 {
    width: 400px !important;
    max-width: 90%;
  }
  .w-500 {
    width: 500px !important;
    max-width: 90%;
  }
  .w-1000 {
    width: 1000px !important;
    max-width: 100%;
  }

  .w-5p { width: 5% !important; }
  .w-10p { width: 10% !important; }
  .w-15p { width: 15% !important; }
  .w-20p { width: 20% !important; }
  .w-25p { width: 25% !important; }
  .w-30p { width: 30% !important; }
  .w-35p { width: 35% !important; }
  .w-40p { width: 40% !important; }
  .w-45p { width: 45% !important; }
  .w-50p { width: 50% !important; }
  .w-55p { width: 55% !important; }
  .w-60p { width: 60% !important; }
  .w-65p { width: 65% !important; }
  .w-70p { width: 70% !important; }
  .w-75p { width: 75% !important; }
  .w-80p { width: 80% !important; }
  .w-85p { width: 85% !important; }
  .w-90p { width: 90% !important; }
  .w-95p { width: 95% !important; }
  .w-100p { width: 100% !important; }
  .mw-100 { max-width: 100% !important; }

  .w-50p-at-726 {
    @media screen and (max-width: 726px) {
      & {
        width: 50% !important;
      }
    }
  }

  .w-100p-at-900 {
    @media screen and (max-width: 900px) {
      & {
        width: 100% !important;
      }
    }
  }

  .w-100p-at-600 {
    @media screen and (max-width: 600px) {
      & {
        width: 100% !important;
      }
    }
  }

  .padding--x-1-at-800 {
    @media screen and (max-width: 800px), screen and (max-height: 800px) {
    & {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  }

  .margin--x-1-at-800 {
    @media screen and (max-width: 800px), screen and (max-height: 800px) {
    & {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  }

  .m-auto {
    margin: 0 auto;
  }
  .borderless {
    border: 0;
  }
  .borderless-button {
    position: absolute;
    top: 2.8rem;
    left: 12px;
    z-index: 99999;
    border: none;
    height: auto;
    padding: 0 1rem;
    font-size: 100%;
    transition: all 0.25s ease-in-out;

    &.video-response-back-btn {
      top: unset;
    }

    &:hover {
      background: none !important;
      color: ${({ theme }) => theme.colors.primary[300]} !important;
    
      svg {
        stroke: ${({ theme }) => theme.colors.primary[300]} !important;
      }

      &.has-icon {
      > span {
        padding-left: 23px;
        transition: all 0.25s ease-in-out;

      }
    }   
    }

    > span {
      font-size: 1.025rem;
      padding: 0;
      font-weight: 500;
    }

    &.has-icon {
      > span {
        padding-left: 20px;
        font-size: 1.025rem;
      }
    }    
  }

  .bg-color_light-gray {
    background:  #F5F6F8!important;
  }
  .bg-color_white {
    background: #fff!important;
  }
  .bg-color_bluish-white {
    background: #EAF5F5!important;
  }
  .column--space-around {
    justify-content: space-evenly;
  }

  .pando-logo {
    width: 32%!important;
  }

  .custom-rayspinner {
    position: absolute;
    top: -5px;
    right: 0;
    transform: scale(0.8)
  }

  .scale-1-5 {
    transform: scale(1.5);
  }

  .teal {
    color: #2C969B !important;
  }

  .close-btn:hover {
    color: #1e6f73 !important;
  } 

  .text-danger {
    color: ${({ theme }) => theme.colors.danger['DEFAULT']}
  }

  .px-3-at-1200 {
    @media screen and (max-width: 1200px) {
      & {
        padding: 0 3rem !important;
      }
    }
  } 

  .px-1-at-762 {
    @media screen and (max-width: 762px) {
      & {
        padding: 0 1rem !important;
      }
    }
  } 

  .px-1-at-600 {
    @media screen and (max-width: 600px) {
      & {
        padding: 0 1rem !important;
      }
    }
  }

  .p-1-at-600 {
    @media screen and (max-width: 600px) {
      & {
        padding: 1rem !important;
      }
    }
  }

  .p-2-at-600 {
    @media screen and (max-width: 600px) {
      & {
        padding: 2rem !important;
      }
    }
  }

  .px-0-at-600 {
    @media screen and (max-width: 600px) {
      & {
        padding: 0 !important;
      }
    }
  }

  .w-100-at-800 {
    @media screen and (max-width: 800px) {
      & {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  .flip-x {
    transform: scaleX(-1)
  }

  .overflow-y {
    overflow-y: auto;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-scroll {
    overflow: scroll;
    /* height: calc(100vh-80px); */
    /* height: 74%; */
    max-height: 78%;
  }

  .question-text {
    position: absolute;
    bottom: 0;
    padding: 1rem 3rem 0;
    background: #fff
  }

  .wrapper-body-height-100 {
    min-height: unset !important;
    max-height: unset !important;
    height: 100% !important;
  }
  
  .bold {
    font-weight: bold;
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .no-bg, .no-bg:hover, .no-bg:active, .no-bg:focus {
    background: none !important;
    color: inherit !important
  }

  .custom-button-link-2 {
    background: transparent;
    border: 0;
    height: auto;
    font-size: 14px;

    & {
      > div.button-icon-div > div > div::after {
        background:#2C969B !important
      }

    }

    &:hover {
      background: none !important;
      color: ${({ theme }) => theme.colors.primary[300]} !important;

      svg {
        stroke: ${({ theme }) => theme.colors.primary[300]} !important;
      }   
    }
  }

  .custom-button-link {
    background: transparent;
    border: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 14px;

    > .button-icon-div {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }

    > span {
      padding: 0;
    }

    & {
      > div.button-icon-div > div > div {
        width: 1.2rem;
        height: 1.2rem
      }

      svg {
        stroke-width: 3px;
      }
    }

    &:hover {
      background: none !important;
      color: ${({ theme }) => theme.colors.primary[300]} !important;
    
      svg {
        stroke: ${({ theme }) => theme.colors.primary[300]} !important;
      }   
    }
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-18 {
    font-size: 18px !important;
  }

  .font-weight-400 {
    font-weight: 400 !important;
  }
  
  .font-weight-500 {
    font-weight: 500 !important;
  }

  .fixed-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center
  }

`
