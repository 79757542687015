/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useState } from 'react'
import { Button, RaySpinner } from '@pando-styles/nirvana'
import ReactHtmlParser, { processNodes } from 'react-html-parser'
import { useModal } from '@pandolink/utils'

import {
  StyledModalContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledWrapperFooter,
  StyledWrapperFooterContainer,
  StyledWrapperHeader,
} from '@styles/index'
import {
  convertHtmlStyleToJSXStyle,
  ESignType,
  SignatureType,
} from '@utils/index'

import { ESignActions, ESignState } from '../ESignProvider'
import { StyledDocumentBody } from './styles'
import { ESignConfirmation } from '.'

type DocumentProps = {
  closeModal: () => void
}

enum ButtonEventState {
  IDLE = 0,
  OPT_IN_DOCUMENT = 1,
  OPT_OUT_DOCUMENT = 2,
  ACKNOWLEDGE_DOCUMENT = 3,
  SIGN_DOCUMENT = 4,
  DECLINE_DOCUMENT = 5,
}

const Document: React.FC<DocumentProps> = ({ closeModal }) => {
  const { providerState } = useContext(ESignState)
  const { providerActions } = useContext(ESignActions)

  if (!providerActions || !providerState) return null

  const [buttonEventState, setButtonEventState] = useState(
    ButtonEventState.IDLE
  )

  const {
    openModal: openModal2,
    closeModal: closeModal2,
    Modal: Modal2,
    modalIsOpen: modalIsOpen2,
  } = useModal()

  const {
    documents = [],
    signature,
    currentDocumentDetails,
  } = providerState?.context ?? {}
  const {
    handleDeclineDocument,
    handleAcknowledgeDocument,
    handleSignDocument,
    handleOptInDocument,
    handleOptOutDocument,
  } = providerActions ?? {}

  const {
    renderedHtml = '',
    guid = '',
    type = SignatureType.GENERATED,
    isRequired = false,
  } = currentDocumentDetails ?? {}

  const modifyRenderedHtml = (html: any) =>
    ReactHtmlParser(html, {
      transform: (node, index) => {
        if (node?.attribs?.id?.includes('signature_')) {
          const { style } = node?.attribs as any

          return (
            <div {...node?.attribs} style={convertHtmlStyleToJSXStyle(style)}>
              {processNodes(node?.children, () => {})}
              <img
                className='inserted-signature'
                src={signature?.image}
                alt='signature'
              />
            </div>
          )
        }
      },
    })

  const isLoading = providerState.matches('ready.document.update_document')

  const BodyComponent: JSX.Element | any = () => {
    if (providerState.hasTag('fetching')) {
      return (
        <>
          <RaySpinner color='primary' colorWeight='DEFAULT' />
          <StyledParagraph className='margin--top-1'>
            Fetching document...
          </StyledParagraph>
        </>
      )
    }

    return (
      <StyledDocumentBody
        key={guid}
        isSignatureGenerated={Boolean(type === SignatureType.GENERATED)}
      >
        {/* {ReactHtmlParser(renderedHtml)} */}
        {modifyRenderedHtml(renderedHtml)}
      </StyledDocumentBody>
    )
  }

  const ButtonsComponent: JSX.Element | any = () => {
    if (type === ESignType.SIGNATURE) {
      return (
        <>
          <Button
            className='font-14'
            label='Decline'
            hoverColorWeight='300'
            onClick={() => {
              if (isRequired) {
                openModal2()
                return
              }

              setButtonEventState(ButtonEventState.DECLINE_DOCUMENT)
              handleDeclineDocument()
            }}
            isLoading={
              isLoading &&
              buttonEventState === ButtonEventState.DECLINE_DOCUMENT
            }
            loadingLabel='Declining...'
            layout='outline'
          />
          <Button
            className='font-14'
            label='Sign'
            hoverColorWeight='300'
            onClick={() => {
              setButtonEventState(ButtonEventState.SIGN_DOCUMENT)
              handleSignDocument()
            }}
            isLoading={
              isLoading && buttonEventState === ButtonEventState.SIGN_DOCUMENT
            }
            loadingLabel='Signing...'
          />
        </>
      )
    }

    if (type === ESignType.OPT_IN) {
      return (
        <>
          <Button
            className='font-14'
            label='Opt Out'
            hoverColorWeight='300'
            onClick={() => {
              setButtonEventState(ButtonEventState.OPT_OUT_DOCUMENT)
              handleOptOutDocument()
            }}
            isLoading={
              isLoading &&
              buttonEventState === ButtonEventState.OPT_OUT_DOCUMENT
            }
            loadingLabel='Opting out...'
            layout='outline'
          />
          <Button
            className='font-14'
            label='Opt In'
            hoverColorWeight='300'
            onClick={() => {
              setButtonEventState(ButtonEventState.OPT_IN_DOCUMENT)
              handleOptInDocument()
            }}
            isLoading={
              isLoading && buttonEventState === ButtonEventState.OPT_IN_DOCUMENT
            }
            loadingLabel='Opting in...'
          />
        </>
      )
    }

    if (type === ESignType.ACKNOWLEDGEMENT) {
      return (
        <>
          <Button
            className='font-14'
            label='Decline'
            hoverColorWeight='300'
            onClick={() => {
              if (isRequired) {
                openModal2()
                return
              }

              setButtonEventState(ButtonEventState.DECLINE_DOCUMENT)
              handleDeclineDocument()
            }}
            isLoading={
              isLoading &&
              buttonEventState === ButtonEventState.DECLINE_DOCUMENT
            }
            loadingLabel='Declining...'
            layout='outline'
          />
          <Button
            className='font-14'
            label='Acknowledge'
            hoverColorWeight='300'
            onClick={() => {
              setButtonEventState(ButtonEventState.ACKNOWLEDGE_DOCUMENT)
              handleAcknowledgeDocument()
            }}
            isLoading={
              isLoading &&
              buttonEventState === ButtonEventState.ACKNOWLEDGE_DOCUMENT
            }
            loadingLabel='Acknowledging...'
          />
        </>
      )
    }

    return null
  }

  const handleConfirmDecline = () => {
    setButtonEventState(ButtonEventState.DECLINE_DOCUMENT)
    handleDeclineDocument()
  }

  return (
    <>
      <Modal2
        portalClassName='ReactModalPortal document--confirmation'
        bodyOpenClassName='ReactModal__Body--open document--confirmation'
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
      >
        <ESignConfirmation
          closeModal={closeModal2}
          handleConfirmDecline={handleConfirmDecline}
        />
      </Modal2>

      <StyledModalContainer
        height={88}
        className='STYLED MODAL CONTAINER @ DOCUMENT'
        padding='0'
      >
        <StyledWrapper fullWidth className='DOCUMENT_WRAPPER padding-0'>
          <Button
            label='Close'
            layout='outline'
            onClick={closeModal}
            className='borderless-button font-14'
          />
          <StyledWrapperBody
            flex={providerState.hasTag('fetching') ? true : false}
            textCenter={false}
            className='DOCUMENT_BODY padding--x-2 padding--y-2 mh-75 margin--top-4'
          >
            <StyledWrapperBodyContainer size='xl'>
              <BodyComponent />
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
          <StyledWrapperFooter positionMobileView='absolute'>
            <StyledWrapperFooterContainer>
              <ButtonsComponent />
            </StyledWrapperFooterContainer>
          </StyledWrapperFooter>
        </StyledWrapper>
      </StyledModalContainer>
    </>
  )
}

export default Document
