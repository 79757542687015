/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from 'react'

import { usePando } from '@utils/index'

import { config, options } from './machine'
import {
  ActionsProps,
  QuestionsStateType,
  QuestionsActionsType,
  QuestionsProps,
} from './types'

export const QuestionsState = createContext<Partial<QuestionsStateType>>({})
export const QuestionsActions = createContext<Partial<QuestionsActionsType>>({})

type PropsWithChildrenOnly = PropsWithChildren<QuestionsProps>

import { BootloaderState, BootloaderActions } from '@system/Bootloader'
import { MainState } from '@pages/index'
import { usePandoLogger } from '@pandolink/utils/dist/usePandoLogger'

const QuestionsProvider: React.FC<PropsWithChildrenOnly> = ({
  children,
  ...props
}) => {
  const { bootloaderState, hasTokenExpired } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)
  const { providerState: homeProviderState } = useContext(MainState)

  if (!bootloaderState || !bootloaderActions || !homeProviderState) return null

  const { handleTokenExpired } = bootloaderActions ?? {}

  const { accessToken } = bootloaderState?.context ?? {}
  const { sitePermissions, surveyConfiguration } =
    homeProviderState?.context ?? {}

  const machine = React.useMemo(
    () => ({
      machine: {
        config,
        options,
        context: {},
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  // OIDC Token Update - Start
  if (!state) return null

  const { accessToken: storedAccessToken, params } = state.context ?? {}

  useEffect(() => {
    if (storedAccessToken !== accessToken) {
      send({
        type: 'GOT_NEW_ACCESS_TOKEN',
        payload: accessToken,
      })
    }
  }, [accessToken])
  // OIDC Token Update - End

  // If OIDC did not transition to token expired
  // due to component is not rendered
  // at the time the token expired

  useEffect(() => {
    if (state.matches('session_expired')) {
      handleTokenExpired()
    }
  }, [state])
  // end

  useEffect(() => {
    if (hasTokenExpired) {
      send('CLEAR_EXPIRED_TOKEN')
    }
  }, [hasTokenExpired])

  useEffect(() => {
    send({
      type: 'UPDATE_SITE_PERMISSIONS',
      payload: {
        sitePermissions: sitePermissions,
        surveyConfiguration: surveyConfiguration,
      },
    })
  }, [
    sitePermissions?.camera,
    sitePermissions?.microphone,
    surveyConfiguration?.audioRequired,
    surveyConfiguration?.videoRequired,
  ])

  usePandoLogger({
    name: 'Question Machine State',
    body: state.value,
    color: 'primary',
  })

  const actions: ActionsProps = {
    handleAnswerViaVoiceInput: (answer) => {
      send({
        type: 'ANSWER_VIA_VOICE_INPUT',
        payload: answer,
      })
    },
    handleAnswerViaButtonClick: (answer) => {
      send({
        type: 'ANSWER_VIA_BUTTON_CLICK',
        payload: answer,
      })
    },
    handleMultipleAnswerViaButtonClick: (answer) => {
      send({
        type: 'MULTIPLE_ANSWER_VIA_BUTTON_CLICK',
        payload: answer,
      })
    },
    handleNoVoiceInputReceived: () => {
      send('NO_VOICE_INPUT_RECEIVED')
    },
    handleStartListening: () => {
      send('START_LISTENING')
    },
    handleTryAgain: () => {
      send('TRY_AGAIN')
    },
    handleSkipVideoPlayback: () => {
      send('SKIP_VIDEO_PLAYBACK')
    },
    handleVideoPlaybackEnded: () => {
      send('VIDEO_PLAYBACK_ENDED')
    },
    handleComponentDidMountPlayMedia: () => {
      send('COMPONENT_DID_MOUNT_PLAY_MEDIA')
    },
    handleReset: () => {
      send('RESET')
    },
    handleContinue: () => {
      send('CONTINUE')
    },
    handleAddFailedUploadIds: (createdAnswerGuid) => {
      send({
        type: 'ADD_FAILED_UPLOAD_ID',
        payload: createdAnswerGuid,
      })
    },
    handleUploadSuccess: () => {
      send({
        type: 'ANSWER_UPLOADED',
      })
    },
    handleUploadFailed: () => {
      send({
        type: 'ANSWER_UPLOAD_FAILED',
      })
    },
    handleReuploadSuccess: (createdAnswerGuid) => {
      send({
        type: 'REUPLOAD_SUCCESS',
        payload: createdAnswerGuid,
      })
    },
    handleReupload: () => {
      send('REUPLOAD')
    },
    handleGotNewAttempt: (attemptGuid) => {
      send({
        type: 'GOT_NEW_ATTTEMPT',
        payload: attemptGuid,
      })
    },
    handleClearContext: () => {
      send('CLEAR_CONTEXT')
    },
    handleReload: () => {
      send('RELOAD')
    },
    handleCompleteSurveyDirectly: () => {
      send('COMPLETE_SURVEY_DIRECTLY')
    },
    handleLastUploadSuccess: () => {
      send('LAST_QUESTION_UPLOAD_SUCCESS')
    },
    handleLastUploadFailed: () => {
      send('LAST_QUESTION_UPLOAD_FAILED')
    },
    handleReplayVideo: () => {
      send('REPLAY_VIDEO')
    },
    handleRestartRecording: () => {
      send('RESTART_RECORDING')
    },
    handleVideoLimitReached: () => {
      send('VIDEO_LIMIT_REACHED')
    },
    handleListeningTimeLimitReached: () => {
      send('LISTENING_TIME_LIMIT')
    },
    handleWaitingForClickTimeLimitReached: () => {
      send('WAITING_FOR_CLICK_TIME_LIMIT')
    },
    handleErrorPlayingVideo: () => {
      send('ERROR_PLAYING_VIDEO')
    },
    handleUploadMediaFailed: () => {
      send('UPLOAD_MEDIA_FAILED')
    },
    handleUpdateRequestProgress: (payload) => {
      send({ type: 'UPDATE_REQUEST_PROGRESS', payload })
    },
    handleUnacceptablePrompt: () => {
      send('UNACCEPTABLE_ANSWER_CLICK')
    },
    handleInterruptedResponseViaWindowBlur: () => {
      send('INTERRUPTED_RESPONSE_VIA_WINDOW_BLUR')
    },
    handleInterruptedMediaRecording: () => {
      send('INTERRUPTED_MEDIA_RECORDING')
    },
    handleChangeAnswer: () => {
      send('CHANGE_ANSWER')
    },
    handleAnswerIsYes: () => {
      send('ANSWER_IS_YES')
    },
    handleAnswerIsNo: () => {
      send('ANSWER_IS_NO')
    },
    handleAnswerIsInvalid: () => {
      send('ANSWER_IS_INVALID')
    },
    handleSRApiRequestError: (errorMessage) => {
      send({
        type: 'REQUEST_ERROR',
        data: errorMessage,
      })
    },
    handleSessionExpired: () => {
      send('SESSION_EXPIRED')
    },
    handleFirstVideoPlayedTrue: () => {
      send('FIRST_VIDEO_PLAYED_TRUE')
    },
  }

  return (
    <QuestionsState.Provider
      value={{
        providerState: state,
        providerProps: props,
      }}
    >
      <QuestionsActions.Provider
        value={{
          providerActions: actions,
        }}
      >
        {children}
      </QuestionsActions.Provider>
    </QuestionsState.Provider>
  )
}
export default QuestionsProvider
