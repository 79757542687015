import React, { useContext } from 'react'

import { Icon, Button } from '@pando-styles/nirvana'
import { MainActions } from '@pages/index'
import { QuestionsActions } from '@applications/index'

import { StyledUserMicContainer } from '../Dialog/styles'

import { UnacceptableAnswerDialogProps } from './types'
import { ButtonWidth, ButtonsContainer } from './styles'
const UnacceptableAnswerDialog = ({
  _fontSize,
  setUserRecordedVideo,
}: UnacceptableAnswerDialogProps) => {
  const { providerActions: homeProviderActions } = useContext(MainActions)
  const { providerActions: questionProviderActions } =
    useContext(QuestionsActions)

  if (!homeProviderActions || !questionProviderActions) return null

  const { handleShouldCreateNewAttempt } = homeProviderActions ?? {}
  const { handleChangeAnswer } = questionProviderActions ?? {}

  return (
    <StyledUserMicContainer isListening={false} className='MicContainer w-400'>
      <Icon
        iconName='alert-circle'
        color='danger'
        className={`scale-1-5 m-auto margin--bottom-1`}
      />
      <span style={{ fontSize: _fontSize }}>
        Your selection will prevent you from completing this survey. If more
        clarification is needed, please contact the organization that sent you
        this survey.
      </span>
      <ButtonsContainer>
        <ButtonWidth>
          <Button
            style={{ fontSize: _fontSize, whiteSpace: 'nowrap' }}
            label='Continue Anyway'
            hoverColorWeight='300'
            color='danger'
            onClick={() =>
              handleShouldCreateNewAttempt && handleShouldCreateNewAttempt()
            }
            colorWeight='DEFAULT'
            block
          />
        </ButtonWidth>
        <ButtonWidth>
          <Button
            style={{ fontSize: _fontSize, whiteSpace: 'nowrap' }}
            label='Change Answer'
            hoverColorWeight='300'
            color='primary'
            onClick={() => {
              setUserRecordedVideo([])
              handleChangeAnswer && handleChangeAnswer()
            }}
            colorWeight='DEFAULT'
            block
          />
        </ButtonWidth>
      </ButtonsContainer>
    </StyledUserMicContainer>
  )
}

export default UnacceptableAnswerDialog
