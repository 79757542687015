/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState, Dispatch } from 'react'
import { RaySpinner } from '@pando-styles/nirvana'
import { useModal, usePandoLogger } from '@pandolink/utils'

import PermissionModal from './PermissionModal'
import Profile from './ProfileTemplate'

type MediaLiveDetectProps = {
  isCaptureVideoActive?: boolean
  testCameraVideoStream?: MediaStream | null
  setTestCameraVideoStream?: Dispatch<MediaStream | null>
}

enum ModalState {
  LOADING = 'loading',
  DENIED = 'denied',
  ALLOWED = 'allowed',
}

const MediaLiveDetect: React.FC<MediaLiveDetectProps> = (props) => {
  const { isCaptureVideoActive } = props

  const videoRef = useRef<HTMLVideoElement>(null)

  const [hasHandleVideoStarted, setHasHandleVideoStarted] = useState(false)
  const [webcamReady, setWebcamReady] = useState(false)
  const [hasCamPermission, setHasCamPermission] = useState(false)
  const [camPermissionAttempts, setCamPermissionAttempts] = useState(0)
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false)
  const [modalState, setModalState] = useState(ModalState.LOADING)
  const [isLoading, setIsLoading] = useState(false)

  const { openModal, closeModal, modalIsOpen, Modal } = useModal()

  const constraints = {
    video: true,
    audio: false,
  }

  let timeout: any

  // Assign Stream
  const handleStreamReady = (stream: any) => {
    setWebcamReady(true)

    // @ts-ignore
    videoRef.current.srcObject = stream

    // @ts-ignore
    window.stream = stream

    timeout = setTimeout(() => setIsLoading(false), 400)
  }

  // Get and Pass Stream
  const initializeWebcam = async () => {
    try {
      let stream: MediaStream | null = null

      setIsLoading(true)

      // @ts-ignore
      if (window.stream) {
        usePandoLogger({
          name: 'Maintain Camera View - Previous Stream',
          body: {
            // @ts-ignore
            stream: window.stream,
            // @ts-ignore
            streamTracks: window.stream.getTracks(),
          },
          color: 'success',
        })

        setHasCamPermission(true)

        setModalState(ModalState.ALLOWED)

        // @ts-ignore
        handleStreamReady(window.stream)

        // return early to use the window.stream
        return
      }

      setCamPermissionAttempts((prev) => prev + 1)

      stream = await navigator?.mediaDevices?.getUserMedia(constraints)

      usePandoLogger({
        name: 'Maintain Camera View - New Stream',
        body: {
          stream,
          streamTracks: stream.getTracks(),
        },
        color: 'success',
      })

      setHasCamPermission(true)

      stream && setModalState(ModalState.ALLOWED)

      handleStreamReady(stream)
    } catch (error: any) {
      console.error('Error [initializeWebcam]:', error.message)

      if (
        error?.message?.toString()?.includes('denied') ||
        error?.message?.toString()?.includes('dismissed')
      ) {
        setHasCamPermission(false)
        setModalState(ModalState.DENIED)
        timeout = setTimeout(() => setIsLoading(false), 400)
        return
      }
    }
  }

  // Initialize Stream and Webcam
  useEffect(() => {
    initializeWebcam()

    return () => {
      // @ts-ignore
      if (window.stream) {
        // @ts-ignore
        window.stream.getTracks().forEach((track) => track.stop())

        // @ts-ignore
        if (window.stream.getTracks()?.length === 0) {
          // @ts-ignore
          window.stream = null
        }
      }

      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (!hasCamPermission) {
      openModal()
    } else {
      closeModal()
    }
  }, [hasCamPermission])

  useEffect(() => {
    if (camPermissionAttempts > 3) {
      setMaxAttemptsReached(true)
    }
  }, [camPermissionAttempts])

  useEffect(() => {
    if (!isCaptureVideoActive) setHasHandleVideoStarted(false)
  }, [isCaptureVideoActive])

  return (
    <>
      {modalIsOpen && modalState === ModalState.DENIED && (
        <Modal isOpen={modalIsOpen} onRequestClose={() => {}}>
          <PermissionModal
            handleTryAgain={initializeWebcam}
            maxAttemptsReached={maxAttemptsReached}
            isLoading={isLoading}
            camPermissionAttempts={camPermissionAttempts}
          />
        </Modal>
      )}
      {
        <>
          {webcamReady ? (
            <Profile />
          ) : (
            <RaySpinner
              className='absolute'
              color='primary'
              colorWeight='DEFAULT'
            />
          )}
          <video className='flip-x' ref={videoRef} autoPlay playsInline muted />
        </>
      }
    </>
  )
}

export default MediaLiveDetect
