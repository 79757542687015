/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'regenerator-runtime/runtime'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Input, Select } from '@pando-styles/nirvana'

import { StyledFlexContainer } from '@styles/index'

import { StyledTextSignatureToolbar, StyledImageWrappper } from './styles'
import { ESignActions, ESignState } from '../ESignProvider'

const TextSignature: React.FC = () => {
  const { providerActions } = useContext(ESignActions)
  const { providerState } = useContext(ESignState)

  const [imageURL, setImageURL] = useState(null)
  const [signatureText, setSignatureText] = useState('')
  const [fontFamily, setFontFamily] = useState('Arial')

  if (!providerActions || !providerState) return null

  const sigRef = useRef(null)

  const { handleSaveSignature, handleClearSignature } = providerActions ?? {}

  const handleClickedClearSignature = () => {
    if (!sigRef || !sigRef.current) return

    const imageWrapper: any = sigRef.current

    const image = new Image()
    image.src = ''
    imageWrapper.replaceChildren(image)

    setImageURL(null)
    setSignatureText('')
    handleClearSignature()
  }

  const handleClickedSaveSignature = () => {
    if (!imageURL) return

    handleSaveSignature(imageURL)
  }

  const textToBitmap = (text: string, font = 'Arial') => {
    const FONT_SIZE = 3

    const canvas = window.document.createElement('canvas')

    const ctx = canvas.getContext('2d')

    if (!ctx) return null

    ctx.font = `${FONT_SIZE}rem ${font || 'Arial'}`

    const {
      actualBoundingBoxLeft,
      actualBoundingBoxRight,
      actualBoundingBoxAscent,
      actualBoundingBoxDescent,
      fontBoundingBoxAscent,
      width,
    } = ctx.measureText(text)

    canvas.height =
      actualBoundingBoxAscent + actualBoundingBoxDescent + fontBoundingBoxAscent

    canvas.width = Math.max(
      width,
      Math.abs(actualBoundingBoxLeft) + actualBoundingBoxRight
    )

    ctx.font = `${FONT_SIZE}rem ${font || 'Arial'}`
    ctx.textBaseline = 'top'
    ctx.fillText(text, 0, 0)

    return new Promise((resolve) => {
      canvas.toBlob(resolve)
    })
  }

  const blobToBase64 = async (blob: Blob | any) =>
    new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })

  const generateImage = async () => {
    const imageWrapper: any = sigRef.current

    if (!signatureText) {
      return
    }

    const imageBlob: any = await textToBitmap(signatureText, fontFamily)

    const imageUrl = URL.createObjectURL(imageBlob)

    const image = new Image()
    image.src = imageUrl
    imageWrapper.replaceChildren(image)

    setImageURL((await blobToBase64(imageBlob)) as any)
  }

  const handleChangeFont = (data: any) => {
    const { value } = data

    setFontFamily(value)
  }

  const handleChangeText = (data: any) => {
    setSignatureText(data?.value)
  }

  useEffect(() => {
    generateImage()
  }, [signatureText, fontFamily])

  return (
    <>
      <StyledTextSignatureToolbar className='Text--Signature--Toolbar'>
        <Input
          id='type-signature'
          value={signatureText}
          type='text'
          placeholder='Type your signature here...'
          actions={{
            handleChange: handleChangeText,
          }}
          name='test'
          disabled={false}
          iconLeft='edit-3'
          className='grid-search-input'
        />
        <Select
          name='fontFamily'
          className='select-container'
          icon=''
          value={fontFamily}
          errorText='This field is required.'
          isClearable={true}
          placeholder='Change Font'
          handleChange={handleChangeFont}
          selectOptions={[
            'Arial',
            'Georgia',
            'Playfair Display',
            'Roboto',
            'Tahoma',
            'Times New Roman',
          ]}
        />
      </StyledTextSignatureToolbar>
      <StyledImageWrappper
        ref={sigRef}
        id='image-wrapper-text-signature'
      ></StyledImageWrappper>
      <StyledFlexContainer className='margin--top-1'>
        <Button
          label='Clear'
          hoverColorWeight='300'
          layout='outline'
          onClick={handleClickedClearSignature}
          isDisabled={!imageURL || providerState.hasTag('saving_signature')}
          className='font-14'
        />
        <Button
          label='Save'
          hoverColorWeight='300'
          onClick={handleClickedSaveSignature}
          isDisabled={!imageURL || providerState.hasTag('saving_signature')}
          isLoading={providerState.hasTag('saving_signature')}
          loadingLabel='Saving...'
          className='font-14'
        />
      </StyledFlexContainer>
    </>
  )
}

export default TextSignature
