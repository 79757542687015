/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { Button, Container } from '@pando-styles/nirvana'

import { StyledContainer } from '@styles/index'
import { StyledLink, Styledh2, StyledParagraph } from './styles'
import { SectionSeparator } from '@styles/index'

import {
  BootloaderActions,
  BootloaderState,
} from '@system/Bootloader/BootloaderProvider'

import Branding from './Branding'

const LandingPage = () => {
  const { bootloaderActions } = useContext(BootloaderActions)
  const { bootloaderState } = useContext(BootloaderState)
  const [loginClicked, setLoginClicked] = useState(false)

  if (!bootloaderState || !bootloaderActions) return null

  const {
    instanceGuid,
    signatoryGuid,
    claimCode,
    anonymousLogin = false,
    isAuthenticated,
  } = bootloaderState?.context ?? {}
  const { handleLogin, handleLoginAsGuest } = bootloaderActions ?? {}

  const handleLoginClick = () => {
    setLoginClicked(true)
    handleLogin()
  }

  useEffect(() => {
    if (isAuthenticated) {
      setLoginClicked(false)
    }
  }, [bootloaderState])

  const hasParameterAndAnonymousLoginEnabled =
    Boolean(instanceGuid || signatoryGuid || claimCode) && anonymousLogin

  return (
    <StyledContainer className='bg-color_bluish-white landing-page'>
      <Container
        size='md'
        direction='column'
        centerXY
        className='outer-container'
        customBg='fff'
      >
        <Container
          size='lg'
          height={hasParameterAndAnonymousLoginEnabled ? 'lg' : 'sm'}
          direction='column'
          centerX
          className='inner-container column--space-around '
        >
          <Styledh2 className='text-center'>Login with account</Styledh2>
          <StyledParagraph className='text-center'>
            By signing up you will be able to view your stored documents and
            videos.
          </StyledParagraph>
          <Button
            label='Login'
            layout='solid'
            block
            onClick={handleLoginClick}
            loadingLabel='Redirecting...'
            isLoading={!isAuthenticated && loginClicked}
            className='font-14'
          />
          <StyledParagraph>
            Don't have an account?{' '}
            <StyledLink className='styled-link' to='/'>
              Sign Up
            </StyledLink>
          </StyledParagraph>

          {hasParameterAndAnonymousLoginEnabled ? (
            <>
              <SectionSeparator>OR</SectionSeparator>
              <Styledh2 className='text-center'>Login as guest</Styledh2>
              <StyledParagraph className='text-center'>
                You can continue as guest but keep in mind that you wont be able
                to view your documents.
              </StyledParagraph>
              <Button
                label='Guest Login'
                layout='outline'
                block
                onClick={handleLoginAsGuest}
                className='font-14'
              />
            </>
          ) : (
            <></>
          )}
        </Container>
      </Container>
      <Container
        size='md'
        direction='column'
        className='outer-container branding'
        centerXY
      >
        <Branding />
      </Container>
    </StyledContainer>
  )
}

export default LandingPage
