/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useRef, useState } from 'react'
import { Button } from '@pando-styles/nirvana'
import SignaturePad from 'react-signature-canvas'

import { StyledFlexContainer } from '@styles/index'

import { StyledSigImage, StyledSigPadContainer } from './styles'
import { ESignActions, ESignState } from '../ESignProvider'

const DrawSignature: React.FC = () => {
  const { providerActions } = useContext(ESignActions)
  const { providerState } = useContext(ESignState)
  const [imageURL, setImageURL] = useState(null)

  if (!providerActions || !providerState) return null

  const sigRef = useRef(null)

  const { handleSaveSignature, handleClearSignature } = providerActions ?? {}

  const handleOnWriteEnd = () => {
    if (!sigRef || !sigRef.current) return

    setImageURL(() =>
      // @ts-ignore
      sigRef.current?.getTrimmedCanvas().toDataURL('image/png')
    )
  }

  const handleClickedClearSignature = () => {
    if (!sigRef || !sigRef.current) return

    // @ts-ignore
    sigRef.current.clear()
    setImageURL(null)
    handleClearSignature()
  }

  const handleClickedSaveSignature = () => {
    if (!imageURL) return

    handleSaveSignature(imageURL)
  }

  return (
    <>
      <StyledSigPadContainer>
        <SignaturePad
          canvasProps={{
            className: 'sigpad',
          }}
          onEnd={handleOnWriteEnd}
          ref={sigRef}
        />
        {imageURL && <StyledSigImage src={imageURL} alt='my signature' />}
      </StyledSigPadContainer>
      <StyledFlexContainer className='margin--top-1'>
        <Button
          label='Clear'
          hoverColorWeight='300'
          layout='outline'
          onClick={handleClickedClearSignature}
          isDisabled={!imageURL || providerState.hasTag('saving_signature')}
          className='font-14'
        />
        <Button
          label='Save'
          hoverColorWeight='300'
          onClick={handleClickedSaveSignature}
          isDisabled={!imageURL || providerState.hasTag('saving_signature')}
          isLoading={providerState.hasTag('saving_signature')}
          loadingLabel='Saving...'
          className='font-14'
        />
      </StyledFlexContainer>
    </>
  )
}

export default DrawSignature
