import 'regenerator-runtime/runtime'
import {
  SurveyInstanceExecutionServicePromiseClient,
  Attempt,
  setRequest,
} from '@utils/index'

type Params = Attempt.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const createAttempt = async (params: Params, accessToken: string) => {
  const service = new SurveyInstanceExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new Attempt(), params)

  const result = await service.createAttempt(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
