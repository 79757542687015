/* eslint-disable indent */
import React, { useContext } from 'react'
import { Button, Icon } from '@pando-styles/nirvana'

import {
  StyledHeadingThree,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { StyledErrorParagraph } from '../styles'

import { MainActions, MainState } from '@pages/index'
import { BootloaderActions, BootloaderState } from '@system/Bootloader'

import { StyledButtonDiv } from '../styles'

type InvalidSurveyInstanceProps = {
  title?: string
  text?: string
  showTitle?: boolean
}

const InvalidSurveyInstance: React.FC<InvalidSurveyInstanceProps> = ({
  text = '',
  title = '',
  showTitle = true,
}) => {
  const { providerState } = useContext(MainState)
  const { providerActions } = useContext(MainActions)
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (
    !providerActions ||
    !providerState ||
    !bootloaderState ||
    !bootloaderActions
  )
    return null

  const { accessToken, instanceGuid, signatoryGuid, claimCode } =
    bootloaderState.context ?? {}
  const { handleGoToSurveyList } = providerActions ?? {}
  const { handleUpgradeGuestAccount, handleClearBootloaderUrlParams } =
    bootloaderActions ?? {}

  return (
    <StyledWrapper className='flex-center flex-column'>
      <StyledWrapperBody>
        <StyledWrapperBodyContainer>
          {showTitle && <StyledHeadingThree>{title}</StyledHeadingThree>}
          {title === 'Survey Already Completed' ? (
            <Icon iconName='check' className='m-auto margin--top-2' size='xl' />
          ) : (
            <Icon
              iconName='alert-circle'
              color='danger'
              className={`scale-1-5 m-auto`}
            />
          )}
          <StyledErrorParagraph className='margin--bottom-1 margin--top-1 padding--x-1-at-800 text-black'>
            {text}
          </StyledErrorParagraph>
          <StyledButtonDiv>
            {showTitle && (
              <Button
                label='Okay'
                hoverColorWeight='300'
                onClick={() => {
                  handleClearBootloaderUrlParams()
                  handleGoToSurveyList()
                }}
                className='font-14'
              />
            )}
            {providerState.matches('guest_not_allowed') && (
              <Button
                label='Login'
                hoverColorWeight='300'
                onClick={() =>
                  handleUpgradeGuestAccount({
                    instanceGuid: instanceGuid ?? '',
                    signatoryGuid: signatoryGuid ?? '',
                    claimCode: claimCode ?? '',
                    guestToken: accessToken,
                  })
                }
                className='font-14'
              />
            )}
          </StyledButtonDiv>
        </StyledWrapperBodyContainer>
      </StyledWrapperBody>
    </StyledWrapper>
  )
}

export default InvalidSurveyInstance
