/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useContext,
} from 'react'
import { useParams } from 'react-router-dom'

import { usePando } from '@utils/index'
import { BootloaderActions, BootloaderState } from '@system/Bootloader'

import { config, options } from './machine'
import {
  ActionsProps,
  VideoResponseStateType,
  VideoResponseActionsType,
} from './types'

export const VideoResponseState = createContext<
  Partial<VideoResponseStateType>
>({})
export const VideoResponseActions = createContext<
  Partial<VideoResponseActionsType>
>({})

type PropsWithChildrenOnly = PropsWithChildren<any>

const VideoResponseProvider: React.FC<PropsWithChildrenOnly> = ({
  children,
  ...props
}) => {
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)
  const { surveyAttemptGuid } = useParams<{ surveyAttemptGuid: string }>()

  if (!bootloaderState || !bootloaderActions) return null

  const { accessToken } = bootloaderState?.context ?? {}
  const { handleTokenExpired } = bootloaderActions ?? {}

  const machine = React.useMemo(
    () => ({
      machine: {
        config,
        options,
        context: {
          urlParamsGuid: surveyAttemptGuid,
        },
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  if (!state) return null

  const { urlParamsGuid: storedSurveyAttemptGuid } = state?.context ?? {}

  const actions: ActionsProps = {
    handleGotNewUrlSurveyGuid: (surveyAttemptGuid) => {
      send({
        type: 'GOT_NEW_URL_SURVEY_GUID',
        payload: surveyAttemptGuid,
        newGuid: storedSurveyAttemptGuid !== surveyAttemptGuid,
      })
    },
    handleViewQuestion: (question) => {
      send({
        type: 'VIEW_QUESTION',
        payload: question,
      })
    },
    handleGoBackToQuestionsList: () => {
      send({
        type: 'GO_BACK_TO_QUESTIONS_LIST',
      })
    },
    handleReload: () => {
      send('RELOAD')
    },
  }

  // OIDC Token Update - Start
  const { accessToken: storedAccessToken } = state.context ?? {}

  useEffect(() => {
    if (storedAccessToken !== accessToken) {
      send({
        type: 'GOT_NEW_ACCESS_TOKEN',
        payload: accessToken,
      })
    }
  }, [accessToken])
  // OIDC Token Update - End

  // If OIDC did not transition to token expired
  // due to component is not rendered
  // at the time the token expired
  useEffect(() => {
    if (state.matches('session_expired')) {
      handleTokenExpired()
    }
  }, [state])
  // end

  useEffect(() => {
    surveyAttemptGuid && actions.handleGotNewUrlSurveyGuid(surveyAttemptGuid)
  }, [surveyAttemptGuid, storedSurveyAttemptGuid])

  return (
    <VideoResponseState.Provider
      value={{
        providerState: state,
      }}
    >
      <VideoResponseActions.Provider
        value={{
          providerActions: actions,
        }}
      >
        {children}
      </VideoResponseActions.Provider>
    </VideoResponseState.Provider>
  )
}
export default VideoResponseProvider
