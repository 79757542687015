/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'grid',
  initial: 'loading',
  on: {
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#loading',
    },
    SESSION_EXPIRED: {
      actions: [],
      target: '#session_expired',
    },
  },
  states: {
    loading: {
      id: 'loading',
      initial: 'check_existing_data',
      states: {
        check_existing_data: {
          always: [
            {
              cond: 'hasExistingData',
              target: '#ready.fetching',
            },
            {
              target: 'initial_fetching',
            },
          ],
        },
        initial_fetching: {
          tags: ['loader'],
          invoke: {
            id: 'initial-fetch',
            src: 'initialFetch',
          },
          on: {
            GOT_INITIAL_DATA: {
              actions: ['assignFetchedData'],
              target: '#ready.loaded',
            },
            // @ts-ignore
            GET_INITIAL_DATA_ERROR: {
              target: '#error',
            },
          },
        },
      },
    },
    ready: {
      id: 'ready',
      initial: 'fetching',
      on: {
        REFETCH: {
          target: '#fetching',
        },
      },
      states: {
        fetching: {
          id: 'fetching',
          tags: ['fetching'],
          invoke: {
            id: 'fetch',
            src: 'fetchData',
          },
          on: {
            GOT_DATA: {
              actions: ['assignFetchedData'],
              target: 'loaded',
            },
            ERROR: {
              target: '#error',
            },
          },
        },
        loaded: {
          on: {
            NEXT: 'fetching',
            PREVIOUS: 'fetching',
            JUMP_TO_FIRST: 'fetching',
            JUMP_TO_LAST: 'fetching',
            JUMP_TO_PAGE: 'fetching',
            CHECKED: {
              actions: ['assignSelectedRow'],
            },
            SORT: {
              actions: ['sortItems'],
              target: 'fetching',
            },
            SELECT_DATE: {
              actions: ['assignDate'],
              target: 'fetching',
            },
            UPDATE_SEARCH_TEXT: {
              actions: ['updateSearchText'],
            },
            SEARCH: 'fetching',
          },
        },
      },
    },
    error: {
      id: 'error',
      on: {
        RELOAD: {
          target: '#loading',
        },
      },
    },
    session_expired: {
      id: 'session_expired',
    },
  },
}
