/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import { Button } from '@pando-styles/nirvana'

import { StyledParagraph, StyledFlexContainer } from '@styles/index'
import { QuestionsState, QuestionsActions } from '@applications/index'
import { AnswerOption, formatBytes } from '@utils/index'

import { ISingleAnswerProps } from './types'

const SingleAnswer: React.FC<ISingleAnswerProps> = (props) => {
  const {
    _fontSize,
    listening,
    clickedBtnId,
    hasOnlyOneAnswerOption,
    userRecordedVideo,
    showMicrophoneComponent,
    setClickedBtnId,
  } = props

  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)

  if (!providerState || !providerActions) return null

  const { question, selectedAnswer } = providerState?.context ?? {}
  const { handleUnacceptablePrompt, handleAnswerViaButtonClick } =
    providerActions ?? {}

  const hasMoreThanOrEqualToOneAnswerOption =
    (question?.answerOptionsList ?? [])?.length >= 1

  const FontAdjustedSpan = ({ text }: any) => (
    <span style={{ fontSize: _fontSize }}>{text}</span>
  )

  const handleClickedAnswer = (answer: AnswerOption.AsObject | any) => {
    if (!answer?.isAcceptable) {
      return handleUnacceptablePrompt && handleUnacceptablePrompt()
    }
    handleAnswerViaButtonClick?.(answer)
    setClickedBtnId(answer?.guid)
  }

  return (
    <>
      <StyledParagraph
        className={`mic-paragraph margin--top-1-2`}
        fontSize={_fontSize}
      >
        {providerState.matches('ready.listening') &&
          !listening &&
          'Please wait until the microphone loads.'}
        {providerState.matches('ready.listening') && listening && (
          <span style={{ fontSize: _fontSize }}>
            Microphone is listening, please say
            <span className='block margin--top-1-4'>
              <q style={{ fontSize: _fontSize }}>'Yes'</q> or{' '}
              <q style={{ fontSize: _fontSize }}>'No'.</q>
            </span>
          </span>
        )}
        {(question?.answerOptionsList ?? [])?.length > 1 &&
          providerState.matches('ready.waiting_for_button_click') && (
            <span style={{ fontSize: _fontSize }}>
              Please confirm your answer below by pressing
              {showMicrophoneComponent() ? (
                <span className='block margin--top-1-4'>
                  <q style={{ fontSize: _fontSize }}>
                    '{selectedAnswer?.name}'.
                  </q>
                </span>
              ) : (
                <span className='block margin--top-1-4'>
                  <q style={{ fontSize: _fontSize }}> 'Yes'</q> or{' '}
                  <q style={{ fontSize: _fontSize }}>'No'.</q>
                </span>
              )}
            </span>
          )}
        {providerState.matches('ready.exec_repeat_question') && (
          <FontAdjustedSpan text='Preparing..' />
        )}
        {providerState.matches('ready.save_answer') && (
          <FontAdjustedSpan text='Uploading..' />
        )}

        {providerState.matches('ready.uploading_answer') && (
          <FontAdjustedSpan text='Uploading..' />
        )}
        {providerState.matches('ready.uploading_answer_failed') && (
          <FontAdjustedSpan text='Response failed to upload.' />
        )}
        {providerState.matches('ready.time_limit') && (
          <FontAdjustedSpan text='Please make a selection within 4 seconds..' />
        )}
        {providerState.matches(
          'ready.verify_answer_via_speech_recognition_api'
        ) && <FontAdjustedSpan text='Recognizing answer...' />}
        {providerState.matches('ready.video_limit_reached') && (
          <span style={{ fontSize: _fontSize }}>
            Video size <q>{formatBytes(userRecordedVideo?.[0]?.size)}</q> is
            over 10 Mb. Please try answering again.
          </span>
        )}
        {providerState.matches('ready.execute_complete_question') && (
          <FontAdjustedSpan text='Uploaded.' />
        )}
        {providerState.hasTag('invalid_response') && (
          <FontAdjustedSpan text='Speech was not detected. Please try again.' />
        )}
        {providerState.matches(
          'ready.interrupted_response_via_window_blur'
        ) && (
          <FontAdjustedSpan text='Your response was interrupted. Please try again.' />
        )}
        {providerState.matches('ready.error_playing_video') && (
          <FontAdjustedSpan text='Something went wrong when playing the video. Please try again.' />
        )}
        {providerState.matches('ready.waiting_for_button_click') &&
          hasOnlyOneAnswerOption && (
            <span style={{ fontSize: _fontSize }}>
              Press the button below to finish this survey.
            </span>
          )}
      </StyledParagraph>

      {providerState.matches('ready.waiting_for_button_click') &&
        hasMoreThanOrEqualToOneAnswerOption && (
          <StyledFlexContainer className='SINGLE_ANSWER_YES_OR_NO_OR_CONTINUE margin--top-1-2 w-300 w-100-at-800'>
            {question?.answerOptionsList
              ?.map((answer) => (
                <Button
                  style={{ fontSize: _fontSize }}
                  isDisabled={
                    !providerState.matches('ready.waiting_for_button_click') ||
                    ((question?.answerOptionsList ?? [])?.length > 1 &&
                      answer?.name?.toLowerCase() !==
                        selectedAnswer?.name?.toLowerCase() &&
                      showMicrophoneComponent())
                  }
                  key={answer?.guid}
                  label={answer?.name}
                  hoverColorWeight='300'
                  color={
                    (question?.answerOptionsList ?? [])?.length > 1 &&
                    showMicrophoneComponent()
                      ? providerState.matches(
                          'ready.waiting_for_button_click'
                        ) && answer?.guid === selectedAnswer?.guid
                        ? 'primary'
                        : 'light'
                      : 'primary'
                  }
                  onClick={() =>
                    handleClickedAnswer({
                      ...answer,
                      videoSize:
                        userRecordedVideo?.[0][
                          userRecordedVideo?.[0]?.length - 1
                        ]?.size ?? 0,
                    })
                  }
                  isLoading={
                    clickedBtnId === answer?.guid &&
                    providerState.hasTag('loader')
                  }
                  loadingLabel={answer?.name}
                  colorWeight={
                    (question?.answerOptionsList ?? [])?.length > 1 &&
                    showMicrophoneComponent()
                      ? providerState.matches(
                          'ready.waiting_for_button_click'
                        ) && answer?.guid === selectedAnswer?.guid
                        ? 'DEFAULT'
                        : '400'
                      : 'DEFAULT'
                  }
                  block
                />
              ))
              .reverse()}
          </StyledFlexContainer>
        )}
    </>
  )
}

export default SingleAnswer
