import React from 'react'
import { Button, Icon } from '@pando-styles/nirvana'

import { StyledModalContainer, StyledParagraph } from '@styles/index'

interface PermissionModalProps {
  handleTryAgain: () => void
  maxAttemptsReached: boolean
  type: 'camera' | 'microphone'
  isPermissionLoading: boolean
  camPermissionAttempts: number
  micPermissionAttempts: number
}

const PermissionModal: React.FC<PermissionModalProps> = (props) => {
  const {
    handleTryAgain,
    maxAttemptsReached = false,
    type = 'camera',
    isPermissionLoading = false,
    camPermissionAttempts = 0,
    micPermissionAttempts = 0,
  } = props

  return (
    <StyledModalContainer
      textAlign
      width={400}
      className='padding--x-2 padding--y-2'
    >
      <Icon
        iconName='alert-circle'
        color='danger'
        className={`scale-1-5 m-auto margin--bottom-1`}
      />
      <StyledParagraph className='m-auto'>
        {type === 'camera' ? 'Camera' : 'Microphone'} access is required to
        continue this survey.{' '}
        {maxAttemptsReached
          ? `Please check that the settings on your device or browser has the ${type} permission set to 'Allow' and then refresh this page.`
          : ''}
      </StyledParagraph>
      {!maxAttemptsReached && (
        <Button
          iconLeft='rotate-ccw'
          label='Try Again'
          color='primary'
          layout='outline'
          hoverColorWeight='300'
          className='m-auto margin--top-1 custom-button-link-2 bold font-14'
          onClick={() => {
            handleTryAgain()
          }}
          isLoading={
            isPermissionLoading &&
            (camPermissionAttempts > 1 || micPermissionAttempts > 1)
          }
          isDisabled={
            isPermissionLoading &&
            (camPermissionAttempts > 1 || micPermissionAttempts > 1)
          }
        />
      )}
    </StyledModalContainer>
  )
}

export default PermissionModal
