import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'site-permission',
  initial: 'user_permissions',
  on: {
    CLEAN_UP: {
      actions: ['clearContext'],
      target: '#user_permissions',
    },
    CLEAR_ATTEMPT: {
      actions: ['clearAttempt'],
    },
    SITE_SETTINGS_ALREADY_DENIED_ACCESS: {
      actions: ['setDeniedAccessToTrue'],
    },
    SITE_SETTINGS_STILL_SHOW_PERMISSION_POPUP: {
      actions: ['setDeniedAccessToFalse'],
    },
  },
  states: {
    user_permissions: {
      id: 'user_permissions',
      on: {
        REQUEST_USER_PERMISSION: {
          actions: [
            'assignSurveyConfiguration',
            'setRequestingUserPermissionToTrue',
          ],
          target: '#execute_permissions',
        },
      },
      initial: 'idle',
      states: {
        idle: {
          id: 'idle',
        },
        execute_permissions: {
          id: 'execute_permissions',
          invoke: {
            id: 'requestUserPermission',
            src: 'requestUserPermission',
          },
          on: {
            ALLOW: [
              {
                cond: 'isVideoAudioNotRequired',
                actions: ['allowAccess', 'setDeniedAccessToFalse'],
                target: '#permitted',
              },
              {
                actions: ['allowAccess', 'setDeniedAccessToFalse'],
                target: '#execute_location_permission',
              },
            ],
            BLOCK_ACCESS: [
              {
                cond: 'isSafariAndMaxAttemptsReached',
                actions: ['blockAccess', 'setDeniedAccessToTrue'],
                target: '#permission_denied',
              },
              {
                cond: 'isSafari',
                actions: [
                  'blockAccess',
                  'incrementAttempt',
                  'setRequestingUserPermissionToFalse',
                ],
                target: '#permission_denied',
              },
              {
                actions: ['blockAccess', 'setDeniedAccessToTrue'],
                target: '#permission_denied',
              },
            ],
            POPUP_DISMISSED: [
              {
                cond: 'maxRetries',
                actions: ['setDeniedAccessToTrue'],
                target: '#permission_denied',
              },
              {
                actions: [
                  'incrementAttempt',
                  'setRequestingUserPermissionToFalse',
                ],
              },
            ],
            NO_DEVICE_FOUND: {
              actions: ['setDeniedAccessToTrue'],
              target: '#no_device_found',
            },
          },
        },
        execute_location_permission: {
          id: 'execute_location_permission',
          invoke: {
            id: 'requestLocationPermission',
            src: 'requestLocationPermission',
          },
          on: {
            LOCATION_PERMISSION: {
              actions: ['assignIsLocationPermissionGranted'],
              target: '#permitted',
            },
          },
        },
      },
    },
    permitted: {
      id: 'permitted',
    },
    permission_denied: {
      id: 'permission_denied',
      on: {
        REQUEST_USER_PERMISSION: [
          {
            cond: 'maxRetries',
          },
          {
            actions: ['setRequestingUserPermissionToTrue'],
            target: '#execute_permissions',
          },
        ],
        GO_TO_PERMISSION_INSTRUCTIONS: {
          target: '#permission_instructions',
        },
      },
    },
    permission_instructions: {
      id: 'permission_instructions',
      on: {
        BACK: {
          actions: ['clearAttempt'],
          target: '#user_permissions',
        },
      },
    },
    no_device_found: {
      id: 'no_device_found',
      on: {
        TRY_AGAIN: '#idle',
      },
    },
  },
}
