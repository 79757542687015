import styled from 'styled-components'

export const ButtonWidth = styled.div`
  width: 49%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`
