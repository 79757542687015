/* eslint-disable arrow-body-style */
import { MachineOptions } from 'xstate'
import Bowser from 'bowser'

import { Context, MachineEvents } from '../types'
import { actions } from './actions'
import { services } from './services'

export const options: MachineOptions<Context, MachineEvents> = {
  actions,
  services,
  delays: {},
  guards: {
    maxRetries: ({ checkPermissionsAttempt = 0 }) =>
      checkPermissionsAttempt >= 3,
    isSafariAndMaxAttemptsReached: ({ checkPermissionsAttempt = 0 }) => {
      const browser = Bowser.getParser(window.navigator.userAgent)
      const isSafari = browser.getBrowserName() === 'Safari'

      return checkPermissionsAttempt >= 3 && !!isSafari
    },
    isSafari: () => {
      // same as above, cannot call window.navigator on the global level
      // will return a window is undefined error
      const browser = Bowser.getParser(window.navigator.userAgent)
      const isSafari = browser.getBrowserName() === 'Safari'

      return !!isSafari
    },
    isVideoAudioNotRequired: ({ surveyConfiguration }) => {
      return (
        !surveyConfiguration?.audioRequired &&
        !surveyConfiguration?.videoRequired
      )
    },
  },
}
