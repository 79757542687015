import { PropsWithChildren } from 'react'
import styled from 'styled-components'

type PropsWithChildrenOnly = PropsWithChildren<unknown>

const StyledSeparator = styled.div`
  display: flex;
  justify-content: center;
  width: 100% !important;
  border-bottom: 0.1rem solid #98a2b3;
`

const StyledSeparatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  box-sizing: border-box;
`

const Content = styled.div`
  padding: 0 0.4rem;
`
const SectionSeparator: React.FC<PropsWithChildrenOnly> = (props) => {
  const { children } = props
  return (
    <StyledSeparatorContainer>
      <StyledSeparator className='styled-separator'></StyledSeparator>
      <Content>{children}</Content>
      <StyledSeparator className='styled-separator'></StyledSeparator>
    </StyledSeparatorContainer>
  )
}

export default SectionSeparator
