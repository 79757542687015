/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react'
import {
  EventObject,
  createMachine,
  Sender,
  State,
  StateMachine,
  StateConfig,
} from 'xstate'
import { useInterpret, useMachine, useSelector } from '@xstate/react'
import { usePandoLogger } from '@pandolink/utils'

import { Context, UsePandoParams } from './types'

const default_context = {}

export const spawn = <TSchema, TContext, TEvents>(
  config: TContext,
  context: Partial<Context>,
  options: TEvents | any
) => {
  const machineConfig = {
    ...config,
    context: {
      ...default_context,
      ...context,
    },
  }

  return createMachine(machineConfig, options)
}

/**
 * RETURNS TWO TUPPLE
 * @remarks
 * This method is part of the pandolink utility system.
 *
 * @param - The first parameter is an object that optionally have [context] and a [slice] key
 * @example
 * {context: { message: 'hello'}, slice: { selector: 'context', value: 'user'}}
 * @remarks
 * The [context] key parameter is spread and added to that state machine context.
 * The [slice] key parameter will tell usePando what specific state, state value,
 * context and context value it should return.
 * This is important so that usePando will only return specific data that  component needs
 * and not return unnecesary data that components doesn't need
 *
 * @returns
 * [FIRST]: state, state value, context or context value
 * [SECOND]: send function to send actions/events to state machine
 *
 * @beta
 */

const usePando = <TContext, TSchema, TEvents>(
  params: UsePandoParams<TContext, TSchema | any, TEvents | any>
): [State<TContext>, Sender<TEvents | any>] => {
  const { machine, slice } = params
  const { context, config, options } = machine!
  const { selectors = [], key } = slice ?? {}

  const noId = `idless-machine-${new Date().toLocaleTimeString()}`

  let configHolder: StateConfig<TContext, TEvents | any> | any = undefined

  try {
    const existingConfigString = localStorage.getItem(config?.id ?? noId)

    if (existingConfigString) {
      configHolder = JSON.parse(existingConfigString)
    }
  } catch (e: any) {}

  const createdMachine = useMemo(
    // @ts-ignore
    () => spawn<TSchema, TContext, TEvents>(config, context, options),
    []
  )

  const recordService = useInterpret(
    // @ts-ignore
    createdMachine,
    {
      state: configHolder,
      actions: {
        ...options?.actions,
        logger: (context, event, { state }) =>
          usePandoLogger({
            name: (config?.id ?? noId).toUpperCase(),
            subTitle: event.type,
            body: { context, event, currentState: state.value },
          }),
      },
    },
    (state) => {
      if (config?.id?.includes('unstorable')) return

      return localStorage.setItem(config?.id ?? noId, JSON.stringify(state))
    }
  )

  const { send } = recordService

  const selectedState = (state: State<TContext> | any) => {
    if (!slice) return state

    const reducedState = selectors.reduce((acc, state_key: string) => {
      if (state_key === 'context' && key) {
        return {
          ...acc,
          [state_key]: {
            [key]: state[state_key][key],
          },
        }
      }

      return {
        ...acc,
        [state_key]: state[state_key],
      }
    }, {})

    return JSON.stringify(reducedState)
  }

  const compare = (prev: any, current: any) => {
    return prev === current
  }

  const state = useSelector(recordService, selectedState, compare)

  return [typeof state === 'string' ? JSON.parse(state) : state, send]
}

export default usePando
