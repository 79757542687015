import { Container } from '@pando-styles/nirvana'
import { Styledh2, StyledParagraph, StyledImg } from './styles'

const { REACT_APP_PUBLIC_URL = '/' } = process.env

const Branding = () => (
  <Container
    size='lg'
    height='lg'
    direction='column'
    centerX
    className='branding-container inner-container column--space-around '
  >
    <StyledImg
      className='pando-logo margin--bottom-2'
      src='https://content.pandolink.com/pando/img/branding/logo-color.png'
      alt='pandolink'
    />
    <Styledh2 className='text-center'>Accord Powered by Pando</Styledh2>
    <StyledParagraph className='text-center'>
      Accord is a platform for identity verification and informed consent. We
      provide ESign and automated Video Surveys on behalf of organizations in
      multiple industries.
    </StyledParagraph>
    <StyledImg
      className='margin--top-2'
      src={`${REACT_APP_PUBLIC_URL}images/pando-branding.svg`}
      alt='pandolink'
    />
  </Container>
)

export default Branding
