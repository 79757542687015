/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
import {
  ESignRequestDataServicePromiseClient,
  GetDocumentSummaryRequest,
  getRequestDetail,
  getDocument,
  setRequest,
} from '@utils/index'

type Params = Partial<GetDocumentSummaryRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getCompletedDetailedDocumentList = async (
  params: Params,
  accessToken: string
) => {
  try {
    const service = new ESignRequestDataServicePromiseClient(
      `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
    )

    const request = setRequest(new GetDocumentSummaryRequest(), params)

    const documentListResult = await service.getCompletedDocumentList(request, {
      authorization: `Bearer ${accessToken}`,
    })

    const { documentsList = [] } = documentListResult?.toObject() ?? {}

    const results = await Promise.all(
      documentsList?.map(async (document) => {
        const withSignatoryDetails = await getRequestDetail(
          {
            signatoryGuid: document?.signatoryGuid ?? '',
          },
          accessToken
        )

        const { documentsList: signatoryDocumentsList } =
          withSignatoryDetails?.toObject() ?? {}

        const withDetailedDocumentResult = await Promise.all(
          signatoryDocumentsList?.map(async (document) => {
            const withDetailedDocument = await getDocument(
              {
                documentGuid: document?.guid ?? '',
              },
              accessToken
            )

            return withDetailedDocument?.toObject()
          })
        )

        return {
          ...document,
          signatoryDetails: {
            ...(withSignatoryDetails?.toObject() ?? {}),
            documentsList: withDetailedDocumentResult ?? [],
          },
        }
      })
    )

    return {
      ...(documentListResult?.toObject() ?? {}),
      documentsList: Object.values(results ?? {}),
    }
  } catch (error) {
    console.error('Error [getCompletedDetailedDocumentList]:', error)
  }
}
