import styled from 'styled-components'
import { StyledParagraph } from '@styles/index'

export const StyledError = styled(StyledParagraph)`
  font-size: 18px !important;
  width: 60% !important;
`

export const BrowserWrapper = styled.div`
  flex-direction: row;
  margin-top: 2rem;
  width: 35%;
  justify-content: space-between;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 50%;
    overflow-y: auto;
  }
`

export const BrowserItem = styled.div`
  flex-direction: column;
`
