import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
  padding: 2rem 0 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 10;

  .borderless-button {
  }
`

export const StyledVideoResponseWrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 2rem;
  width: 100%;
`

export const StyledMediaPlayback = styled.div`
  display: flex;
  width: 100%; ;
`

export const StyledVideo = styled.video`
  width: 100%;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  height: 350px;
  background: #333;
`
