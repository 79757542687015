/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react'
import LogRocket from 'logrocket'

import Bootloader from '@system/Bootloader'
import { Modifiers } from '@styles/index'

import 'normalize.css'
import { isBrowserUsedSupported } from './utils'

import UnsupportedBrowser from './pages/UnsupportedBrowser'

const { REACT_APP_ENABLE_LOGROCKET = false } = process.env

const App: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(REACT_APP_ENABLE_LOGROCKET)) {
      LogRocket.init('pando/accordwebapplogger')
    }
  }, [])

  if (!isBrowserUsedSupported()) {
    return <UnsupportedBrowser />
  }

  return (
    <>
      <Modifiers />
      <Bootloader />
    </>
  )
}

export default App
