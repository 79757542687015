/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
import { Sender } from 'xstate'
import { usePandoLogger } from '@pandolink/utils'

import {
  getInstanceData,
  getAttemptQuestions,
  getQuestionWithAnswerTimestamps,
} from '@utils/index'

import { Context } from '../types'

export const services: any = {
  getInstanceData:
    ({ urlParamsGuid, accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        if (accessToken) {
          const result = await getInstanceData(
            {
              guid: urlParamsGuid,
            },
            accessToken
          )

          send({
            type: 'GOT_INSTANCE',
            payload: result.toObject(),
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'getInstance: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_INSTANCE_FAILED',
          data: error?.message,
        })
      }
    },

  getAttemptQuestions:
    ({ selectedInstance, accessToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result = await getAttemptQuestions(
          {
            surveyAttemptGuid: selectedInstance?.current?.guid ?? '',
          },
          accessToken!
        )

        send({
          type: 'GOT_ATTEMPT_QUESTIONS',
          payload: result.toObject()?.questionGuidsList,
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getAttemptQuestions: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_ATTEMPT_QUESTIONS_FAILED',
          payload: error?.message,
        })
      }
    },

  getQuestionWithAnswers:
    ({ selectedInstance, accessToken, questionGuidsList = [] }: Context) =>
    async (send: Sender<any>) => {
      try {
        const results = await Promise.all(
          questionGuidsList?.map(async (questionGuid) => {
            const result = await getQuestionWithAnswerTimestamps(
              {
                surveyAttemptGuid: selectedInstance?.current?.guid ?? '',
                questionGuid,
              },
              accessToken!
            )

            return result?.toObject()
          })
        )

        send({
          type: 'GOT_QUESTION_WITH_ANSWER',
          payload: Object.values(results ?? []),
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getQuestionWithAnswers: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_QUESTION_WITH_ANSWER_FAILED',
        })
      }
    },
}
