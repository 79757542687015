/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// MediaRecorderContext.tsx
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useModal } from '@pandolink/utils'

import { QuestionsState, QuestionsActions } from '@applications/index'
import { MainActions, MainState } from '@pages/index'

import { SelectionType, useBowser, useEventListener } from '@utils/index'

import { useVideoPlayer } from './useVideoPlayer'
import { useDeviceStream } from './useDeviceStream'
import { useMediaRecorder } from './useMediaRecorder'
import {
  ModalStateCam,
  ModalStateMic,
  SurveyMediaProps,
  SurveyMediaStateValue,
} from './types'

export const SurveyMediaState = createContext<Partial<SurveyMediaStateValue>>(
  {}
)

type PropsWithChildrenOnly = PropsWithChildren<SurveyMediaProps>

export const SurveyMediaProvider: React.FC<PropsWithChildrenOnly> = ({
  children,
  ...props
}) => {
  // props
  const { setUserRecordedVideo } = props

  // context
  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)

  // state
  const [isCaptureVideoActive, activateVideoCapture] = useState(true)
  const [isStreamUpdated, setIsStreamUpdated] = useState(false)
  const [videoTrack, setVideoTrack] = useState<MediaStream | null>(null)

  const [recordTimeout, setRecordTimeout] = useState<any>(null)

  const [isVideoMuted, setIsVideoMuted] = useState(true)

  // ref
  const mediaRecorderRef = useRef<any | null>(null)
  const videoPlayerRef = useRef(null)

  // other hooks
  const { isOSNotSupported, isAppleDevice, isIPadChrome, isMobile, isSafari } =
    useBowser()
  const {
    openModal: openModalPause,
    closeModal: closeModalPause,
    modalIsOpen: modalIsOpenPause,
    Modal: ModalPause,
  } = useModal()
  const {
    openModal: openModalCam,
    closeModal: closeModalCam,
    modalIsOpen: modalIsOpenCam,
    Modal: ModalCam,
  } = useModal()
  const {
    openModal: openModalMic,
    closeModal: closeModalMic,
    modalIsOpen: modalIsOpenMic,
    Modal: ModalMic,
  } = useModal()

  if (
    !providerState ||
    !providerActions ||
    !homeProviderState ||
    !homeProviderActions
  )
    return null

  const {
    question,
    accessToken,
    shouldRecordAgain,
    firstVideoPlayed = false,
  } = providerState?.context ?? {}
  const {
    handleVideoPlaybackEnded,
    handleListeningTimeLimitReached,
    handleWaitingForClickTimeLimitReached,
    handleErrorPlayingVideo,
    handleStartListening,
    handleInterruptedResponseViaWindowBlur,
    handleInterruptedMediaRecording,
    handleTryAgain,
    handleFirstVideoPlayedTrue,
  } = providerActions ?? {}
  const { sitePermissions, surveyConfiguration, isAllowedRecording } =
    homeProviderState?.context ?? {}
  const { mediaGuid, noAnswerWaitTimeout = 0 } = question ?? {}

  // memoized variables
  const hasOnlyOneAnswerOption =
    (question?.answerOptionsList ?? [])?.length === 1
  const isMultiChoiceQuestion =
    question?.selectionType === SelectionType.MULTI_ANSWER

  const isAtPlayingState = providerState.hasTag('video_playback')
  const isAtListeningState = providerState.matches('ready.listening')
  const isAtInterruptedRecordingState = providerState.matches(
    'ready.interrupted_media_recording'
  )
  const isAtWaitingForBtnClickState = providerState.matches(
    'ready.waiting_for_button_click'
  )
  const isNavBarHidden = true

  // local hooks
  const {
    modalStateMic,
    modalStateCam,
    hasCamPermission,
    hasMicPermission,
    maxAttemptsReachedCam,
    maxAttemptsReachedMic,
    isPermissionLoading,
    camPermissionAttempts,
    micPermissionAttempts,
    getCameraPermission,
    getMicPermission,
    showCameraComponent,
  } = useDeviceStream({
    sitePermissions,
    surveyConfiguration,
    isAllowedRecording,
    isIPadChrome,
    isAtInterruptedRecordingState,
    handleTryAgain,
    setIsStreamUpdated,
    setVideoTrack,
    closeModalCam,
    closeModalMic,
    openModalCam,
    openModalMic,
  })
  const { handleStopRecording, handleStartRecording } = useMediaRecorder({
    mediaRecorderRef,
    shouldRecordAgain,
    setIsStreamUpdated,
    setUserRecordedVideo,
  })
  const {
    hasVideoEnded,
    isVideoPlaying,
    showVideoLoader,
    showPlayButton,
    mediaUrl,
    vidAndAudioNotRequired,
    setIsVideoPlaying,
    setHasVideoEnded,
    shouldQuestionMediaPlay,
    setShowVideoLoader,
    setShowPlayButton,
  } = useVideoPlayer({
    sitePermissions,
    surveyConfiguration,
    accessToken,
    hasCamPermission,
    isAtPlayingState,
    videoTrack,
    mediaGuid,
    modalIsOpenPause,
    videoPlayerRef,
    isCaptureVideoActive,
    firstVideoPlayed,
    handleStartRecording,
    handleStopRecording,
    handleVideoPlaybackEnded,
    getMicPermission,
    setVideoTrack,
    openModalCam,
  })

  const resumeSurvey = useCallback(() => {
    // Don't remove this commented line yet
    // if (shouldQuestionMediaPlay() && !modalIsOpenPause) {
    if (shouldQuestionMediaPlay()) {
      if (hasVideoEnded) {
        setIsVideoPlaying(true)
      }

      if (isVideoPlaying && videoPlayerRef.current) {
        //@ts-ignore
        videoPlayerRef.current.play()

        return
      }
    }
  }, [
    shouldQuestionMediaPlay(),
    modalIsOpenPause,
    isVideoPlaying,
    hasVideoEnded,
  ])

  const handleFocus = () => {
    if (!isAtPlayingState || !hasCamPermission) return
  }

  const handleBlur = () => {
    if (isAtListeningState) {
      handleInterruptedResponseViaWindowBlur()
      handleStopRecording(
        '> Recording stopped at handleBlur [SurveyMediaProvider]'
      )
      clearTimeout(recordTimeout)
      setRecordTimeout(null)
      return
    }

    if (!providerState.hasTag('video_playback') || !hasCamPermission) return

    if (isVideoPlaying && videoPlayerRef.current) {
      //@ts-ignore
      videoPlayerRef.current.pause()
    }
  }

  useEventListener('focus', handleFocus, window)
  useEventListener('blur', handleBlur, window)

  const checkPlatform = () => {
    // iphones
    if (isAppleDevice && isMobile) return true

    // mac
    if (isAppleDevice && !isMobile && isSafari) return true

    if (isIPadChrome) return true

    return false
  }

  const showDialog = () => {
    // if in the first place the vid/cam recording is required
    // but there is no stream active, don't show the dialog
    // @ts-ignore
    if (!window.stream && surveyConfiguration?.videoRequired) return false

    if (surveyConfiguration?.audioRequired && !hasMicPermission) return false

    if (providerState.hasTag('show_dialog')) return true

    return false
  }

  const showNotAcceptableAnswerDialog = () =>
    providerState.matches('ready.unacceptable_answer')

  const showAskCameraPermissionModal = () => {
    if (!modalIsOpenCam && !surveyConfiguration?.videoRequired) return false

    if (modalStateCam === ModalStateCam.DENIED) return true

    return false
  }

  const showAskMicPermissionModal = () => {
    if (modalIsOpenMic && modalStateMic === ModalStateMic.DENIED) return true

    return false
  }

  // Effects
  useEffect(() => {
    if (videoPlayerRef && videoPlayerRef.current !== null) {
      if (shouldQuestionMediaPlay()) {
        if (firstVideoPlayed) {
          console.log('Playing video from useEffect.')
          setShowPlayButton(false)

          // @ts-ignore
          videoPlayerRef.current.play()
        }
      }
    }
  }, [firstVideoPlayed, videoPlayerRef, shouldQuestionMediaPlay()])

  // This useEffect will handle events when only the state.value is changing
  // Don't forget the 'return' in each block to avoid triggering succeeding blocks
  useEffect(() => {
    if (providerState.hasTag('video_playback')) {
      activateVideoCapture(true)
      setHasVideoEnded(false)
      return
    }

    if (
      hasOnlyOneAnswerOption &&
      isAtWaitingForBtnClickState &&
      isStreamUpdated &&
      hasMicPermission
    ) {
      activateVideoCapture(true)

      setRecordTimeout(
        setTimeout(() => {
          handleWaitingForClickTimeLimitReached()
          activateVideoCapture(false)
        }, noAnswerWaitTimeout)
      )
      return
    }

    if (isAtListeningState && isStreamUpdated && hasMicPermission) {
      activateVideoCapture(true)
      setRecordTimeout(
        setTimeout(() => {
          handleListeningTimeLimitReached()
          activateVideoCapture(false)
        }, noAnswerWaitTimeout)
      )
      return
    }

    return () => {
      clearTimeout(recordTimeout)
      setRecordTimeout(null)
    }
  }, [providerState.value, isStreamUpdated, hasMicPermission])

  useEffect(() => {
    if (isStreamUpdated && isOSNotSupported && hasMicPermission) {
      if (!vidAndAudioNotRequired || Boolean(isAllowedRecording))
        handleStartRecording(
          'line 941 : isStreamUpdated & iOSNotSupported & hasMicpermission'
        )
      handleStartListening()
      return
    }
  }, [isStreamUpdated, hasMicPermission, isOSNotSupported])

  const value: SurveyMediaStateValue['surveyMediaState'] = {
    accessToken,
    surveyPausedModal: {
      openModal: openModalPause,
      closeModal: closeModalPause,
      modalIsOpen: modalIsOpenPause,
      Modal: ModalPause,
    },
    camPermissionModal: {
      openModal: openModalCam,
      closeModal: closeModalCam,
      modalIsOpen: modalIsOpenCam,
      Modal: ModalCam,
    },
    micPermissionModal: {
      openModal: openModalMic,
      closeModal: closeModalMic,
      modalIsOpen: modalIsOpenMic,
      Modal: ModalMic,
    },
    surveyMediaProviderState: {
      providerState,
      isAtWaitingForBtnClickState,
      isAtInterruptedRecordingState,
      isAtPlayingState,
      hasOnlyOneAnswerOption,
      isNavBarHidden,
      isStreamUpdated,
      mediaGuid,
      isCaptureVideoActive,
      isMultiChoiceQuestion,
      firstVideoPlayed,
      isVideoMuted,
      resumeSurvey,
      showDialog,
      showNotAcceptableAnswerDialog,
      showAskCameraPermissionModal,
      showAskMicPermissionModal,
      handleErrorPlayingVideo,
      handleInterruptedMediaRecording,
      handleFirstVideoPlayedTrue,
      setIsStreamUpdated,
      handleBlur,
      handleFocus,
      checkPlatform,
      setIsVideoMuted,
    },
    useDeviceStreamState: {
      hasCamPermission,
      hasMicPermission,
      modalStateMic,
      modalStateCam,
      maxAttemptsReachedCam,
      maxAttemptsReachedMic,
      isPermissionLoading,
      camPermissionAttempts,
      micPermissionAttempts,
      getCameraPermission,
      getMicPermission,
      showCameraComponent,
    },
    useVideoPlayerState: {
      mediaUrl,
      videoPlayerRef,
      hasVideoEnded,
      showVideoLoader,
      showPlayButton,
      isVideoPlaying,
      setHasVideoEnded,
      setShowVideoLoader,
      setShowPlayButton,
      setIsVideoPlaying,
      shouldQuestionMediaPlay,
    },
    useMediaRecorderState: {
      handleStartRecording,
      handleStopRecording,
    },
    useBowserState: {
      isAppleDevice,
      isIPadChrome,
      isMobile,
      isSafari,
      isOSNotSupported,
    },
  }

  return (
    <SurveyMediaState.Provider
      value={{
        surveyMediaState: value,
        surveyMediaProps: props,
      }}
    >
      {children}
    </SurveyMediaState.Provider>
  )
}
