/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import {
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledHeadingTwo,
  StyledHeadingThree,
  StyledOrderedList,
  StyledBoldText,
  StyledBoldTextBlue,
  StyledImage,
} from '@styles/index'

const { REACT_APP_PUBLIC_URL = '/' } = process.env

const PermissionSteps: React.FC = () => {
  return (
    <StyledWrapperBody
      height='75vh'
      flex={false}
      textCenter={false}
      className='padding--x-2 padding--y-2 mh-75'
    >
      <StyledWrapperBodyContainer size='xl' flex={false}>
        <StyledHeadingTwo className='margin--bottom-2'>
          Allow Accord to Access your camera and microphone Instructions
        </StyledHeadingTwo>
        <div className='margin--bottom-4'>
          <StyledHeadingThree>Google Chrome Instructions</StyledHeadingThree>
          <StyledOrderedList>
            <li>
              Click on the lock icon on the left of the URL. A pop-up will apear
              with options.
            </li>
            <li>
              Click on the <StyledBoldText>Reset permissions</StyledBoldText>{' '}
              button. This will allow you to active permissions.
            </li>
            <li>
              To allow permsissions again click on the{' '}
              <StyledBoldTextBlue>Allow Permissions</StyledBoldTextBlue> on your
              survey window.
            </li>
          </StyledOrderedList>
          <div className='text-center'>
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Chrome.jpg`}
              alt='permission instruction Chrome'
            />
          </div>
        </div>
        <div className='margin--bottom-4'>
          <StyledHeadingThree>Microsoft Edge Instructions</StyledHeadingThree>
          <StyledOrderedList>
            <li>
              Click on the lock icon on the left of the URL. A pop-up will apear
              with options.
            </li>
            <li>
              Click on the{' '}
              <StyledBoldText>Camera &amp; Microphone</StyledBoldText> drop down
              box under{' '}
              <StyledBoldText>Permsisions for this site.</StyledBoldText>
            </li>
            <li>
              Click on <StyledBoldText>Allow</StyledBoldText> for both Camera
              &amp; Microphone.
            </li>
            <li>
              To allow permsissions again click on the{' '}
              <StyledBoldTextBlue>Allow Permissions</StyledBoldTextBlue> on your
              survey window.
            </li>
          </StyledOrderedList>
          <div className='text-center'>
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Edge-1.jpg`}
              alt='permission instruction Edge'
            />
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Edge-2.jpg`}
              alt='permission instruction Edge 2'
            />
          </div>
        </div>
        <div className='margin--bottom-4'>
          <StyledHeadingThree>Safari Instructions</StyledHeadingThree>
          <StyledOrderedList>
            <li>
              Click on <StyledBoldText>“Safari”</StyledBoldText> from the top
              left menu. This will bring up a drop-down.
            </li>
            <li>
              Click on <StyledBoldText>"Preferences"</StyledBoldText>.
            </li>
            <li>
              A new pop-up will appear. Click on{' '}
              <StyledBoldText>“Websites”</StyledBoldText> from the top menu bar.
            </li>
            <li>
              On the left section navigate to{' '}
              <StyledBoldText>“Camera”</StyledBoldText>.
            </li>
            <li>
              Click on the drop down to the right of the URL website and select
              <StyledBoldText>“Allow”</StyledBoldText>.
            </li>
            <li>
              On the left section navigate to{' '}
              <StyledBoldText>“Microphone”</StyledBoldText>.
            </li>
            <li>
              Click on the drop down to the right of the URL website and select
              <StyledBoldText>“Allow”</StyledBoldText>.
            </li>
            <li>
              To allow permsissions again click on the{' '}
              <StyledBoldTextBlue>Allow Permissions</StyledBoldTextBlue> on your
              survey window.
            </li>
          </StyledOrderedList>
          <div className='text-center'>
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Safari-1.jpg`}
              alt='permission instruction Safari'
            />
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Safari-2.jpg`}
              alt='permission instruction Safari 2'
            />
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Safari-3.jpg`}
              alt='permission instruction Safari 3'
            />
          </div>
        </div>
        <div>
          <StyledHeadingThree>Firefox Instructions</StyledHeadingThree>
          <StyledOrderedList>
            <li>
              Click on the lock icon on the left of the URL. A pop-up will
              appear with options.
            </li>
            <li>
              Click on the <StyledBoldText>“Connection secure”</StyledBoldText>.
            </li>
            <li>
              Click on the <StyledBoldText>“More information”</StyledBoldText>.
            </li>
            <li>
              A new pop-up will appear. Click on the{' '}
              <StyledBoldText>“Permissions”</StyledBoldText> tab on the top.
            </li>
            <li>
              Scroll down to the bottom and uncheck{' '}
              <StyledBoldText>“Use the camera”</StyledBoldText> &amp;{' '}
              <StyledBoldText>“Use the microphone”</StyledBoldText>.
            </li>
            <li>
              Select <StyledBoldText>“Allow”</StyledBoldText> for{' '}
              <StyledBoldText>“Use the camera”</StyledBoldText> &amp;{' '}
              <StyledBoldText>“Use the microphone”</StyledBoldText>.
            </li>
            <li>
              To allow permsissions again click on the{' '}
              <StyledBoldTextBlue>Allow Permissions</StyledBoldTextBlue> on your
              survey window.
            </li>
          </StyledOrderedList>
          <div className='text-center'>
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Firefox-1.jpg`}
              alt='permission instruction Firefox'
            />
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Firefox-2.jpg`}
              alt='permission instruction Firefox 2'
            />
            <StyledImage
              src={`${REACT_APP_PUBLIC_URL}images/Permissions-Firefox-3.jpg`}
              alt='permission instruction Firefox 3'
            />
          </div>
        </div>
      </StyledWrapperBodyContainer>
    </StyledWrapperBody>
  )
}

export default PermissionSteps
