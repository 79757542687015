import React from 'react'

import QuestionsProvider from './QuestionsProvider'
import QuestionsConsumer from './QuestionsConsumer'
import { QuestionsProps } from './types'

export * from './QuestionsProvider'

const Questions: React.FC<QuestionsProps> = (props) => (
  <QuestionsProvider {...props}>
    <QuestionsConsumer />
  </QuestionsProvider>
)

export default Questions
