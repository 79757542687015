/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Container } from '@pando-styles/nirvana'
import { InstanceShort } from '@utils/index'

import { StyledHeadingThree, StyledParagraph } from '@styles/index'
import { VideoResponse } from '@applications/index'
import { Tabs } from '@styles/index'
import { Grid } from '@atomic/index'
import {
  getSurveyInstancesToDo,
  getCompletedSurveyInstances,
  getIncompleteSurveyInstances,
  getRecentlyCompletedSurveyInstances,
} from '@utils/index'

import { tabItems } from './tab-items'
import { TodoSurveys, RecentlyCompletedSurveys } from './components'

const MainTabHome: React.FC = () => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const navigate = useHistory()

  const component = [
    {
      key: 'todos',
      name: (
        <Grid
          appConfig={{
            storage_key: 'todo-surveys',
            query_entity: 'instances',
            no_data_text: 'No pending surveys at this time.',
            fetching_data_text: 'Fetching surveys to do.',
            enablePagination: true,
            showDatePicker: false,
            showCurrentPage: false,
            searchFields: ['surveyName', 'surveyDescription'],
            initialState: {
              pageSize: 5,
              sortBy: 'dateCreated.seconds',
              sortOrder: 'descending',
            },
          }}
          queryParams={{
            resultsPerPage: 5,
            page: 0,
          }}
          actions={{
            onFetch: getIncompleteSurveyInstances,
          }}
          customComponent={(item: InstanceShort.AsObject | any) => (
            <TodoSurveys {...item} />
          )}
        />
      ),
    },
    {
      key: 'recent',
      name: (
        <Grid
          appConfig={{
            storage_key: 'recently-completed-surveys',
            query_entity: 'instances',
            no_data_text: 'No recent activity at this time.',
            fetching_data_text: 'Fetching recent activity.',
            enablePagination: true,
            showDatePicker: false,
            showCurrentPage: false,
            searchFields: ['surveyName', 'surveyDescription'],
            initialState: {
              pageSize: 5,
              sortBy: 'dateCompleted.seconds',
              sortOrder: 'descending',
            },
          }}
          queryParams={{
            resultsPerPage: 5,
            page: 0,
          }}
          actions={{
            onFetch: getCompletedSurveyInstances,
          }}
          customComponent={(item) => <RecentlyCompletedSurveys {...item} />}
        />
      ),
    },
  ]

  useEffect(() => {
    location?.pathname.includes('home') &&
      !location?.pathname.includes('recent') &&
      navigate.push('/home/todos')
  }, [])

  useEffect(() => {
    location?.pathname.includes('home') &&
      !location?.pathname.includes('recent') &&
      navigate.push('/home/todos')
  }, [location.pathname])

  return (
    <Container
      direction='column'
      height='full'
      centerX
      className='outer-container overflow-y px-1-at-600 padding--x-2 px-1-at-762'
    >
      <Switch>
        <Route exact path={`${path}/recent/:surveyAttemptGuid`}>
          <VideoResponse />
        </Route>
        <Route path={path}>
          <Container
            height='full'
            direction='column'
            centerX
            className='survey-tab-list--inner-container inner-container'
          >
            <Tabs tabItems={tabItems} component={component} />
          </Container>
        </Route>
      </Switch>
    </Container>
  )
}

export default MainTabHome
