/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { Icon } from '@pando-styles/nirvana'

import { MainActions } from '@pages/index'
import { QuestionsState } from '@applications/index'

import { StyledCamera, StyledContainer } from './styles'
import { CameraProps } from './types'
import MediaLiveDetect from './MediaLiveDetect'

const UserCamera: React.FC<CameraProps> = ({ isCaptureVideoActive }) => {
  const { providerState } = useContext(QuestionsState)

  if (!providerState) return null

  const { providerActions } = useContext(MainActions)
  if (!providerActions) return null

  const showCameraCheckingIcon =
    providerState.matches('ready.video_playback_with_voice_recognition') ||
    providerState.matches('ready.video_playback') ||
    providerState.matches('ready.listening')

  return (
    <>
      <StyledCamera className='styled-camera'>
        <MediaLiveDetect isCaptureVideoActive={isCaptureVideoActive} />
      </StyledCamera>
    </>
  )
}

export default UserCamera
