import 'regenerator-runtime/runtime'
import {
  SurveyInstanceDataServicePromiseClient,
  GetQuestionWithAnswerTimestampsRequest,
  setRequest,
} from '@utils/index'

type Params = Partial<GetQuestionWithAnswerTimestampsRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getQuestionWithAnswerTimestamps = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )
  const request = setRequest(
    new GetQuestionWithAnswerTimestampsRequest(),
    params
  )

  const result = await service.getQuestionWithAnswerTimestamps(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
