/* eslint-disable indent */
import React from 'react'
import { Icon } from '@pando-styles/nirvana'

import {
  StyledHeadingTwo,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { StyledError, BrowserWrapper, BrowserItem } from './styles'

const UnsupportedBrowser: React.FC = () => {
  const { REACT_APP_PUBLIC_URL } = process.env
  return (
    <StyledWrapper className='flex-center flex-column'>
      <StyledWrapperBody>
        <StyledWrapperBodyContainer>
          <StyledHeadingTwo>Unsupported Browser</StyledHeadingTwo>
          <Icon
            iconName='alert-circle'
            color='danger'
            className={`scale-1-5 m-auto`}
            size='xl'
          />
          <StyledError className='text-black '>
            Your current web browser is not supported yet. Please take the
            survey from one of the following supported browsers:
          </StyledError>
          <BrowserWrapper>
            <BrowserItem>
              <img
                src={`${REACT_APP_PUBLIC_URL}images/chrome.png`}
                width={75}
                height={75}
              />
              <p>Google Chrome</p>
            </BrowserItem>
            <BrowserItem>
              <img
                src={`${REACT_APP_PUBLIC_URL}images/edge.png`}
                width={75}
                height={75}
              />
              <p>Microsoft Edge</p>
            </BrowserItem>
            <BrowserItem>
              <img
                src={`${REACT_APP_PUBLIC_URL}images/safari.png`}
                width={75}
                height={75}
              />
              <p>Safari</p>
            </BrowserItem>
          </BrowserWrapper>
        </StyledWrapperBodyContainer>
      </StyledWrapperBody>
    </StyledWrapper>
  )
}

export default UnsupportedBrowser
