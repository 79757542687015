import Bowser from 'bowser'

const { REACT_APP_UNSUPPORTED_OS: unsupportedOS } = process.env

export const useBowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isSafari = browser.getBrowserName() === 'Safari'
  const isMobile = browser.getPlatformType() === 'mobile'
  const isAppleDevice = browser.getPlatform()?.vendor === 'Apple'
  const isIPadChrome =
    browser.getPlatform()?.model === 'iPad' &&
    browser.getBrowserName() === 'Chrome'
  const isOSNotSupported =
    Array.isArray(unsupportedOS) &&
    unsupportedOS?.find((os: any) => os === browser.getOS().name)

  return {
    browser,
    isSafari,
    isMobile,
    isAppleDevice,
    isIPadChrome,
    isOSNotSupported,
  }
}
