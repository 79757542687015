import { useEffect, useState } from 'react'
import Bowser from 'bowser'

export const useFontSize = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isMobile = browser.getPlatformType() === 'mobile'

  const [_fontSize, setFontSize] = useState(0)

  const handleFontSizeIncrease = () => {
    if (_fontSize < (isMobile ? 18 : 20)) {
      return setFontSize((prevState) => prevState + 2)
    }
    return
  }

  const handleFontSizeDecrease = () => {
    if (_fontSize > (isMobile ? 14 : 16)) {
      return setFontSize((prevState) => prevState - 2)
    }
    return
  }

  const getFontButtonColors = () => {
    const green = '#2C969B'
    const grey = '#A5A5A5'

    const MIN_SIZE = isMobile ? 14 : 16
    const MAX_SIZE = isMobile ? 18 : 20

    return {
      minus: _fontSize > MIN_SIZE ? green : grey,
      plus: _fontSize < MAX_SIZE ? green : grey,
    }
  }

  useEffect(() => {
    //set default font size on localStorage
    const fontSizeFromStorage = localStorage.getItem('fontSize')
    if (!fontSizeFromStorage) {
      const defaultSize = isMobile ? '14' : '16'
      setFontSize(parseInt(defaultSize))
      return localStorage.setItem('fontSize', defaultSize)
    }
    return setFontSize(parseInt(fontSizeFromStorage))
  }, [])

  useEffect(() => {
    //saving fontSize to localStorage every click of the font buttons
    localStorage.setItem('fontSize', _fontSize.toString())
  }, [_fontSize])

  return {
    _fontSize,
    handleFontSizeIncrease,
    handleFontSizeDecrease,
    getFontButtonColors,
  }
}
