import 'regenerator-runtime/runtime'
import {
  ESignSignatoryExecutionServicePromiseClient,
  SignDocumentRequest,
  setRequest,
} from '@utils/index'

type Params = SignDocumentRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const execAcknowledgeDocument = async (
  params: Params,
  accessToken: string
) => {
  const service = new ESignSignatoryExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new SignDocumentRequest(), params)

  const result = await service.execAcknowledgeDocument(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
