/* eslint-disable indent */
import styled from 'styled-components'

export const StyledUserMicContainer = styled.div<{ isListening: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 0.4rem;
  padding: 2rem 1rem;

  .mic-icon {
    transform: scale(1.4);
    background: #00c82c;

    svg {
      > path:first-child {
        fill: #ffffff;
      }
    }

    &.stops-listening {
      background: #efefef;

      svg {
        stroke: #98a2b3;

        > path:first-child {
          fill: #98a2b3;
        }
      }
    }

    &.no-voice-detected {
      background: #ef4444;

      svg {
        stroke: #ffffff;
      }
    }

    border-radius: 50%;
    border: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${({ isListening = false }) =>
      isListening ? 'svg-accent 1.2s ease-in-out infinite alternate' : 'none'};

    @keyframes svg-accent {
      0% {
        border: 0 transparent;
      }
      25% {
        border: 2px solid #b2eec0;
      }
      50% {
        border: 6px solid #b2eec0;
      }
      75% {
        border: 2px solid #b2eec0;
      }
      100% {
        border: 0 transparent;
      }
    }
  }

  .invalid-response-icon {
    transform: scale(1.5);
    margin: 1.8rem 0 -1rem;
  }

  .divider {
    border-top: 1px dotted #ffffff;
    margin: 30px 0 15px;
    width: 100px;
    display: block;
  }

  .mic-paragraph {
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  @media screen and (max-width: 450px) {
    & {
      padding: 2rem 1rem;
    }

    .mic-paragraph {
      font-size: 12px !important;
    }
  }
`

export const StyledError = styled.span`
  color: ${({ theme }) => theme.colors.danger['DEFAULT']};
  font-size: inherit;
`

export const StyledInvalidResponseContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    position: relative;
    bottom: unset;
    margin: 1rem 0;
  }
`

export const StyledGridSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  width: auto;
  max-height: 145px;
  overflow-y: auto;
  padding-bottom: 1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  button {
    width: 48% !important;
    border: none !important;
    height: 27px !important;
    font-size: 10px !important;

    &.selected {
      background: #eaf5f5;
    }

    &.not-selected {
      background: #f8f8f8;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7b7b7b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #676767;
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    max-height: 180px;
    margin-right: -1rem;
    margin-left: -1rem;

    /* ::-webkit-scrollbar-track {
      background: inherit;
    }

    ::-webkit-scrollbar-thumb {
      background: inherit;
    } */

    button {
      width: 100% !important;
      height: 2.75rem !important;
      font-size: 80% !important;
    }
  }

  @media screen and (max-height: 700px) {
    max-height: 130px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`
