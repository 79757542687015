import styled from 'styled-components'

import { StyledParagraph } from '@styles/index'

export const StyledButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const StyledSurveyListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 0 3rem;

  @media screen and (max-width: 762px) {
    padding: 0;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`

export const StyledSurveyListSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;

  @media screen and (max-width: 762px) {
    &.left-container {
      height: 30%;
    }

    &.right-container {
      height: 70%;
    }
  }
`

export const StyledSurveyContainer = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  cursor: ${({ clickable = false }) => (clickable ? 'pointer' : 'default')};

  ${({ clickable = false }) =>
    clickable &&
    `&:hover {
      background: #eaf5f5;
      }`}

  .left {
    width: 80%;
  }

  .left-2 {
    width: 100%;
  }

  .completed-survey-header {
    display: flex;
    justify-content: space-between;

    small {
      color: #284f52;
    }

    small.completion-type {
      opacity: 0.2;
      margin-right: -20px;
    }
  }

  .right {
    width: 20%;
    height: 50px;

    > div {
      transform: scale(0.4) translate(-75%, -75%);

      > svg circle:nth-child(1) {
        stroke: #c4c4c4;
      }
    }
  }

  .btn-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
`

export const StyledLoadingContainer = styled.div``

export const StyledSurveyLoadingContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
`

export const StyledLoadingContainer2 = styled.div`
  margin-top: 30px;

  div.box {
    height: auto;
  }

  svg {
    display: none;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;

  small {
    margin: 0;
    padding: 0;
  }

  .custom-blue-btn {
    background: #1570ef !important;
    border-color: #1570ef !important;

    &:hover {
      background: #1161d2 !important;
    }
  }
`

export const StyledErrorText = styled.p`
  color: #ef4444 !important;
  font-size: 14px !important;
  margin: 0.4rem 0;
  line-height: 150%;
`

export const RestartSurveyPopContainer = styled.div`
  position: fixed;
  background: #00000099;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const RestartSurveyBox = styled.div`
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  display: block;
  text-align: center;
  padding: 15px;
  max-width: 400px;
  width: 85%;
`

export const RestartSurveyTitle = styled.span`
  color: #2c969b !important;
  font-weight: 500;
`

export const RestartSurveyPopupButtonContainer = styled(StyledButtonContainer)`
  width: 100%;
  flex-direction: row;
`
export const StyledErrorParagraph = styled(StyledParagraph)`
  width: 60% !important;

  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
`
