import React from 'react'
import { RaySpinner } from '@pando-styles/nirvana'

import { StyledParagraph, StyledFlexContainer } from '@styles/index'

const Loader: React.FC = () => (
  <StyledFlexContainer className='flex-column vh-100'>
    <RaySpinner color='primary' colorWeight='DEFAULT' />
    <StyledParagraph>One moment...</StyledParagraph>
  </StyledFlexContainer>
)

export default Loader
