/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MachineConfig, AnyStateNodeDefinition } from 'xstate'

import { Context, MachineEvents } from './types'

export const config: MachineConfig<
  Context,
  AnyStateNodeDefinition,
  MachineEvents
> = {
  id: 'esign-execution',
  initial: 'loading',
  on: {
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#loading',
    },
    // @ts-ignore
    GOT_NEW_SIGNATORY_GUID: {
      actions: ['updateSignatoryGuid'],
      target: '#loading',
    },
    SESSION_EXPIRED: {
      actions: [],
      target: '#session_expired',
    },
  },
  states: {
    loading: {
      id: 'loading',
      invoke: {
        id: 'get-signatory-details',
        src: 'getSignatoryDetails',
      },
      on: {
        GOT_SIGNATORY_DETAILS: [
          {
            cond: 'hasHandwrittenSignature',
            actions: ['assignSignatoryDetails', 'assignHandwrittenSignature'],
            target: '#ready.signature.existing_signature_loaded',
          },
          {
            cond: 'hasGeneratedSignature',
            actions: ['assignSignatoryDetails', 'assignGeneratedSignature'],
            target: '#ready.signature.existing_signature_loaded',
          },
          {
            actions: ['assignSignatoryDetails'],
            target: '#ready',
          },
        ],
        GET_SIGNATORY_DETAILS_FAILED: {
          target: '#get_signatory_details_error',
        },
      },
    },
    ready: {
      id: 'ready',
      type: 'parallel',
      states: {
        signature: {
          id: 'signature',
          initial: 'type_signature',
          states: {
            type_signature: {
              id: 'type_signature',
              on: {
                CLEAR_SIGNATURE: {
                  actions: ['removeSignature'],
                },
                SAVE_SIGNATURE: {
                  actions: ['assignGeneratedSignature'],
                  target: ['#document.adopt_signature'],
                },
                SWITCH_TO_DRAW_SIGNATURE: [
                  '#draw_signature',
                  '#waiting_for_signature',
                ],
              },
            },
            draw_signature: {
              id: 'draw_signature',
              on: {
                CLEAR_SIGNATURE: {
                  actions: ['removeSignature'],
                },
                SAVE_SIGNATURE: {
                  actions: ['assignHandwrittenSignature'],
                  target: ['#document.adopt_signature'],
                },
                SWITCH_TO_TYPE_SIGNATURE: [
                  '#type_signature',
                  '#waiting_for_signature',
                ],
              },
            },
            existing_signature_loaded: {
              id: 'existing_signature_loaded',
              on: {
                // @ts-ignore
                PROCEED: {
                  target: '#document.get_document',
                },
              },
            },
          },
        },
        document: {
          id: 'document',
          initial: 'waiting_for_signature',
          on: {
            RESET: {
              target: '#waiting_for_signature',
            },
          },
          states: {
            waiting_for_signature: {
              id: 'waiting_for_signature',
            },
            adopt_signature: {
              id: 'adopt_signature',
              tags: ['saving_signature'],
              invoke: {
                id: 'adopt-signature',
                src: 'adoptSignature',
              },
              on: {
                SIGNATURE_SAVED: {
                  target: ['#get_document', '#existing_signature_loaded'],
                },
                SIGNATURE_ERROR: {
                  target: '#error',
                },
              },
            },
            get_document: {
              id: 'get_document',
              tags: ['fetching', 'open_modal'],
              invoke: {
                id: 'get-document',
                src: 'getDocument',
              },
              on: {
                GOT_DOCUMENT: [
                  {
                    cond: 'noActionTaken',
                    actions: ['assignDocument'],
                    target: '#document_loaded',
                  },
                  {
                    actions: ['incrementCurrentDocumentIndex'],
                    target: '#get_document',
                  },
                ],
                GET_DOCUMENT_FAILED: {
                  target: '#error',
                },
                NO_DOCUMENTS: {
                  target: '#no_documents_found',
                },
                NO_MORE_UNTOUCHED_DOCUMENTS: {
                  target: '#esign_done',
                },
              },
            },
            document_loaded: {
              id: 'document_loaded',
              tags: ['open_modal'],
              on: {
                DECLINE_DOCUMENT: {
                  target: '#update_document',
                },
                SIGN_DOCUMENT: {
                  target: '#update_document',
                },
                ACKNOWLEDGE_DOCUMENT: {
                  target: '#update_document',
                },
                OPT_IN_DOCUMENT: {
                  target: '#update_document',
                },
                OPT_OUT_DOCUMENT: {
                  target: '#update_document',
                },
                BACK: [
                  {
                    cond: 'isSignatureHandwritten',
                    target: '#signature.draw_signature',
                  },
                  {
                    target: '#signature.type_signature',
                  },
                ],
              },
            },
            update_document: {
              id: 'update_document',
              tags: ['open_modal'],
              invoke: {
                id: 'update-document',
                src: 'updateDocument',
              },
              on: {
                UPDATE_SUCCESS: [
                  {
                    cond: 'isLastDocument',
                    target: '#esign_done',
                  },
                  {
                    actions: ['incrementCurrentDocumentIndex'],
                    target: '#get_document',
                  },
                ],
                UPDATE_FAILED: {
                  target: '#error',
                },
              },
            },
          },
        },
        hist: {
          type: 'history',
          history: 'deep',
        },
      },
    },
    esign_done: {
      id: 'esign_done',
      entry: ['clearContext'],
    },
    no_documents_found: {
      id: 'no_documents_found',
      entry: ['clearContext'],
    },
    get_signatory_details_error: {
      id: 'get_signatory_details_error',
    },
    error: {
      id: 'error',
      on: {
        RELOAD: '#loading',
        BACK: '#loading',
      },
    },
    session_expired: {
      id: 'session_expired',
    },
  },
}
