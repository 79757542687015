import React from 'react'

import ESignProvider from './ESignProvider'
import ESignConsumer from './ESignConsumer'
import { ESignProps } from './types'

export * from './ESignProvider'

const ESign: React.FC<ESignProps> = (props) => (
  <ESignProvider {...props}>
    <ESignConsumer />
  </ESignProvider>
)

export default ESign
