import styled from 'styled-components'

import { StyledWrapperBody } from '@styles/index'

export const CustomStyledWrapperBody = styled(StyledWrapperBody)`
  display: block;
  margin-top: 2rem;

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    display: flex;
  }
`

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 20px 0;
`
