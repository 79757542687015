/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'useDetectUser',
  initial: 'waiting',
  on: {
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#waiting',
    },
    EXEC_FACIAL_RECOGNITION: {
      target: 'facial_recognition_on',
    },
    SESSION_EXPIRED: {
      target: '#session_expired',
    },
    RESET_FACIAL_RECOGNITION: {
      actions: ['setHasFaceInImageToFalse'],
      target: '#waiting',
    },
  },
  states: {
    waiting: {
      id: 'waiting',
      on: {
        EXEC_FACIAL_RECOGNITION: {
          target: 'facial_recognition_on',
        },
      },
    },
    session_expired: {
      id: 'session_expired',
    },
    facial_recognition_on: {
      invoke: {
        id: 'execFacialRecognition',
        src: 'execFacialRecognition',
      },
      on: {
        GOT_EXEC_FACIAL_RECOGNITION_RESULTS: {
          actions: ['assignFacialRecognitionResults'],
        },
      },
    },
  },
}
