import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'survey-main',
  initial: 'loading',
  on: {
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#ready.initialize_state.hist',
    },
    ASSIGN_SITE_PERMISSIONS: {
      actions: ['assignSitePermissions'],
    },
    GOT_NEW_URL_PARAMS: [
      {
        cond: 'isStoredInstanceSameWithNewInstanceGuid',
        actions: ['updateUrlParams'],
      },
      {
        actions: ['updateUrlParams'],
        target: '#check_if_guest_or_user_account',
      },
    ],
  },
  states: {
    loading: {
      id: 'loading',
      tags: ['loader'],
      always: {
        target: '#ready',
      },
    },
    ready: {
      id: 'ready',
      type: 'parallel',
      states: {
        initialize_state: {
          id: 'initialize_state',
          initial: 'home',
          states: {
            hist: {
              type: 'history',
              history: 'deep',
            },
            home: {
              id: 'home',
              initial: 'check_if_guest_or_user_account',
              tags: ['exclude_from_permissions_change'],
              states: {
                check_if_guest_or_user_account: {
                  id: 'check_if_guest_or_user_account',
                  tags: ['loader'],
                  always: [
                    {
                      cond: 'isGuestLogin',
                      target: '#check_if_instance_is_already_claimed',
                    },
                    {
                      target: '#survey_instances_list',
                    },
                  ],
                },
                check_if_instance_is_already_claimed: {
                  id: 'check_if_instance_is_already_claimed',
                  tags: ['loader'],
                  on: {
                    BOOTLOADER_CLAIM_INSTANCE_SUCCESS: {
                      target: '#get_survey_instance_details',
                    },
                  },
                },
                survey_instances_list: {
                  id: 'survey_instances_list',
                  entry: ['assignClaimInstanceAndSignatoryToFalse'],
                  on: {
                    REFETCH_SURVEYS: {
                      target: '#loading',
                    },
                    SELECT_SURVEY: {
                      actions: ['assignInstance'],
                      target: '#get_survey_instance_details',
                    },

                    RESUME_SURVEY: {
                      actions: ['assignInstance'],
                      target: '#refetch_instance',
                    },
                    NEW_ATTEMPT: {
                      actions: ['assignInstance'],
                      target: '#create_attempt',
                    },
                    BOOTLOADER_CLAIM_INSTANCE_SUCCESS: {
                      target: '#get_survey_instance_details',
                    },
                  },
                },
                get_survey_instance_details: {
                  id: 'get_survey_instance_details',
                  tags: [
                    'survey_selected',
                    'getting_survey_details',
                    'start_survey_btn_disabled',
                  ],
                  invoke: {
                    id: 'get-instance-details',
                    src: 'getInstanceDetails',
                  },
                  on: {
                    GOT_INSTANCE_DETAILS: {
                      actions: ['assignInstance'],
                      target: '#get_survey_config',
                    },
                    GET_INSTANCE_DETAILS_ERROR: {
                      target: '#error',
                      actions: 'assignErrorMessage',
                    },
                    SURVEY_ALREADY_COMPLETED: {
                      target: '#survey_already_completed',
                    },
                  },
                },
                get_survey_config: {
                  id: 'get_survey_config',
                  invoke: {
                    id: 'get-survey-config',
                    src: 'getSurveyConfiguration',
                  },
                  tags: [
                    'survey_selected',
                    'getting_survey_details',
                    'start_survey_btn_disabled',
                  ],
                  on: {
                    GOT_SURVEY_CONFIGURATION: {
                      actions: 'assignSurveyConfiguration',
                      target: '#survey_initiated',
                    },
                    GET_SURVEY_CONFIGURATION_ERROR: {
                      target: '#error',
                      actions: 'assignErrorMessage',
                    },
                  },
                },
                survey_initiated: {
                  id: 'survey_initiated',
                  tags: ['survey_selected', 'survey_initiated'],
                  on: {
                    SELECT_SURVEY: {
                      cond: 'isVideoAudioNotRequired',
                      actions: ['assignInstance'],
                      target: '#check_attempt',
                    },
                    START_SURVEY: '#check_attempt',
                    GO_BACK_TO_SURVEY_LIST: {
                      actions: ['removeSelectedInstance'],
                      target: '#survey_instances_list',
                    },
                  },
                },
                check_attempt: {
                  id: 'check_attempt',
                  invoke: {
                    id: 'check-attempt',
                    src: 'checkAttempt',
                  },
                  on: {
                    HAS_ATTEMPT: [
                      {
                        cond: 'isAttemptCompleted',
                        target: '#create_attempt',
                      },
                      {
                        target: '#should_continue_attempt',
                      },
                    ],
                    NEW_ATTEMPT: {
                      target: '#create_attempt',
                    },
                  },
                },
                should_continue_attempt: {
                  id: 'should_continue_attempt',
                  on: {
                    CONTINUE_ATTEMPT: [
                      {
                        cond: 'isAllowedRecording',
                        actions: ['setAllowedRecordingToTrue'],
                        target: '#test_camera',
                      },
                      {
                        cond: 'isCameraNotAllowed',
                        actions: ['setAllowedRecordingToFalse'],
                        target: '#start_survey_questions',
                      },
                      {
                        cond: 'isVideoAudioNotRequired',
                        actions: ['setAllowedRecordingToFalse'],
                        target: '#start_survey_questions',
                      },
                      {
                        target: '#test_camera',
                      },
                    ],
                    NEW_ATTEMPT: {
                      target: '#create_attempt',
                    },
                    ERROR: {
                      target: '#error',
                      actions: 'assignErrorMessage',
                    },
                    // not the list but the previous state 'survey_initiated'
                    GO_BACK_TO_SURVEY_LIST: {
                      target: '#survey_initiated',
                    },
                  },
                },
                create_attempt: {
                  id: 'create_attempt',
                  invoke: {
                    id: 'create-attempt',
                    src: 'createAttempt',
                  },
                  tags: ['loader', 'create_attempt'],
                  on: {
                    ATTEMPT_CREATED: {
                      target: '#refetch_instance',
                    },
                    CONTINUE_ATTEMPT: {
                      target: '#attempt_not_started',
                    },
                    CREATE_ATTEMPT_ERROR: {
                      target: '#error',
                      actions: 'assignErrorMessage',
                    },
                    GO_BACK_TO_SHOULD_CONTINUE_ATTEMPT: {
                      target: '#should_continue_attempt',
                    },
                  },
                },
                attempt_not_started: {
                  id: 'attempt_not_started',
                  tags: ['attemp_not_started'],
                  on: {
                    GO_BACK_TO_SHOULD_CONTINUE_ATTEMPT: {
                      target: '#should_continue_attempt',
                    },
                    CONTINUE_ATTEMPT: {
                      target: '#refetch_instance',
                    },
                  },
                },
                // this is for new attempts
                refetch_instance: {
                  id: 'refetch_instance',
                  tags: ['attemp_not_started', 'refetch_instance'],
                  invoke: {
                    id: 'get-instance-details',
                    src: 'getInstanceDetails',
                  },
                  on: {
                    GOT_INSTANCE_DETAILS: [
                      {
                        cond: 'isAllowedRecording',
                        actions: [
                          'assignInstance',
                          'setAllowedRecordingToTrue',
                        ],
                        target: '#test_camera',
                      },
                      {
                        cond: 'isVideoAudioNotRequired',
                        actions: [
                          'assignInstance',
                          'setAllowedRecordingToFalse',
                        ],
                        target: '#start_survey_questions',
                      },
                      {
                        cond: 'isIOSDebuggingEnabled',
                        actions: ['assignInstance'],
                        target: '#test_camera',
                      },
                      {
                        cond: 'isCameraNotAllowed',
                        actions: [
                          'assignInstance',
                          'setAllowedRecordingToFalse',
                        ],
                        target: '#start_survey_questions',
                      },
                      {
                        actions: ['assignInstance'],
                        target: '#test_camera',
                      },
                    ],
                    GET_INSTANCE_DETAILS_ERROR: {
                      target: '#error',
                      actions: 'assignErrorMessage',
                    },
                  },
                },
              },
            },
            test_camera: {
              id: 'test_camera',
              on: {
                START_SURVEY_QUESTIONS: {
                  target: '#start_survey_questions',
                },
                EXIT: '#survey_initiated',
              },
            },
            start_survey_questions: {
              id: 'start_survey_questions',
              tags: ['survey_started'],
              on: {
                SHOULD_CREATE_NEW_ATTEMPT: '#survey_unacceptable',
                EXIT: '#get_survey_instance_details',
                GO_BACK_TO_SURVEY_LIST: {
                  actions: ['removeSelectedInstance'],
                  target: '#survey_instances_list',
                },
                START_ESIGN_EXECUTION: [
                  {
                    target: '#start_esign_execution',
                  },
                ],
                RETEST_VIDEO_AND_AUDIO: '#test_camera',
              },
              initial: 'idle',
              states: {
                idle: {},
                video_required_by_survey: {},
                audio_required_by_survey: {},
              },
            },
            start_esign_execution: {
              id: 'start_esign_execution',
              on: {
                GO_BACK_TO_SURVEY_LIST: {
                  target: '#survey_instances_list',
                },
              },
            },
            survey_unacceptable: {
              id: 'survey_unacceptable',
              on: {
                NEW_ATTEMPT: '#create_attempt',
                GO_BACK_TO_SURVEY_LIST: {
                  actions: ['removeSelectedInstance'],
                  target: '#survey_instances_list',
                },
              },
            },
          },
        },
      },
    },
    error: {
      id: 'error',
      on: {
        RELOAD: {
          target: '#check_if_guest_or_user_account',
        },
      },
    },
    invalid_instance_guid: {
      id: 'invalid_instance_guid',
      entry: ['logger'],
      on: {
        GO_BACK_TO_SURVEY_LIST: {
          actions: ['removeSelectedInstance'],
          target: '#survey_instances_list',
        },
      },
    },
    invalid_claim_code: {
      id: 'invalid_claim_code',
      entry: ['logger'],
      on: {
        GO_BACK_TO_SURVEY_LIST: {
          actions: ['removeSelectedInstance'],
          target: '#survey_instances_list',
        },
      },
    },
    instance_already_claimed: {
      id: 'instance_already_claimed',
      entry: ['logger'],
      on: {
        GO_BACK_TO_SURVEY_LIST: {
          actions: ['removeSelectedInstance'],
          target: '#survey_instances_list',
        },
      },
    },
    guest_not_allowed: {
      id: 'guest_not_allowed',
      entry: ['logger'],
      on: {
        GO_BACK_TO_SURVEY_LIST: {
          actions: ['removeSelectedInstance'],
          target: '#survey_instances_list',
        },
      },
    },
    session_expired: {
      id: 'session_expired',
    },
    survey_already_completed: {
      id: 'survey_already_completed',
      on: {
        GO_BACK_TO_SURVEY_LIST: {
          actions: ['removeSelectedInstance'],
          target: '#survey_instances_list',
        },
      },
    },
    survey_completed: {
      id: 'survey_completed',
      on: {
        NEW_ATTEMPT: {
          target: '#ready.initialize_state.home.create_attempt',
        },
      },
    },
    done: {
      id: 'done',
    },
  },
}
