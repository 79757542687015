/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'

import axios from 'axios'

const { REACT_APP_AUTH_SERVER = 'https://login.staging.pandolink.com' } =
  process.env

export const execUpgradeGuest = async (
  guestToken: string,
  newAccessToken: string
) => {
  const extractToken = (token: string) => {
    const base64Url = token?.split('.')[1]
    const base64 = base64Url?.replace('-', '+')?.replace('_', '/')
    return JSON.parse(atob(base64))
  }

  const extractedToken = extractToken(newAccessToken)

  const result = await axios.request({
    method: 'POST',
    url: `${REACT_APP_AUTH_SERVER}/api/public/upgradeguestaccount/${extractedToken?.sub}`,
    headers: {
      Authorization: `Bearer ${guestToken}`,
    },
  })

  return result
}
