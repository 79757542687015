/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'

import { BootloaderState } from '@system/Bootloader'
import { Container, Icon } from '@pando-styles/nirvana'
import {
  StyledHeadingThree,
  StyledParagraph,
  StyledExitSpan,
  StyledExitContainer,
} from '@styles/index'
import { parseQuestionText } from '@utils/index'

import { StyledVideo } from '../styles'
import {
  VideoResponseState,
  VideoResponseActions,
} from '../../VideoResponseProvider'

const {
  REACT_APP_QUERY_HOST: accord,
  REACT_APP_ANSWER_MEDIA_ENDPOINT:
    get_media = 'api/v2/survey/media/get-answer-media',
} = process.env

const MediaPlayback: React.FC = () => {
  const { providerState } = useContext(VideoResponseState)
  const { providerActions } = useContext(VideoResponseActions)
  const { bootloaderState } = useContext(BootloaderState)

  if (!providerState) return null

  const { selectedQuestion, questions = [] } = providerState?.context ?? {}

  const { accessToken } = bootloaderState?.context ?? {}
  const { handleGoBackToQuestionsList, handleViewQuestion } =
    providerActions ?? {}
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(
    selectedQuestion!
  )
  const [isDisabledBackBtn, setDisabledBackBtn] = useState(false)
  const [isDisabledNextBtn, setDisabledNextBtn] = useState(false)
  const mediaGuid = selectedQuestion?.answerMediaGuid ?? ''

  const videoUrl = `${accord}/${get_media}/${mediaGuid}?access_token=${accessToken}`

  const next = () => {
    const i = questions?.indexOf(selectedQuestionIndex!)
    if (i >= 0 && i < questions.length - 1) {
      setSelectedQuestionIndex(questions[i + 1])
    } else {
      handleViewQuestion?.(questions[0])
    }
  }

  const back = () => {
    const i = questions?.indexOf(selectedQuestionIndex)
    if (i >= 0 && i <= questions.length - 1) {
      setSelectedQuestionIndex(questions[i - 1])
    } else {
      setSelectedQuestionIndex(selectedQuestion!)
    }
  }

  useEffect(() => {
    if (
      selectedQuestion?.answerMediaGuid !==
      selectedQuestionIndex.answerMediaGuid
    ) {
      handleViewQuestion?.(selectedQuestionIndex!)
    }
    if (questions?.length - 1 === questions?.indexOf(selectedQuestionIndex)) {
      setDisabledNextBtn(true)
    }
    if (questions?.indexOf(selectedQuestionIndex) > 0) {
      setDisabledBackBtn(false)
    }
    if (questions?.indexOf(selectedQuestionIndex) === 0) {
      setDisabledBackBtn(true)
    }
  }, [selectedQuestionIndex.answerMediaGuid])

  return (
    <>
      <StyledExitContainer>
        <StyledExitSpan
          className='flex align-center'
          onClick={handleGoBackToQuestionsList}
        >
          <Icon iconName='chevron-left' size='xl' />
          Back
        </StyledExitSpan>
      </StyledExitContainer>
      <Container direction='column' centerX size='xl' bgColor='red'>
        <StyledHeadingThree className='margin--top-2'>
          Video Preview
        </StyledHeadingThree>
        <Container
          direction='row'
          centerX
          className='margin--top-1 align--items-center'
        >
          <Icon
            iconName='chevron-left'
            className='chevronLeft'
            clickable
            hoverable
            onClick={!isDisabledBackBtn ? back : undefined}
            disabled={isDisabledBackBtn}
          />
          <StyledHeadingThree className='padding--left-1 padding--right-1 padding--bottom-1'>
            {selectedQuestion?.question?.name}
          </StyledHeadingThree>
          <Icon
            iconName='chevron-right'
            className='chevronRight'
            clickable
            hoverable
            onClick={!isDisabledNextBtn ? next : undefined}
            disabled={isDisabledNextBtn}
          />
        </Container>

        <StyledParagraph className='padding--bottom-1'>
          {parseQuestionText(selectedQuestion?.question?.text ?? '')}
        </StyledParagraph>
        <StyledVideo playsInline controls>
          <source type='video/mp4' src={videoUrl} />
        </StyledVideo>
      </Container>
    </>
  )
}

export default MediaPlayback
