/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import 'regenerator-runtime/runtime'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Icon, RaySpinner } from '@pando-styles/nirvana'
import { useModal } from '@pandolink/utils'

import {
  StyledHeadingThree,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledExitContainer,
  StyledExitSpan,
} from '@styles/index'
import { QuestionsState, QuestionsActions } from '@applications/index'
import { MainActions, MainState } from '@pages/index'

import UnsavedChangesModal from './UnsavedChangesModal'
import {
  StyledLoadingContainer,
  StyledErrorText,
  StyledTextDisplay,
} from './styles'
import { BootloaderActions } from '@system/Bootloader'

import { capitalize } from '@utils/index'

type ClearUnsavedChanges = {
  clearUnsavedChanges: boolean
}

// const { REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList } = process.env

const CompletingSurvey: React.FC = () => {
  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (
    !providerState ||
    !providerActions ||
    !homeProviderActions ||
    !homeProviderState ||
    !bootloaderActions
  )
    return null

  const { question, failedUploadIds } = providerState?.context ?? {}
  const { selectedInstance } = homeProviderState?.context ?? {}
  const { signatoryGuid = '', organizationName = '' } = selectedInstance ?? {}

  const { handleClearContext, handleReupload, handleCompleteSurveyDirectly } =
    providerActions ?? {}
  const { handleGoToSurveyList, handleExitSurvey, handleStartEsignExecution } =
    homeProviderActions ?? {}

  const { handleClearBootloaderUrlParams } = bootloaderActions ?? {}

  const handleExit = ({ clearUnsavedChanges }: ClearUnsavedChanges) => {
    handleClearContext?.()
    handleClearBootloaderUrlParams()

    if (providerState.matches('survey_completed')) {
      handleGoToSurveyList()
      return
    }

    if (clearUnsavedChanges) {
      handleCompleteSurveyDirectly?.()
      return
    }

    handleExitSurvey()
  }

  const handleCompleteSurvey = () => {
    if (providerState.matches('survey_completed')) {
      handleClearBootloaderUrlParams()
      handleGoToSurveyList()
      return
    }
  }

  const { openModal, closeModal, modalIsOpen, Modal } = useModal()

  const handleTriggerModal = () => {
    if (Object.keys(failedUploadIds ?? {})?.length) {
      openModal()
      return
    }

    handleExit({
      clearUnsavedChanges: false,
    })
  }

  //will set to hidden by default as of now
  const isNavBarHidden = true
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )

  const getText = () => {
    if (providerState.matches('max_reupload_attempts_reached')) {
      return 'Max reupload attempts reached.'
    }

    if (providerState.matches('complete_survey')) {
      return 'Thank you for taking this survey please wait until your results are uploaded.'
    }

    if (providerState.matches('has_failed_items')) {
      return `Thank you for taking this survey please wait until your results are uploaded. Once results are uploaded click on ${
        signatoryGuid ? 'Continue to ESign' : 'Done'
      } to ${signatoryGuid ? 'proceed' : 'finish'}.`
    }

    if (providerState.matches('survey_failed_upload')) {
      return 'Your survey failed to upload results. Please try again or restart this survey.'
    }

    if (providerState.matches('survey_completed')) {
      if (!isNavBarHidden) {
        return `Thank you for taking this survey. Press the button below to ${
          signatoryGuid ? 'continue' : 'finish'
        }.`
      }
      return `That concludes this automated survey. If you have any questions, your ${capitalize(
        organizationName
      )} representative will be happy to answer them. You can now close this page. Thank you.`
    }
  }

  const ProgressSectionComponent = () => {
    if (
      providerState.matches('complete_survey') ||
      providerState.matches('has_failed_items')
    ) {
      return (
        <>
          <RaySpinner color='primary' colorWeight='DEFAULT' />
          <StyledParagraph className='margin--top-1'>
            Uploading results...
          </StyledParagraph>
        </>
      )
    }

    if (providerState.matches('survey_completed')) {
      return (
        <>
          <Icon iconName='check' className='m-auto margin--top-2' size='xl' />
        </>
      )
    }

    if (providerState.matches('survey_failed_upload')) {
      return (
        <>
          <Icon
            iconName='alert-circle'
            className='m-auto margin--top-2'
            size='xl'
            color='danger'
          />
          <StyledErrorText className='margin--bottom-2'>
            Upload Failed
          </StyledErrorText>
        </>
      )
    }

    if (providerState.matches('max_reupload_attempts_reached')) {
      return (
        <>
          <Icon
            iconName='alert-circle'
            className='m-auto margin--top-2'
            size='xl'
          />
          <StyledErrorText className='margin--bottom-2'>
            Survey Failed
          </StyledErrorText>
        </>
      )
    }

    return null
  }

  useEffect(() => {
    () =>
      //remove fontSize upon finishing survey
      localStorage.removeItem('fontSize')
  })

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <UnsavedChangesModal
          handleHideModal={closeModal}
          handleExit={() =>
            handleExit({
              clearUnsavedChanges: true,
            })
          }
        />
      </Modal>

      <StyledWrapper>
        {!isNavBarHidden && (
          <StyledExitContainer>
            <StyledExitSpan onClick={handleTriggerModal}>
              Exit Survey
            </StyledExitSpan>
          </StyledExitContainer>
        )}
        <StyledWrapperBody height='0'>
          <StyledWrapperBodyContainer>
            <StyledLoadingContainer className='margin--bottom-2'>
              <ProgressSectionComponent />
            </StyledLoadingContainer>
            <StyledHeadingThree>
              {providerState.matches('survey_completed') && 'Survey Complete'}
              {providerState.matches('max_reupload_attempts_reached') &&
                'Survey Failed'}
            </StyledHeadingThree>
            <StyledTextDisplay className='margin--bottom-4 margin--top-2 padding--x-1-at-800'>
              {getText()}
            </StyledTextDisplay>
            {providerState.matches('survey_failed_upload') ? (
              <Button
                label='Try Again'
                className='w-300 font-14'
                onClick={handleReupload}
              />
            ) : (
              !isNavBarHidden && (
                <Button
                  label={
                    providerState.matches('survey_completed') && signatoryGuid
                      ? 'Continue'
                      : 'Finish'
                  }
                  hoverColorWeight='300'
                  className='w-300 margin-top-2 font-14'
                  onClick={() =>
                    providerState.matches('survey_completed') && signatoryGuid
                      ? handleStartEsignExecution()
                      : handleCompleteSurvey()
                  }
                  isDisabled={
                    !providerState.matches('survey_completed') &&
                    !providerState.matches('max_reupload_attempts_reached')
                  }
                />
              )
            )}
          </StyledWrapperBodyContainer>
        </StyledWrapperBody>
      </StyledWrapper>
    </>
  )
}

export default CompletingSurvey
