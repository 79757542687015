import React, { useContext } from 'react'
import { Button, Icon } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledHomeContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { MainActions, MainState } from '@pages/index'

import { StyledButtonDiv } from '../styles'

const Error: React.FC = () => {
  const { providerActions } = useContext(MainActions)
  const { providerState } = useContext(MainState)

  if (!providerActions || !providerState) return null

  const { handleReload } = providerActions ?? {}

  const { errorMessage, showTryAgainButton = true } =
    providerState.context ?? {}

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <Icon
                iconName='alert-circle'
                color='danger'
                className={`scale-1-5 m-auto margin--bottom-1`}
              />
              <StyledParagraph>
                {errorMessage ?? 'Something went wrong!'}
              </StyledParagraph>
              {showTryAgainButton && (
                <StyledButtonDiv className='margin--top-1'>
                  <Button
                    label='Try Again'
                    hoverColorWeight='300'
                    onClick={handleReload}
                    className='font-14'
                  />
                </StyledButtonDiv>
              )}
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default Error
