/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'

interface UseVideoPlayerProps {
  sitePermissions: any
  surveyConfiguration: any
  mediaGuid?: string
  accessToken?: string
  hasCamPermission: boolean
  videoTrack: MediaStream | null
  isAtPlayingState: boolean
  isCaptureVideoActive: boolean
  isAllowedRecording?: boolean
  videoPlayerRef: any
  modalIsOpenPause: boolean
  firstVideoPlayed: boolean
  getMicPermission?: () => void
  handleStopRecording?: (stateValue: string) => void
  handleStartRecording?: (stateValue: string) => void
  handleVideoPlaybackEnded: () => void
  setVideoTrack: Dispatch<any>
  openModalCam: () => void
}

const {
  REACT_APP_QUERY_HOST: accord,
  REACT_APP_QUESTION_MEDIA_ENDPOINT: get_media,
  REACT_APP_IOS_DEBUGGGING = false,
} = process.env

export const useVideoPlayer = (props: UseVideoPlayerProps) => {
  const {
    mediaGuid,
    hasCamPermission,
    accessToken,
    videoTrack,
    isAtPlayingState,
    isAllowedRecording,
    sitePermissions,
    surveyConfiguration,
    isCaptureVideoActive,
    videoPlayerRef,
    modalIsOpenPause,
    firstVideoPlayed,
    handleStopRecording,
    handleStartRecording,
    getMicPermission,
    handleVideoPlaybackEnded,
    setVideoTrack,
    openModalCam,
  } = props

  // states
  const [mediaUrl, setMediaUrl] = useState<string>('')
  const [hasVideoEnded, setHasVideoEnded] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [showVideoLoader, setShowVideoLoader] = useState(true)
  const [showPlayButton, setShowPlayButton] = useState(true)

  // memos
  const videoUrl = useMemo(
    () => `${accord}/${get_media}/${mediaGuid}?access_token=${accessToken}`,
    [mediaGuid]
  )
  const vidAndAudioNotRequired = useMemo(
    () =>
      !surveyConfiguration?.audioRequired &&
      !surveyConfiguration?.videoRequired,
    [surveyConfiguration]
  )

  // other functions
  const shouldQuestionMediaPlay = () => {
    // ios debugging is true
    if (REACT_APP_IOS_DEBUGGGING) return true

    // if video/camera recording is not required
    if (!surveyConfiguration?.videoRequired) return true

    // since it's required, it should require the permission and video track present
    if (hasCamPermission && videoTrack) {
      // make sure the current state of the machine is in a playing state
      // if its is currently playing and no other factors causing the media to pause
      // Don't remove this commented line yet
      if (isAtPlayingState) {
        return true
      }

      return false
    }

    return false
  }

  // useEffects

  // set video src
  useEffect(() => {
    if (videoUrl) {
      setMediaUrl(videoUrl)
    }
  }, [videoUrl])

  // Video Ended - Trigger Stop Recording When isCaptureVideoActive is off
  useEffect(() => {
    if (hasVideoEnded && !isCaptureVideoActive) {
      handleStopRecording?.(
        '> Recording stopped at useEffect hasVideoEnded and !isCaptureVideoActive [useVideoPlayer]'
      )
    }

    if (hasVideoEnded && isAtPlayingState) {
      handleVideoPlaybackEnded()
    }

    // not yet sure if i should separate this into useDeviceStream and useMediaRecorder
    if (hasVideoEnded) {
      sitePermissions?.microphone
        ? getMicPermission?.()
        : (!vidAndAudioNotRequired || Boolean(isAllowedRecording)) &&
          handleStartRecording?.(
            '> Recording started at useEffect hasVideoEnded [useVideoPlayer]'
          )
    }
  }, [hasVideoEnded, isCaptureVideoActive])

  useEffect(() => {
    if (!videoTrack) {
      return
    }

    const handleTrackEnded = (event: any) => {
      console.error(
        'Error: ❗❗ MediaStreamTrack ended due to a capture failure:',
        event
      )
      setVideoTrack(null)

      // @ts-ignore
      window.stream = null
    }

    // NOTE: DON'T REMOVE COMMENTED LINES YET INSIDE THIS USEEFFECT
    // const isIOSSafariDevice =
    //   (isAppleDevice && isMobile) || (isAppleDevice && !isMobile && isSafari)

    // const hasMediaRecordingFailedState =
    //   providerState.matches('ready.waiting_for_button_click') ||
    //   providerState.matches('ready.interrupted_response_via_window_blur') ||
    //   providerState.matches('ready.listening')

    // const shouldInvokeInterruptedMediaRecording =
    //   isIOSSafariDevice &&
    //   hasMediaRecordingFailedState &&
    //   surveyConfiguration?.videoRequired

    // const handleStopMediaRecording = (event: any) => {
    //   if (
    //     shouldInvokeInterruptedMediaRecording &&
    //     !hasInterruptedMediaRecording
    //   ) {
    //     setHasInterruptedMediaRecording(true)
    //     handleInterruptedMediaRecording()
    //     openModalCam()
    //     return
    //   }
    // }

    videoTrack.addEventListener('ended', handleTrackEnded)

    return () => {
      videoTrack.removeEventListener('ended', handleTrackEnded)
    }
  }, [videoTrack])

  return {
    mediaUrl,
    vidAndAudioNotRequired,
    hasVideoEnded,
    isVideoPlaying,
    showVideoLoader,
    showPlayButton,
    shouldQuestionMediaPlay,
    setHasVideoEnded,
    setIsVideoPlaying,
    setShowVideoLoader,
    setShowPlayButton,
  }
}
