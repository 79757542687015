/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { State } from 'xstate'

import React, { useEffect } from 'react'

import { usePando } from '../index'
import { config, context, options, Context } from './machine'
import { ExposedActions, UsePermissionParams, ComponentProps } from './types'
import { PermissionComponent } from './components'

export const usePermission = ({
  loggedOut = false,
}: UsePermissionParams): [
  State<Context>,
  ExposedActions,
  React.FC<ComponentProps>
] => {
  const machine = React.useMemo(
    () => ({
      machine: {
        config,
        options,
        context,
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  useEffect(() => {
    if (loggedOut) {
      send('CLEAN_UP')
    }
  }, [loggedOut])

  useEffect(() => {
    send('CLEAN_UP')
  }, [])

  const exposedActions: ExposedActions = {
    handleRequestUserPermission: (surveyConfiguration) => {
      send({
        type: 'REQUEST_USER_PERMISSION',
        payload: surveyConfiguration,
      })
    },
    handleGoToPermissionInstructions: () => {
      send('GO_TO_PERMISSION_INSTRUCTIONS')
    },
    handleTryAgain: () => {
      send('TRY_AGAIN')
    },
    handleStillShowPermissionPopup: () => {
      send('SITE_SETTINGS_STILL_SHOW_PERMISSION_POPUP')
    },
    handleAlreadyDeniedPermissionPopup: () => {
      send('SITE_SETTINGS_ALREADY_DENIED_ACCESS')
    },
    handleClearAttempt: () => {
      send('CLEAR_ATTEMPT')
    },
  }

  return [state, exposedActions, PermissionComponent]
}
