/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable indent */
import 'regenerator-runtime/runtime'
import { AnyEventObject, Sender } from 'xstate'
import axios from 'axios'
import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  GRANT_TYPE_AUTHORIZATION_CODE,
  TokenRequest,
} from '@openid/appauth'
import { usePandoLogger } from '@pandolink/utils'

import {
  execClaimInstance,
  execClaimSignatory,
  execUpgradeGuest,
  snakeToCamel,
} from '@utils/index'

import { Context, AuthorizedEvent } from '../types'
import { NoHashQueryString, convertToQueryParams } from '../../utils/index'

const { REACT_APP_QUERY_HOST, REACT_APP_RECREATE_SURVEY_INSTANCE } = process.env

const {
  REACT_APP_AUTH_SERVER = 'https://login.staging.pandolink.com',
  REACT_APP_LOGOUT_ENDPOINT = '/connect/endsession',
  REACT_APP_REDIRECT_URI = 'http://localhost:3000',
  REACT_APP_SCOPE = '',
  REACT_APP_ADMIN_CLIENT_SECRET = '',
  REACT_APP_ADMIN_CLIENT_ID = '',
} = process.env

export const services: any = {
  checkAuthorization:
    ({
      accessToken,
      loggedInAsGuest,
      allowAccountCreation,
      guestLoginButtonText,
    }: Context) =>
    async (send: Sender<AuthorizedEvent>) => {
      const {
        AuthorizationServiceConfiguration,
        AuthorizationRequest,
        LocalStorageBackend,
        FetchRequestor,
        DefaultCrypto,
        RedirectRequestHandler,
      } =
        typeof window !== 'undefined'
          ? await import('@openid/appauth')
          : ({} as any)

      const authorizationHandler = new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryString(),
        window.location,
        new DefaultCrypto()
      )

      if (!accessToken) {
        ;(async () => {
          try {
            AuthorizationServiceConfiguration.fetchFromIssuer(
              REACT_APP_AUTH_SERVER!,
              new FetchRequestor()
            ).then((response: any) => {
              const authRequest = new AuthorizationRequest({
                client_id: REACT_APP_ADMIN_CLIENT_ID,
                redirect_uri: REACT_APP_REDIRECT_URI,
                scope: REACT_APP_SCOPE,
                response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
                state: undefined,
                extras: {
                  client_secret: REACT_APP_ADMIN_CLIENT_SECRET,
                  login_code: '',
                  user_id: '',
                  event_id: '',
                  prompt: 'login',
                  access_type: 'offline',
                  allow_anonymous: loggedInAsGuest,
                  allow_account_creation: allowAccountCreation,
                  guest_login_button_text: guestLoginButtonText,
                },
              })

              authorizationHandler.performAuthorizationRequest(
                response,
                authRequest
              )

              send('AUTHORIZED')
            })
          } catch (error) {
            console.error('Error [checkAuthorization]:', error)
          }
        })()
      }
    },

  checkAuthentication:
    ({
      loggedInAsGuest,
      allowAccountCreation,
      guestLoginButtonText,
    }: Context) =>
    (send: Sender<any>) => {
      ;(async () => {
        const {
          AuthorizationServiceConfiguration,
          LocalStorageBackend,
          FetchRequestor,
          DefaultCrypto,
          RedirectRequestHandler,
          BaseTokenRequestHandler,
          AuthorizationNotifier,
          TokenRequest,
          GRANT_TYPE_AUTHORIZATION_CODE,
        } =
          typeof window !== 'undefined'
            ? await import('@openid/appauth')
            : ({} as any)

        const authorizationHandler = new RedirectRequestHandler(
          new LocalStorageBackend(),
          new NoHashQueryString(),
          window.location,
          new DefaultCrypto()
        )

        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
        const notifier = new AuthorizationNotifier()
        authorizationHandler.setAuthorizationNotifier(notifier)

        notifier.setAuthorizationListener(
          (request: any, response: any, error: any) => {
            if (error) console.error('Error [setAuthorizationListener]:', error)

            const requestToken = new TokenRequest({
              client_id: REACT_APP_ADMIN_CLIENT_ID,
              redirect_uri: REACT_APP_REDIRECT_URI,
              grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
              code: response?.code,
              refresh_token: undefined,
              extras:
                request && request.internal
                  ? {
                      ...request.internal,
                      client_secret: REACT_APP_ADMIN_CLIENT_SECRET,
                      scope: REACT_APP_SCOPE,
                      allow_anonymous: loggedInAsGuest,
                      allow_account_creation: allowAccountCreation,
                      guest_login_button_text: guestLoginButtonText,
                    }
                  : {},
            })

            AuthorizationServiceConfiguration.fetchFromIssuer(
              REACT_APP_AUTH_SERVER!,
              new FetchRequestor()
            )
              .then((response: any) => {
                return tokenHandler.performTokenRequest(response, requestToken)
              })
              .then(async (response: any) => {
                const { accessToken } = response

                // a different key beside the oidc key in store which is cleared when you logout
                // this is for the survey link parameters
                const parameters = {
                  loggedInAsGuest,
                  allowAccountCreation,
                  guestLoginButtonText,
                }

                localStorage.setItem(
                  'oidc-other-params',
                  JSON.stringify(parameters)
                )

                accessToken &&
                  send({
                    type: 'AUTHENTICATED',
                    payload: {
                      ...response,
                      isAuthenticated: true,
                    },
                  })
              })
              .catch((error: any) => {
                send({ type: 'AUTHENTICATION_ERROR', payload: error })
              })
          }
        )
        authorizationHandler.completeAuthorizationRequestIfPossible()
      })()
    },

  refreshToken:
    ({ refreshToken }: Context) =>
    async (send: Sender<any>) => {
      try {
        console.log(
          '@DEBUG: [useOIDC]: Requesting new access token using the refresh token'
        )
        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())

        let request: TokenRequest | null = null

        const authConfig =
          await AuthorizationServiceConfiguration.fetchFromIssuer(
            REACT_APP_AUTH_SERVER,
            new FetchRequestor()
          )
        request = new TokenRequest({
          client_id: REACT_APP_ADMIN_CLIENT_ID,
          redirect_uri: REACT_APP_REDIRECT_URI,
          grant_type: GRANT_TYPE_REFRESH_TOKEN,
          code: undefined,
          refresh_token: refreshToken ?? '',
          extras: {
            client_secret: REACT_APP_ADMIN_CLIENT_SECRET,
            scope: REACT_APP_SCOPE,
          },
        })

        const tokenResponse = await tokenHandler.performTokenRequest(
          authConfig,
          request
        )

        if (tokenResponse) {
          console.log(
            '@DEBUG [useOIDC]: Got new access token via refresh token'
          )

          send({
            type: 'GOT_NEW_ACCESS_TOKEN',
            payload: tokenResponse,
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'refreshToken: error',
          color: 'danger',
          body: error,
        })

        console.log('@DEBUG [useOIDC]: Invalid refresh token.')
        send('INVALID_REFRESH_TOKEN')
      }
    },

  getNewAccessAndRefreshTokens:
    ({
      loggedInAsGuest,
      allowAccountCreation,
      guestLoginButtonText,
    }: Context) =>
    async (send: Sender<any>) => {
      console.log('@DEBUG: [useOIDC]: Requesting new access AND refresh tokens')
      try {
        const requestBody = new URLSearchParams()
        requestBody.append('client_id', REACT_APP_ADMIN_CLIENT_ID)
        requestBody.append('client_secret', REACT_APP_ADMIN_CLIENT_SECRET)
        requestBody.append('grant_type', 'guest')
        requestBody.append('scope', REACT_APP_SCOPE)

        const tokenResponse = await axios.post(
          `${REACT_APP_AUTH_SERVER}/connect/token`,
          requestBody,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )

        if (tokenResponse) {
          const currentDate = new Date()
          const unixTimestamp = Math.floor(currentDate.getTime() / 1000)

          console.log(
            '@DEBUG [useOIDC]: Got new access AND refresh tokens',
            tokenResponse,
            unixTimestamp
          )

          send({
            type: 'GOT_NEW_ACCESS_AND_REFRESH_TOKENS',
            payload: {
              ...snakeToCamel(tokenResponse.data),
              issuedAt: unixTimestamp,
            },
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'getNewAccessAndRefreshTokens: error',
          color: 'danger',
          body: error,
        })
      }
    },

  logOutUser:
    ({ idToken }: Context) =>
    async (send: Sender<any>) => {
      const queryParams = convertToQueryParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: REACT_APP_REDIRECT_URI,
      })
      const { data } = await axios.get(
        `${REACT_APP_AUTH_SERVER}${REACT_APP_LOGOUT_ENDPOINT}${queryParams}`
      )

      if (data) {
        send({
          type: 'LOG_OUT_SUCCESS',
        })
      }
    },

  notifiyIdentityServerForlogoutEvent:
    ({ idToken }: Context) =>
    async (send: Sender<any>) => {
      const queryParams = convertToQueryParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: REACT_APP_REDIRECT_URI,
      })
      const { data } = await axios.get(
        `${REACT_APP_AUTH_SERVER}${REACT_APP_LOGOUT_ENDPOINT}${queryParams}`
      )
      if (data) {
        send({
          type: 'SERVER_NOTIFIED',
        })
      }
    },

  removeLocalStorageItems: () => {
    try {
      localStorage.removeItem('oidc')
      localStorage.clear()
    } catch (e) {
      console.error('Error [removeLocalStorageItems]:', e)
    }
  },

  removeLocalStorageItemsExceptForOIDC: () => {
    try {
      const oidc = localStorage.getItem('oidc')
      localStorage.clear()
      oidc && localStorage.setItem('oidc', JSON.parse(oidc))
    } catch (e) {
      console.error('Error [removeLocalStorageItemsExceptForOIDC]:', e)
    }
  },

  emptyLocalStorage: () => {
    try {
      localStorage.removeItem('oidc')
      localStorage.clear()
    } catch (e) {
      console.error('Error [emptyLocalStorage]:', e)
    }
  },

  upgradeGuestAccount:
    ({ accessToken = '', guestToken = '' }: Context) =>
    async (send: Sender<any>) => {
      try {
        const result: any = await execUpgradeGuest(guestToken, accessToken!)

        if (result) {
          send({
            type: 'ACCOUNT_UPGRADE_SUCCESS',
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'Error: upgradeGuestAccount',
          color: 'danger',
          body: error,
        })

        send('ACCOUNT_UPGRADE_FAILED')
      }
    },

  recreateSurvey:
    ({ surveyReferenceGuid }: Context) =>
    async (send: Sender<any>) => {
      try {
        const requestBody = new URLSearchParams()
        requestBody.append('client_id', REACT_APP_ADMIN_CLIENT_ID)
        requestBody.append('client_secret', REACT_APP_ADMIN_CLIENT_SECRET)
        requestBody.append('grant_type', 'client_credentials')
        requestBody.append('scope', 'accord survey:admin')

        // get client token for /recreate instance endpoint
        const tokenResponse = await axios.post(
          `${REACT_APP_AUTH_SERVER}/connect/token`,
          requestBody,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )

        if (tokenResponse) {
          const response = await axios.post(
            `${REACT_APP_QUERY_HOST}/${REACT_APP_RECREATE_SURVEY_INSTANCE}`,
            {
              surveyReferenceGuid,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenResponse.data.access_token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          console.log('@DEBUG [recreateSurvey]:', {
            response,
          })

          send({
            type: 'RECREATE_SURVEY_SUCCESS',
            payload: response.data?.newSurveyInstanceGuid,
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'clientToken: error',
          color: 'danger',
          body: error,
        })
      }
    },

  claimInstance:
    (
      {
        accessToken = '',
        instanceGuid = '',
        claimCode = '',
        loggedInAsGuest = false,
      }: Context,
      event: AnyEventObject
    ) =>
    async (send: Sender<any>) => {
      try {
        console.log(
          '@DEBUG [useOIDC] service trying to claimInstance:',
          accessToken
        )

        if (event.type === 'xstate.init' || !accessToken) return

        const result = await execClaimInstance(
          {
            instanceGuid,
            claimCode,
          },
          accessToken
        )

        console.log(
          '@DEBUG [useOIDC]: Claiming survey instance result:',
          result?.toObject()
        )

        const { isSuccess = false, message = '' } = result.toObject()

        if (isSuccess) {
          send({
            type: 'CLAIM_INSTANCE_SUCCESS',
            payload: instanceGuid,
          })
        } else {
          throw {
            isSuccess,
            message,
          }
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'claimInstance: error',
          color: 'danger',
          body: error,
        })

        send({
          type: 'CLAIM_INSTANCE_FAILED',
          payload: error?.message,
        })
      }
    },

  claimSignatory:
    ({ accessToken = '', signatoryGuid = '', claimCode = '' }: Context) =>
    async (send: Sender<any>) => {
      try {
        if (!accessToken) return

        send('CLAIM_SIGNATORY_SUCCESS')
      } catch (error: any) {
        usePandoLogger({
          name: 'claimSignatory: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'CLAIM_SIGNATORY_FAILED',
          payload: error?.message,
        })
      }
    },
}
