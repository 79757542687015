/* eslint-disable indent */
import styled from 'styled-components'

import {
  StyledWrapper,
  StyledWrapperHeader,
  StyledWrapperBody,
  StyledParagraph,
  StyledWrapperBodyContainer,
  StyledHeadingThree,
} from '@styles/index'

export const StyledQuestionContentContainer = styled.div<{
  questionDivHeight: number
}>`
  width: 100%;
  position: absolute;
  top: 48vh;
  height: 45vh !important;
  background: #fff;
  transition: all 0.5s ease-in-out;
  padding-top: 5px;

  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-height: 800px), screen and (max-width: 800px) {
    /* top: 55vh !important; */
    top: unset !important;
    bottom: 0;
    height: 44vh !important;
  }
`
export const StyledAnswerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 10px 0 20px;
  width: 100%;
`

export const StyledSelectAllAnswerText = styled(StyledParagraph)`
  margin-right: 5rem;
  margin-left: 5rem;

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
`

export const StyledLoadingContainer = styled.div``

export const StyledHeaderFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  flex-direction: column;
  background: #fff;
  margin-top: 3px;
  margin-bottom: 3px;
`

export const StyledQuestionTitle = styled(StyledHeadingThree)<{
  fontSize: number
}>`
  font-size: ${({ fontSize }) => fontSize}px !important;
`

export const StyledCustomWrapper = styled(StyledWrapper)`
  // padding: 2rem !important;
  flex-direction: column;
  display: flex;
`

export const QuestionHeaderContainer = styled(StyledWrapperHeader)`
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  margin-top: 2rem !important;
`

export const QuestionBodyContainer = styled(StyledWrapperBody)`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  justify-content: flex-start;
  min-height: 100vh !important;
  max-height: 100vh !important;
`

export const QuestionContainerBody = styled.div<{
  isScrollable: boolean
  isMobile?: boolean
}>`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: ${({ isMobile = false }) => (isMobile ? '3.5rem' : 0)};
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  margin-bottom: 3rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }
`
export const QuestionContainer = styled(StyledParagraph)<{
  fontSize: number
  isMobile?: boolean
}>`
  font-size: ${({ fontSize }) => fontSize}px !important;
  padding-bottom: ${({ isMobile = false }) => (isMobile ? '3.5rem' : '10px')};
  height: 100%;
  overflow-y: auto;
`

export const StyledErrorText = styled.p`
  color: #ef4444 !important;
  font-size: 14px !important;
  margin: 0.4rem 0;
  line-height: 150%;
`

export const FontButton = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
`
export const SurveyMediaMainWrapper = styled(StyledWrapperBodyContainer)`
  height: 55vh !important;
`

export const ShadowTop = styled.div`
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 35px;
  top: 0;
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.8), transparent);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0;
`

export const ShadowBottom = styled.div<{
  isMobile?: boolean
  isNotIOS?: boolean
}>`
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 35px;
  bottom: ${({ isNotIOS = false, isMobile = false }) =>
    isNotIOS ? '3.5rem' : isMobile ? '5.5rem' : 0};
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.8), transparent);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent);
`

export const StyledTextDisplay = styled(StyledParagraph)`
  width: 65%;
  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    width: 90%;
  }
`
