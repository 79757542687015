import React from 'react'
import { Button } from '@pando-styles/nirvana'

import {
  StyledFlexContainer,
  StyledHeadingThree,
  StyledModalContainer,
  StyledParagraph,
} from '@styles/index'

interface UnsavedChangesModalProps {
  handleHideModal?: () => void

  handleExit: ({
    clearUnsavedChanges,
  }: {
    clearUnsavedChanges: boolean
  }) => void
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = (props) => {
  const { handleHideModal, handleExit } = props

  return (
    <StyledModalContainer width={500} className='padding--y-2'>
      <StyledHeadingThree className='m-auto'>
        Are you sure you want to exit?
      </StyledHeadingThree>
      <StyledParagraph className='m-auto margin--top-1 '>
        Your progress will be saved if you exit this survey.
      </StyledParagraph>
      <StyledFlexContainer className='margin--top-2 align-self-center'>
        <Button
          label='Exit'
          hoverColorWeight='300'
          onClick={() => {
            handleHideModal?.()
            handleExit({
              clearUnsavedChanges: false,
            })
          }}
          layout='outline'
          block
          className='font-14'
        />
        <Button
          label='Resume'
          hoverColorWeight='300'
          onClick={handleHideModal}
          block
          className='font-14'
        />
      </StyledFlexContainer>
    </StyledModalContainer>
  )
}

export default UnsavedChangesModal
