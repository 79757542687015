/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  allowAccess: assign({
    sitePermissions: ({ sitePermissions }, { payload }: any) => {
      if (payload === 'camera_and_microphone') {
        return {
          ...(sitePermissions ?? {}),
          camera: true,
          microphone: true,
        }
      }
      if (payload === 'camera') {
        return {
          ...(sitePermissions ?? {}),
          camera: true,
        }
      }
      if (payload === 'microphone') {
        return {
          ...(sitePermissions ?? {}),
          microphone: true,
        }
      }

      return sitePermissions ?? {}
    },
  }),

  blockAccess: assign({
    sitePermissions: ({ sitePermissions }, { payload }: any) => {
      if (payload === 'camera_and_microphone') {
        return {
          camera: false,
          microphone: false,
          ...(sitePermissions ?? {}),
        }
      }
      if (payload === 'camera') {
        return {
          ...(sitePermissions ?? {}),
          camera: false,
        }
      }
      if (payload === 'microphone') {
        return {
          ...(sitePermissions ?? {}),
          microphone: false,
        }
      }

      return sitePermissions ?? {}
    },
  }),

  incrementAttempt: assign({
    checkPermissionsAttempt: ({ checkPermissionsAttempt = 0 }) =>
      checkPermissionsAttempt + 1,
  }),

  assignSurveyConfiguration: assign({
    surveyConfiguration: ({ surveyConfiguration }, { payload }: any) => ({
      ...(surveyConfiguration ?? {}),
      ...payload,
    }),
  }),

  clearAttempt: assign({
    checkPermissionsAttempt: ({}) => 0,
  }),

  clearContext: assign({
    sitePermissions: ({}) => ({}),
    surveyConfiguration: ({}) => ({}),
    receivedPrompt: ({}) => false,
    checkPermissionsAttempt: ({}) => 0,
    requestingPermission: ({}) => false,
    deniedAccess: ({}) => false,
  }),
  assignIsLocationPermissionGranted: assign({
    sitePermissions: ({ sitePermissions }, { payload }: any) => {
      if (payload.status === 'Allowed') {
        return {
          ...sitePermissions,
          browserDeviceDetails: {
            isLocationPermissionGranted: true,
          },
        }
      }
      return {
        ...sitePermissions,
        browserDeviceDetails: {
          isLocationPermissionGranted: false,
        },
      }
    },
  }),

  setRequestingUserPermissionToTrue: assign({
    requestingPermission: ({}) => true,
  }),

  setRequestingUserPermissionToFalse: assign({
    requestingPermission: ({}) => false,
  }),

  setDeniedAccessToTrue: assign({
    deniedAccess: ({}) => true,
  }),

  setDeniedAccessToFalse: assign({
    deniedAccess: ({}) => false,
  }),
}
