import React from 'react'

import { PermissionSteps, PrivacyPolicy } from './'
import { ComponentProps } from '../types'

const Permission: React.FC<ComponentProps> = ({
  type = 'instructions',
  ...props
}) => {
  const getComponentByType = () => {
    if (type === 'privacy_policy') return <PrivacyPolicy {...props} />

    return <PermissionSteps />
  }

  return getComponentByType()
}

export default Permission
