/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react'
import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Icon } from '@pando-styles/nirvana'

import { CompletionType, InstanceShort } from '@utils/index'
import { StyledSurveyContainer } from '@pages/Main/styles'
import { StyledHeadingThree, StyledParagraph } from '@styles/index'

type CompletedSurveysProps = Partial<InstanceShort.AsObject>

const CompletedSurveys: React.FC<CompletedSurveysProps | any> = (props) => {
  const { url } = useRouteMatch()
  const navigate = useHistory()

  const {
    guid,
    surveyName,
    surveyDescription,
    dateCompleted,
    completionType = 2,
    isAllowedVideoPlayback,
  } = props ?? {}

  const getCompletionType = (completionType: CompletionType) => {
    switch (completionType) {
      case 2:
        return 'Acceptable'
      case 1:
        return 'Unacceptable'
      case 0:
      default:
        return 'Incomplete'
    }
  }

  const handleClickedCompletedSurvey = () => {
    navigate.push(`${url}/${guid}`)
  }

  return (
    <StyledSurveyContainer
      key={guid}
      className='margin--bottom-1'
      onClick={
        !isAllowedVideoPlayback ? undefined : handleClickedCompletedSurvey
      }
      clickable={isAllowedVideoPlayback}
    >
      <div className='left-2'>
        <div className='completed-survey-header'>
          <small>
            Completed On:{' '}
            {moment.unix(dateCompleted?.seconds).format('DD-MM-YYYY hh:mm a')}{' '}
          </small>
          <small className='completion-type'>
            {getCompletionType(completionType)}
          </small>
        </div>
        <StyledHeadingThree className='margin--top-1-2 margin--bottom-1-2 teal'>
          {surveyName}
        </StyledHeadingThree>
        <StyledParagraph>{surveyDescription}</StyledParagraph>
      </div>
      <Icon
        iconName='chevron-right'
        className='chevronRight'
        clickable={isAllowedVideoPlayback}
        hoverable={isAllowedVideoPlayback}
        onClick={
          !isAllowedVideoPlayback ? undefined : handleClickedCompletedSurvey
        }
      />
    </StyledSurveyContainer>
  )
}

export default CompletedSurveys
