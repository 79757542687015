/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useContext } from 'react'
import { Spinner, RaySpinner, Icon } from '@pando-styles/nirvana'

import {
  StyledSurveyMedia,
  SurveyCameraContainer,
  SurveyMicrophoneContainer,
  StyledReactPlayer,
  FontButtonsContainer,
} from './styles'

import UserCamera from './UserCamera'
import Dialog from './Dialog'
import UnacceptableAnswerDialog from './UnacceptableAnswerDialog'
import { StyledParagraph } from '@styles/typography'
import FontButtons from '@atomic/Question/FontButtons'

import PermissionModal from './PermissionModal'
import { SurveyMediaState } from './SurveyMediaProvider'
import { ModalStateMic } from './types'

const SurveyMediaConsumer: React.FC = () => {
  if (!SurveyMediaState) return null

  const { surveyMediaState, surveyMediaProps } = useContext(SurveyMediaState)

  if (!surveyMediaState || !surveyMediaProps) return null

  const {
    userRecordedVideo,
    fontButtonColors,
    fontSize,
    setUserRecordedVideo,
    handleFontSizeIncrease,
    handleFontSizeDecrease,
  } = surveyMediaProps

  const {
    camPermissionModal,
    micPermissionModal,
    useBowserState,
    useDeviceStreamState,
    useVideoPlayerState,
    useMediaRecorderState,
    surveyMediaProviderState,
  } = surveyMediaState

  const { isAppleDevice, isMobile } = useBowserState ?? {}
  const {
    hasMicPermission,
    modalStateMic,
    maxAttemptsReachedCam,
    maxAttemptsReachedMic,
    isPermissionLoading,
    camPermissionAttempts,
    micPermissionAttempts,
    getCameraPermission,
    getMicPermission,
    showCameraComponent,
  } = useDeviceStreamState ?? {}
  const {
    hasVideoEnded,
    showVideoLoader,
    showPlayButton,
    videoPlayerRef,
    mediaUrl,
    setHasVideoEnded,
    setShowVideoLoader,
    setShowPlayButton,
    setIsVideoPlaying,
  } = useVideoPlayerState ?? {}
  const { handleStopRecording, handleStartRecording } =
    useMediaRecorderState ?? {}
  const {
    providerState,
    isNavBarHidden,
    mediaGuid,
    hasOnlyOneAnswerOption,
    isCaptureVideoActive,
    isStreamUpdated,
    isMultiChoiceQuestion,
    firstVideoPlayed = false,
    isVideoMuted,
    resumeSurvey,
    showDialog,
    showNotAcceptableAnswerDialog,
    showAskCameraPermissionModal,
    setIsStreamUpdated,
    handleErrorPlayingVideo,
    handleFirstVideoPlayedTrue,
    checkPlatform,
    setIsVideoMuted,
  } = surveyMediaProviderState ?? {}

  if (!mediaGuid) return <>Media Guid Not Found.</>

  // NOTE: This is to fix the volume issue
  if (
    // @ts-ignore
    window?.stream &&
    // @ts-ignore
    window?.stream?.getAudioTracks()?.length >= 1 &&
    !hasVideoEnded
  )
    return null

  return (
    <>
      {showAskCameraPermissionModal() && (
        <camPermissionModal.Modal
          isOpen={camPermissionModal?.modalIsOpen}
          onRequestClose={() => {}}
        >
          <PermissionModal
            handleTryAgain={getCameraPermission}
            maxAttemptsReached={maxAttemptsReachedCam}
            type='camera'
            isPermissionLoading={isPermissionLoading}
            camPermissionAttempts={camPermissionAttempts}
            micPermissionAttempts={micPermissionAttempts}
          />
        </camPermissionModal.Modal>
      )}

      {micPermissionModal.modalIsOpen &&
        modalStateMic === ModalStateMic.DENIED && (
          <micPermissionModal.Modal
            isOpen={micPermissionModal?.modalIsOpen}
            onRequestClose={() => {}}
          >
            <PermissionModal
              handleTryAgain={getMicPermission}
              maxAttemptsReached={maxAttemptsReachedMic}
              type='microphone'
              isPermissionLoading={isPermissionLoading}
              camPermissionAttempts={camPermissionAttempts}
              micPermissionAttempts={micPermissionAttempts}
            />
          </micPermissionModal.Modal>
        )}

      <StyledSurveyMedia
        className='syled-survey-media'
        isNavBarHidden={Boolean(isNavBarHidden)}
      >
        {showVideoLoader &&
          !providerState.matches('ready.waiting_for_button_click') && (
            <div className='video-loader'>
              <RaySpinner color='light' colorWeight='DEFAULT' />
              <StyledParagraph className='text-white margin--top-1'>
                Loading video...
              </StyledParagraph>
            </div>
          )}

        {mediaGuid ? (
          <>
            {showCameraComponent() && (
              <SurveyCameraContainer className='SurveyCameraContainer'>
                <UserCamera isCaptureVideoActive={isCaptureVideoActive} />
              </SurveyCameraContainer>
            )}
            {showDialog() && (
              <SurveyMicrophoneContainer
                hasVideoEnded={
                  hasVideoEnded && Boolean(!hasOnlyOneAnswerOption)
                }
              >
                <Dialog
                  setIsVideoPlaying={setIsVideoPlaying}
                  handleStopRecording={handleStopRecording}
                  handleStartRecording={handleStartRecording}
                  setUserRecordedVideo={setUserRecordedVideo}
                  isStreamUpdated={isStreamUpdated}
                  hasVideoEnded={hasVideoEnded}
                  resumeSurvey={resumeSurvey}
                  setIsStreamUpdated={setIsStreamUpdated}
                  _fontSize={fontSize}
                  hasMicPermission={hasMicPermission}
                  userRecordedVideo={userRecordedVideo}
                  videoPlayerRef={videoPlayerRef}
                />
              </SurveyMicrophoneContainer>
            )}

            {showNotAcceptableAnswerDialog() ? (
              <SurveyMicrophoneContainer
                hasVideoEnded={
                  hasVideoEnded && Boolean(!hasOnlyOneAnswerOption)
                }
              >
                <UnacceptableAnswerDialog
                  _fontSize={fontSize}
                  setUserRecordedVideo={setUserRecordedVideo}
                />
              </SurveyMicrophoneContainer>
            ) : null}

            {/* Play Buton for specific platforms/browsers*/}
            {!checkPlatform() && showPlayButton && !showVideoLoader && (
              <div className='video-control-container'>
                <Icon
                  className='video-control'
                  iconName='play'
                  clickable
                  hoverable
                  onClick={() => {
                    videoPlayerRef.current.play()
                  }}
                  size='xl'
                />
              </div>
            )}

            {/* Play Buton for specific platforms/browsers e.g apple */}
            {checkPlatform() && showPlayButton && (
              <div className='video-control-container'>
                <Icon
                  className='video-control'
                  iconName='play'
                  clickable
                  hoverable
                  onClick={() => {
                    videoPlayerRef.current.play()
                  }}
                  size='xl'
                />
              </div>
            )}

            {/* NOTE: We don't need it here but don't remove this yet - for testing purposes */}
            {/* {isAppleDevice && isMobile && (
              <Icon
                className='video-unmute-btn'
                iconName={isVideoMuted ? 'volume-x' : 'volume-2'}
                clickable
                hoverable
                onClick={() => setIsVideoMuted(!isVideoMuted)}
                size='xl'
              />
            )} */}

            {/* Video Player */}
            <StyledReactPlayer
              className='STYLED_REACT_PLAYER_CONTAINER'
              isMultiChoiceType={isMultiChoiceQuestion}
            >
              {checkPlatform() ? (
                <video
                  ref={videoPlayerRef}
                  playsInline
                  onLoadedData={() => {
                    console.log('OnLoaded triggered.')
                    setShowVideoLoader(false)
                  }}
                  onPlay={() => {
                    if (!firstVideoPlayed) {
                      handleFirstVideoPlayedTrue()
                    }
                  }}
                  onPlaying={() => {
                    console.log('OnPlaying triggered.')
                    setHasVideoEnded(false)
                    setShowPlayButton(false)
                    setIsVideoPlaying(true)
                  }}
                  onPause={(e: any) => {
                    console.log('OnPause triggered.', e)

                    !e.target.ended && setShowPlayButton(true)
                    setIsVideoPlaying(false)
                  }}
                  onEnded={() => {
                    setHasVideoEnded(true)
                    setIsVideoPlaying(false)
                  }}
                  onError={(err) => {
                    console.log('❌ OnError triggered:', err)
                    // @ts-ignore
                    err.target.error?.code === 3 &&
                      providerState.matches(
                        'ready.video_playback_with_voice_recognition'
                      ) &&
                      handleErrorPlayingVideo()
                  }}
                >
                  <source type='video/mp4' src={mediaUrl} />
                </video>
              ) : (
                <video
                  ref={videoPlayerRef}
                  playsInline
                  onLoadedData={() => {
                    setShowVideoLoader(false)
                  }}
                  onPlay={() => {
                    if (!firstVideoPlayed) {
                      handleFirstVideoPlayedTrue()
                    }
                  }}
                  onPlaying={() => {
                    setHasVideoEnded(false)
                    setShowPlayButton(false)
                    setIsVideoPlaying(true)
                  }}
                  onPause={(e: any) => {
                    console.log('OnPause triggered', e)

                    !e.target.ended && setShowPlayButton(true)

                    setIsVideoPlaying(false)
                  }}
                  onEnded={() => {
                    setHasVideoEnded(true)
                    setIsVideoPlaying(false)
                  }}
                  src={mediaUrl}
                  onError={(err) => {
                    console.log('❌ OnError triggered.', err)
                    // @ts-ignore
                    err.target.error?.code === 3 &&
                      providerState.matches(
                        'ready.video_playback_with_voice_recognition'
                      ) &&
                      handleErrorPlayingVideo()
                  }}
                >
                  <source type='video/mp4' src={mediaUrl} />
                </video>
              )}
            </StyledReactPlayer>
          </>
        ) : (
          <Spinner />
        )}
        <FontButtonsContainer>
          <FontButtons
            type='minus'
            callBack={handleFontSizeDecrease}
            color={fontButtonColors.minus}
          />
          <FontButtons
            type='plus'
            callBack={handleFontSizeIncrease}
            color={fontButtonColors.plus}
          />
        </FontButtonsContainer>
      </StyledSurveyMedia>
    </>
  )
}

export default SurveyMediaConsumer
