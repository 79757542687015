import styled, { keyframes } from 'styled-components'

export const StyledButtonWrapper = styled.div`
  padding: 1rem 0;
  width: 100%;
  button: {
    padding-left: 0 !important;
  }
  .borderless-button {
    margin-left: -18px;
  }
`

export const StyledSignatureBox = styled.div`
  html body {
    max-width: 100% !important;
  }

  .signatureBox {
    img {
      width: auto;
      height: 0.5in;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      padding: 5px;
    }
  }
`

export const StyledAccordion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.light.DEFAULT};
  small {
    color: ${({ theme }) => theme.colors.dark[100]};
  }
`

export const AccordionHeader = styled.div<{ isFetching?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.light[200]};
  width: 100%;
  padding: 0.4rem 2rem;
  cursor: ${({ isFetching = false }) =>
    isFetching ? 'not-allowed' : 'pointer'};
  z-index: 1000;
  box-shadow: 0px 25px 17px -21px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 25px 17px -21px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 25px 17px -21px rgba(0, 0, 0, 0.07);
`

const move = keyframes`
  0%{
    transform: translateY(-40px);
  }
  100%{
    transform: translateY(0);
  }
`
interface Props {
  isAccordionOpen: boolean
}
export const StyledDocuments = styled.div<Props>`
  display: flex;
  width: 100%;
  transform: translateY(0);
  background-color: ${({ theme }) => theme.colors.light.DEFAULT};
  cursor: pointer;
  section {
    flex-basis: 1;
    flex-shrink: 1;
    flex-grow: 1;
    box-sizing: border-box;
  }
  animation: ${move} 0.6s cubic-bezier(0.25, 0, 0.01, 1) alternate;
`

const animate = keyframes`
  0% { 
    opacity: .8;
    width: 98%;
  }

  100% {  
    background-color: #EAECF0;
    width: 100%;
    opacity: 1;
  }`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  padding: 0.8rem 2rem;
  transition: width 0.5s;
  opacity: 0.8;
  :hover {
    animation: ${animate} 0.3s cubic-bezier(0.25, 0, 0.01, 1) forwards;
  }
`

export const Section = styled.section`
  width: 100%;
`

export const SubHeading = styled.p`
  padding: 0;
  margin: 0;
  /* color: blue !important; */
`
