/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react'
import { Icon } from '@pando-styles/nirvana'
import moment from 'moment'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import { Params } from '@atomic/Grid/machine'

import {
  DatePickerContainer,
  StyledButton,
  StyledButtonContainer,
} from './styles'

export interface DatePickerProps {
  handleSelectDate: (payload: Pick<Params, 'end' | 'start'>) => void
  initialStartDate?: string
  initialEndDate?: string
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { handleSelectDate, initialStartDate = '', initialEndDate = '' } = props

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [date, setDate] = useState([initialStartDate, initialEndDate])

  const handleDateChange = (value: any) => {
    if (!value) {
      setDate([initialStartDate, initialEndDate])

      handleSelectDate({
        start: '',
        end: '',
      })

      return
    }

    setDate(value)

    handleSelectDate({
      start: value[0] || '',
      end: value[1] || '',
    })
  }

  const handleCalendarClose = () => {
    setIsCalendarOpen(false)
  }

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true)
  }

  const handleClearDate = () => {
    handleDateChange(null)
  }

  useEffect(() => {
    setDate([initialStartDate, initialEndDate])
  }, [initialEndDate, initialStartDate])

  return (
    <DatePickerContainer className='DatePickerContainer'>
      {isCalendarOpen ? (
        <>
          {/* Didn't use Button from nirvana, have issues with pointer events */}
          <StyledButtonContainer className='StyledButtonContainer'>
            <StyledButton
              onClick={handleCalendarClose}
              className='date-btn-calendar calendar-open justify-start'
            >
              <span className='button-label'>
                {initialStartDate && initialEndDate
                  ? `${moment(initialStartDate).format('L')}-${moment(
                      initialEndDate
                    ).format('L')}`
                  : 'Date'}
              </span>
              <div className='button-icon-div icon-right'>
                <Icon
                  iconName={`${isCalendarOpen ? 'chevron-up' : 'chevron-down'}`}
                  size='sm'
                  clickable
                />
              </div>
            </StyledButton>
            {initialStartDate && initialEndDate && (
              <Icon
                iconName='x'
                size='xs'
                clickable
                hoverable
                onClick={handleClearDate}
                className='date-clear-x-btn'
              />
            )}
          </StyledButtonContainer>
          <DateRangePicker
            className='custom-date-picker'
            onChange={handleDateChange}
            value={date}
            onCalendarClose={handleCalendarClose}
            isOpen={isCalendarOpen}
          />
        </>
      ) : (
        <StyledButtonContainer className='StyledButtonContainer'>
          <StyledButton
            onClick={handleCalendarOpen}
            className='date-btn-calendar justify-start'
          >
            <span className='button-label'>
              {initialStartDate && initialEndDate
                ? `${moment(initialStartDate).format('L')}-${moment(
                    initialEndDate
                  ).format('L')}`
                : 'Date'}
            </span>
            <div className='button-icon-div icon-right'>
              <Icon
                iconName={`${isCalendarOpen ? 'chevron-up' : 'chevron-down'}`}
                size='sm'
                clickable
              />
            </div>
          </StyledButton>
          {initialStartDate && initialEndDate && (
            <Icon
              iconName='x'
              size='xs'
              clickable
              hoverable
              onClick={handleClearDate}
              className='date-clear-x-btn'
            />
          )}
        </StyledButtonContainer>
      )}
    </DatePickerContainer>
  )
}

export default DatePicker
