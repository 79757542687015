/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react'
import Bowser from 'bowser'
import LogRocket from 'logrocket'

import {
  StyledContainer,
  StyledHomeContainer,
  StyledHeadingThree,
  StyledFullHeightContainer,
  StyledParagraphAgreement,
} from '@styles/index'
import { ESign, Questions } from '@applications/index'
import { SetupSurveyCamera as TestCamera } from '@atomic/index'
import {
  BootloaderState,
  BootloaderActions,
  SitePermissionState,
} from '@system/Bootloader'
import { MainState, MainActions } from '@pages/index'

import { capitalize, createSurveyAttemptDeviceDetail } from '@utils/index'

import {
  ContinueConfirmation,
  Error,
  HomeStartSurvey,
  Loading,
  SurveyUnacceptable,
  RequiredComponent,
  MainTabs,
  TokenExpired,
  SurveyAlreadyCompleted,
  InvalidSurveyInstance,
  TokenRequestFailed,
} from './components'

const HomeConsumer: React.FC = () => {
  const { bootloaderState, hasTokenExpired } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)
  const { providerState } = useContext<any>(MainState)
  const { providerActions } = useContext(MainActions)
  const { sitePermissionState } = useContext(SitePermissionState)

  if (
    !bootloaderState ||
    !bootloaderActions ||
    !providerState ||
    !providerActions ||
    !sitePermissionState
  )
    return null

  const { handleContinueAttempt } = providerActions ?? {}
  const { selectedInstance, loggedInAsGuest } = providerState?.context ?? {}
  const {
    current,
    surveyName = '',
    surveyReferenceGuid,
  } = selectedInstance ?? {}
  const { accessToken, hasInvalidRefreshToken } = bootloaderState?.context ?? {}
  const { sitePermissions } = sitePermissionState?.context ?? {}
  const { handleClearBootloaderUrlParams } = bootloaderActions ?? {}

  const [platformViaInnerWidth, setPlatformViaInnerWidth] = useState('')
  const [platformType, setPlatformType] = useState('')

  const browser = Bowser.getParser(window.navigator.userAgent)
  const isAppleDevice = browser.getPlatform()?.vendor === 'Apple'

  const handleCreateSurveyAttempDeviceDetails = async () => {
    try {
      await createSurveyAttemptDeviceDetail(
        {
          surveyAttemptGuid: selectedInstance.current.guid,
          isLocationPermissionGranted: sitePermissions?.browserDeviceDetails
            ?.isLocationPermissionGranted
            ? sitePermissions?.browserDeviceDetails?.isLocationPermissionGranted
            : false,
        },
        accessToken
      )
    } catch (error) {
      console.error('Error [handleCreateSurveyAttempDeviceDetails]: ', error)
    }
  }

  useEffect(() => {
    if (
      providerState.matches(
        'ready.initialize_state.start_survey_questions.idle'
      )
    ) {
      handleCreateSurveyAttempDeviceDetails()
    }

    if (providerState.hasTag('survey_initiated')) {
      handleClearBootloaderUrlParams()
    }

    if (
      providerState.hasTag('attemp_not_started') &&
      !providerState.hasTag('refetch_instance')
    ) {
      handleContinueAttempt && handleContinueAttempt()
    }
  }, [providerState.value])

  useEffect(() => {
    if (window.innerWidth <= 768) return setPlatformViaInnerWidth('mobile')
    if (window.innerWidth <= 1024) return setPlatformViaInnerWidth('tablet')
    if (window.innerWidth > 1024) return setPlatformViaInnerWidth('desktop')
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) return setPlatformViaInnerWidth('mobile')
      if (window.innerWidth <= 1024) return setPlatformViaInnerWidth('tablet')
      if (window.innerWidth > 1024) return setPlatformViaInnerWidth('desktop')
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window])

  useEffect(() => {
    setPlatformType(browser.getPlatformType())
  }, [browser])

  useEffect(() => {
    if (selectedInstance && current && current.guid) {
      LogRocket.identify(
        `InstanceGuid:${selectedInstance?.guid}:AttemptGuid:${current?.guid}`,
        {
          ...(selectedInstance as any),
          name: capitalize(surveyName),
          email: selectedInstance?.contactInfo,
        }
      )
    }
  }, [selectedInstance])

  if (
    isAppleDevice &&
    platformViaInnerWidth === 'mobile' &&
    platformType === 'desktop'
  ) {
    return (
      <StyledFullHeightContainer
        flexDirection='column'
        className='padding--x-2 padding-y-2'
      >
        <div className='fixed-center w-90p'>
          <StyledHeadingThree className='margin--bottom-2'>
            {/* Default: 'Warning' is added whenever you haven't selected a survey from the home page */}
            {selectedInstance?.surveyName || 'Warning'}
          </StyledHeadingThree>
          <StyledParagraphAgreement className='text-red m-auto'>
            You are viewing the 'Desktop Version' of this website. Please switch
            it to the 'Mobile Version' by pressing on the 'AA' icon next to the
            address bar. This survey cannot be completed using the 'Desktop
            Version'.
          </StyledParagraphAgreement>
        </div>
      </StyledFullHeightContainer>
    )
  }

  if (bootloaderState.matches('max_token_request_attempts_reached')) {
    return <TokenRequestFailed />
  }

  if (
    hasTokenExpired &&
    !bootloaderState.matches('max_token_request_attempts_reached')
  ) {
    return <TokenExpired />
  }

  if (
    providerState.matches('ready.initialize_state.home.should_continue_attempt')
  ) {
    return <ContinueConfirmation />
  }

  if (providerState.matches('ready.initialize_state.survey_unacceptable')) {
    return <SurveyUnacceptable />
  }

  if (providerState.matches('error')) {
    return <Error />
  }

  if (providerState.hasTag('loader')) {
    return <Loading />
  }

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer className='styled-modal' id='MAIN CONSUMER'>
        {providerState.matches(
          'ready.initialize_state.home.survey_instances_list'
        ) && <MainTabs />}

        {providerState.matches('ready.initialize_state.test_camera') && (
          <TestCamera />
        )}

        {providerState.matches('invalid_instance_guid') && (
          <InvalidSurveyInstance
            title='Invalid Survey Instance'
            text="Can't claim an invalid survey instance. Please go back to the survey
          list instead."
          />
        )}
        {providerState.matches('invalid_claim_code') && (
          <InvalidSurveyInstance
            showTitle={false}
            title='Invalid Claim Code'
            text='This survey link is invalid. Please contact the organization that sent you this survey if more clarification is needed.'
          />
        )}
        {providerState.matches('instance_already_claimed') && (
          <InvalidSurveyInstance
            showTitle={false}
            title='Survey Already Claimed'
            text='This survey has already been claimed. Please contact the organization that sent you this survey if more clarification is needed.'
          />
        )}
        {providerState.matches('guest_not_allowed') && (
          <InvalidSurveyInstance
            showTitle={false}
            title="Survey Doesn't Allow Guest"
            text='Please login to access the survey.'
          />
        )}

        {providerState.matches('survey_already_completed') && (
          <InvalidSurveyInstance
            showTitle={false}
            title='Survey Already Completed'
            text='This survey has already been completed.'
          />
        )}

        {providerState.hasTag('survey_selected') && <HomeStartSurvey />}

        {providerState.matches(
          'ready.initialize_state.start_survey_questions.video_required_by_survey'
        ) && <RequiredComponent type='camera' />}

        {providerState.matches(
          'ready.initialize_state.start_survey_questions.audio_required_by_survey'
        ) && <RequiredComponent type='microphone' />}
        {providerState.matches(
          'ready.initialize_state.start_survey_questions'
        ) && (
          <Questions
            attemptGuid={selectedInstance?.current?.guid}
            surveyInstanceGuid={selectedInstance?.guid ?? ''}
          />
        )}

        {providerState.matches(
          'ready.initialize_state.start_esign_execution'
        ) && <ESign />}
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default HomeConsumer
