import 'regenerator-runtime/runtime'
import {
  SurveyConfigurationServicePromiseClient,
  setRequest,
  GetSurveyConfigurationsByInstanceGuidRequest,
} from '@utils/index'

type Params = Partial<GetSurveyConfigurationsByInstanceGuidRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getSurveyOrganizationConfiguration = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyConfigurationServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )
  const request = setRequest(
    new GetSurveyConfigurationsByInstanceGuidRequest(),
    params
  )
  const result = await service.getSurveyConfigurationsByInstanceGuid(request, {
    authorization: `Bearer ${accessToken}`,
  })
  return result
}
