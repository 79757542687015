/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { Button, Icon } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledHomeContainer,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'

import { StyledErrorParagraph } from '../styles'

import { MainActions } from '@pages/index'

import { StyledButtonDiv, StyledErrorText } from '../styles'

const SurveyUnacceptable: React.FC = () => {
  const { providerActions } = useContext(MainActions)

  if (!providerActions) return null

  const { handleNewAttempt } = providerActions ?? {}

  const handleRestartSurvey = () => {
    handleNewAttempt()
  }

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <Icon
                iconName='alert-circle'
                className='margin--bottom-2'
                size='xl'
                color='danger'
              />
              <StyledErrorParagraph className='padding--x-1-at-800'>
                This survey has been marked as incomplete. Please restart this
                survey or contact the organization that sent you this survey if
                more clarification is needed.
              </StyledErrorParagraph>
              <StyledButtonDiv className='margin--top-1'>
                <Button
                  hoverColorWeight='300'
                  label='Restart Survey'
                  onClick={() => handleRestartSurvey()}
                  className='font-14'
                />
              </StyledButtonDiv>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default SurveyUnacceptable
