/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon, Container } from '@pando-styles/nirvana'

import {
  StyledHeadingThree,
  StyledParagraph,
  StyledExitSpan,
  StyledExitContainer,
} from '@styles/index'

import { VideoResponseActions } from '../../VideoResponseProvider'

type ErrorProps = {
  text: string
}

const Error: React.FC<ErrorProps> = ({ text }) => {
  const { providerActions } = useContext(VideoResponseActions)

  const navigate = useHistory()

  if (!providerActions) return null

  return (
    <Container centerX direction='column'>
      <StyledExitContainer>
        <StyledExitSpan
          className='flex align-center'
          onClick={() => navigate.goBack()}
        >
          <Icon iconName='chevron-left' size='xl' />
          Back
        </StyledExitSpan>
      </StyledExitContainer>
      <Container direction='column' centerX size='xl'>
        <StyledHeadingThree className='margin--bottom-2'>
          Error!
        </StyledHeadingThree>

        <StyledParagraph className='padding--bottom-1'>{text}</StyledParagraph>
      </Container>
    </Container>
  )
}

export default Error
