/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  createMachine,
  State,
  InterpreterOptions,
  AnyEventObject,
  EventObject,
} from 'xstate'
import { useInterpret, useSelector } from '@xstate/react'

import { usePandoLogger } from '@pandolink/utils'

import { config, options, Context, ConfigParams } from './machine'
import { ExposedActions } from './types'
import { isAccessTokenExpired } from '@utils/index'
import moment from 'moment'

export * from './types'
export * from './machine'

interface MyMachineOptions extends InterpreterOptions {
  getServerSnapshot?: () => any
}

export const default_context: Context = {
  accessToken: null,
  expiresIn: null,
  idToken: null,
  issuedAt: null,
  refreshToken: null,
  scope: null,
  tokenType: null,
  isAuthenticated: false,
  authenticationAttempts: 0,
  maxAuthenticationAttempts: 3,
  allowAccountCreation: 'true',
  guestLoginButtonText: '',
  hasInvalidRefreshToken: false,
  claimedInstances: {},
}

export const spawn = <Config, Options>(config: Config, options: Options) =>
  // @ts-ignore
  createMachine({ ...config, context: { ...default_context } }, options)

export const useOIDC = (
  params?: ConfigParams
): [State<Context>, ExposedActions, boolean] => {
  const { redirectUriRoute = '/' } = params ?? {}

  console.log('@DEBUG [useOIDC]: Rendered.')

  const navigate = useHistory()
  const { search: useLocationSearch, pathname } = useLocation()

  const search = useLocationSearch

  const instanceGuid = new URLSearchParams(search)?.get('instance_guid')
  const signatoryGuid = new URLSearchParams(search)?.get('signatory_guid')
  const claimCode = new URLSearchParams(search)?.get('claim_code')
  const anonymousLogin = new URLSearchParams(search)?.get('allow_anonymous')
  const allowAccountCreation = new URLSearchParams(search)?.get(
    'allow_account_creation'
  )
  const guestLoginButtonText = new URLSearchParams(search)?.get(
    'guest_login_button_text'
  )

  const [hasTokenExpired, setHasTokenExpired] = useState<boolean>(false)

  const stateDefinition =
    typeof window !== 'undefined' ? localStorage.getItem('oidc') : undefined

  const noId = `idless-machine-${new Date().toLocaleTimeString()}`

  let anonLogin = false

  try {
    anonLogin = anonymousLogin
      ? JSON.parse(anonymousLogin.toLowerCase())
      : false
  } catch (error) {
    console.error('Error [allow_anonymous]:', error)
  }

  const machine = React.useMemo(() => spawn(config, options), [])

  const recordService = useInterpret(
    machine,
    {
      state: stateDefinition ? JSON.parse(stateDefinition) : undefined,
      context: {
        ...default_context,
        waitForUserAction: true,
        instanceGuid: instanceGuid ?? '',
        signatoryGuid: signatoryGuid ?? '',
        claimCode: claimCode ?? '',
        anonymousLogin: anonLogin,
        allowAccountCreation: allowAccountCreation ?? 'true',
        guestLoginButtonText: guestLoginButtonText ?? '',
      },
      actions: {
        ...options.actions,
        logger: (context: Context, event: AnyEventObject, { state }: any) =>
          usePandoLogger({
            name: (config?.id ?? noId).toUpperCase(),
            subTitle: event.type,
            body: { context, event, currentState: state.value },
          }),
      },
      getServerSnapshot: () => {},
    } as MyMachineOptions,

    //ENSURES THAT LOCAL STORAGE WILL BE EMPTY AFTER LOGGING OUT
    (state) => {
      if (!state) return

      const { isAuthenticated = false } = state?.context ?? {}

      if (state.matches('token_expired') && !hasTokenExpired) {
        setHasTokenExpired(true)
      }

      if (state.matches('authenticated') && hasTokenExpired) {
        setHasTokenExpired(false)
      }

      if (isAuthenticated && search.includes('code')) {
        navigate.push(redirectUriRoute)
      }

      if (!state.matches('log_out'))
        typeof window !== 'undefined' &&
          localStorage.setItem('oidc', JSON.stringify(state))
    }
  )

  const { send } = recordService

  const selectedState = (state: State<Context>) => state
  const compare = <T,>(prev: T, current: T) => prev === current
  const state = useSelector(recordService, selectedState, compare)

  const urlParams = [
    instanceGuid,
    signatoryGuid,
    claimCode,
    anonymousLogin,
    allowAccountCreation,
    guestLoginButtonText,
  ]
  const hasChangeInUrlParams = urlParams.some((params) => params)

  const oidcString = localStorage.getItem('oidc')
  const oidc = oidcString ? JSON.parse(oidcString) : {}
  const surveyMainString = localStorage.getItem('survey-main')
  const surveyMain = surveyMainString ? JSON.parse(surveyMainString) : {}

  useEffect(() => {
    if (hasChangeInUrlParams) {
      console.log(
        `@DEBUG [useOIDC]: Sending GOT_NEW_PARAMS event from OIDC hooks during hasChangeInUrlParams: instanceGuid:${instanceGuid}, signatoryGuid:${signatoryGuid}
        )}`
      )

      send({
        type: 'GOT_NEW_PARAMS',
        payload: {
          instanceGuid: instanceGuid ?? '',
          signatoryGuid: signatoryGuid ?? '',
          claimCode: claimCode ?? '',
          anonymousLogin: anonLogin,
          allowAccountCreation,
          guestLoginButtonText,
          waitForUserAction: true,
        },
      })

      const instanceAlreadyClaimed =
        oidc?.context?.claimedInstances?.[instanceGuid ?? '']

      const { accessToken = null, isAuthenticated = false } =
        oidc?.context ?? {}

      // Check if instanceGuid is present in claimedInstances and has a truthy value
      if (
        instanceGuid &&
        instanceAlreadyClaimed !== undefined &&
        instanceAlreadyClaimed !== null
      ) {
        if (instanceAlreadyClaimed && (!isAuthenticated ?? false)) {
          // If instance is already claimed and user is not authenticated, return early
          return
        }
      }

      if (!accessToken || accessToken === null) return

      const { issuedAt = 0, expiresIn = 3600 } = oidc?.context ?? {}

      // check if token is expired
      if (issuedAt && expiresIn && isAccessTokenExpired(issuedAt, expiresIn)) {
        console.log('@DEBUG [useOIDC]: Access Token expired.')

        send('GET_NEW_ACCESS_TOKEN_USING_REFRESH_TOKEN')
        navigate.push('/token')
        return
      } else {
        console.log(
          '@DEBUG [useOIDC]: Token NOT Expired, claiming instance',
          5555
        )

        send('CLAIM_INSTANCE')
      }
    }
  }, [hasChangeInUrlParams, search])

  useEffect(() => {
    const payload = {
      instanceGuid: instanceGuid ?? '',
      signatoryGuid: signatoryGuid ?? '',
      claimCode: claimCode ?? '',
      anonymousLogin: anonLogin,
      allowAccountCreation,
      guestLoginButtonText,
      waitForUserAction: true,
    }

    if (instanceGuid && claimCode) {
      send({
        type: 'GOT_NEW_PARAMS',
        payload,
      })
    }
  }, [search])

  useEffect(() => {
    if (search.includes('code')) return

    const {
      issuedAt = 0,
      expiresIn = 3600,
      accessToken = null,
      isAuthenticated = false,
    } = oidc?.context ?? {}

    console.log(
      '🔥 @DEBUG [useOIDC]: Token checker',
      JSON.stringify({
        isAccessTokenExpired:
          issuedAt && expiresIn && isAccessTokenExpired(issuedAt, expiresIn),
        oidcContext: {
          ...oidc?.context,
          idToken: 'for logs purposes only',
        },
        oidcContextIssuedAt: issuedAt,
        issuedAtFormatted: moment(issuedAt * 1000).format('LLL'),
      })
    )

    if (
      (accessToken === null || accessToken === undefined) &&
      !isAuthenticated
    ) {
      send('REAUTHORIZE')
      return
    }

    if (issuedAt && expiresIn && isAccessTokenExpired(issuedAt, expiresIn)) {
      console.log('@DEBUG [useOIDC]: Access Token expired.')
      send('GET_NEW_ACCESS_TOKEN_USING_REFRESH_TOKEN')
      navigate.push('/token')
      return
    }
  }, [search])

  const hasInvalidRefreshToken = oidc?.context?.hasInvalidRefreshToken ?? false
  const surveyReferenceGuid =
    surveyMain?.context?.selectedInstance?.surveyReferenceGuid ?? ''

  useEffect(() => {
    if (hasInvalidRefreshToken && state.matches('recreate_survey.idle')) {
      if (!surveyReferenceGuid) return

      if (oidc?.context?.loggedInAsGuest) {
        console.log('@DEBUG [useOIDC]: Logged in as GUEST')
        send({
          type: 'RECREATE_SURVEY_INSTANCE',
          payload: surveyReferenceGuid,
        })
        return
      } else {
        console.log('@DEBUG [useOIDC]: Logged in as USER')
        send('RELOGIN')
        return
      }
    }
  }, [hasInvalidRefreshToken, surveyReferenceGuid, state.value])

  console.log(
    `@DEBUG [useOIDC]: %c${JSON.stringify({
      state: state.value,
      event: state.event.type,
    })}`,
    'color: pink'
  )

  const exposedActions: ExposedActions = {
    handleKeepMeSignedIn: () => {
      send('KEEP_ME_SIGNED_IN')
    },
    handleLogout: () => {
      navigate.push('/')
      send('LOG_OUT')
    },
    handleLogin: () => {
      send('LOGIN_USER')
    },
    handleLoginAsGuest: () => {
      send('LOGIN_AS_GUEST')
    },
    handleTokenExpired: () => {
      console.log(
        '@DEBUG [useOIDC]: Sending Token expired (but not executing event TOKEN_EXPIRED)'
      )
      // Note: not using this one yet, so other events
      // that were calling TOKEN_EXPIRED are just ignored for now
      // token expiration is already being checked in this file
      // but don't delete this one yet.
      // send('TOKEN_EXPIRED')
    },
    handleReAuthorize: () => {
      send('REAUTHORIZE')
    },
    handleUpgradeGuestAccount: (payload) => {
      send({
        type: 'UPGRADE_GUEST_ACCOUNT',
        payload,
      })
    },
    handleClearBootloaderUrlParams: () => {
      send('CLEAR_URL_PARAMS')
    },
  }

  return [state, exposedActions, hasTokenExpired]
}
