/* eslint-disable arrow-body-style */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState, Dispatch, useContext } from 'react'

import { BootloaderState } from '@system/Bootloader'
import { QuestionsState } from '@applications/index'
import { MainState } from '@pages/index'

import Profile from './ProfileTemplate'

interface MediaLiveDetectProps {
  isCaptureVideoActive: boolean
}

const MediaLiveDetect: React.FC<MediaLiveDetectProps> = ({
  isCaptureVideoActive,
}) => {
  const { providerState } = useContext(QuestionsState)
  const { bootloaderState } = useContext(BootloaderState)
  const { providerState: homeProviderState } = useContext(MainState)

  const camPreviewRef = useRef<HTMLVideoElement>(null)

  const [hasHandleVideoStarted, setHasHandleVideoStarted] = useState(false)

  if (!bootloaderState || !providerState || !homeProviderState) return null

  let streamCloned: MediaStream | null

  useEffect(() => {
    // @ts-ignore
    if (window.stream && camPreviewRef && camPreviewRef.current) {
      // @ts-ignore
      streamCloned = window.stream.clone()
      camPreviewRef.current.srcObject = streamCloned
    }
    // @ts-ignore
  }, [window.stream, camPreviewRef])

  useEffect(() => {
    return () => {
      if (streamCloned) {
        streamCloned.getTracks().forEach((track) => track.stop())

        if (streamCloned.getTracks()?.length === 0) {
          streamCloned = null
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!isCaptureVideoActive) setHasHandleVideoStarted(false)
  }, [isCaptureVideoActive])

  return (
    <>
      <>
        <Profile />
        <video
          className='flip-x object-cover'
          ref={camPreviewRef}
          autoPlay
          playsInline
          muted
        />
      </>
    </>
  )
}

export default MediaLiveDetect
