import React from 'react'

import {
  StyledParagraph,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'

interface RequiredComponent {
  type: 'camera' | 'microphone'
}

const RequiredComponent: React.FC<RequiredComponent> = ({ type }) => (
  <StyledWrapperBody>
    <StyledWrapperBodyContainer>
      <StyledParagraph>
        Unable to proceed with survey. It seems the {type} permissions was
        changed. Please make sure it is allowed in the site settings.
      </StyledParagraph>
    </StyledWrapperBodyContainer>
  </StyledWrapperBody>
)

export default RequiredComponent
