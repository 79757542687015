import styled from 'styled-components'

export const StyledCamera = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31.438rem;
  max-width: 100%;
  height: 18.375rem;
  overflow: hidden;

  .camera-icon {
    transform: scale(2);
  }
  video {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
  }

  canvas {
    position: absolute;
    /* border: solid 2px red; */
    /* height: 200;
    width: 200; */
  }

  .face-detected {
    width: auto;
    height: 100%;
  }

  @media screen and (max-width: 400px) {
    height: 15rem;
  }
`

export const StyledProfile = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 200px;
    z-index: 9999;
  }

  @keyframes title-outline {
    from {
      stroke-dashoffset: 400;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`

export const StyledContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.6rem;
  color: ${({ theme }) => theme.colors.dark[50]};
`
