/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, useEffect, useState } from 'react'
import { usePandoLogger } from '@pandolink/utils'

import { ModalStateCam, ModalStateMic } from './types'

interface UseDeviceStreamProps {
  sitePermissions: any
  surveyConfiguration: any
  isAllowedRecording?: boolean
  isIPadChrome: boolean
  isAtInterruptedRecordingState: boolean
  setIsStreamUpdated: Dispatch<any>
  setVideoTrack: Dispatch<any>
  handleTryAgain: () => void
  openModalMic: () => void
  closeModalMic: () => void
  openModalCam: () => void
  closeModalCam: () => void
}

export const useDeviceStream = (props: UseDeviceStreamProps) => {
  const {
    sitePermissions,
    surveyConfiguration,
    isAllowedRecording,
    isIPadChrome,
    isAtInterruptedRecordingState,
    setIsStreamUpdated,
    setVideoTrack,
    handleTryAgain,
    openModalMic,
    openModalCam,
    closeModalCam,
    closeModalMic,
  } = props

  // states
  const [isPermissionLoading, setIsPermissionLoading] = useState(false)
  const [camPermissionAttempts, setCamPermissionAttempts] = useState(0)
  const [micPermissionAttempts, setMicPermissionAttempts] = useState(0)
  const [hasCamPermission, setHasCamPermission] = useState(false)
  const [hasMicPermission, setHasMicPermission] = useState(false)
  const [maxAttemptsReachedCam, setMaxAttemptsReachedCam] = useState(false)
  const [maxAttemptsReachedMic, setMaxAttemptsReachedMic] = useState(false)
  const [modalStateCam, setModalStateCam] = useState(ModalStateCam.LOADING)
  const [modalStateMic, setModalStateMic] = useState(ModalStateMic.LOADING)

  // timeout or intervals
  // let permissionTimeout: any

  const showCameraComponent = () => {
    if (sitePermissions?.camera || Boolean(isAllowedRecording)) {
      return true
    }

    if (!sitePermissions?.camera && !surveyConfiguration?.videoRequired) {
      return false
    }
    return false
  }

  let permissionTimeout: any

  const prioritizeAudioBeforeVideo = (stream: MediaStream) => {
    // Get all tracks from the original MediaStream
    const allTracks = stream.getTracks()

    // Separate audio and video tracks
    const audioTracks = allTracks.filter((track) => track.kind === 'audio')
    const videoTracks = allTracks.filter((track) => track.kind === 'video')

    // Create a new array with audio tracks followed by video tracks
    const reorderedTracks = [...videoTracks, ...audioTracks]

    // Create a new MediaStream and add the reordered tracks
    const newMediaStream = new MediaStream()
    reorderedTracks.forEach((track) => newMediaStream.addTrack(track))

    return newMediaStream
  }

  // Assign Stream
  const handleStreamReady = (stream: any) => {
    const reorderedStream = prioritizeAudioBeforeVideo(stream)

    setVideoTrack(reorderedStream.getVideoTracks()?.[0])

    // @ts-ignore
    window.stream = reorderedStream

    permissionTimeout = setTimeout(() => setIsPermissionLoading(false), 400)
  }

  // Get Camera Permission and Create a MediaStream with only a video track
  const getCameraPermission = async () => {
    try {
      let stream: MediaStream | null = null

      const shouldModifyVideoConstraints =
        sitePermissions?.camera ||
        (isIPadChrome && surveyConfiguration?.videoRequired) ||
        surveyConfiguration?.videoRequired

      setIsPermissionLoading(true)

      const streamConstraints: MediaStreamConstraints = {
        video: shouldModifyVideoConstraints
          ? // ? true
            {
              width: {
                exact: 320,
              },
              height: {
                exact: 240,
              },
            }
          : false,
        audio: false,
      }

      setCamPermissionAttempts((prev) => prev + 1)

      stream = await navigator.mediaDevices.getUserMedia(streamConstraints)

      if (stream) {
        usePandoLogger({
          name: 'Survey Media - New Stream',
          body: {
            stream,
            streamTracks: stream.getTracks(),
          },
          color: 'success',
        })

        setHasCamPermission(true)

        setModalStateCam(ModalStateCam.ALLOWED)
        if (isAtInterruptedRecordingState) {
          handleTryAgain()
        }
      }

      handleStreamReady(stream)
    } catch (error: any) {
      console.error('Error [getCamPermission]:', error.message)

      if (
        error?.message?.toString()?.includes('denied') ||
        error?.message?.toString()?.includes('dismissed')
      ) {
        setHasCamPermission(false)
        setModalStateCam(ModalStateCam.DENIED)
        permissionTimeout = setTimeout(() => setIsPermissionLoading(false), 400)
        return
      }
    }
  }

  // Get Mic Permission and add the new stream's audio track to the previous/running stream
  const getMicPermission = async () => {
    try {
      setIsPermissionLoading(true)
      // @ts-ignore
      if (window.stream) {
        // @ts-ignore
        const hasAudioTrack = window.stream?.getAudioTracks()?.length

        if (!hasAudioTrack) {
          try {
            // @ts-ignore
            setMicPermissionAttempts((prev) => prev + 1)
            const micStream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            })
            if (micStream && micStream.getAudioTracks()[0]) {
              // @ts-ignore
              window.stream.addTrack(micStream.getAudioTracks()[0])

              usePandoLogger({
                name: 'Microphone',
                body: 'Audio track added.',
                color: 'light',
              })
            }

            setHasMicPermission(true)

            micStream && setModalStateMic(ModalStateMic.ALLOWED)

            permissionTimeout = setTimeout(
              () => setIsPermissionLoading(false),
              400
            )
          } catch (error: any) {
            console.error('Error [getMicPermission]:', error.message)

            if (
              error?.message?.toString()?.includes('denied') ||
              error?.message?.toString()?.includes('dismissed')
            ) {
              setHasMicPermission(false)
              setModalStateMic(ModalStateMic.DENIED)
              permissionTimeout = setTimeout(
                () => setIsPermissionLoading(false),
                400
              )
              return
            }
          }
        } else {
          usePandoLogger({
            name: 'Microphone',
            body: 'Audio track already added.',
            color: 'light',
          })
        }

        setIsStreamUpdated(true)
      }

      const isAudioRequiredOnly =
        // @ts-ignore
        Boolean(!window.stream) &&
        surveyConfiguration?.audioRequired &&
        !surveyConfiguration?.videoRequired
      if (isAudioRequiredOnly) {
        try {
          setMicPermissionAttempts((prev) => prev + 1)

          const micStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          })

          if (micStream && micStream.getAudioTracks()[0]) {
            // @ts-ignore
            window.stream = micStream

            usePandoLogger({
              name: 'Microphone',
              body: 'Audio track added.',
              color: 'light',
            })
          }

          setHasMicPermission(true)

          micStream && setModalStateMic(ModalStateMic.ALLOWED)

          permissionTimeout = setTimeout(
            () => setIsPermissionLoading(false),
            400
          )
          handleStreamReady(micStream)
        } catch (error: any) {
          console.error('Error [getMicPermission]:', error.message)

          if (
            error?.message?.toString()?.includes('denied') ||
            error?.message?.toString()?.includes('dismissed')
          ) {
            setHasMicPermission(false)
            setModalStateMic(ModalStateMic.DENIED)
            permissionTimeout = setTimeout(
              () => setIsPermissionLoading(false),
              400
            )
            return
          }
        }
        setIsStreamUpdated(true)
      }
    } catch (e: any) {
      usePandoLogger({
        name: 'getMicPermission',
        body: e.message,
        color: 'danger',
      })
    }
  }

  // useEffects

  // Initialize Stream and Recorder
  useEffect(() => {
    if (surveyConfiguration?.videoRequired || Boolean(isAllowedRecording)) {
      getCameraPermission()
      // @ts-ignore
      console.log('Mounted: stream:', window.stream)
    }

    return () => {
      // @ts-ignore
      if (window.stream) {
        // @ts-ignore
        window.stream.getTracks().forEach((track) => track.stop())

        // @ts-ignore
        if (window.stream.getTracks()?.length === 0) {
          // @ts-ignore
          window.stream = null
        }
      }
    }
  }, [])

  // during survey execution - check for mic permission and set attempts
  useEffect(() => {
    if (micPermissionAttempts > 3) {
      setMaxAttemptsReachedMic(true)
    }
  }, [micPermissionAttempts])

  // during survey execution - check for cam permission and set attempts
  useEffect(() => {
    if (camPermissionAttempts > 3) {
      setMaxAttemptsReachedCam(true)
    }
  }, [camPermissionAttempts])

  // during survey execution - check for cam permission
  useEffect(() => {
    if (!hasCamPermission) {
      openModalCam()
    } else {
      closeModalCam()
    }
  }, [hasCamPermission])

  // during survey execution - check for mic permission
  useEffect(() => {
    if (!hasMicPermission) {
      openModalMic()
    } else {
      closeModalMic()
    }
  }, [hasMicPermission])

  return {
    modalStateMic,
    modalStateCam,
    isPermissionLoading,
    hasCamPermission,
    hasMicPermission,
    maxAttemptsReachedCam,
    maxAttemptsReachedMic,
    camPermissionAttempts,
    micPermissionAttempts,
    showCameraComponent,
    getCameraPermission,
    getMicPermission,
    clearPermissionTimeout: () => clearTimeout(permissionTimeout),
  }
}
