/* eslint-disable no-empty */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import { Sender } from 'xstate'

import { Context } from '../types'

export const services: any = {
  requestUserPermission:
    ({ surveyConfiguration }: Context) =>
    async (send: Sender<any>) => {
      const audioAndVideoRequired =
        surveyConfiguration?.videoRequired && surveyConfiguration?.audioRequired
      const audioAndVideoNotRequired =
        !surveyConfiguration?.videoRequired &&
        !surveyConfiguration?.audioRequired

      const constraints = audioAndVideoNotRequired
        ? {
            video: true,
            audio: true,
          }
        : {
            video: surveyConfiguration?.videoRequired ?? true,
            audio: surveyConfiguration?.audioRequired ?? true,
          }

      let stream

      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints)

        // @ts-ignore
        window.stream = stream

        // @ts-ignore
        window.stream.customID = 'usePermissionStream'

        if (stream && stream?.active) {
          send({
            type: 'ALLOW',
            payload: audioAndVideoRequired
              ? 'camera_and_microphone'
              : audioAndVideoNotRequired
              ? 'camera_and_microphone'
              : surveyConfiguration?.videoRequired
              ? 'camera'
              : 'microphone',
          })
        }
      } catch (error: any) {
        if (error?.toString()?.includes('dismissed')) {
          send('POPUP_DISMISSED')

          return
        }

        if (audioAndVideoNotRequired) {
          send({
            type: 'BLOCK_ACCESS',
            payload: 'camera_and_microphone',
          })

          return
        }

        if (error?.toString()?.includes('denied')) {
          send({
            type: 'BLOCK_ACCESS',
            payload: audioAndVideoRequired
              ? 'camera_and_microphone'
              : audioAndVideoNotRequired
              ? 'camera_and_microphone'
              : surveyConfiguration?.videoRequired
              ? 'camera'
              : 'microphone',
          })

          return
        }

        console.error('Error [requestUserPermission]:', error)

        send('NO_DEVICE_FOUND')
      }
    },
  requestLocationPermission:
    (context: Context) => async (send: Sender<any>) => {
      function getPosition(): Promise<any> {
        return new Promise((resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject)
        )
      }

      try {
        const position = await getPosition()
        return send({
          type: 'LOCATION_PERMISSION',
          payload: {
            status: 'Allowed',
            coordinates: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          },
        })
      } catch (err) {
        return send({
          type: 'LOCATION_PERMISSION',
          payload: {
            status: 'Denied',
          },
        })
      }
    },
}
