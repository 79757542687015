/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { MachineOptions } from 'xstate'

import { CompletionType } from '@utils/index'

import { Context, MachineEvents } from '../types'
import { actions } from './actions'
import { services } from './services'

const { REACT_APP_IOS_DEBUGGGING = false } = process.env

export const options: MachineOptions<Context, MachineEvents> = {
  actions,
  services,
  delays: {},
  guards: {
    isAttemptCompleted: ({ selectedInstance }) => {
      const { current } = selectedInstance ?? {}

      if (
        current?.completionType === CompletionType.ACCEPTABLE ||
        current?.completionType === CompletionType.UNACCEPTABLE
      ) {
        return true
      } else {
        return false
      }
    },

    isVideoRequiredBySurveyConfig: ({ surveyConfiguration }) => {
      const { videoRequired = true } = surveyConfiguration ?? {}

      return !!videoRequired
    },

    isAudioRequiredBySurveyConfig: ({ surveyConfiguration }) => {
      const { audioRequired = true } = surveyConfiguration ?? {}

      return !!audioRequired
    },

    isCameraAllowedInSiteSettings: ({ sitePermissions }) => {
      const { camera = false } = sitePermissions ?? {}

      return camera
    },

    isMicrophoneAllowedInSiteSettings: ({ sitePermissions }) => {
      const { microphone = false } = sitePermissions ?? {}

      return microphone
    },

    isAtCameraRequiredState: (__, _, { state }) =>
      state.matches(
        'ready.initialize_state.setup_tools.setup_camera.video_required_by_survey'
      ) ||
      state.matches(
        'ready.initialize_state.setup_tools.setup_camera.allow_camera_permission'
      ),

    isAtMicrophoneRequiredState: (__, _, { state }) =>
      state.matches(
        'ready.initialize_state.setup_tools.setup_microphone.audio_required_by_survey'
      ) ||
      state.matches(
        'ready.initialize_state.setup_tools.setup_microphone.allow_microphone_permission'
      ),

    isAtSetupMicrophoneState: (__, _, { state }) =>
      state.matches('ready.initialize_state.setup_tools.setup_microphone'),

    isAtCameraRequiredStateAndMicIsAllowed: (
      { sitePermissions },
      _,
      { state }
    ) => {
      const { microphone = false } = sitePermissions ?? {}
      if (
        state.matches(
          'ready.initialize_state.start_survey_questions.video_required_by_survey'
        ) &&
        !!microphone
      ) {
        return true
      } else if (!microphone) {
        return false
      } else {
        return false
      }
    },

    isAtMicRequiredStateAndCamIsAllowed: (
      { sitePermissions },
      _,
      { state }
    ) => {
      const { camera = false } = sitePermissions ?? {}
      if (
        state.matches(
          'ready.initialize_state.start_survey_questions.audio_required_by_survey'
        ) &&
        !!camera
      ) {
        return true
      } else if (!camera) {
        return false
      } else {
        return false
      }
    },

    alreadySetSitePermissions: ({ sitePermissions }) =>
      Boolean(sitePermissions),
    hasInstanceGuidAndClaimCode: ({ instanceGuid, claimCode }) =>
      Boolean(instanceGuid) && Boolean(claimCode),
    hasSignatoryGuidAndClaimCode: ({ signatoryGuid, claimCode }) =>
      Boolean(signatoryGuid) && Boolean(claimCode),
    hasClaimCode: ({ claimCode }) => Boolean(claimCode),

    vidAndMicNotRequiredAndPermissionsNotSet: ({
      sitePermissions,
      surveyConfiguration,
    }) => {
      const { camera = false, microphone = false } = sitePermissions ?? {}
      const { videoRequired = false, audioRequired = false } =
        surveyConfiguration ?? {}

      const hasNoSitePermissions = !sitePermissions || (!camera && !microphone)

      const surveyVideoAndAudioNotRequired = !videoRequired && !audioRequired

      return surveyVideoAndAudioNotRequired && hasNoSitePermissions
    },
    isVideoNotRequired: ({ surveyConfiguration }) => {
      const { videoRequired = false } = surveyConfiguration ?? {}
      return !videoRequired
    },
    isCameraNotAllowed: ({ sitePermissions, surveyConfiguration }) => {
      const { camera = false, microphone = false } = sitePermissions ?? {}

      return !camera
    },
    isIOSDebuggingEnabled: () => Boolean(REACT_APP_IOS_DEBUGGGING),

    isVideoAudioNotRequired: ({ surveyConfiguration }) =>
      Boolean(
        !surveyConfiguration?.audioRequired &&
          !surveyConfiguration?.videoRequired
      ),
    isAllowedRecording: ({ sitePermissions, surveyConfiguration }) =>
      Boolean(
        !surveyConfiguration?.audioRequired &&
          !surveyConfiguration?.videoRequired
      ) && Boolean(sitePermissions?.camera && sitePermissions.microphone),
    isGuestLogin: ({ loggedInAsGuest = false }) => {
      return loggedInAsGuest
    },
    isStoredInstanceSameWithNewInstanceGuid: (
      { instanceGuid: storedInstanceGuid },
      { payload }
    ) => {
      const { instanceGuid: payloadInstanceGuid } = payload
      console.log('@DEBUG [Survey Main] check instance similarity:', {
        payload,
        payloadInstanceGuid,
        storedInstanceGuid,
        isSame: storedInstanceGuid === payloadInstanceGuid,
      })
      return storedInstanceGuid === payloadInstanceGuid
    },
  },
}
