/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, PropsWithChildren, memo, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { useOIDC } from '@utils/hooks/useOIDC'
import { usePermission } from '@utils/index'

import {
  BootloaderStateType,
  BootloaderActionType,
  SitePermissionActionType,
  SitePermissionStateType,
} from './types'

export const BootloaderState = createContext<Partial<BootloaderStateType>>({})
export const BootloaderActions = createContext<Partial<BootloaderActionType>>(
  {}
)

export const SitePermissionState = createContext<
  Partial<SitePermissionStateType>
>({})
export const SitePermissionActions = createContext<
  Partial<SitePermissionActionType>
>({})

type PropsWithChildrenOnly = PropsWithChildren<unknown>

const Bootloader: React.FC<PropsWithChildrenOnly> = ({ children }) => {
  if (typeof window === 'undefined') return null

  const navigate = useHistory()
  const { pathname } = useLocation()

  console.log('@DEBUG [Bootloader Provider]: We are still rendered')

  const [state, actions, hasTokenExpired] = useOIDC({
    redirectUriRoute: '/home',
  })

  const permissionMachine = React.useMemo(
    () => ({
      loggedOut: Boolean(state?.matches('logout.logout_success')),
    }),
    []
  )

  const [permissionState, permissionActions, Component] =
    usePermission(permissionMachine)

  if (!state) return null

  useEffect(() => {
    if (state.matches('authenticated.idle')) {
      navigate.push('/')
    }

    if (state.matches('error')) {
      navigate.push('/')
    }
  }, [state.value])

  return (
    <BootloaderState.Provider
      value={{
        bootloaderState: state ?? {},
        hasTokenExpired,
      }}
    >
      <BootloaderActions.Provider value={{ bootloaderActions: actions }}>
        <SitePermissionState.Provider
          value={{
            sitePermissionState: permissionState,
            PermissionComponent: Component,
            PrivacyPolicyComponent: Component,
          }}
        >
          <SitePermissionActions.Provider
            value={{ sitePermissionActions: permissionActions }}
          >
            {children}
          </SitePermissionActions.Provider>
        </SitePermissionState.Provider>
      </BootloaderActions.Provider>
    </BootloaderState.Provider>
  )
}

export default memo(Bootloader)
