/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MachineOptions } from 'xstate'

import { CompletionType, SelectionType } from '@utils/index'

import { Context, MachineEvents } from '../types'
import { actions } from './actions'
import { services } from './services'
import Bowser from 'bowser'

const { REACT_APP_MAX_REUPLOAD_ATTEMPT = 5 } = process.env

export const browser = Bowser.getParser(window.navigator.userAgent)

export const options: MachineOptions<Context, MachineEvents> = {
  actions,
  services,
  delays: {},
  guards: {
    requiresVoiceRecognition: (_, { data }: any) => {
      const { noAnswerWaitTimeout = 0 } = (data?.question || _?.question) ?? {}
      return !!noAnswerWaitTimeout
    },
    isSameWithVoiceAnswer: ({ selectedAnswer }, { payload }) => {
      const { guid: voiceGuid } = selectedAnswer ?? {}

      const { guid: buttonClickGuid } = payload ?? {}

      return voiceGuid === buttonClickGuid
    },
    isLastQuestion: ({ question }) => {
      const { currentQuestion, totalQuestions } = question ?? {}

      return currentQuestion === totalQuestions
    },
    isSurveyAcceptable: (_, { data }: any) => {
      const { completionType = 1 } = data ?? {}
      return completionType === CompletionType.ACCEPTABLE
    },
    isAlreadyCompleted: (_, { data }: any) => {
      const { code = 9 } = data ?? {}

      return code === 9
    },
    hasOnlyOneAnswerOption: ({ question }) => {
      const { answerOptionsList = [] } = question ?? {}

      return answerOptionsList?.length === 1
    },
    isMultiChoiceQuestion: ({ question }) => {
      const { selectionType } = question ?? {}

      return Boolean(selectionType === SelectionType.MULTI_ANSWER)
    },
    voiceRecognitionDisabledThruEnv: () => {
      return false
    },
    hasReachedMaxReuploadAttempt: ({ reuploadAttempt = 0 }) =>
      reuploadAttempt > Number(REACT_APP_MAX_REUPLOAD_ATTEMPT) ?? 5,

    disableVideoRecorderAndSpeechRecognition: ({ sitePermissions }) => {
      return (
        Boolean(sitePermissions?.camera) && Boolean(sitePermissions?.microphone)
      )
    },
    disableVideoRecorder: ({ sitePermissions }) => {
      return Boolean(!sitePermissions?.camera)
    },
    disableSpeechRecognition: ({ sitePermissions, surveyConfiguration }) => {
      return (
        Boolean(!sitePermissions?.microphone) &&
        Boolean(!surveyConfiguration?.audioRequired)
      )
    },
    isVideoOnlyRequired: ({ sitePermissions, surveyConfiguration }) => {
      return (
        Boolean(sitePermissions?.camera) &&
        Boolean(surveyConfiguration?.videoRequired)
      )
    },
    isMicOnlyRequired: ({ sitePermissions, surveyConfiguration }) => {
      return (
        Boolean(sitePermissions?.microphone) &&
        Boolean(surveyConfiguration?.audioRequired)
      )
    },
    isLastQuestionAndVideoRecorderAndSpeechDisabled: ({
      question,
      sitePermissions,
      surveyConfiguration,
    }) => {
      const { currentQuestion, totalQuestions } = question ?? {}

      return (
        currentQuestion === totalQuestions &&
        Boolean(!sitePermissions?.microphone) &&
        Boolean(!surveyConfiguration?.audioRequired) &&
        Boolean(!sitePermissions?.camera) &&
        Boolean(!surveyConfiguration?.videoRequired)
      )
    },
    hasQuestionData: ({ question }) => {
      return Object.keys(question ?? {}).length === 0 ? false : true
    },
    hasSavedAnswer: ({ selectedAnswer }) => {
      return Object.keys(selectedAnswer ?? {}).length === 0 ? false : true
    },
    hasQuestionInterrupted: ({
      isProcessingSaveAnswer,
      isProcessingMediaUpload,
    }) => {
      return Boolean(isProcessingSaveAnswer) || Boolean(isProcessingMediaUpload)
    },
    isQuestionInterruptedRequiresVoiceRecognition: (_, { data }: any) => {
      const { noAnswerWaitTimeout = 0 } = (data?.question || _?.question) ?? {}

      return (
        Boolean(noAnswerWaitTimeout) &&
        (Boolean(_?.isProcessingSaveAnswer) ||
          Boolean(_?.isProcessingMediaUpload))
      )
    },
    hasMediaUploaded: ({ isMediaUploaded }) => {
      return !!isMediaUploaded
    },

    isMultipleAnswer: ({ isMultipleAnswer }) => {
      return Boolean(isMultipleAnswer)
    },
    hasAlreadySavedAnswer: ({
      createdAnswer: createdAnswerObj,
      savedAnswer,
      isProcessingSaveAnswer,
      isQuestionAlreadyAnswered,
      isProcessingMediaUpload,
    }) => {
      const isCreatedAnswerObjEmpty =
        Object.keys(createdAnswerObj ?? {}).length <= 0
      const skipSavedAnswerDelete =
        Object.keys(savedAnswer ?? {}).length > 0 &&
        savedAnswer?.answerAction === 1

      const shouldCallSavedAnswer =
        !isCreatedAnswerObjEmpty &&
        (isQuestionAlreadyAnswered ||
          isProcessingSaveAnswer ||
          isProcessingMediaUpload)

      return Boolean(shouldCallSavedAnswer) && Boolean(!skipSavedAnswerDelete)
    },
    hasMediaRecordingInterrupted: ({ hasMediaRecordingInterrupted }) => {
      return Boolean(hasMediaRecordingInterrupted)
    },
    isAllowedRecording: ({ sitePermissions, surveyConfiguration }) => {
      return (
        Boolean(
          !surveyConfiguration?.audioRequired &&
            !surveyConfiguration?.videoRequired
        ) && Boolean(sitePermissions?.camera && sitePermissions.microphone)
      )
    },
  },
}
