import 'regenerator-runtime/runtime'
import {
  SurveyInstanceExecutionServicePromiseClient,
  CompleteSurveyRequest,
  setRequest,
} from '@utils/index'

type Params = CompleteSurveyRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const execCompleteSurvey = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const request = setRequest(new CompleteSurveyRequest(), params)

  const result = await service.execCompleteSurvey(request, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
