import React from 'react'
import { StyledContainer, StyledHeadingThree } from '@styles/index'

interface IErrorProps {
  errorMessage: string
}

const Error: React.FC<IErrorProps> = ({ errorMessage = '' }) => (
  <StyledContainer>
    <StyledHeadingThree style={{ padding: '5rem' }}>
      {errorMessage}
    </StyledHeadingThree>
  </StyledContainer>
)

export default Error
