/* eslint-disable indent */
import styled from 'styled-components'

export const StyledGridContainer = styled.div<{
  hasItemsMoreThanThree?: boolean
}>`
  padding: 20px 0;
  height: 100%;
  position: relative;

  .table-container .tbody {
    overflow: unset !important;
  }

  .tableMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 86%;
    align-items: center;
  }

  .tableMainContainer > div:first-child {
    overflow-y: scroll;
  }

  .defaultTablePagination {
    margin: 0 0 15px;
    padding-bottom: ${({ hasItemsMoreThanThree = false }) =>
      hasItemsMoreThanThree ? '20px' : '0'};

    /* @media screen and (max-height: 900px) {
      & {
        padding-bottom: 20px;
      }
    } */
    /* position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 500px) {
      & {
        transform: translateX(-50%) scale(0.75);
      }
    } */
  }

  @media screen and (max-width: 600px) {
    .date-filter-text {
      font-size: 0.7rem !important;
    }
  }
`

export const StyledGridHeader = styled.div<{ showDatePicker?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 99999;

  .grid-search-input {
    width: 100%;
  }

  .sort-btn {
    border-color: #2c969b;
    color: #2c969b;
    width: 100%;
    justify-content: flex-start;

    svg {
      stroke: #2c969b;
    }

    span {
      padding: 0;
      padding-left: 10px;
    }

    &:hover {
      color: #2c969b;
      background: #fff;
      border-color: #2c969b;

      svg {
        stroke: #2c969b;
      }
    }
  }

  @media screen and (max-width: 500px) {
    & {
      flex-direction: ${({ showDatePicker }) =>
        showDatePicker ? 'column' : 'row'};
    }
  }
`

export const GridToolbar = styled.div<{ showDatePicker: boolean }>`
  display: flex;
  width: ${({ showDatePicker }) => (showDatePicker ? 'auto' : '100px')};
  align-items: center;
  gap: 5px;
  margin: 0;

  .DatePickerContainer {
    width: 185px;
  }

  .sort-btn {
    width: 100px;
  }

  @media screen and (max-width: 500px) {
    & {
      width: ${({ showDatePicker }) => (showDatePicker ? '100%' : '100px')};
    }

    .DatePickerContainer {
      width: 100%;
    }

    .sort-btn {
      span {
        padding: 0;
      }
    }
  }

  /* @media screen and (max-width: 450px) {
    > div {
      width: 65%;
    }
  } */
`

export const SearchBar = styled.div<{ showDatePicker: boolean }>`
  display: flex;
  width: calc(
    100% - ${({ showDatePicker }) => (showDatePicker ? '0%' : '100px')}
  );
  align-items: center;
  margin: 0;
  position: relative;

  > div {
    margin: 0;
  }

  .grid-search-input {
    padding-right: 50px;
  }

  .grid-search-loader {
    position: absolute;
    right: 10px;
    transform: scale(0.8);
  }

  @media screen and (max-width: 600px) {
  }

  /* @media screen and (max-width: 450px) {
    & {
      width: 40%;
    }
  } */
`
