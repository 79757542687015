import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledTabLabel = styled.p`
  /* margin-right: 2rem; */
  color: #747474;
  list-style: none;
  text-decoration: none;
  font-weight: 700;
  &.active {
    color: #2c969b;
    font-weight: 600;
  }
  font-weight: 400;
`

export const StyledTabs = styled.div`
  position: relative;
  padding-top: 1.563rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 0.8rem;
`

export const StyledTabItem = styled(Link)`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
`
