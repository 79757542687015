/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container } from '@pando-styles/nirvana'

import { BootloaderActions } from '@system/Bootloader'

import { StyledNavLink, StyledTabs } from './styles'
import { tabItems } from './tab-items'
import {
  MainTabHome,
  MainTabSurvey,
  MainTabDocument,
} from '@applications/index'

const Surveys: React.FC = () => {
  const { bootloaderActions } = useContext(BootloaderActions)
  if (!bootloaderActions) return null
  const { handleLogout } = bootloaderActions
  const { config, routes } = tabItems

  return (
    <Container className='surveys'>
      <StyledTabs className='styled-tabs'>
        {config.is_enabled && (
          <>
            <StyledNavLink to={`/home`} className='styled-nav-link'>
              Home
            </StyledNavLink>
            {routes.map(({ tab_name, route_name, application_id }) => (
              <StyledNavLink to={`/${route_name}`} key={application_id}>
                {tab_name}
              </StyledNavLink>
            ))}
            <StyledNavLink
              to=''
              className='styled-nav-link logout'
              onClick={handleLogout}
            >
              Logout
            </StyledNavLink>
          </>
        )}
      </StyledTabs>
      <Switch>
        <Route path={`/home`}>
          <MainTabHome />
        </Route>
        <Route path={`/list`}>
          <MainTabSurvey />
        </Route>
        <Route path={`/documents`}>
          <MainTabDocument />
        </Route>
        <Route path={`/`}>
          <Redirect to='/home' />
        </Route>
      </Switch>
    </Container>
  )
}

export default Surveys
