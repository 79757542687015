import { MachineConfig } from 'xstate'

import { Context, StateSchema, MachineEvents } from './types'

export const config: MachineConfig<Context, StateSchema, MachineEvents> = {
  id: 'video-response',
  initial: 'check_existing_data',
  on: {
    GOT_NEW_ACCESS_TOKEN: {
      actions: ['updateAccessToken'],
      target: '#check_existing_data',
    },
    GOT_NEW_URL_SURVEY_GUID: [
      {
        cond: 'isNewGuid',
        actions: ['updateUrlParamsGuid'],
        target: '#loading',
      },
    ],
    SESSION_EXPIRED: {
      actions: [],
      target: '#session_expired',
    },
  },
  states: {
    check_existing_data: {
      id: 'check_existing_data',
      always: [
        {
          cond: 'hasExistingData',
          target: '#ready',
        },
        {
          target: '#loading',
        },
      ],
    },
    loading: {
      id: 'loading',
      initial: 'get_instance_attempt',
      states: {
        get_instance_attempt: {
          id: 'get_instance_attempt',
          invoke: {
            id: 'get-instance-attempt',
            src: 'getInstanceAttempt',
          },
          on: {
            GOT_INSTANCE: {
              actions: ['assignInstance'],
              target: '#get_attempt_questions',
            },
            GET_INSTANCE_FAILED: {
              target: '#invalid_survey_attempt',
            },
          },
        },
        get_attempt_questions: {
          id: 'get_attempt_questions',
          invoke: {
            id: 'get-attempt-questions',
            src: 'getAttemptQuestions',
          },
          on: {
            GOT_ATTEMPT_QUESTIONS: {
              actions: ['assignAttemptQuestions'],
              target: '#get_question_with_answers',
            },
            GET_ATTEMPT_QUESTIONS_FAILED: {
              target: '#error',
              actions: 'updateErrorMessage',
            },
          },
        },
        get_question_with_answers: {
          id: 'get_question_with_answers',
          invoke: {
            id: 'get-question-with-answers',
            src: 'getQuestionWithAnswers',
          },
          on: {
            GOT_QUESTION_WITH_ANSWER: {
              actions: ['assignQuestionsWithAnswers'],
              target: '#ready',
            },
            GET_QUESTION_WITH_ANSWER_FAILED: {
              target: '#error',
            },
          },
        },
      },
    },
    ready: {
      id: 'ready',
      initial: 'questions_loaded',
      states: {
        questions_loaded: {
          id: 'questions_loaded',
          on: {
            VIEW_QUESTION: {
              actions: ['assignSelectedQuestion'],
              target: '#view_question',
            },
          },
        },
        view_question: {
          id: 'view_question',
          on: {
            GO_BACK_TO_QUESTIONS_LIST: {
              target: '#questions_loaded',
            },
            VIEW_QUESTION: {
              actions: ['assignSelectedQuestion'],
              target: '#view_question',
            },
          },
        },
      },
    },
    invalid_survey_attempt: {
      id: 'invalid_survey_attempt',
    },
    error: {
      id: 'error',
      on: {
        RELOAD: {
          target: '#loading',
        },
      },
    },
    session_expired: {
      id: 'session_expired',
    },
  },
}
