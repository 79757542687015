/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense, useContext, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { RaySpinner } from '@pando-styles/nirvana'

import { StyledFullHeightContainer } from '@styles/index'
import { BootloaderState, BootloaderActions } from './BootloaderProvider'

import Loader from './Loader'
import Error from './Error'

const Main = lazy(() => import('@pages/Main'))
const LandingPage = lazy(() => import('@pages/LandingPage'))
const InvalidRefreshToken = lazy(
  () => import('@pages/Main/components/InvalidRefreshToken')
)

const BootloaderConsumer: React.FC = () => {
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (!bootloaderState || !bootloaderActions) return null

  const {
    isAuthenticated,
    hasInvalidRefreshToken,
    errorMessage = '',
  } = bootloaderState?.context ?? {}

  const showLandingPage = bootloaderState.matches?.('wait_for_user_interaction')

  const getRouteComponents = () => {
    if (
      hasInvalidRefreshToken &&
      bootloaderState.matches('show_security_error_sending_new_link_state')
    ) {
      return <InvalidRefreshToken />
    }

    if (bootloaderState.matches('error')) {
      return <Error errorMessage={errorMessage} />
    }
    if (bootloaderState.matches('claim_instance')) {
      return <Loader />
    }

    if (isAuthenticated) {
      return <Main />
    } else if (showLandingPage) {
      return <LandingPage />
    } else {
      return <Loader />
    }
  }

  return (
    <Switch>
      <Suspense
        fallback={
          <StyledFullHeightContainer>
            <RaySpinner color='primary' colorWeight='DEFAULT' />
          </StyledFullHeightContainer>
        }
      >
        <Route path='*'>{getRouteComponents()}</Route>
      </Suspense>
    </Switch>
  )
}

export default BootloaderConsumer
