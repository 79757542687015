/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MachineOptions } from 'xstate'

import { SignatureType, ESignStatus } from '@utils/index'

import { Context, MachineEvents } from '../types'
import { actions } from './actions'
import { services } from './services'

export const options: MachineOptions<Context, MachineEvents> = {
  actions,
  services,
  delays: {},
  guards: {
    hasHandwrittenSignature: (_, { data }: any) =>
      Boolean(data?.signatureImage) &&
      data?.signatureType === SignatureType.HANDWRITTEN,

    hasGeneratedSignature: (_, { data }: any) =>
      Boolean(data?.signatureImage) &&
      data?.signatureType === SignatureType.GENERATED,

    noActionTaken: (_, { data }: any) =>
      data?.details?.status === ESignStatus.INCOMPLETE,

    isSignatureHandwritten: ({ signature }) =>
      signature?.type === SignatureType.HANDWRITTEN,

    isLastDocument: ({ currentDocumentIndex = 0, signatoryDetails }) => {
      const { documentsList = [] } = signatoryDetails ?? {}

      return documentsList?.length - 1 === currentDocumentIndex
    },
  },
}
