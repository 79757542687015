/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import 'regenerator-runtime/runtime'
import React, { useContext, useState } from 'react'
import { Button, Icon, RaySpinner } from '@pando-styles/nirvana'
import { useModal } from '@pandolink/utils'

import {
  StyledFlexContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledWrapperHeader,
  StyledExitSpan,
  StyledExitContainer,
} from '@styles/index'
import { QuestionsState, QuestionsActions } from '@applications/index'
import { ProgressBar } from '@atomic/index'
import { MainActions, MainState } from '@pages/index'

import UnsavedChangesModal from './UnsavedChangesModal'
import {
  QuestionHeaderContainer,
  StyledCustomWrapper,
  StyledHeaderFlexContainer,
} from './styles'

type ClearUnsavedChanges = {
  clearUnsavedChanges: boolean
}

interface WrappedQuestionProps {
  text: string
  showLoader?: boolean
}

// const { REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList } = process.env

const WrappedQuestion: React.FC<WrappedQuestionProps> = ({
  text = '',
  showLoader = false,
}) => {
  const { providerState } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)

  const [showModal, setShowModal] = useState<boolean>(false)

  if (
    !providerState ||
    !providerActions ||
    !homeProviderState ||
    !homeProviderActions
  )
    return null

  const {
    question,
    failedUploadIds,
    errorShowTryAgainButton = true,
  } = providerState?.context ?? {}
  const { currentQuestion = 0, totalQuestions = 10 } = question ?? {}

  const { handleClearContext, handleReload, handleCompleteSurveyDirectly } =
    providerActions ?? {}
  const { handleGoToSurveyList, handleExitSurvey } = homeProviderActions ?? {}
  const { selectedInstance } = homeProviderState?.context ?? {}
  const { organizationName } = selectedInstance ?? {}

  const handleExit = ({ clearUnsavedChanges }: ClearUnsavedChanges) => {
    handleClearContext?.()

    if (providerState.matches('survey_completed')) {
      handleGoToSurveyList()
      return
    }

    if (clearUnsavedChanges) {
      handleCompleteSurveyDirectly?.()
      return
    }

    handleExitSurvey()
  }

  const { openModal, closeModal, modalIsOpen, Modal } = useModal()

  const handleTriggerModal = () => {
    if (Object.keys(failedUploadIds ?? {})?.length) {
      openModal()
      return
    }

    openModal()
  }

  const progress = (currentQuestion / totalQuestions) * 100

  //will set to hidden by default as of now
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )
  const isNavBarHidden = true

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <UnsavedChangesModal
          handleHideModal={closeModal}
          handleExit={handleExit}
        />
      </Modal>

      <StyledCustomWrapper>
        {!isNavBarHidden && (
          <StyledExitContainer>
            <StyledExitSpan onClick={handleTriggerModal}>
              Exit Survey
            </StyledExitSpan>
          </StyledExitContainer>
        )}
        <StyledWrapperBody
          flex={true}
          className={
            providerState.matches('loading') ? 'wrapper-body-height-100' : ''
          }
          height='0'
        >
          {providerState.matches('error') ? (
            <StyledWrapperBodyContainer size='xl'>
              <div className='absolute absolute-center w-400'>
                <Icon
                  iconName='alert-circle'
                  color='danger'
                  className={`scale-1-5 m-auto margin--bottom-1-5`}
                />
                <StyledParagraph>{text}</StyledParagraph>
                {errorShowTryAgainButton && (
                  <StyledFlexContainer className='margin--top-1'>
                    <Button
                      label='Try Again'
                      hoverColorWeight='300'
                      className='font-14'
                      onClick={handleReload}
                    />
                  </StyledFlexContainer>
                )}
              </div>
            </StyledWrapperBodyContainer>
          ) : (
            <StyledWrapperBodyContainer size='xl'>
              <div className='absolute absolute-center w-200 margin--top-4-n'>
                {showLoader && (
                  <RaySpinner color='primary' colorWeight='DEFAULT' />
                )}
                <StyledParagraph>{text}</StyledParagraph>
              </div>
            </StyledWrapperBodyContainer>
          )}
        </StyledWrapperBody>
      </StyledCustomWrapper>
    </>
  )
}

export default WrappedQuestion
