/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
import { Sender } from 'xstate'
import { usePandoLogger } from '@pandolink/utils'

import {
  getRequestDetail,
  getDocument,
  adoptSignature,
  SignatureType,
  execSignDocument,
  execAcknowledgeDocument,
  execDeclineDocument,
  execOptInDocument,
  execOptOutDocument,
} from '@utils/index'

import { Context } from '../types'

export const services: any = {
  getSignatoryDetails:
    ({ accessToken, signatoryGuid = '' }: Context) =>
    async (send: Sender<any>) => {
      try {
        if (!accessToken) {
          send('NO_ACCESS_TOKEN')
          return
        }

        const result = await getRequestDetail(
          {
            signatoryGuid,
          },
          accessToken!
        )

        const resultWithModifiedImage = {
          ...(result?.toObject() ?? {}),
          signatureImage:
            'data:image/png;base64, ' + result?.toObject()?.signatureImage,
        }

        send({
          type: 'GOT_SIGNATORY_DETAILS',
          data: resultWithModifiedImage,
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getSignatoryDetails: error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_SIGNATORY_DETAILS_FAILED',
          payload: error?.message,
        })
      }
    },

  adoptSignature:
    ({ accessToken, signatoryGuid = '', signature }: Context) =>
    async (send: Sender<any>) => {
      try {
        const { image } = signature ?? {}
        const formattedPayload = image && image.replace(/^[^,]+, */, '')

        const result = await adoptSignature(
          {
            signatoryGuid,
            image: formattedPayload ?? '',
            type: SignatureType.HANDWRITTEN,
          },
          accessToken!
        )

        send({
          type: 'SIGNATURE_SAVED',
          data: result?.toObject(),
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'adoptSignature: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'SIGNATURE_ERROR',
          payload: error?.message,
        })
      }
    },

  getDocument:
    ({ accessToken, signatoryDetails, currentDocumentIndex = 0 }: Context) =>
    async (send: Sender<any>) => {
      try {
        const { documentsList = [] } = signatoryDetails ?? {}

        if (!documentsList?.length) {
          send('NO_DOCUMENTS')
          return
        }

        if (currentDocumentIndex >= documentsList?.length) {
          send('NO_MORE_UNTOUCHED_DOCUMENTS')
          return
        }

        const result = await getDocument(
          {
            documentGuid: documentsList[currentDocumentIndex]?.guid ?? '',
          },
          accessToken!
        )

        send({
          type: 'GOT_DOCUMENT',
          data: {
            details: result?.toObject(),
            index: currentDocumentIndex,
          },
        })
      } catch (error: any) {
        usePandoLogger({
          name: 'getDocument: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'GET_DOCUMENT_FAILED',
          payload: error?.message,
        })
      }
    },

  updateDocument:
    (
      {
        accessToken,
        signatoryGuid = '',
        signatoryDetails,
        currentDocumentIndex = 0,
      }: Context,
      event: any
    ) =>
    async (send: Sender<any>) => {
      try {
        const { documentsList = [] } = signatoryDetails ?? {}
        const documentGuid = documentsList[currentDocumentIndex]?.guid ?? ''

        const { type } = event ?? {}

        switch (type) {
          case 'OPT_IN_DOCUMENT': {
            const result = await execOptInDocument(
              {
                signatoryGuid,
                documentGuid,
              },
              accessToken!
            )

            send({
              type: 'UPDATE_SUCCESS',
              data: result?.toObject(),
            })

            return
          }
          case 'OPT_OUT_DOCUMENT': {
            const result = await execOptOutDocument(
              {
                signatoryGuid,
                documentGuid,
              },
              accessToken!
            )

            send({
              type: 'UPDATE_SUCCESS',
              data: result?.toObject(),
            })

            return
          }
          case 'ACKNOWLEDGE_DOCUMENT': {
            const result = await execAcknowledgeDocument(
              {
                signatoryGuid,
                documentGuid,
              },
              accessToken!
            )

            send({
              type: 'UPDATE_SUCCESS',
              data: result?.toObject(),
            })

            return
          }
          case 'SIGN_DOCUMENT': {
            const result = await execSignDocument(
              {
                signatoryGuid,
                documentGuid,
              },
              accessToken!
            )

            send({
              type: 'UPDATE_SUCCESS',
              data: result?.toObject(),
            })

            return
          }
          case 'DECLINE_DOCUMENT':
          default: {
            const result = await execDeclineDocument(
              {
                signatoryGuid,
                documentGuid,
              },
              accessToken!
            )

            send({
              type: 'UPDATE_SUCCESS',
              data: result?.toObject(),
            })
          }
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'updateDocument: error',
          color: 'danger',
          body: error?.message,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        send({
          type: 'UPDATE_FAILED',
          payload: error?.message,
        })
      }
    },
}
