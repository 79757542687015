/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'
import { Button } from '@pando-styles/nirvana'

import { StyledParagraph } from '@styles/index'

import {
  RestartSurveyPopContainer,
  RestartSurveyBox,
  RestartSurveyTitle,
  RestartSurveyPopupButtonContainer,
} from '../styles'

const RestartSurveyPopup = ({
  handleCloseRestartSurveyPopup,
  handleNewAttempt,
}: any) => (
  <RestartSurveyPopContainer>
    <RestartSurveyBox>
      <RestartSurveyTitle>Are you sure you want to restart?</RestartSurveyTitle>
      <StyledParagraph className='margin--top-1'>
        You will lose your progress if you restart this survey.
      </StyledParagraph>
      <RestartSurveyPopupButtonContainer className='margin--top-2' style={{}}>
        <Button
          style={{ fontSize: 14 }}
          hoverColorWeight='300'
          label='No'
          onClick={handleCloseRestartSurveyPopup}
          className='bg-light-green borderless teal w-100p'
        />
        <Button
          style={{ fontSize: 14 }}
          label='Yes'
          hoverColorWeight='300'
          onClick={handleNewAttempt}
          className='w-100p'
        />
      </RestartSurveyPopupButtonContainer>
    </RestartSurveyBox>
  </RestartSurveyPopContainer>
)

export default RestartSurveyPopup
