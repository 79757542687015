/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import axios from 'axios'
import { usePandoLogger } from '@pandolink/utils'

interface Params {
  answerGuid?: string
  fileName?: string
  initialPath?: number | any
  file?: Blob | any
}

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_UPLOAD_MEDIA_ENDPOINT = 'api/v2/survey/media/template',
} = process.env

export const uploadAnswerMedia = async (
  params: Params,
  accessToken: string,
  blobFetch = true,
  handleUpdateRequestProgress: any
) => {
  try {
    const {
      answerGuid = '',
      fileName = '',
      initialPath = 3,
      file,
    } = params ?? {}

    let blob = null
    let newFile = file

    if (blobFetch) {
      blob = await fetch(file).then((r) => r.blob())

      newFile = new File([blob], fileName, {
        type: 'video/mp4;codecs=vp8,opus',
      })
    } else {
      newFile = new File([file], fileName, {
        type: 'video/mp4;codecs=vp8,opus',
      })
    }

    usePandoLogger({
      name: 'Debug Upload',
      body: {
        blobFetch,
        newFile,
        params: {
          Guid: answerGuid,
          Name: fileName,
          InitialPath: initialPath,
          File: newFile,
        },
      },
      color: 'warning',
    })

    const formData = new FormData()

    formData.append('Guid', answerGuid ?? '')
    formData.append('Name', fileName ?? '')
    formData.append('InitialPath', initialPath)
    formData.append('File', newFile ?? file)

    const result = await axios.post(
      `${REACT_APP_QUERY_HOST}/${REACT_APP_UPLOAD_MEDIA_ENDPOINT}`,
      formData,
      {
        // @ts-ignore
        responseType: 'multipart/form-data',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )

          handleUpdateRequestProgress?.(percentCompleted)
        },
      }
    )

    usePandoLogger({
      name: 'Upload Result',
      body: {
        result,
      },
      color: 'warning',
    })

    if (result?.status === 200) {
      return {
        success: true,
      }
    } else {
      return {
        success: false,
      }
    }
  } catch (error: any) {
    let hasUploadSuccess = false

    if (error?.code === 9) {
      return {
        success: false,
        sessionExpired: true,
      }
    }

    if (
      error?.response?.status === 400 ||
      error?.response?.data === 'This survey answer already has saved media.'
    ) {
      hasUploadSuccess = true
    }

    return {
      success: false,
      hasUploadSuccess,
    }
  }
}
