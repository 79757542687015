/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import { Button, Progress, Icon } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledHeadingThree,
  StyledHomeContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledExitContainer,
  StyledExitSpan,
} from '@styles/index'

import { BootloaderActions } from '@system/Bootloader'
import { MainActions, MainState } from '@pages/index'

import { StyledButtonContainer, StyledLoadingContainer2 } from '../styles'
import RestartSurveyPopup from './RestartSurveyPopup'

// const { REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList } = process.env

const ContinueConfirmation: React.FC = () => {
  const { providerActions } = useContext(MainActions)
  const { providerState } = useContext(MainState)

  const [isRestartPopupVisible, setIsRestartPopupVisible] =
    useState<boolean>(false)

  if (!providerActions || !providerState) return null

  const { selectedInstance } = providerState?.context ?? {}
  const { current, organizationName = '' } = selectedInstance ?? {}
  const { totalAnswered = 0, totalQuestions = 0 } = current ?? {}

  const { handleContinueAttempt, handleNewAttempt, handleGoToSurveyList } =
    providerActions ?? {}
  const { bootloaderActions } = useContext(BootloaderActions)
  const { handleClearBootloaderUrlParams } = bootloaderActions ?? {}

  const progress = Math.ceil((totalAnswered / totalQuestions) * 100)

  //will set to hidden by default as of now
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )
  const isNavBarHidden = true

  const handleExit = () => {
    handleClearBootloaderUrlParams && handleClearBootloaderUrlParams()
    handleGoToSurveyList()
  }

  const handleCloseRestartSurveyPopup = () => {
    setIsRestartPopupVisible(false)
  }

  const handleContinueNewAttempt = () => {
    handleCloseRestartSurveyPopup()
    return handleNewAttempt && handleNewAttempt()
  }

  return (
    <StyledContainer className='styled-container'>
      {isRestartPopupVisible && (
        <RestartSurveyPopup
          handleCloseRestartSurveyPopup={handleCloseRestartSurveyPopup}
          handleNewAttempt={handleContinueNewAttempt}
        />
      )}
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          {!isNavBarHidden && (
            <StyledExitContainer>
              <StyledExitSpan
                className='flex align-center'
                onClick={handleExit}
              >
                <Icon iconName='chevron-left' size='xl' />
                Back
              </StyledExitSpan>
            </StyledExitContainer>
          )}
          <StyledWrapperBody>
            <StyledWrapperBodyContainer>
              <StyledHeadingThree>Survey Status</StyledHeadingThree>
              <StyledParagraph className='margin--top-2 padding--x-1-at-800 margin--x-1-at-800'>
                This survey has already been started. Please make a selection
                below.
              </StyledParagraph>
              <StyledLoadingContainer2>
                <Progress progress={progress} size='xs' />
                <StyledParagraph className='margin--top-1 teal'>
                  Progress
                </StyledParagraph>
              </StyledLoadingContainer2>

              <StyledButtonContainer className='margin--top-2 flex-row padding--x-1 w-400'>
                <Button
                  hoverColorWeight='300'
                  label='Restart'
                  onClick={() => handleNewAttempt()}
                  className='bg-light-green borderless teal w-100p font-14'
                />
                <Button
                  label='Resume'
                  hoverColorWeight='300'
                  onClick={handleContinueAttempt}
                  className='w-100p font-14'
                />
              </StyledButtonContainer>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default ContinueConfirmation
