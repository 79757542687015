/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Container } from '@pando-styles/nirvana'
import { InstanceShort } from '@utils/index'

import { StyledHeadingThree, StyledParagraph } from '@styles/index'
import { Grid } from '@atomic/index'
import { Tabs } from '@styles/index'
import {
  getCompletedDocumentList,
  getCompletedDetailedDocumentList,
  getIncompleteDetailedDocumentList,
  getIncompleteDocumentList,
} from '@utils/index'

import { Document, SignatoryItem } from './components'
import { tabItems } from './tab-items'

const MainTabDocument: React.FC = () => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const navigate = useHistory()

  const component = [
    {
      key: 'signed',
      name: (
        <Grid
          appConfig={{
            storage_key: 'signed-documents:unstorable',
            query_entity: 'documents',
            no_data_text: 'No completed documents at this time.',
            fetching_data_text: 'Fetching signed documents.',
            enablePagination: true,
            showCurrentPage: false,
            searchFields: ['name'],
            initialState: {
              pageSize: 5,
              sortBy: 'dateCompletedUtc.seconds',
              sortOrder: 'descending',
            },
          }}
          queryParams={{
            resultsPerPage: 5,
            page: 0,
          }}
          actions={{
            onFetch: getCompletedDetailedDocumentList,
          }}
          customComponent={(
            item: InstanceShort.AsObject | any,
            isFetching = false
          ) => {
            return <SignatoryItem {...item} isFetching={isFetching} />
          }}
        />
      ),
    },
    {
      key: 'unsigned',
      name: (
        <Grid
          appConfig={{
            storage_key: 'unsigned-documents:unstorable',
            query_entity: 'documents',
            no_data_text: 'No incomplete documents at this time.',
            fetching_data_text: 'Fetching unsigned documents.',
            enablePagination: true,
            showCurrentPage: false,
            searchFields: ['name'],
            initialState: {
              pageSize: 5,
              sortBy: 'dateCompletedUtc.seconds',
              sortOrder: 'descending',
            },
          }}
          queryParams={{
            resultsPerPage: 5,
            page: 0,
          }}
          actions={{
            onFetch: getIncompleteDetailedDocumentList,
          }}
          customComponent={(
            item: InstanceShort.AsObject | any,
            isFetching = false
          ) => {
            return <SignatoryItem {...item} isFetching={isFetching} />
          }}
        />
      ),
    },
  ]

  useEffect(() => {
    location?.pathname.includes('documents') &&
      !location?.pathname.includes('signed') &&
      navigate.push('/documents/signed')
  }, [])

  useEffect(() => {
    location?.pathname.includes('documents') &&
      !location?.pathname.includes('signed') &&
      navigate.push('/documents/signed')
  }, [location.pathname])

  return (
    <Container
      direction='column'
      height='full'
      centerX
      className='outer-container overflow-y px-1-at-600 padding--x-2 px-1-at-762'
    >
      <Switch>
        <Route path={`${path}/signed/:signatoryGUID/:documentGUID`}>
          <Document />
        </Route>
        <Route path={`${path}/unsigned/:signatoryGUID/:documentGUID`}>
          <Document />
        </Route>
        <Route path={path}>
          <Container
            height='full'
            direction='column'
            centerX
            className='survey-tab-list--inner-container inner-container'
          >
            <Tabs tabItems={tabItems} component={component} />
          </Container>
        </Route>
      </Switch>
    </Container>
  )
}

export default MainTabDocument
