import { FontButton } from './styles'

export interface FontButtonProps {
  type: 'minus' | 'plus'
  callBack: () => void
  color: string
}

const FontButtons = ({ type, callBack, color }: FontButtonProps) => {
  if (type === 'minus') {
    return (
      <FontButton onClick={callBack}>
        <svg
          width='30'
          height='29'
          viewBox='0 0 30 29'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_60_5793)'>
            {/* <rect width='30' height='29' fill='white' /> */}
            <rect width='30' height='29' rx='4.13462' fill={color} />
            <path
              d='M21.5762 14.9258L9.15318 14.9258'
              stroke='white'
              strokeWidth='2.16711'
              strokeLinecap='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_60_5793'>
              <rect width='30' height='29' fill='white' />
            </clipPath>
          </defs>
        </svg>
      </FontButton>
    )
  }
  return (
    <FontButton onClick={callBack}>
      <svg
        width='30'
        height='29'
        viewBox='0 0 30 29'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_60_5796)'>
          {/* <rect width='30' height='29' fill='white' /> */}
          <path
            d='M21.4238 15L9.00084 15'
            stroke='white'
            strokeWidth='2.16711'
            strokeLinecap='round'
          />
          <rect width='30' height='29' rx='4.13462' fill={color} />
          <path
            d='M14.998 8L14.998 20.423'
            stroke='white'
            strokeWidth='2.16711'
            strokeLinecap='round'
          />
          <path
            d='M21.4238 14.4258L9.00084 14.4258'
            stroke='white'
            strokeWidth='2.16711'
            strokeLinecap='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_60_5796'>
            <rect width='30' height='29' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </FontButton>
  )
}

export default FontButtons
