/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'

export interface SurveyMediaProps {
  clickedBtnId: string | undefined
  waitingForBtnClick?: boolean
  userRecordedVideo: any
  fontButtonColors: any
  fontSize: number
  setUserRecordedVideo: Dispatch<any>
  handleFontSizeIncrease: () => void
  handleFontSizeDecrease: () => void
}

export enum ModalStateCam {
  LOADING = 'loading',
  DENIED = 'denied',
  ALLOWED = 'allowed',
}

export enum ModalStateMic {
  LOADING = 'loading',
  DENIED = 'denied',
  ALLOWED = 'allowed',
}

export interface UseModalValue {
  openModal: () => void
  closeModal: () => void
  modalIsOpen: boolean
  Modal: any
}

export interface SurveyMediaStateValue {
  surveyMediaState: {
    accessToken?: string
    surveyPausedModal: UseModalValue
    camPermissionModal: UseModalValue
    micPermissionModal: UseModalValue
    surveyMediaProviderState: {
      providerState: any
      isAtWaitingForBtnClickState: boolean
      isAtInterruptedRecordingState: boolean
      isAtPlayingState: boolean
      hasOnlyOneAnswerOption: boolean
      isNavBarHidden: boolean
      isCaptureVideoActive: boolean
      isStreamUpdated: boolean
      mediaGuid: string | undefined
      isMultiChoiceQuestion: boolean
      firstVideoPlayed: boolean
      isVideoMuted: boolean
      resumeSurvey: () => void
      showDialog: () => boolean
      showNotAcceptableAnswerDialog: () => boolean
      showAskCameraPermissionModal: () => boolean
      showAskMicPermissionModal: () => boolean
      handleErrorPlayingVideo: () => void
      handleInterruptedMediaRecording: () => void
      handleFirstVideoPlayedTrue: () => void
      setIsStreamUpdated: Dispatch<boolean>
      handleBlur: () => void
      handleFocus: () => void
      checkPlatform: () => boolean
      setIsVideoMuted: Dispatch<boolean>
    }
    useDeviceStreamState: {
      hasCamPermission: boolean
      hasMicPermission: boolean
      modalStateMic: ModalStateMic
      modalStateCam: ModalStateCam
      maxAttemptsReachedCam: boolean
      maxAttemptsReachedMic: boolean
      isPermissionLoading: boolean
      camPermissionAttempts: number
      micPermissionAttempts: number
      getCameraPermission: () => void
      getMicPermission: () => void
      showCameraComponent: () => boolean
    }
    useVideoPlayerState: {
      mediaUrl: any
      videoPlayerRef: any
      hasVideoEnded: boolean
      showVideoLoader: boolean
      showPlayButton: boolean
      isVideoPlaying: boolean
      setHasVideoEnded: Dispatch<boolean>
      setShowVideoLoader: Dispatch<boolean>
      setShowPlayButton: Dispatch<boolean>
      setIsVideoPlaying: Dispatch<boolean>
      shouldQuestionMediaPlay: () => boolean
    }
    useMediaRecorderState: {
      handleStopRecording: (stateValue: string) => void
      handleStartRecording: (stateValue: string) => void
    }
    useBowserState: {
      isAppleDevice: boolean
      isIPadChrome: boolean
      isMobile: boolean
      isSafari: boolean
      isOSNotSupported: boolean
    }
  }
  surveyMediaProps: SurveyMediaProps
}
