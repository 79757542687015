/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react'
import { Icon } from '@pando-styles/nirvana'

import { MainActions } from '@pages/index'

import { StyledCamera, StyledContainer } from './styles'
import { CameraProps } from './types'
import MediaLiveDetect from './MediaLiveDetect'

const { REACT_APP_DISABLE_FACE_RECOGNITION_API: disableFaceApi = false } =
  process.env

const Camera: React.FC<CameraProps> = ({ isCaptureVideoActive }) => {
  const { providerActions: homeProviderActions } = useContext(MainActions)

  if (!homeProviderActions) return null

  return (
    <div className='padding--x-1-at-800 mw-100'>
      <StyledCamera className='styled-camera xxx'>
        {isCaptureVideoActive && (
          <MediaLiveDetect isCaptureVideoActive={isCaptureVideoActive} />
        )}
        {!isCaptureVideoActive && (
          <Icon iconName='camera-off' size='xl' className={'camera-icon'} />
        )}
      </StyledCamera>
    </div>
  )
}

export default Camera
