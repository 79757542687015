/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import 'regenerator-runtime/runtime'
// @ts-ignore
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import {
  SurveyInstanceDataServicePromiseClient,
  GetInstanceShortListRequest,
  DateType,
  ConfigurationKey,
  getSurveyOrganizationConfiguration,
} from '@utils/index'

type Params = Partial<GetInstanceShortListRequest.AsObject>

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const getCompletedSurveyInstances = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const payload = new GetInstanceShortListRequest()

  payload.setPage(params?.page ?? 0)
  payload.setResultsPerPage(params?.resultsPerPage ?? 0)

  const startDateTimeStamp = new Timestamp()
  const endDateTimeStamp = new Timestamp()

  if (params?.start || params?.end) {
    if (isNaN(params?.start) || isNaN(params?.start)) return

    startDateTimeStamp.fromDate(new Date(params?.start))
    endDateTimeStamp.fromDate(new Date(params?.end))

    payload.setStart(startDateTimeStamp)
    payload.setEnd(endDateTimeStamp)
    payload.setDatetype(DateType.COMPLETED)
  }

  const result = await service.getCompletedSurveyInstances(payload, {
    authorization: `Bearer ${accessToken}`,
  })

  //get organization configuration per survey
  const surveyInstances = await Promise.all(
    result.toObject().instancesList.map(async (survey: any) => {
      const surveyOrgConfigResult = await getSurveyOrganizationConfiguration(
        {
          instanceGuid: survey.guid,
          configurationKeysList: [ConfigurationKey.IS_VIDEO_PLAYBACK_ALLOWED],
        },
        accessToken
      )
      return {
        ...survey,
        isAllowedVideoPlayback:
          surveyOrgConfigResult.toObject().surveyConfigurationsList[0],
      }
    })
  )

  return {
    ...result.toObject(),
    instancesList: surveyInstances,
  }
}
