/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Button } from '@pando-styles/nirvana'

import {
  StyledFlexContainer,
  StyledHeadingThree,
  StyledModalContainer,
  StyledParagraph,
} from '@styles/index'

type ConfirmationProps = {
  closeModal?: () => void
  handleConfirmDecline?: () => void
}

const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const { closeModal, handleConfirmDecline } = props

  return (
    <StyledModalContainer width={500} className='padding--y-2'>
      <StyledHeadingThree>Document is required.</StyledHeadingThree>
      <StyledParagraph className='margin--top-1'>
        Are you sure you want to decline?
      </StyledParagraph>
      <StyledFlexContainer className='margin--top-2 align-self-center'>
        <Button
          className='font-14'
          label='Yes'
          hoverColorWeight='300'
          onClick={() => {
            closeModal?.()
            handleConfirmDecline?.()
          }}
        />
        <Button
          className='font-14'
          label='Cancel'
          hoverColorWeight='300'
          onClick={closeModal}
          layout='outline'
        />
      </StyledFlexContainer>
    </StyledModalContainer>
  )
}

export default Confirmation
