/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useContext, useEffect } from 'react'

import { Button, Icon } from '@pando-styles/nirvana'
import { Question, WrappedQuestion, CompletingSurvey } from '@atomic/index'
import { MainActions, MainState } from '@pages/index'

import { QuestionsActions, QuestionsState } from './QuestionsProvider'

const QuestionsConsumer: React.FC = () => {
  const { providerState, providerProps } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)

  if (
    !providerState ||
    !providerProps ||
    !providerActions ||
    !homeProviderState ||
    !homeProviderActions
  )
    return null

  const { params, errorMessage } = providerState?.context ?? {}

  const { handleReset, handleGotNewAttempt } = providerActions ?? {}
  const { handleShouldCreateNewAttempt } = homeProviderActions ?? {}

  useEffect(() => {
    if (providerState.matches('done')) {
      handleShouldCreateNewAttempt()
      handleReset()
    }
  }, [providerState.value])

  useEffect(() => {
    if (params && params?.attemptGuid !== providerProps?.attemptGuid) {
      handleGotNewAttempt?.(providerProps)
    }
  }, [providerProps.attemptGuid, params])

  useEffect(() => {
    if (providerProps) {
      handleGotNewAttempt?.(providerProps)
    }
  }, [])

  return (
    <>
      {providerState.matches('loading') && (
        <WrappedQuestion text='Loading next question...' showLoader />
      )}
      {providerState.matches('ready') && <Question />}
      {providerState.matches('survey_completed_but_unacceptable') && (
        <WrappedQuestion text='Survey is not acceptable.' />
      )}
      {providerState.hasTag('completing_survey') && <CompletingSurvey />}

      {providerState.matches('error') && (
        <WrappedQuestion text={errorMessage ?? 'Something went wrong!'} />
      )}
    </>
  )
}

export default QuestionsConsumer
