/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from 'react'
import { Button, RaySpinner } from '@pando-styles/nirvana'

import {
  StyledFlexContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
  StyledWrapperHeader,
} from '@styles/index'
import { MainState, MainActions } from '@pages/index'
import { BootloaderActions } from '@system/Bootloader'

import { ESignActions, ESignState } from '../ESignProvider'

type ESignComponentProps = {
  text: string
  showLoader?: boolean
}

const ESignComponent: React.FC<ESignComponentProps> = ({
  text,
  showLoader = false,
}) => {
  const { providerState } = useContext(ESignState)
  const { providerActions } = useContext(ESignActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)
  const { bootloaderActions } = useContext(BootloaderActions)

  if (
    !providerActions ||
    !providerState ||
    !homeProviderState ||
    !homeProviderActions ||
    !bootloaderActions
  )
    return null

  const { handleReload } = providerActions ?? {}
  const { handleLogout } = bootloaderActions ?? {}
  const { handleGoToSurveyList } = homeProviderActions ?? {}
  const { loggedInAsGuest = false } = homeProviderState.context ?? {}

  return (
    <StyledWrapper className='flex-center flex-column'>
      <Button
        label='Exit'
        layout='outline'
        onClick={handleGoToSurveyList}
        className='borderless-button font-14'
      />
      <StyledWrapperBody>
        <StyledWrapperBodyContainer>
          {providerState?.matches('error') ? (
            <StyledFlexContainer className='flex-column'>
              <StyledParagraph>{text}</StyledParagraph>
              <StyledFlexContainer className='margin--top-1'>
                <Button
                  label='Try Again'
                  layout='outline'
                  hoverColorWeight='300'
                  onClick={handleReload}
                  className='font-14'
                />
                <Button
                  label='Logout'
                  hoverColorWeight='300'
                  onClick={handleLogout}
                  className='font-14'
                />
              </StyledFlexContainer>
            </StyledFlexContainer>
          ) : (
            <>
              {showLoader && (
                <RaySpinner color='primary' colorWeight='DEFAULT' />
              )}
              <StyledParagraph className='margin--top-1'>
                {text}
              </StyledParagraph>
              {providerState?.matches('esign_done') && (
                <Button
                  label='Return to Dashboard'
                  block
                  hoverColorWeight='300'
                  className='w-100p margin--top-1 font-14'
                  onClick={handleGoToSurveyList}
                />
              )}
            </>
          )}
        </StyledWrapperBodyContainer>
      </StyledWrapperBody>
    </StyledWrapper>
  )
}

export default ESignComponent
