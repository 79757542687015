/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import { Sender, AnyEventObject } from 'xstate'
import { usePandoLogger } from '@pandolink/utils'

import { Context, MachineEvents, Params } from '../types'

export const services: any = {
  initialFetch:
    (
      { params, accessToken, onFetch, applicationConfig }: Context,
      { onFetch: eventOnFetch }: any
    ) =>
    async (send: Sender<any>) => {
      try {
        if (accessToken) {
          const fetchRequest = eventOnFetch ? eventOnFetch : onFetch
          const result = await fetchRequest?.(params, accessToken)

          send({
            type: 'GOT_INITIAL_DATA',
            payload: result?.toObject ? result?.toObject() : result,
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'Grid Initial Fetching: Error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        const { query_entity } = applicationConfig ?? {}

        send({
          type: 'GOT_INITIAL_DATA',
          payload: {
            [query_entity + 'List']: [],
            pagination: {},
          },
        })
      }
    },

  fetchData:
    (
      {
        applicationConfig,
        applicationData,
        params,
        accessToken,
        onFetch,
      }: Context,
      event: AnyEventObject
    ) =>
    async (send: Sender<MachineEvents | any>) => {
      try {
        if (accessToken) {
          const { query_entity = 'instances' } = applicationConfig ?? {}
          const { currentPage = 0, totalPageCount = 1 } =
            applicationData?.pagination ?? {}
          const { type, payload = 0, onFetch: eventOnFetch } = event

          let pageIndexParams = 0

          switch (type) {
            case 'SORT': {
              send({
                type: 'GOT_DATA',
                payload: {
                  pagination: applicationData?.pagination,
                  [`${query_entity}List`]: applicationData?.items,
                },
              })

              return
            }
            case 'JUMP_TO_PAGE': {
              pageIndexParams = payload
              break
            }
            case 'JUMP_TO_FIRST': {
              pageIndexParams = 0
              break
            }
            case 'JUMP_TO_LAST': {
              pageIndexParams = totalPageCount - 1
              break
            }
            case 'NEXT': {
              pageIndexParams = currentPage + 1
              break
            }
            case 'PREVIOUS': {
              pageIndexParams = currentPage - 1
              break
            }
            case 'GOT_NEW_ACCESS_TOKEN':
            case 'SEARCH':
            case 'SELECT_DATE':
            case 'REFETCH': {
              pageIndexParams = currentPage
              break
            }
            default:
              return pageIndexParams
          }

          const modifiedParams: Params = {
            ...params,
            page: pageIndexParams,
          }

          const fetchRequest = eventOnFetch ? eventOnFetch : onFetch

          const result = await fetchRequest?.(modifiedParams, accessToken)

          send({
            type: 'GOT_DATA',
            payload: result?.toObject ? result?.toObject() : result,
          })
        }
      } catch (error: any) {
        usePandoLogger({
          name: 'Grid Refetching Error',
          color: 'danger',
          body: error,
        })

        if (error?.code === 16) {
          send({
            type: 'SESSION_EXPIRED',
            data: error?.message,
          })
          return
        }

        const { query_entity } = applicationConfig ?? {}

        send({
          type: 'GOT_DATA',
          payload: {
            [query_entity + 'List']: [],
            pagination: {},
          },
        })
      }
    },
}
