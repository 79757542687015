/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import axios from 'axios'
import { usePandoLogger } from '@pandolink/utils'

interface Params {
  fileName?: string
  file?: Blob | any
}

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_SPEECH_RECOGNITION_ENDPOINT = 'api/v2/survey/media/recognizespeech',
} = process.env

export const speechRecognitionAPI = async (
  params: Params,
  accessToken: string
) => {
  try {
    const { fileName = '', file: paramsFile } = params ?? {}

    /* This is for reference please don't delete this
    const sampleFile = `${process.env.REACT_APP_PUBLIC_URL}video/test-yes.mp3`

    Fetch the MP3 file content
    const blobFile = await fetch(paramsFile)

    Access the blob file and use directly
    const file = await blobFile.blob()
    */

    const newFile = new File([paramsFile], fileName, {
      type: 'audio/mp3;codecs=vp8,opus',
    })

    const formData = new FormData()

    formData.append('Name', fileName ?? '')
    formData.append('File', newFile)

    const result = await axios.post(
      `${REACT_APP_QUERY_HOST}/${REACT_APP_SPEECH_RECOGNITION_ENDPOINT}`,
      formData,
      {
        // @ts-ignore
        responseType: 'multipart/form-data',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      }
    )

    usePandoLogger({
      name: 'SR API Result',
      body: {
        result,
      },
      color: 'warning',
    })

    return result
  } catch (error) {
    console.error('Error at [speechRecognitionAPI]', error)
    throw error
  }
}
