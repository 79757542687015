/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@pando-styles/nirvana'

import { StyledFlexContainer } from '@styles/index'

import { StyledSigPadContainer, StyledSigImage2 } from './styles'
import { ESignActions, ESignState } from '../ESignProvider'

const ExistingSignature: React.FC = () => {
  const { providerActions } = useContext(ESignActions)
  const { providerState } = useContext(ESignState)
  const [imageURL, setImageURL] = useState<any>('')

  if (!providerActions || !providerState) return null

  const { signatoryDetails, signature } = providerState?.context ?? {}
  const { handleProceed } = providerActions ?? {}

  const { signatureImage = '' } = signatoryDetails ?? {}
  const { image = '' } = signature ?? {}

  useEffect(() => {
    if (signatureImage || image) {
      setImageURL(image || signatureImage)
    }
  }, [signatureImage, image])

  return (
    <>
      <StyledSigPadContainer>
        {imageURL && <StyledSigImage2 src={imageURL} alt='my signature' />}
      </StyledSigPadContainer>
      <StyledFlexContainer className='margin--top-1'>
        <Button
          label='Proceed'
          hoverColorWeight='300'
          onClick={handleProceed}
          className='font-14'
        />
      </StyledFlexContainer>
    </>
  )
}

export default ExistingSignature
