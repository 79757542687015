import React, { useContext, useEffect } from 'react'
import { RaySpinner } from '@pando-styles/nirvana'

import {
  StyledContainer,
  StyledHomeContainer,
  StyledParagraph,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperBodyContainer,
} from '@styles/index'
import { BootloaderActions } from '@system/Bootloader'

const TokenExpired: React.FC = () => {
  const { bootloaderActions } = useContext(BootloaderActions)

  if (!bootloaderActions) return null

  const { handleKeepMeSignedIn } = bootloaderActions ?? {}

  useEffect(() => {
    console.log(
      '@DEBUG [TokenExpired Component]: get new access token triggered in useEffect.'
    )
    handleKeepMeSignedIn()
  }, [])

  return (
    <StyledContainer className='styled-container'>
      <StyledHomeContainer>
        <StyledWrapper className='flex-center flex-column'>
          <StyledWrapperBody className='w-400'>
            <StyledWrapperBodyContainer>
              <RaySpinner color='primary' colorWeight='DEFAULT' />
              <StyledParagraph>One moment...</StyledParagraph>
            </StyledWrapperBodyContainer>
          </StyledWrapperBody>
        </StyledWrapper>
      </StyledHomeContainer>
    </StyledContainer>
  )
}

export default TokenExpired
