/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import { RaySpinner } from '@pando-styles/nirvana'
import { usePandoLogger, useModal } from '@pandolink/utils'
import Bowser from 'bowser'

import { StyledExitContainer, StyledExitSpan } from '@styles/index'
import { QuestionsState, QuestionsActions } from '@applications/index'
import { ProgressBar, SurveyMedia } from '@atomic/index'
import { MainActions, MainState } from '@pages/index'
import {
  uploadAnswerMedia,
  capitalize,
  parseQuestionText,
  PlaybackData,
  speechRecognitionAPI,
} from '@utils/index'

import {
  StyledQuestionTitle,
  StyledHeaderFlexContainer,
  StyledQuestionContentContainer,
  StyledCustomWrapper,
  QuestionBodyContainer,
  QuestionContainer,
  SurveyMediaMainWrapper,
  QuestionContainerBody,
  ShadowTop,
  ShadowBottom,
} from './styles'
import UnsavedChangesModal from './UnsavedChangesModal'
import { useFontSize } from '@utils/index'

export { default as WrappedQuestion } from './WrappedQuestion'
export { default as CompletingSurvey } from './CompletingSurvey'

type ClearUnsavedChanges = {
  clearUnsavedChanges: boolean
}

const {
  REACT_APP_ACCESS_TOKEN_FOR_DEBUGGING: isDebugging = '',
  // REACT_APP_HIDDEN_NAV_BAR_ORG_LIST: hiddenNavBarOrgList,
} = process.env

const Question: React.FC = () => {
  const { providerState, providerProps } = useContext(QuestionsState)
  const { providerActions } = useContext(QuestionsActions)
  const { providerState: homeProviderState } = useContext(MainState)
  const { providerActions: homeProviderActions } = useContext(MainActions)

  const browser = Bowser.getParser(window.navigator.userAgent)
  const isMobile = browser.getPlatformType() === 'mobile'
  const isAppleDevice = browser.getPlatform()?.vendor === 'Apple'

  const [clickedBtnId, setClickedBtnId] = useState<string | undefined>(
    undefined
  )
  const [userRecordedVideo, setUserRecordedVideo] = useState<any | undefined>(
    undefined
  )
  const [isQuestionTextScrollable, setIsQuestionTextScrollable] =
    useState<boolean>(false)

  const [questionDivHeight, setQuestionDivHeight] = useState(0)

  const questionTextRef = useRef<any>(null)
  const questionBodyDivRef = useRef<any>(null)
  const shadowTopRef = useRef<any>(null)
  const shadowBottomRef = useRef<any>(null)

  if (
    !providerState ||
    !providerProps ||
    !homeProviderActions ||
    !homeProviderState
  )
    return null

  const {
    question,
    accessToken: storedAccessToken,
    createdAnswer,
    failedUploadIds,
    lastQuestionUploaded = false,
    requestProgress,
  } = providerState?.context ?? {}

  const { currentQuestion = 0, mediaGuid } = question ?? {}

  const waitingForBtnClick = providerState.matches(
    'ready.waiting_for_button_click'
  )

  const {
    handleAddFailedUploadIds,
    handleUploadSuccess,
    handleUploadFailed,
    handleClearContext,
    handleCompleteSurveyDirectly,
    handleLastUploadSuccess,
    handleLastUploadFailed,
    handleRestartRecording,
    handleVideoLimitReached,
    handleUploadMediaFailed,
    handleUpdateRequestProgress,
    handleSRApiRequestError,
    handleAnswerIsYes,
    handleAnswerIsNo,
    handleAnswerIsInvalid,
    handleSessionExpired,
  } = providerActions ?? {}
  const { handleGoToSurveyList } = homeProviderActions ?? {}
  const { selectedInstance } = homeProviderState?.context ?? {}
  const { current, organizationName } = selectedInstance ?? {}
  const { totalQuestions = 0 } = current ?? {}

  const callSpeechRecognitionAPI = async () => {
    try {
      const media = {
        fileName: `question_guid-${question?.guid}.mp3`,
        file: userRecordedVideo?.[0],
      }

      const result = await speechRecognitionAPI(media, storedAccessToken ?? '')

      if (result && result.data) {
        if (result.data.isYes) {
          handleAnswerIsYes?.()
        } else if (result.data.isNo) {
          handleAnswerIsNo?.()
        } else {
          handleAnswerIsInvalid?.()
        }
      }
    } catch (error: any) {
      console.error('Error at callSpeechRecognitionAPI:', error)

      handleSRApiRequestError?.(error.message)
    }
  }

  const uploadAnswer = async () => {
    try {
      console.log('\n🌀 Uploading...\n\n')
      if (!userRecordedVideo?.[0]) {
        handleUploadFailed?.()
        return
      }

      if (userRecordedVideo?.[0]?.size >= 10485760) {
        console.log('\n❌ Upload Failed: Size must be lesser than 10mb.\n\n')

        handleVideoLimitReached?.()
        return
      }

      const media = {
        answerGuid: createdAnswer?.guid ?? '',
        fileName: `question_${createdAnswer?.guid}.mp4`,
        initialPath: 3,
        file: userRecordedVideo?.[0],
      }

      const { success, hasUploadSuccess, sessionExpired } =
        await uploadAnswerMedia(
          media,
          storedAccessToken ?? '',
          false,
          handleUpdateRequestProgress
        )

      //* Note: this estatement handles already uploaded answer
      if (hasUploadSuccess) {
        console.log('\n✅ Upload Success.\n\n')

        handleUploadSuccess?.()
        return
      }
      if (!success) {
        handleAddFailedUploadIds?.(createdAnswer?.guid ?? '')

        console.log('\n❌ Upload request failed.\n\n')

        handleUploadFailed?.()

        if (sessionExpired) {
          handleSessionExpired?.()
        }
        return
      }

      console.log('\n✅ Upload Success.\n\n')

      handleUploadSuccess?.()
    } catch (error: any) {
      usePandoLogger({
        name: 'uploadAnswer: error',
        color: 'danger',
        body: error?.message,
      })
    }
  }

  const handleExit = ({ clearUnsavedChanges }: ClearUnsavedChanges) => {
    handleClearContext?.()

    if (providerState.matches('survey_completed')) {
      handleGoToSurveyList()
      return
    }

    if (clearUnsavedChanges) {
      handleCompleteSurveyDirectly?.()
      return
    }

    handleGoToSurveyList()
  }

  const { openModal, closeModal, modalIsOpen, Modal } = useModal()

  const handleTriggerModal = () => {
    if (Object.keys(failedUploadIds ?? {})?.length) {
      openModal()
      return
    }

    openModal()
  }

  useEffect(() => {
    //! Invoke handleUploadFailed if no video is recorded
    if (
      userRecordedVideo?.[0] &&
      (providerState.matches('ready.uploading_answer') ||
        providerState.matches('ready.wait_for_last_question_upload')) &&
      !lastQuestionUploaded &&
      requestProgress === 0
    ) {
      uploadAnswer()
    }
    //*  Handler for null userRecorded
    if (
      !userRecordedVideo?.[0] &&
      (providerState.matches('ready.uploading_answer') ||
        providerState.matches('ready.wait_for_last_question_upload'))
    ) {
      handleUploadMediaFailed?.()
      return
    }

    if (
      userRecordedVideo?.[0] &&
      providerState.matches('ready.verify_answer_via_speech_recognition_api')
    ) {
      callSpeechRecognitionAPI()
    }
  }, [userRecordedVideo, providerState?.value])

  const progress = ((currentQuestion - 1) / totalQuestions) * 100

  //will set to hidden by default as of now
  const isNavBarHidden = true
  // const isNavBarHidden =
  //   Array.isArray(hiddenNavBarOrgList) &&
  //   hiddenNavBarOrgList.find(
  //     (org) => org?.toLowerCase() === organizationName?.toLowerCase()
  //   )

  const {
    _fontSize,
    handleFontSizeDecrease,
    handleFontSizeIncrease,
    getFontButtonColors,
  } = useFontSize()

  const currentScoll = useCallback(() => {
    const contentScrollHeight =
      questionTextRef.current.scrollHeight -
      questionBodyDivRef.current.offsetHeight

    const _currentScroll =
      questionTextRef.current.scrollTop / contentScrollHeight

    const _currentScrollAbs =
      _currentScroll < 0 ? Math.abs(_currentScroll) : _currentScroll

    shadowTopRef.current.style.opacity = _currentScrollAbs
    shadowBottomRef.current.style.opacity = 1 - _currentScrollAbs

    if (
      questionTextRef.current.scrollTop +
        questionTextRef.current.clientHeight ===
      questionTextRef.current.scrollHeight
    ) {
      shadowBottomRef.current.style.opacity = 0
      return
    }
  }, [])

  useEffect(() => {
    questionTextRef &&
      questionTextRef.current.addEventListener('scroll', currentScoll)
  }, [currentScoll])

  useEffect(() => {
    if (
      questionTextRef.current.scrollHeight >
      questionTextRef.current.clientHeight
    ) {
      shadowBottomRef.current.style.opacity = 1
      return
    }

    if (
      questionTextRef &&
      questionBodyDivRef &&
      questionTextRef.current.scrollTop === 0 &&
      shadowBottomRef
    ) {
      shadowBottomRef.current.style.opacity = 0

      return
    }
  }, [_fontSize, questionTextRef, shadowBottomRef])

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <UnsavedChangesModal
          handleHideModal={closeModal}
          handleExit={handleExit}
        />
      </Modal>

      <StyledCustomWrapper>
        {!isNavBarHidden && (
          <StyledExitContainer>
            <StyledExitSpan onClick={handleTriggerModal}>
              {isDebugging ? (
                <p style={{ color: 'red', fontWeight: 'bold' }}>
                  {JSON.stringify(providerState.value, null, 2)}
                </p>
              ) : (
                'Exit Survey'
              )}
            </StyledExitSpan>
          </StyledExitContainer>
        )}

        <QuestionBodyContainer questionComponent>
          <SurveyMediaMainWrapper
            size='xl'
            justifyContent='flex-start'
            id='SurveyMediaMainWrapper'
          >
            {mediaGuid ? (
              <SurveyMedia
                clickedBtnId={clickedBtnId}
                waitingForBtnClick={waitingForBtnClick}
                setUserRecordedVideo={setUserRecordedVideo}
                handleFontSizeIncrease={handleFontSizeIncrease}
                handleFontSizeDecrease={handleFontSizeDecrease}
                fontButtonColors={getFontButtonColors()}
                fontSize={_fontSize}
                userRecordedVideo={userRecordedVideo}
              />
            ) : (
              <RaySpinner color='primary' colorWeight='DEFAULT' />
            )}
          </SurveyMediaMainWrapper>
          <StyledQuestionContentContainer
            ref={questionBodyDivRef}
            className='Question_BODY'
            questionDivHeight={questionDivHeight}
          >
            <StyledHeaderFlexContainer className='body-progress BODY_PROGRESS_TITLE'>
              <StyledQuestionTitle fontSize={_fontSize}>
                {capitalize(question?.name)}
              </StyledQuestionTitle>
              <ProgressBar
                progress={progress}
                isLoading={providerState.matches('loading')}
                newProgressDesign={true}
                _fontSize={_fontSize}
              />
            </StyledHeaderFlexContainer>
            <QuestionContainerBody
              isScrollable={isQuestionTextScrollable}
              className='padding--x-1-at-800 text-left'
              ref={questionBodyDivRef}
              isMobile={isMobile}
            >
              <ShadowTop ref={shadowTopRef} />
              <ShadowBottom
                ref={shadowBottomRef}
                isMobile={isMobile}
                isNotIOS={isMobile && !isAppleDevice}
              />
              <QuestionContainer
                fontSize={_fontSize}
                ref={questionTextRef}
                isMobile={isMobile}
              >
                {parseQuestionText(question?.text ?? '')}
              </QuestionContainer>
            </QuestionContainerBody>
          </StyledQuestionContentContainer>
        </QuestionBodyContainer>
      </StyledCustomWrapper>
    </>
  )
}

export default Question
