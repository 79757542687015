/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-pattern */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import { ActionFunctionMap, assign } from 'xstate'

import { AnswerAction, AnswerType } from '@utils/index'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  assignQuestion: assign({
    question: ({ question }, { data }: any) => ({
      ...(question ?? {}),
      ...data?.question,
    }),
  }),

  assignSelectedAnswerAndAnswerTypeAsVocal: assign({
    selectedAnswer: ({ selectedAnswer }, { payload }: any) => ({
      ...(selectedAnswer ?? {}),
      ...payload,
      answerType: AnswerType.VOCAL,
    }),
  }),
  assignMultipleSelectedAnswers: assign({
    selectedAnswers: ({ selectedAnswers = {} }, { payload }) => {
      if (selectedAnswers?.[payload?.guid]) {
        const { [payload?.guid]: removed, ...restSelectedAnswers } =
          selectedAnswers ?? {}

        return restSelectedAnswers
      }

      return {
        ...(selectedAnswers ?? {}),
        [payload?.guid]: payload,
      }
    },
  }),
  assignSelectedAnswerAndAnswerTypeAsButton: assign({
    selectedAnswer: ({ selectedAnswer }, { payload }: any) => ({
      ...(selectedAnswer ?? {}),
      ...payload,
      answerType: AnswerType.BUTTON,
    }),
  }),

  assignCreatedAnswerResult: assign({
    createdAnswer: ({ createdAnswer }, { payload }: any) => ({
      ...(createdAnswer ?? {}),
      ...payload,
    }),
  }),

  removeCreatedAnswerResource: assign({
    createdAnswer: ({}) => ({}),
  }),

  assignParams: assign({
    params: ({ params }, { payload }: any) => ({
      ...(params ?? {}),
      ...payload,
    }),
  }),

  updateAccessToken: assign({
    accessToken: ({ accessToken }, { payload }) => payload,
  }),

  clearPreviousContext: assign({
    question: ({ question }) => ({}),
    selectedAnswer: ({ selectedAnswer }) => ({}),
    selectedAnswers: ({ selectedAnswers }) => ({}),
    savedAnswer: ({ savedAnswer }) => ({}),
    createdAnswer: ({ createdAnswer }) => ({}),
    failedUploadIds: ({ failedUploadIds }) => ({}),
    reuploadAttempt: ({}) => 0,
    lastQuestionUploaded: ({}) => false,
    isMediaUploaded: ({ isMediaUploaded }) => false,
    isQuestionAlreadyAnswered: ({ isQuestionAlreadyAnswered }) => false,
    isProcessingSaveAnswer: ({ isProcessingSaveAnswer }) => false,
    isProcessingMediaUpload: ({ isProcessingMediaUpload }) => false,
    errorShowTryAgainButton: ({}) => true,
    isMultipleAnswer: ({ isMultipleAnswer }) => false,
    hasMediaRecordingInterrupted: ({ hasMediaRecordingInterrupted }) => false,
  }),

  clearPreviousQuestionContext: assign({
    createdAnswer: ({ createdAnswer }) => ({}),
    question: ({ question }) => ({}),
    selectedAnswer: ({ selectedAnswer }) => ({}),
    selectedAnswers: ({ selectedAnswers }) => ({}),
    savedAnswer: ({ savedAnswer }) => ({}),
    isMediaUploaded: ({ isMediaUploaded }) => false,
    isQuestionAlreadyAnswered: ({ isQuestionAlreadyAnswered }) => false,
    isProcessingSaveAnswer: ({ isProcessingSaveAnswer }) => false,
    isProcessingMediaUpload: ({ isProcessingMediaUpload }) => false,
    errorShowTryAgainButton: ({}) => true,
    isMultipleAnswer: ({ isMultipleAnswer }) => false,
    hasMediaRecordingInterrupted: ({ hasMediaRecordingInterrupted }) => false,
    reuploadAttempt: ({}) => 0,
  }),

  clearExpiredToken: assign({
    accessToken: ({ accessToken }) => '',
  }),

  clearSelectedAnswers: assign({
    selectedAnswer: ({ selectedAnswer }) => ({}),
    selectedAnswers: ({ selectedAnswers }) => ({}),
  }),

  addFailedUploadId: assign({
    failedUploadIds: ({ failedUploadIds }, { payload }: any) => ({
      ...(failedUploadIds ?? {}),
      [payload]: payload,
    }),
  }),

  removeFromFailedUploadIds: assign({
    failedUploadIds: ({ failedUploadIds }, { payload }: any) => {
      const { [payload]: removed, ...restFailedUploadIds } =
        failedUploadIds ?? {}

      return restFailedUploadIds ?? {}
    },
  }),

  incrementReuploadAttempt: assign({
    reuploadAttempt: ({ reuploadAttempt = 0 }) => reuploadAttempt + 1,
  }),

  clearIndexedDBFailedUploads: async () => {},

  updateSitePermissions: assign({
    sitePermissions: ({ sitePermissions = false }, { payload }) =>
      payload?.sitePermissions,
    surveyConfiguration: ({ surveyConfiguration = false }, { payload }) =>
      payload?.surveyConfiguration,
  }),

  setLastQuestionUploadToTrue: assign({
    lastQuestionUploaded: ({ lastQuestionUploaded }) => true,
  }),
  setLastQuestionUploadToFalse: assign({
    lastQuestionUploaded: ({ lastQuestionUploaded }) => false,
  }),

  setIsMediaUploadedToTrue: assign({
    isMediaUploaded: ({ isMediaUploaded }) => true,
  }),
  setIsQuestionAlreadyAnsweredToTrue: assign({
    isQuestionAlreadyAnswered: ({ isQuestionAlreadyAnswered }) => true,
  }),

  // new actions if TRUE will prevent getNextQuestion
  setIsProcessingSaveAnswerToTrue: assign({
    isProcessingSaveAnswer: ({ isProcessingSaveAnswer }) => true,
  }),
  setIsProcessingSaveAnswerToFalse: assign({
    isProcessingSaveAnswer: ({ isProcessingSaveAnswer }) => false,
  }),
  setIsProcessingMediaUploadToTrue: assign({
    isProcessingMediaUpload: ({ isProcessingMediaUpload }) => true,
  }),
  setIsProcessingMediaUploadToFalse: assign({
    isProcessingMediaUpload: ({ isProcessingMediaUpload }) => false,
  }),

  updateRequestProgress: assign({
    requestProgress: (_, { payload }) => payload,
  }),

  resetRequestProgress: assign({
    requestProgress: ({}) => 0,
  }),

  assignSavedAnswer: assign({
    savedAnswer: ({ savedAnswer }, { payload }: any) => ({
      ...(savedAnswer ?? {}),
      ...payload,
      isAlreadySavedAnswer: true,
    }),
  }),

  hideTryAgainButtonOnErrorState: assign({
    errorShowTryAgainButton: ({}) => false,
  }),
  assignErrorMessage: assign({
    errorMessage: ({}, { data }: any) => data,
  }),
  setIsMultipleAnswerToTrue: assign({
    isMultipleAnswer: ({ isMultipleAnswer }) => true,
  }),
  setHasMediaRecordingInterruptedToTrue: assign({
    hasMediaRecordingInterrupted: ({ hasMediaRecordingInterrupted }) => true,
  }),
  setHasMediaRecordingInterruptedToFalse: assign({
    hasMediaRecordingInterrupted: ({ hasMediaRecordingInterrupted }) => false,
  }),

  assignYesAsSelectedAnswer: assign({
    selectedAnswer: ({ question }) => {
      const yesAnswerOption = question?.answerOptionsList?.find((answer) =>
        answer?.name?.toLowerCase()?.includes('yes')
      )

      return {
        ...yesAnswerOption,
        answerType: AnswerType.VOCAL,
      }
    },
  }),

  assignNoAsSelectedAnswer: assign({
    selectedAnswer: ({ question }) => {
      const noAnswerOption = question?.answerOptionsList?.find((answer) =>
        answer?.name?.toLowerCase()?.includes('no')
      )

      return {
        ...noAnswerOption,
        answerType: AnswerType.VOCAL,
      }
    },
  }),

  clearSelectedAnswer: assign({
    selectedAnswer: ({}) => ({}),
  }),
  setShouldRecordAgainToTrue: assign({
    shouldRecordAgain: ({}) => true,
  }),
  setShouldRecordAgainToFalse: assign({
    shouldRecordAgain: ({}) => false,
  }),
  setFirstVideoPlayedToTrue: assign({
    firstVideoPlayed: ({}) => true,
  }),
  setFirstVideoPlayedToFalse: assign({
    firstVideoPlayed: ({}) => false,
  }),
}
