/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'regenerator-runtime/runtime'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactHtmlParser, { processNodes } from 'react-html-parser'
import { Button, Container, Icon } from '@pando-styles/nirvana'

import { StyledHeadingThree, StyledParagraph } from '@styles/index'
import {
  SignatoryRequestDetail,
  Document as DocumentType,
  getRequestDetail,
  getDocument,
  convertHtmlStyleToJSXStyle,
} from '@utils/index'

import { StyledButtonWrapper, StyledSignatureBox } from './styles'
import { BootloaderState } from '@system/Bootloader'

const Document: React.FC = () => {
  const { bootloaderState } = useContext(BootloaderState)

  if (!bootloaderState) return null

  const { accessToken = '' } = bootloaderState?.context ?? {}

  const navigate = useHistory()
  const { signatoryGUID = '', documentGUID = '' } = useParams<{
    signatoryGUID: string
    documentGUID: string
  }>()

  const [signatoryDetails, setSignatoryDetails] = useState<
    Partial<SignatoryRequestDetail.AsObject> | undefined
  >({})
  const [documentDetails, setDocumentDetails] = useState<
    Partial<DocumentType.AsObject> | undefined
  >({})
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<any>(0)

  useEffect(() => {
    const getSignatoryDetails = async () => {
      const result = await getRequestDetail(
        {
          signatoryGuid: signatoryGUID,
        },
        accessToken!
      )

      const resultWithModifiedImage = {
        ...(result?.toObject() ?? {}),
        signatureImage: result?.toObject()?.signatureImage
          ? 'data:image/png;base64, ' + result?.toObject()?.signatureImage
          : '',
      }

      setSignatoryDetails(resultWithModifiedImage)
    }

    getSignatoryDetails()
  }, [signatoryGUID])

  useEffect(() => {
    const getDocumentDetails = async () => {
      const result = await getDocument(
        {
          documentGuid: documentGUID,
        },
        accessToken!
      )

      setDocumentDetails(result?.toObject() ?? {})
    }

    getDocumentDetails()
  }, [documentGUID])

  const modifyRenderedHtml = (html: any) =>
    ReactHtmlParser(html, {
      transform: (node, index) => {
        if (
          node?.attribs?.id?.includes('signature_') &&
          signatoryDetails?.signatureImage
        ) {
          const { style } = node?.attribs as any

          return (
            <div {...node?.attribs} style={convertHtmlStyleToJSXStyle(style)}>
              {processNodes(node?.children, () => {})}
              <img
                className='inserted-signature'
                // @ts-ignore
                src={signatoryDetails?.signatureImage ?? ''}
                alt='signature'
              />
            </div>
          )
        }
      },
    })

  const { documentsList = [] } = signatoryDetails ?? {}

  const next = () => {
    if (documentsList?.length > 0) {
      const i = documentsList
        ?.map((object) => object?.guid)
        .indexOf(documentGUID)
      if (i < documentsList.length - 1) {
        setSelectedDocumentIndex(documentsList[i + 1])
      }
    }
  }

  const back = () => {
    if (documentsList.length > 0) {
      const i = documentsList
        ?.map((object) => {
          if (object && object.guid !== undefined) return object.guid
        })
        .indexOf(documentGUID)
      if (i >= 0 && i < documentsList.length) {
        setSelectedDocumentIndex(documentsList[i - 1])
      }
    }
  }

  const path = navigate?.location?.pathname?.includes('unsigned')
    ? 'unsigned'
    : 'signed'

  useEffect(() => {
    if (documentGUID && selectedDocumentIndex?.guid) {
      if (documentGUID !== selectedDocumentIndex?.guid) {
        navigate.push(
          `/documents/${path}/${signatoryGUID}/${selectedDocumentIndex?.guid}`
        )
      }
    }
  }, [selectedDocumentIndex])

  return (
    <Container centerX direction='column'>
      <StyledButtonWrapper>
        <Button
          label='Back'
          layout='outline'
          onClick={() => navigate.push(`/documents/${path}`)}
          className='borderless-button has-icon video-response-back-btn font-14'
          icon='chevron-left'
        />
      </StyledButtonWrapper>
      <Container direction='column' centerX size='xl'>
        <StyledHeadingThree className='margin--bottom-2'>
          Signed Document
        </StyledHeadingThree>
        <Container direction='row' centerX className='margin-0 align-center'>
          <Icon
            iconName='chevron-left'
            className='chevronLeft'
            clickable
            hoverable
            onClick={back}
            disabled={
              documentsList?.findIndex((item) => item.guid === documentGUID) ===
              0
                ? true
                : false
            }
          />
          <StyledParagraph className='padding--x-1'>
            {documentDetails?.name}
          </StyledParagraph>
          <Icon
            iconName='chevron-right'
            className='chevronRight'
            clickable
            hoverable
            onClick={next}
            disabled={
              documentsList?.findIndex((item) => item.guid === documentGUID) ===
              documentsList?.length - 1
                ? true
                : false
            }
          />
        </Container>
        <StyledSignatureBox className='SIGNATUREBOX_CONTAINER'>
          {modifyRenderedHtml(documentDetails?.renderedHtml ?? '')}
        </StyledSignatureBox>
      </Container>
    </Container>
  )
}

export default Document
