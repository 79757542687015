import React from 'react'
import { RaySpinner } from '@pando-styles/nirvana'
import Bowser from 'bowser'

import {
  StyledProgressBar,
  StyledProgressBarContainer,
  StyledProgressContainer,
  StyledProgressInfo,
  StyledProgressContainerVertical,
  StyledNewProgressInfo,
  ProgressTextInfo,
} from './styles'
import { ProgressBarProps } from './types'

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  isLoading = false,
  showProgressText = true,
  bgColor = '',
  isShownOnSurveyQuestions = true,
  newProgressDesign = false,
  _fontSize,
  progressContainerWidth = 'auto',
  progressColor,
}) => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isMobile = browser.getPlatformType() === 'mobile'

  const ProgressBarInfo = () => (
    <>
      <StyledProgressInfo>
        <span className='float--left margin--bottom-1-2'>Progress</span>
        <span className='float--right relative'>
          {isLoading ? (
            <RaySpinner
              color='primary'
              colorWeight='DEFAULT'
              size={'xs'}
              rayHeight={6}
              rayWidth={4}
              className='custom-rayspinner'
            />
          ) : (
            `${Math.ceil(progress)}%`
          )}
        </span>
      </StyledProgressInfo>
      <StyledProgressBarContainer bgColor={bgColor}>
        <StyledProgressBar progress={progress}></StyledProgressBar>
      </StyledProgressBarContainer>
    </>
  )

  if (!showProgressText) {
    return (
      <StyledProgressContainer progressContainerWidth={progressContainerWidth}>
        <StyledProgressInfo className='w-50' progressColor={progressColor}>
          <span>{`${Math.ceil(progress)}%`}</span>
        </StyledProgressInfo>
        <StyledProgressBarContainer bgColor={bgColor}>
          <StyledProgressBar
            progress={progress}
            progressColor={progressColor}
          ></StyledProgressBar>
        </StyledProgressBarContainer>
      </StyledProgressContainer>
    )
  }

  if (newProgressDesign) {
    return (
      <StyledNewProgressInfo>
        <ProgressTextInfo>
          <span
            className='padding--right-0.5'
            style={{ fontSize: _fontSize ? _fontSize : isMobile ? 14 : 16 }}
          >
            Progress:
          </span>
          <span
            style={{
              fontSize: _fontSize ? _fontSize : isMobile ? 14 : 16,
              paddingLeft: '2px',
              paddingRight: '2px',
            }}
          >
            {isLoading ? (
              <RaySpinner
                color='primary'
                colorWeight='DEFAULT'
                size={'xs'}
                rayHeight={6}
                rayWidth={4}
                className='custom-rayspinner'
              />
            ) : (
              `${Math.ceil(progress)}%`
            )}
          </span>
        </ProgressTextInfo>
        <StyledProgressBarContainer bgColor={bgColor}>
          <StyledProgressBar progress={progress}></StyledProgressBar>
        </StyledProgressBarContainer>
      </StyledNewProgressInfo>
    )
  }

  return isShownOnSurveyQuestions ? (
    <ProgressBarInfo />
  ) : (
    <StyledProgressContainerVertical>
      <ProgressBarInfo />
    </StyledProgressContainerVertical>
  )
}

export default ProgressBar
