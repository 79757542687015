import 'regenerator-runtime/runtime'
import {
  ExecRepeatQuestionRequest,
  SurveyInstanceExecutionServicePromiseClient,
} from '@utils/index'

type Params = ExecRepeatQuestionRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const execRepeatQuestion = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const execRepeatQuestionParams = new ExecRepeatQuestionRequest()
  execRepeatQuestionParams.setAttemptGuid(params.attemptGuid)
  execRepeatQuestionParams.setQuestionGuid(params.questionGuid)

  const result = await service.execRepeatQuestion(execRepeatQuestionParams, {
    authorization: `Bearer ${accessToken}`,
  })
  return result
}
