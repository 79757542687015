/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from 'react'

import { usePando } from '@utils/index'

import { config, options } from './machine'
import {
  ActionsProps,
  ESignStateType,
  ESignActionsType,
  ESignProps,
} from './types'

export const ESignState = createContext<Partial<ESignStateType>>({})
export const ESignActions = createContext<Partial<ESignActionsType>>({})

type PropsWithChildrenOnly = PropsWithChildren<ESignProps>

import { BootloaderState, BootloaderActions } from '@system/Bootloader'
import { MainState } from '@pages/index'

const ESignProvider: React.FC<PropsWithChildrenOnly> = ({
  children,
  ...props
}) => {
  const { bootloaderState } = useContext(BootloaderState)
  const { bootloaderActions } = useContext(BootloaderActions)
  const { providerState: homeProviderState } = useContext(MainState)

  if (!bootloaderState || !bootloaderActions || !homeProviderState) return null

  const { handleTokenExpired } = bootloaderActions ?? {}

  const { accessToken } = bootloaderState?.context ?? {}
  const { signatoryGuid = '', selectedInstance } =
    homeProviderState?.context ?? {}
  const { signatoryGuid: selectedInstanceSignatoryGuid } =
    selectedInstance ?? {}

  const machine = React.useMemo(
    () => ({
      machine: {
        config,
        options,
        context: {},
      },
    }),
    []
  )

  const [state, send] = usePando(machine)

  // OIDC Token Update - Start
  if (!state) return null

  const { accessToken: storedAccessToken } = state.context ?? {}

  useEffect(() => {
    if (storedAccessToken !== accessToken) {
      send({
        type: 'GOT_NEW_ACCESS_TOKEN',
        payload: accessToken,
      })
    }
  }, [accessToken])
  // OIDC Token Update - End

  // If OIDC did not transition to token expired
  // due to component is not rendered
  // at the time the token expired
  useEffect(() => {
    if (state.matches('session_expired')) {
      handleTokenExpired()
    }
  }, [state])
  // end

  const actions: ActionsProps = {
    handleSignDocument: () => {
      send('SIGN_DOCUMENT')
    },
    handleAcknowledgeDocument: () => {
      send('ACKNOWLEDGE_DOCUMENT')
    },
    handleDeclineDocument: () => {
      send('DECLINE_DOCUMENT')
    },
    handleOptInDocument: () => {
      send('OPT_IN_DOCUMENT')
    },
    handleOptOutDocument: () => {
      send('OPT_OUT_DOCUMENT')
    },
    handleClearSignature: () => {
      send('CLEAR_SIGNATURE')
    },
    handleSaveSignature: (image) => {
      send({
        type: 'SAVE_SIGNATURE',
        payload: image,
      })
    },
    handleSwitchToDrawSignature: () => {
      send('SWITCH_TO_DRAW_SIGNATURE')
    },
    handleSwitchToTypeSignature: () => {
      send('SWITCH_TO_TYPE_SIGNATURE')
    },
    handleBack: () => {
      send('BACK')
    },
    handleReload: () => {
      send('RELOAD')
    },
    handleReset: () => {
      send('RESET')
    },
    handleProceed: () => {
      send('PROCEED')
    },
    handleNewSignatory: (signatoryGuid) => {
      send({
        type: 'GOT_NEW_SIGNATORY_GUID',
        payload: signatoryGuid,
      })
    },
  }

  return (
    <ESignState.Provider
      value={{
        providerState: state,
        providerProps: props,
      }}
    >
      <ESignActions.Provider
        value={{
          providerActions: actions,
        }}
      >
        {children}
      </ESignActions.Provider>
    </ESignState.Provider>
  )
}
export default ESignProvider
