/* eslint-disable indent */
import 'regenerator-runtime/runtime'
import {
  SurveyInstanceExecutionServicePromiseClient,
  CreateSurveyAttemptDeviceDetailRequest,
  BrowserDetail,
  LocationCoordinates,
  OSDetail,
  PlatformDetail,
  EngineDetail,
} from '@utils/index'

import Browser from 'bowser'

type Params = CreateSurveyAttemptDeviceDetailRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const createSurveyAttemptDeviceDetail = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceExecutionServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const newParamRequest = new CreateSurveyAttemptDeviceDetailRequest()
  const locationCoordinates = new LocationCoordinates()
  const browserDetails = new BrowserDetail()
  const osDetails = new OSDetail()
  const platformDetails = new PlatformDetail()
  const engineDetail = new EngineDetail()
  const browser = Browser.getParser(navigator.userAgent)

  newParamRequest.setSurveyAttemptGuid(params.surveyAttemptGuid)
  newParamRequest.setIsLocationPermissionGranted(
    params.isLocationPermissionGranted
  )
  newParamRequest.setUserAgent(navigator.userAgent)

  browserDetails.setBrowserName(browser.getBrowserName())
  browserDetails.setBrowserVersion(browser.getBrowserVersion())
  newParamRequest.setBrowserDetail(browserDetails)

  platformDetails.setPlatformVendor(browser.getPlatform()?.vendor ?? '')
  platformDetails.setPlatformType(browser.getPlatformType())
  newParamRequest.setPlatformDetail(platformDetails)

  if (browser.getPlatformType() === 'desktop') {
    osDetails.setOsVersionName(browser.getOS().versionName!)
  }

  if (browser.getPlatformType() === 'mobile') {
    if (browser.getPlatform().model === 'iPhone') {
      platformDetails.setPlatformModel(browser.getPlatform().model!)
    } else {
      const enclosedWords = navigator.userAgent.match(/\(([^()])*\)/g)
      const platformData = enclosedWords![0].split(';')
      const _platformModel = platformData[2].slice(0, -1)
      platformDetails.setPlatformModel(_platformModel)
    }
  }

  osDetails.setOsName(browser.getOSName())
  osDetails.setOsVersion(browser.getOSVersion())
  newParamRequest.setOsDetail(osDetails)

  engineDetail.setEngineName(browser.getEngine().name ?? '')
  engineDetail.setEngineVersion(browser.getEngine().version ?? '')
  newParamRequest.setEngineDetail(engineDetail)

  const getIPResponse = await fetch('https://ipapi.co/json/')
  const data = await getIPResponse.json()
  newParamRequest.setIpAddress(data.ip)

  if (params.isLocationPermissionGranted) {
    locationCoordinates.setLatitude(data.latitude.toString()),
      locationCoordinates.setLongitude(data.longitude.toString())
    newParamRequest.setLocationCoordinates(locationCoordinates)
  }

  const result = await service.createSurveyAttemptDeviceDetail(
    newParamRequest,
    {
      authorization: `Bearer ${accessToken}`,
    }
  )
  return result
}
