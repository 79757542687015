/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'regenerator-runtime/runtime'

// @ts-ignore
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import {
  SurveyInstanceDataServicePromiseClient,
  GetInstanceShortListRequest,
} from '@utils/index'

type Params = Partial<GetInstanceShortListRequest.AsObject>

const { REACT_APP_QUERY_HOST = '', REACT_APP_QUERY_PORT } = process.env

export const getIncompleteSurveyInstances = async (
  params: Params,
  accessToken: string
) => {
  const service = new SurveyInstanceDataServicePromiseClient(
    `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
  )

  const payload = new GetInstanceShortListRequest()

  payload.setPage(params?.page ?? 0)
  payload.setResultsPerPage(params?.resultsPerPage ?? 0)

  const startDateTimeStamp = new Timestamp()
  const endDateTimeStamp = new Timestamp()

  if (params?.start || params?.end) {
    if (isNaN(params?.start) || isNaN(params?.start)) return

    startDateTimeStamp.fromDate(new Date(params?.start))
    endDateTimeStamp.fromDate(new Date(params?.end))

    payload.setStart(startDateTimeStamp)
    payload.setEnd(endDateTimeStamp)
  }

  const result = await service.getIncompleteSurveyInstances(payload, {
    authorization: `Bearer ${accessToken}`,
  })

  return result
}
