/* eslint-disable arrow-body-style */
import React from 'react'

import {
  StyledWrapperBodyContainer,
  StyledHeadingTwo,
  StyledOrderedList,
  StyledParagraph,
  StyledFlexContainer,
} from '@styles/index'

import { StyledPrivacyPolicyContainer } from './styles'

export interface PrivacyPolicyProps {
  handleHideModal: () => void
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ handleHideModal }) => {
  return (
    <StyledPrivacyPolicyContainer className='padding--x-3 padding--y-2 p-2-at-600 w-1000 vh-85 bg-white overflow-y'>
      <StyledFlexContainer
        justifyContext='flex-end'
        className='margin--bottom-2'
      >
        <span
          className='teal cursor-pointer font-weight-400 font-16 close-btn'
          onClick={handleHideModal}
        >
          Close
        </span>
      </StyledFlexContainer>
      <StyledWrapperBodyContainer size='xl' flex={false}>
        <StyledHeadingTwo className='margin--bottom-2'>
          Privacy Policy of Pando Holdings, LLC
        </StyledHeadingTwo>
        <div className='margin--bottom-2 padding--bottom-4'>
          <StyledParagraph className='margin--bottom-3'>
            Effective: January 1, 2018
          </StyledParagraph>
          <StyledOrderedList>
            <li>
              <StyledParagraph fontSize={16}>
                Some Definitions Pando Holdings respects your privacy. Within
                this Privacy Policy (the “Privacy Policy”) the following terms
                shall have the definitions specified.
              </StyledParagraph>

              <StyledParagraph fontSize={16}>
                “Pando Holdings,” “Pando,” or “we” (and related pronouns) means
                Pando Holdings, LLC, and its subsidiaries and affiliates that
                own and operate websites and Internet services on its behalf.
                “Customer” and “you” (and related pronouns) means you as a user
                of the Pando websites and services as described below.
                “Personally Identifiable Information” or “PII” means to
                information that identifies a specific person, such as the
                person’s name, address, social security number, financial
                account number, and telephone number. “Non-Personally
                Identifiable Information” or “non-PII” means information that is
                demographic, aggregated, or does not otherwise identify a
                specific person, such as a person’s gender, ZIP code, or other
                information when that information is not used with Personally
                Identifiable Information.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Scope of this Privacy Policy This Privacy Policy explains how we
                will use information you provide when you use certain websites,
                mobile applications, and other interactive features that link to
                this Privacy Policy, including, but not limited to the website,
                pandolink. com; all mobile applications that link to this
                Privacy Policy such as Facebook, Instagram, Twitter, and such
                applications like those listed, and any software or services
                provided by Pando, including those services made available
                through its alarm security services.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                If any Pando Web Services contains a link to a third party
                website, those websites are not operated by Pando and have their
                own privacy policies you should read before using that site.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Information We Collect Pando routinely logs Non-PII that is
                automatically generated when any user visits or uses the Pando
                Web Services (including both the Pando Sites and Pando Apps), as
                a by-product of the user’s activities (“Web Log Data”). This
                information includes, but is not limited to, Internet protocol
                (“IP”) and HTTP header information that is automatically passed
                between a user’s device and the Pando Web Services, such as the
                device’s IP address, the browser being used at the time, the
                operating system being used at the time, the UDID of the device
                (if the device being used to access the Pando Web Services is a
                mobile device), and the URL of the webpage or other location
                within the Pando Web Services being requested. This information
                may also include details relating to the activities users
                perform within the Pando Web Services, such as what links they
                click on, in what order they access specific web pages within
                the Pando Web Services, and search queries they perform on the
                Pando Web Services.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando asks its Customers to provide certain information when
                subscribing to Pando Web Services, and may ask you to provide
                additional information when you use certain Pando Web Services
                for the first time, such as your first and last name, email
                addresses, mailing address, telephone number, and gender. In
                addition, Pando also collects information about your use of the
                Pando Web Services as a registered user. For example, when you
                log-in to our use your account of the Pando Web Services by
                flagging or rating news articles, movies, television shows or
                other content, posting blog entries or comments, sharing URLs,
                and content and data feeds from non-Pando social networks or
                services that you choose. This table summarizes the different
                types of information that we may collect from you when you
                register for the Pando Web Services and use the Pando Web
                Services as a registered user. Your Registration Data and
                Activity Data, and any other generally available demographic
                data about you we gather from other sources are referred to
                collectively as “Your Data”. Your Data does not include
                information third parties may independently gather from you from
                using other websites, by use of cookies, or otherwise.
              </StyledParagraph>

              <StyledParagraph fontSize={16}>Referral Service</StyledParagraph>
              <StyledParagraph fontSize={16}>
                If you choose to use our referral service to tell a friend about
                the Pando Web Services, we will ask you for your friend’s name
                and email address. We will automatically send your friend a
                one-time email inviting him or her to visit the Pando Website.
                We store this information and may use it for the sole purpose of
                sending this email offers to our and tracking the success of our
                referral program.
              </StyledParagraph>

              <StyledParagraph fontSize={16}>
                User Access and Choice
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                You may review and update your PII by emailing us at
                marketing@Pandolink. com and verifying your identity. If you’d
                like us to help you remove any of your PII that we have
                previously collected through the Pando Websites, please contact
                us. We will respond to your request within 30 days. In some
                cases, we may not be able to remove your PII, in which case we
                will let you know if we are unable to do so and why.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We will retain Your Data for as long as your account is active
                or as needed to provide you with the Pando Web Services, or as
                otherwise necessary to help us improve our products and
                services, comply with our legal obligations, resolve disputes,
                and enforce our agreements with you.
              </StyledParagraph>

              <StyledParagraph fontSize={16}>
                Tracking Technologies and Pando’s Data Collection
              </StyledParagraph>

              <StyledParagraph fontSize={16}>
                Pando, and its marketing partners and service providers, may use
                technologies such as cookies, beacons, tags, and scripts to
                collect information as further described in this Privacy Policy.
                A cookie is a small file stored on a user’s computer. Among
                other things, cookies enable Pando Web Services to store
                Preferences Data, as described in this Privacy Policy. For
                example, cookies enable our website to remember information
                about your browser such preferred zip code, and IP address.
                Cookies can also hold information like an anonymous but unique
                string of numbers and letters Pando may use to help it collect
                Activity Data, as further described in this Privacy Policy.
              </StyledParagraph>

              <StyledParagraph fontSize={16}>
                The Pando Web Services may use different types of cookies, like
                HTTP cookies (sometimes referred to as “browser cookies”), Flash
                cookies (sometimes referred to as “Flash LSOs”), and HTML5 local
                storage (sometimes referred to as “LS” or “LS cookies”). For
                more information on controlling HTTP cookies and these cookies,
                please see Section 8, below.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Use of Information Pando uses Web Log Data, Preferences Data,
                and Your Data, including both PII and Non-PII in both aggregated
                and individual forms, to provide the Pando Web Services and
                support them. We may process and use this data for purposes
                consistent with this Privacy Policy including, but not limited
                to:
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Customize, measure, and improve the Pando Web Services, and the
                content and advertising provided through them;
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Enforce our Web Services Terms of Service and detect fraud and
                other illegal activities;
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Bill and collect applicable fees or charges;
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Let you know about new products or services from Pando or other
                companies we work with as well as promotional offers, based on
                your preference settings;
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Let you know about changes to the Pando Web Services, the Web
                Services Terms of Service, this Privacy Policy, and other terms
                that apply to our services; and
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Update any other tools or information we provide in connection
                with Pando Web Services.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We may use Web Log Data, Preferences Data, and Your Data in
                these forms for our internal business purposes, such as
                determining how much traffic certain portions of the Pando Web
                Services receive, for license reporting and assessment of
                service levels, to better understand how the Pando Web Services
                are used, to gauge traffic patterns, to determine what types of
                content and services are most popular with users of the Pando
                Web Services, to determine which search queries on certain Pando
                Web Service are the most popular, or track the path of our
                customers. Pando may also use Web Log Data, Preferences Data,
                and Your Data to customize the Pando Web Services and make them
                more relevant to you. Pando may use your Registration Data to
                verify you are the person authorized to use the Pando Web
                Services and to manage your account.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Sharing of Information
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando does not share your PII with any third party, except as
                required by law.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Online Tracking Policy
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Some of the third-party service providers that Pando uses to
                deliver services, content, and advertising on the Pando Web
                Services may collect information from those services, as
                disclosed in this Privacy Policy. This information may include
                PII or may be used to contact you online. As stated in this
                Privacy Policy, in the sections 7 and 8 below, we and our
                service providers may also use cookies to deliver relevant
                advertising to you when you visit other websites, including
                advertising based on the products and services you viewed on the
                Pando Web Services.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Advertisements purchased by Pando
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando, or third parties acting at Pando’s request, may deliver
                advertisements to you through the Pando Web Services and other
                websites, depending on whether you are a registered user or not,
                as described in more detail below.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We, or our advertising providers, automatically deliver ads to
                all users on the Pando Web Services, whether the users are
                registered or not, based on non-PII including: (i) the IP
                address associated with your device for purposes of determining
                your approximate geographic location; (ii) the type of web page
                being displayed, e. g. news or sports page; or (iii) the content
                on the page shown, e. g. a sports article or movie review.
                Because this advertising activity automatically applies to all
                users and it is contextual, this type of advertising delivery
                cannot be customized or controlled by individual users.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We may also use one or more advertising networks and/or other
                audience segmenting technology providers to help select and
                deliver: advertisements purchased by Pando on the Pando Web
                Services and other websites, other content on the Pando Web
                Services, and Pando-purchased advertisements on other websites.
                These providers help us deliver advertisements or content
                tailored to interests you have shown by the way you interact
                with the Pando Web Services, and other websites and web-based
                content containing their technology, such as opening an
                HTML-formatted email that contains their web beacon. This is
                intended to provide you with an opportunity to look at
                advertising that may be of interest to you. These providers may
                collect and use data subject to their own privacy policies, not
                Pando’s Web Services Privacy Policy. If you do not want the
                benefits of the data collected by these providers, you may
                opt-out here:
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Ad Choices for Registered Users
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                If you are a registered user, Pando, or service providers acting
                at Pando’s request, may use Your Data, regardless of where we
                gathered such information, to determine what type of ad to
                display to you on the Pando Web Services and other websites. For
                example, Pando may use portions of Your Data that it gathered on
                Facebook and Pandolink. com, to determine which ad to show you
                when you visit that site. In addition, some of the graphical
                display, text, and other ads sold or purchased by Pando on the
                Pando Web Services and other websites are customized for you
                based on the ZIP code or other geographic data of your Pando Web
                Services, information about your current subscription, or use of
                Pando products and services. If you do not want this information
                to be used to serve you targeted ads, you may opt out by
                emailing marketing@Pandolink. com. Please note this does not opt
                you out of being served advertising. You will continue to
                receive generic advertisements.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Advertising Networks and Advertising Sold By Content Providers
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                The advertising networks and/or content providers that deliver
                ads on the Pando Web Services may use technologies such as HTTP
                cookies, Flash cookies, and HTML5 local storage to uniquely
                distinguish your web browser and keep track of information
                relating to serving ads on your web browser, such as the type of
                ads shown and the web pages on which the ads appeared. In
                addition, the third parties that provide Web Log services for
                Pando may use cookies to distinguish your web browser and to
                keep track of the websites your web browser visits on the
                service provider’s network of websites. Cookie management tools
                provided by your browser may not affect Flash cookies. To learn
                how to manage privacy and storage settings for HTML5 local
                storage, you may refer to the end user documentation for your
                browser.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Some of these companies may combine information they collect in
                connection with the Pando Web Services with other information
                they have independently collected relating to your web browser’s
                activities across their network of websites. These companies
                collect and use this information (which may include PII or
                information that can be used to contact you online) under their
                own privacy policies. More information about these companies,
                their privacy policies, and the opt-outs they offer can be found
                here.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Communicating with You
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando may use your Registration Data to send you a confirmation
                email verifying the ownership of the email addresses provided in
                your Registration Data and to send you service-related
                communications about the Pando Web Services. Pando may send you
                promotional or commercial email relating to each Pando Web
                Services for which you have activated your account. You can
                opt-out from receiving such promotional or commercial email by
                following the instructions contained in the emails.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>Security</StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando uses industry standard approaches to securely store,
                control access to, and make appropriate use of Your Data, and we
                require our contractors and other providers to do so as well.
                However, no security measure is completely effective and Pando
                cannot guarantee the complete security of Your Data. If you
                don’t want Pando to know any particular information about you,
                you should not register to use the Pando Web Services or include
                it in anything that you submit to Pando.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando also takes additional steps to increase the security and
                reliability of customer communications. We do not read your
                outgoing or incoming email, file attachments, video mail,
                private chat, or instant messages. However, we (along with our
                third-party providers) use software and hardware tools to help
                prevent and block “spam” emails, viruses, spyware, and other
                harmful or unwanted communications and programs from being sent
                and received. These tools may automatically scan your emails,
                video mails, instant messages, file attachments, and other files
                and communications in order to help us protect you and the Web
                Services against these harmful or unwanted communications and
                programs.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We follow generally-accepted standards to protect the
                information submitted to us, both during transmission and after
                we receive it. Please remember that no method of transmission of
                any information over the Internet, or method of electronic
                storage, is 100% secure. Therefore, we cannot guarantee data
                security absolutely or unconditionally. If you have any
                questions about security on the Pando Web Services, you can
                contact us.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                As an additional security precaution, neither Pando nor any of
                our authorized service providers will ever ask you for your
                Pando Web Services password in any email, over the telephone, or
                in an online support forum or chat. Do not give your Pando Web
                Services password to anyone in an email, over the telephone, or
                in an online support from any source.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Disclosure of Information
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando holds customer privacy in the highest regard and we make
                every reasonable effort to protect your privacy as described in
                this Privacy Policy. Nevertheless, we may be required by law to
                disclose PII or other information about you or other users of
                Pando Web Services. Such disclosure may be made with or without
                your consent, and with or without notice to you, in compliance
                with the terms of a subpoena, court order, search warrant, or
                other valid legal process. We may also disclose information
                about you or users of Pando Web Services when we believe in good
                faith the disclosure of information is necessary to prevent
                financial loss, address suspected illegal activity, protect our
                or your rights or property, or prevent imminent physical harm.
                In addition, Pando may disclose your PII or other information if
                Pando enters into a sale of its business or assets (see Section
                14, below).
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Our Sites are Not Intended for Children
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Through the Pando Web Services, Pando does not knowingly collect
                PII from anyone under the age of 13. Children should always seek
                permission from a parent or legal guardian before sending any
                information about themselves (such as their names, email
                addresses, and telephone numbers) over the Internet including in
                any email.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Your California Privacy Rights
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                California law permits its residents to request and receive
                information about a business’ disclosure of certain categories
                of PII to other companies for their use for direct marketing. If
                you are a California resident and a user of any of the Pando Web
                Services, you can request a copy of this information from Pando
                by sending email to marketing@Pandolink. com or by letter to
                Pando Holdings, LLC, Attn: Marketing Department, 450 N. 1500 W.
                Orem, UT 84057. You should include your name and email address
                with email requests, and your name and postal address with in
                mail requests.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Change of Pandos Ownership
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                If Pando enters into a merger, acquisition, or the sale of
                substantially all its assets, Your Data (including your PII and
                non-PII associated with the Pando Web Services) will likely be
                part of the assets transferred. If you are a Customer or
                subscriber, we will notify you of any such transaction that
                transfers Your Data.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                Privacy Policy Changes
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Pando reserves the right to change this Privacy Policy from time
                to time. If we make material changes to it, and you are a
                Customer or subscriber, we will make a copy of the updated
                Privacy Policy available to you before it takes effect. We may
                notify you by email, direct mail, or any other reasonable method
                we may select. You understand and agree that if you use the
                Pando Web Services after the effective date of the updated
                Privacy Policy, Pando will consider your use as acceptance of
                the updated Privacy Policy.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                EU and Other International Users and Subscribers
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Our computer systems are based in the United States, and your
                PII will be processed by us in USA. Applicable data protection
                and privacy regulations may be different outside of USA. If you
                create an online account to use the Pando Sites as a visitor
                from outside the United States, you will have agreed to the
                terms of the Privacy Policy and our Web Services Terms of
                Service and you will have consented to the transfer to and
                processing of all such information in the United States, which
                many not offer an equivalent level of protection of other
                countries, such as the European Union or certain other
                countries.
              </StyledParagraph>
            </li>
            <li>
              <StyledParagraph fontSize={16}>
                How to Contact Customer Support
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                Your questions regarding this Privacy Policy may be sent to us
                by email to marketing@Pandolink. com. You may also write to us
                at Pando Holdings, LLC, Attn: Marketing Department, 450 N 1500 W
                Orem, UT 84057. You should include your name and email address
                with email requests, and your name and postal address with in
                mail requests.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                You can find customer support and help information at
                https://pandolink.com. Some online support areas and tools may
                ask you to provide information to Pando to help us address your
                questions or problems.
              </StyledParagraph>
              <StyledParagraph fontSize={16}>
                We may also make software tools available to help configure your
                device’s or network’s settings, or collect information from your
                device or network to help us provide support to you. If you
                choose to provide this information to Pando, or use these tools,
                we will use the information only for customer support purposes.
              </StyledParagraph>
            </li>
          </StyledOrderedList>
        </div>
      </StyledWrapperBodyContainer>
    </StyledPrivacyPolicyContainer>
  )
}

export default PrivacyPolicy
