/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime'
import {
  SurveyBetaServicePromiseClientExecution as SurveyInstanceExecutionServicePromiseClient,
  ExecClaimInstanceRequest,
  setRequest,
} from '@utils/index'

type Params = ExecClaimInstanceRequest.AsObject

const {
  REACT_APP_QUERY_HOST = 'https://staging.pandolink.com',
  REACT_APP_QUERY_PORT = '443',
} = process.env

export const execClaimInstance = async (
  params: Params,
  accessToken: string
) => {
  try {
    const service = new SurveyInstanceExecutionServicePromiseClient(
      `${REACT_APP_QUERY_HOST}:${REACT_APP_QUERY_PORT}`
    )

    console.log(
      `@DEBUG [execClaimInstance]: Executing execClaimInstance from queries with params: ${JSON.stringify(
        params
      )}`
    )

    const request = setRequest(new ExecClaimInstanceRequest(), params)

    const result = await service.execClaimInstance(request, {
      authorization: `Bearer ${accessToken}`,
    })

    console.log(
      `@DEBUG [execClaimInstance]: Executing execClaimInstance from queries with result: ${JSON.stringify(
        result?.toObject()
      )}`
    )

    return result
  } catch (error) {
    console.log(
      '@DEBUG [execClaimInstance]: Error at execClaimInstance request.',
      error
    )

    // in case of other errors, this is thrown here regardless of isSuccess' value
    throw error
  }
}
