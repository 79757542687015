/* eslint-disable indent */
import styled from 'styled-components'

export const StyledMicTestContainer = styled.div<{
  show: boolean
  isRecording: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  canvas {
    width: 100%;
  }

  .playback-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 10px 0;
  }

  .record-button {
    margin-top: 10px;
  }

  #waveform {
    width: 200px;
  }

  .react-mic-div {
    display: ${({ show = true }) => (show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic-icon {
      transform: scale(2);
      margin-bottom: ${({ isRecording = true }) =>
        isRecording ? '20px' : '0'};
    }

    wave {
      width: 100%;
    }

    .visible {
      visibility: visible;
    }

    .not-visible {
      visibility: hidden;
    }
  }
`

export const StyledWaveDiv = styled.div<{ isRecording: boolean }>`
  /* display: ${({ isRecording = false }) =>
    isRecording ? 'none' : 'block'}; */
`
