/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'
import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  assignInstance: assign({
    selectedInstance: ({ selectedInstance, instanceGuid }, { data }: any) => ({
      ...selectedInstance,
      ...data,
    }),
  }),
  assignSurveyConfiguration: assign({
    surveyConfiguration: ({ surveyConfiguration }, { data }: any) => ({
      ...surveyConfiguration,
      ...data,
    }),
  }),

  removeSelectedInstance: assign({
    selectedInstance: ({ selectedInstance }) => undefined,
  }),

  assignFacialRecognitionResults: assign({
    hasFaceInImage: (_, { payload }) => payload,
  }),

  assignSitePermissions: assign({
    sitePermissions: ({ sitePermissions }, { payload }) => payload,
  }),

  updateAccessToken: assign({
    accessToken: ({ accessToken }, { payload }) => payload,
  }),

  assignClaimInstance: assign({
    claimedInstance: ({}) => {
      console.log('@DEBUG [Survey Main]: Claimed Instance.')

      return true
    },
  }),
  assignClaimSignatory: assign({
    claimedSignatory: ({}) => true,
  }),
  assignClaimInstanceAndSignatoryToFalse: assign({
    claimedInstance: ({}) => false,
    claimedSignatory: ({}) => false,
  }),

  clearInstanceGuidAndClaimCode: assign((context) => {
    console.log('@DEBUG [Survey Main]: Clearing instanceGuid and claimCode')

    return {
      ...context,
      claimCode: '',
      instanceGuid: '',
    }
  }),

  updateUrlParams: assign((context, { payload }) => {
    console.log(
      `@DEBUG [Survey Main]: Update updateUrlParams: ${JSON.stringify(payload)}`
    )
    return {
      ...context,
      ...payload,
    }
  }),
  assignErrorMessage: assign({
    errorMessage: ({}, { payload }: any) => payload,
    showTryAgainButton: ({}) => true,
  }),
  assignShowTryAgainButton: assign({
    showTryAgainButton: ({}, { showTryAgainButton }: any) => showTryAgainButton,
  }),
  setAllowedRecordingToTrue: assign({
    isAllowedRecording: ({}) => true,
  }),
  setAllowedRecordingToFalse: assign({
    isAllowedRecording: ({}) => false,
  }),
}
