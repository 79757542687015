/* eslint-disable indent */
import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #eaecf0;
  flex-direction: row;

  &.landing-page {
    .outer-container {
      height: 100vh;
    }
  }

  @media screen and (max-width: 1200px) {
    &.landing-page .outer-container {
      padding: 3rem 1rem;
    }
  }
  @media screen and (max-width: 762px) {
    &.landing-page {
      flex-direction: column;
      height: 100%;

      .outer-container {
        height: 100vh;
      }
    }
  }

  .outer-container.branding {
    background: #eaf5f5;

    .branding-container {
      background-color: transparent;
    }
  }
`

export const StyledHomeContainer = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  width: 860px;
  max-width: 90%;
  height: 88vh;
  z-index: 1000;
  border-radius: 0.375rem;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  transition: all 0.3s linear;

  @media screen and (max-height: 900px) {
    max-width: 95%;
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    max-width: 100%;
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

export const StyledFullHeightContainer = styled.div<{
  flexDirection?: 'row' | 'column'
}>`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
`

export const StyledWrapper = styled.div<{
  fullWidth?: boolean
}>`
  position: relative;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    padding: 0;
  }
`

export const StyledWrapperBody = styled.div<{
  flex?: boolean
  ySet?: boolean
  textCenter?: boolean
  height?: string
  questionComponent?: boolean
  esignComponent?: boolean
}>`
  position: relative;
  min-height: ${({ questionComponent = false, esignComponent = false }) =>
    questionComponent ? '100vh' : esignComponent ? '100%' : '50vh'};
  max-height: ${({ questionComponent = false, esignComponent = false }) =>
    questionComponent ? '100vh' : esignComponent ? '100%' : '100vh'};
  display: ${({ flex = true }) => (flex ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: ${({ textCenter = true }) => (textCenter ? 'center' : 'unset')};
  height: 100%;
  width: 100%;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: black;
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    top: 0;
    left: 0;
    right: 0;
    min-height: ${({ questionComponent = false, esignComponent = false }) =>
      questionComponent ? '90vh' : esignComponent ? '100%' : '50vh'};
    max-height: ${({ questionComponent = false, esignComponent = false }) =>
      questionComponent ? '90vh' : esignComponent ? '100%' : '100vh'};
    position: ${({ questionComponent = false }) =>
      questionComponent ? 'absolute' : 'relative'};
  }
`

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const StyledWrapperBodyContainer = styled.div<{
  size?: Sizes
  flex?: boolean
  justifyContent?: 'flex-start' | 'center'
  overflowY?: 'auto' | 'unset'
}>`
  display: ${({ flex = true }) => (flex ? 'flex' : 'block')};
  flex-direction: column;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  height: 100%;
  overflow-y: ${({ overflowY = 'unset' }) => overflowY};

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    justify-content: center;
  }
`

export const StyledWrapperHeader = styled.div<{ height?: string }>`
  position: relative;
  min-height: ${({ height = '12vh' }) => height};
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9999;
  margin: 0 auto;

  .absolute-exit {
    position: absolute;
    left: -20px;
    top: 0;
  }

  @media screen and (max-width: 762px) {
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    padding: 0 1rem;

    .absolute-exit {
      position: relative;
      left: 0;
      top: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 600px) {
  }
`

export const StyledWrapperFooter = styled.div<{
  bottom?: string
  questionComponent?: boolean
  positionMobileView?: 'fixed' | 'absolute'
}>`
  position: absolute;
  bottom: ${({ bottom = '2rem' }) => bottom};
  right: 5rem;
  left: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #fff;

  button {
    width: 170px;
  }

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    min-height: ${({ questionComponent = false }) =>
      questionComponent ? '30vh' : '0'};
    max-height: 30vh;
    right: 1rem;
    left: 1rem;
    position: ${({ positionMobileView = 'fixed' }) => positionMobileView};
  }
`

export const StyledWrapperFooterContainer = styled.div<{
  flexDirection?: 'row' | 'column'
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 100%;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
`

export const StyledFlexContainer = styled.div<{
  flexDirection?: 'row' | 'column'
  justifyContext?: 'center' | 'flex-start' | 'flex-end'
  alignItems?: 'center' | 'flex-start' | 'flex-end'
}>`
  display: flex;
  justify-content: ${({ justifyContext = 'center' }) => justifyContext};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  gap: 5px;
  width: 100%;
  position: relative;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
`

export const StyledModalContainer = styled.div<{
  flexRow?: boolean
  width?: number
  height?: number
  padding?: string
  textAlign?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: ${({ flexRow = false }) => (flexRow ? 'center' : 'flex-start')};
  background: #fff;
  flex-direction: ${({ flexRow = false }) => (flexRow ? 'row' : 'column')};
  padding: ${({ padding = '0 2rem' }) => padding};
  box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px -1px 34px 8px rgba(0, 0, 0, 0.2);
  width: ${({ width = 860 }) => width}px;
  max-width: 100%;
  height: ${({ height }) => (height ? `${height}vh` : 'auto')};
  z-index: 1000;
  border-radius: 0.375rem;
  margin: 0 auto;
  position: relative;
  padding: 5rem;
  text-align: center;

  @media screen and (max-width: 900px) {
    & {
      max-width: 90%;
    }
  }

  @media screen and (max-width: 600px) {
    & {
      padding: 2rem;
    }
  }

  animation: 0.1s fadeIn ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const StyledOrderedList = styled.ol`
  & {
    padding-left: 1.2rem !important;
  }

  li {
    color: #000000;
    font-weight: 400;
    padding: 5px 0;
  }
`

export const StyledImage = styled.img`
  max-width: 100%;
`
export const StyledExitContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #f8f8f8;
  padding-left: 3rem;
  align-items: center;
  height: 3rem;
  display: flex;
  border-bottom: solid #d0d5dd 1px;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin-top: 0;
  }
`

export const StyledExitSpan = styled.span`
  cursor: pointer;
  color: #2c969b;
  z-index: 2;

  @media screen and (max-width: 800px), screen and (max-height: 800px) {
    position: fixed;
    left: 1rem;
  }
`
