/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useRef, useEffect } from 'react'

export const useEventListener = (
  eventName: string,
  functionToCall: any,
  element: any
) => {
  const savedFunction = useRef()

  useEffect(() => {
    savedFunction.current = functionToCall
  }, [functionToCall])

  useEffect(() => {
    if (!element) return
    const eventListener = (event: any) =>
      savedFunction &&
      savedFunction.current !== null &&
      // @ts-ignore
      savedFunction?.current?.(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
