/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionFunctionMap, assign } from 'xstate'

import { Context, MachineEvents } from '../types'

export const actions: ActionFunctionMap<Context, MachineEvents> = {
  assignFacialRecognitionResults: assign({
    hasFaceInImage: (_: any, { payload }: any) => payload,
  }),

  setHasFaceInImageToFalse: assign({
    hasFaceInImage: ({}) => false,
  }),

  updateAccessToken: assign({
    accessToken: ({}, { payload }: any) => payload,
  }),
}
