import { v4 as uuidv4 } from 'uuid'

export const tabItems = {
  config: {
    is_enabled: true,
    max_item: 4,
  },
  routes: [
    {
      tab_name: 'Surveys',
      application_id: `${uuidv4()}`,
      is_enabled: true,
      route_name: 'list',
    },
    {
      tab_name: 'Documents',
      application_id: `${uuidv4()}`,
      is_enabled: true,
      route_name: 'documents',
    },
  ],
}
