import React from 'react'

import BootloaderProvider from './BootloaderProvider'
import BootloaderConsumer from './BootloaderConsumer'

export * from './BootloaderProvider'
export * from './BootloaderConsumer'

const Bootloader: React.FC = (props) => (
  <BootloaderProvider {...props}>
    <BootloaderConsumer />
  </BootloaderProvider>
)

export default Bootloader
