import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  grid-gap: 0.4rem;
  svg {
    width: 100%;
  }
`

export const StyledLink = styled(NavLink)`
  /* font-size: ${({ theme }) => theme.text.size.sm.fontSize}!important; */
  color: ${({ theme }) => theme.colors.primary.DEFAULT};
`

export const Styledh2 = styled.h2`
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary[300]};
`

export const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.dark[100]};
`

export const StyledHeroBranding = styled.section`
  width: 100%;
  height: 500px;
  /* background: url('/images/pando-branding.svg') center center no-repeat !important;
  background-size: contain; */
`

export const StyledImg = styled.img`
  width: 100%;
`
